import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { IUserSubscription } from '../../pages/subscriptions/Subscription'; // Make sure this path is correct and updated
import { useToast } from '../../components/ToastProvider';
import axios from '../../../lib/axios';

interface LocationState {
  subscription_id: string;
}

const UserSubscription: React.FC = () => {
  let navigate = useNavigate();
  const location = useLocation();

  const [subscription, setSubscription] = useState<IUserSubscription | null>(null);

  const handleView = (subscription_id: string): void => {
    navigate(`/subscriptions/subscription-request-view`, { state: { subscription_id } });
  };

  const { showError, showSuccess, showInfo, showWarning } = useToast();

  const getSubscription = async (subscription_id: string): Promise<IUserSubscription> => {
    const url = `/api/subscription/show/${subscription_id}`; // Fixed URL construction
    const response = await axios.get<IUserSubscription>(url); // Ensure axios is typed correctly
    return response.data;
  };

  useEffect(() => {
    const fetchData = async () => {
      const subscription_id = (location.state as LocationState)?.subscription_id;
      if (subscription_id) {
        const subscriptionData = await getSubscription(subscription_id);
        setSubscription(subscriptionData);
      }
    };

    fetchData();
  }, [location.state]);

  return (
    <>
      {
        !subscription ? (
          <div className="alert alert-info" role="alert">
            No subscription found.
          </div>
        ) : (
          <>
            <div>
              <button onClick={() => showError('This is an error!')}>Show Error</button>
              <button onClick={() => showSuccess('Operation successful!')}>Show Success</button>
              <button onClick={() => showInfo('Here is some info.')}>Show Info</button>
              <button onClick={() => showWarning('Warning!')}>Show Warning</button>
            </div>
            <div className="card">
              <div className="card-body">
                <h5 className="card-title">{subscription.id}</h5>
                <p className="card-text">{subscription.description || 'No description available.'}</p>
                <p className="card-text">Subscription Status: {subscription.status}</p>
              </div>
            </div>
            {subscription.histories && subscription.histories.length > 0 && (
              <div className="card mt-4">
                <div className="card-body">
                  <h5 className="card-title">Subscription History</h5>
                  {subscription.histories.map((history) => (
                    <div key={history.id}>
                      <p className="card-text">Event Type: {history.eventType}</p>
                      <p className="card-text">Description: {history.description || 'No description provided.'}</p>
                      <p className="card-text">Event Date: {history.eventDate}</p>
                    </div>
                  ))}
                </div>
              </div>
            )}
          </>
        )
      }
    </>
  );
};

export default UserSubscription;
