import { useEffect, useState, useCallback } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { useForm, Controller } from 'react-hook-form';
import { Form, Button } from 'react-bootstrap';
import axios from '../../../lib/axios';
import { useToast } from '../../components/ToastProvider';
import { useAuth } from '../../../hooks/auth';
import Slider from 'react-slick';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Select from 'react-select';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import LoadingSpinner from '../../../_metronic/helpers/components/LoadingSpinner';
import { PageLink, PageTitle } from '../../../_metronic/layout/core';

import PeriodDropDownAutoPopulate from './components/PeriodDropDownAutoPopulate';

type LocationState = {
  subscriptionRequestId: string
}

const requestBreadCrumb: Array<PageLink> = [
  {
    title: 'All Subscription Requests',
    path: '/subscriptions/admin-subscription-request',
    isSeparator: false,
    isActive: false,
  },
  {
    title: 'Edit Subscription Request',
    path: '/subscriptions/edit-subscription-request',
    isSeparator: true,
    isActive: false,
  },
];

interface Product {
  id: number;
  name: string;
  description: string;
  type: string;
  active?: number;
  price: number;
  quantity: number;
  created_at: string;
  updated_at: string;
  prices?: Price[];
  features?: Feature[];
}

interface Price {
  id: number;
  product_id: number;
  amount: number; // Fix: Change the type from string to number
  currency: string;
  default_price: number;
  active: number;
  created_at: string;
  updated_at: string;
  deleted_at: string | null;
}

interface Feature {
  id: number;
  product_id: number;
  feature_name: string;
  feature_value: string;
  active: number;
  created_at: string;
  updated_at: string;
  deleted_at: string | null;
}




const AdminEditSubscriptionRequest = () => {
  const slider_settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    draggable: true
  };



  const { loading, user } = useAuth('auth');
  const location = useLocation();
  const navigate = useNavigate();

  const [
    productLoaded, setProductLoaded
  ] = useState(false);
  const [products, setProducts] = useState<Product[]>([]);

  const [initialData, setInitialData] = useState<any>({});


  const [formState, setFormState] = useState('update');
  const [isLoading, setIsLoading] = useState(false);
  const [isLoadingProducts, setIsLoadingProducts] = useState(false);
  const [isLoadingRequest, setIsLoadingRequest] = useState(false);
  const [isLoaded, setIsLoaded] = useState(false);
  const [error, setError] = useState(null);
  const [subscription_period, setSubscriptionPeriod] = useState<string>('');
  const [product_list, setProductList] = useState<{ value: number; label: string; }[]>([]);
  const [subscriptionRequestStatus, setSubscriptionRequestStatus] = useState<string>('');
  const [selectedProducts, setSelectedProducts] = useState<Product[]>([]);
  const subscriptionRequestId = (location.state as LocationState)?.subscriptionRequestId
  const { register, handleSubmit, setValue, control, reset, formState: { errors } } = useForm(



  );
  const handleSelectProduct = product => {
    const index = selectedProducts.findIndex(p => p.id === product.id);
    const updatedSelectedProducts = [...selectedProducts];
    if (index >= 0) {
      updatedSelectedProducts.splice(index, 1);
    } else {
      updatedSelectedProducts.push(product);
    }
    setSelectedProducts(updatedSelectedProducts);
    setValue('product_list', updatedSelectedProducts.map(p => ({ value: p.id, label: p.name })));
  };
  const onSelectProduct = (product: Product) => {
    handleSelectProduct(product);
  };


  const handleApproveRequest = (id: string) => {
    //navigate 
    navigate('/subscriptions/approve-subscription-request', { state: { subscriptionRequestId: id } });
  };

  const {
    showInfo,
    showSuccess,
    showError } = useToast();



  // Custom validation for checking the length and non-empty content
  const validateQuillContent = (htmlString) => {
    const text = htmlString.replace(/<[^>]+>/g, '').trim(); // Remove HTML tags and trim
    return text.length > 0 && text.length <= 1000 || 'Content must be between 1 and 1000 characters long without HTML tags';
  };
  const fetchProducts = useCallback(async () => {
    try {
      setIsLoadingProducts(true);
      const response = await axios.get('/api/products');
      if (response.status === 200) {
        setProducts(response.data);
        return response.data;  // Return the products directly.
      } else {
        throw new Error('Failed to fetch products');
      }
    } catch (err: any) {
      setError(err?.message);
      throw err;
    } finally {
      setIsLoadingProducts(false);
    }
  }, []);

  const fetchData = useCallback(async (products) => {
    try {
      setIsLoadingRequest(true);
      const response = await axios.post(`/api/subscription-requests/get-request`, { id: subscriptionRequestId });
      if (response.status === 200 && response.data.status === 'Success') {
        const dataForm = response.data.request;
        setSubscriptionRequestStatus(dataForm.status);
        if (dataForm && products) {
          if (dataForm.product_list) {
            const productListArray = dataForm.product_list.split(',');
            const dataProductArray = productListArray.map(id => id.trim());
            const formattedProducts = products.filter(product => dataProductArray.includes(product.id.toString())).map(product => ({
              value: product.id,
              label: product.name
            }));
            setValue('product_list', formattedProducts);
            setInitialData({
              ...dataForm,
              product_list: formattedProducts,
            });
            reset({
              ...dataForm,
              product_list: formattedProducts,
            });
          }
        } else {
          throw new Error('Failed to fetch subscription request data');
        }
      }
    } catch (err: any) {
      setError(err?.message);
      throw err;
    } finally {
      setIsLoadingRequest(false);
    }
  }, [subscriptionRequestId, setValue, reset]);

  const initializeData = useCallback(async () => {
    if (isLoaded) return;
    setIsLoading(true);
    try {
      const fetchedProducts = await fetchProducts(); // Await the products to be fetched directly.
      await fetchData(fetchedProducts); // Pass the fetched products directly to fetchData.
      setIsLoaded(true);
    } catch (error) {
      showError('Initialization failed');
    } finally {
      setIsLoading(false);
    }
  }, [fetchProducts, fetchData, showError, isLoaded]);

  useEffect(() => {
    ; (
      async () => {
        if (subscriptionRequestId) {
          initializeData();
        } else {
          showError('Invalid subscription request ID');
          navigate('/subscriptions/admin-subscription-request');
        }
      })()
  },
    [
      subscriptionRequestId,
      initializeData,
      navigate,
      showError
    ]

  );


  if (error) {
    return <div>Error: {error}</div>;
  }

  const handleReset = () => {
    reset(initialData);  // Reset to initial fetched values
  };

  const handleClearForm = () => {
    reset({
      name: '',
      email: '',
      phone: '',
      product_list: [],
      agreeToTerms: false,
      organization_name: '',
      organization_phone: '',
      organization_email: '',
      organization_address: '',
      organization_website: '',
      subscription_period: ''

    });
  };

  const onSubmit = async (formData) => {
    setIsLoading(true);
    try {
      //add another field
      formData.id = subscriptionRequestId;
      const response = await axios.put(`/api/subscription-requests/update`, formData);
      if (response.status === 200) {
        showSuccess('Subscription request updated successfully');
        navigate('/subscriptions/admin-subscription-request');
      } else {
        showError('Error updating subscription request');
      }
    } catch (error) {
      showError('Error updating subscription request');
      console.error('Error:', error);
    } finally {
      setIsLoading(false);
    }
  };

  if (isLoading && loading) {
    return (
      <LoadingSpinner />
    );
  }
  const Title = () => {
    return (
      'Edit  Subscription Requests'
    );

  };
  // Form layout with pre-filled values
  return (
    <>
      <PageTitle breadcrumbs={requestBreadCrumb}>{Title()}</PageTitle>
      <div className="container py-4">
        {isLoading ? (<div className="d-flex justify-content-center">
          <div className="spinner-border" role="status">
            <span className="visually-hidden">Loading...</span>
          </div>
        </div>) : (
          <>
            <h1>Edit Request #:{subscriptionRequestId}</h1>

            {isLoadingProducts ? (
              <LoadingSpinner />
            ) : (
              <>
                <Slider {...slider_settings}>
                  {products.map(product => (
                    <div key={product.id} className="card card-custom gutter-b">
                      <div className="card-header">
                        <div className="card-title">
                          <h3 className="card-label">{product.name}</h3>
                        </div>
                        <div className="card-toolbar">
                          <button
                            type="button"
                            className="btn btn-sm btn-info font-weight-bold"
                            onClick={() => onSelectProduct(product)}
                          >
                            <i className="fa-solid fa-bolt"></i> Select
                          </button>
                        </div>
                      </div>
                      <div className="card-body">
                        Price: {product.price} {product.price?.[0]?.currency || 'MYR'}
                        <table>
                          <thead>
                            <tr>
                              <th>Name</th>
                              <th>Features Included</th>
                            </tr>
                          </thead>
                          <tbody>
                            {product.features?.map((feature: any) => (
                              <tr key={feature.id}>
                                <td>{feature.feature_name}</td>
                                <td>
                                  {feature.feature_value === 'Yes' ? (
                                    <span style={{ color: 'green' }}>
                                      <i className="fa-solid fa-circle-check"></i>
                                    </span>
                                  ) : (
                                    <span style={{ color: 'red' }}>
                                      <i className="fa-solid fa-circle-xmark"></i>
                                    </span>
                                  )}
                                </td>
                              </tr>
                            ))}
                          </tbody>
                        </table>
                      </div>
                    </div>
                  ))}
                </Slider>
                <Form onSubmit={handleSubmit(onSubmit)}>
                  {/** if product list not null, preselect */}
                  <Form.Group className="mb-3">
                    <Form.Label>Product List</Form.Label>
                    <Controller
                      name="product_list"
                      defaultValue={product_list}
                      control={control}
                      rules={{ required: 'Please select at least one product.' }}
                      render={({ field, fieldState: { error } }) => (
                        <Select
                          {...field}
                          options={products.map(product => ({ value: product.id, label: product.name }))}
                          isMulti
                          className={error ? 'is-invalid' : ''}
                        />
                      )}
                    />
                    {errors.product_list && <>{errors.product_list.message}</>}
                  </Form.Group>
                  <PeriodDropDownAutoPopulate control={control} preselectedValue={subscription_period} />

                  <Form.Group className="mb-3">
                    <Form.Label>Name</Form.Label>
                    <Form.Control
                      type="text"
                      {...register('name', { required: true, value: user?.name })}
                      isInvalid={!!errors.name}
                    />
                    <Form.Control.Feedback type="invalid">
                      Name is required.
                    </Form.Control.Feedback>
                  </Form.Group>

                  <Form.Group className="mb-3">
                    <Form.Label>Email</Form.Label>
                    <Form.Control
                      type="email"
                      {...register('email', {
                        required: true,
                        pattern: /^\S+@\S+$/i,
                        value: user?.email
                      })}
                      isInvalid={!!errors.email}
                    />
                    <Form.Control.Feedback type="invalid">
                      Please enter a valid email.
                    </Form.Control.Feedback>
                  </Form.Group>

                  <Form.Group className="mb-3">
                    <Form.Label>Telephone</Form.Label>
                    <Form.Control
                      type="tel"
                      {...register('phone', {
                        required: true,
                        pattern: /^\+?(?:\d{1,3})?[-. (]?\d{3}[-. )]?\d{3}[-. ]?\d{4}$/
                      })}
                      isInvalid={!!errors.phone}
                    />
                    <Form.Control.Feedback type="invalid">
                      Please enter a valid telephone number please use Malaysia phone number.
                    </Form.Control.Feedback>
                  </Form.Group>

                  <Form.Group className="mb-3">
                    <Form.Label>Organization</Form.Label>
                    <Form.Control
                      type="text"
                      {
                      ...register('organization_name',
                        {
                          required: true,
                          minLength: 3,
                          maxLength: 255

                        }


                      )}
                      isInvalid={!!errors.organization}

                    />
                    <Form.Control.Feedback type="invalid">
                      Please enter a organization name.
                    </Form.Control.Feedback>
                  </Form.Group>

                  <Form.Group className="mb-3">
                    <Form.Label>Organization Telephone</Form.Label>
                    <Form.Control
                      type="tel"
                      {...register('organization_phone',
                        { required: true, pattern: /^\+?(?:\d{1,3})?[-. (]?\d{3}[-. )]?\d{3}[-. ]?\d{4}$/ })}
                      isInvalid={!!errors.organization_phone}
                    />
                    <Form.Control.Feedback type="invalid">
                      Please enter a valid organization telephone number please use Malaysia phone number.
                    </Form.Control.Feedback>
                  </Form.Group>



                  <Form.Group className="mb-3">
                    <Form.Label>Organization Address</Form.Label>
                    <Form.Control
                      type="text"
                      {
                      ...register('organization_address',
                        {
                          required: true,
                          minLength: 3,
                          maxLength: 255

                        }
                      )}
                      isInvalid={!!errors.organization_address}
                    />
                    <Form.Control.Feedback type="invalid">
                      Please enter a organization address.
                    </Form.Control.Feedback>
                  </Form.Group>
                  {
                    /** organization_address */

                  }

                  <Form.Group className="mb-3">
                    <Form.Label>Organization Website</Form.Label>
                    <Form.Control
                      type="text"
                      {
                      ...register('organization_website',
                        {
                          required: true,
                          minLength: 3,
                          maxLength: 255,
                          pattern: /^(http|https):\/\/[^ "]+$/


                        }
                      )}
                      isInvalid={!!errors.organization_website}
                    />
                    <Form.Control.Feedback type="invalid">
                      Please enter a organization website must include https://.
                    </Form.Control.Feedback>
                  </Form.Group>
                  <Form.Group className="mb-3">
                    <Form.Label>Organization Note</Form.Label>
                    <Controller
                      name="organization_note"
                      control={control}

                      rules={{ validate: validateQuillContent }}
                      render={({ field, fieldState: { error } }) => (
                        <>
                          <ReactQuill
                            theme="snow"
                            {...field}
                          />
                          {error && <p style={{ color: '#EA868F' }}>{error.message}</p>}
                        </>
                      )}
                    />
                  </Form.Group>








                  <Form.Group className="mb-3">
                    <Form.Check
                      type="checkbox"
                      label="I agree to the terms and conditions"
                      {...register('agreeToTerms', { required: true })}
                      isInvalid={!!errors.agreeToTerms}
                      feedback="You must agree before submitting."
                      feedbackType="invalid"
                    />
                  </Form.Group>
                  {
                    (user?.role === 'Super Admin' && subscriptionRequestStatus === 'pending') ? (
                      <>
                        <Button variant="primary" type="submit" disabled={isLoading}>
                          {isLoading ? 'Updating...' : 'Update'}
                        </Button>

                        <Button variant="secondary" onClick={handleReset}>
                          Reset Initial Data
                        </Button>
                        <Button variant="danger" onClick={handleClearForm}>
                          Clear Form
                        </Button>

                        <Button variant="success" onClick={() => handleApproveRequest(subscriptionRequestId)}>
                          Approve Request
                        </Button>
                        <Button variant="warning" onClick={() => handleApproveRequest(subscriptionRequestId)}>
                          Approve Request
                        </Button>
                      </>
                    ) : <p className='text-info'> Subscription Request status is {subscriptionRequestStatus} it is cannot be editable anymore</p>
                  }
                </Form>
              </>
            )}
          </>
        )}

      </div>
    </>
  );
};

export default AdminEditSubscriptionRequest;
