import { Navigate, Route, Routes } from 'react-router-dom'
import { MasterLayout } from '../../_metronic/layout/MasterLayout'
import { DashboardWrapper } from '../pages/dashboard/DashboardWrapper'
// import {MenuTestPage} from '../pages/MenuTestPage'
// import BuilderPageWrapper from '../pages/layout-builder/BuilderPageWrapper'
import { AuthPage } from '../pages/login/AuthPage'
import { MyPage } from '../pages/mypage/MyPage'
import { SmeModel } from '../pages/smemodel/SmeModel'
import { SmeModelCompute } from '../pages/smemodel/SmeModelCompute'
import { SmeModelComputeEx } from '../pages/smemodel/SmeModelComputeEx'
import SmeModelResult from '../pages/smemodel/SmeModelResult'
import { UserSettings } from '../pages/user/UserSettings'
import VerifyEmail from '../pages/login/components/VerifyEmail'
import VerifiedEmail from '../pages/login/components/VerifiedEmail'
import LinkExpired from '../pages/login/components/LinkExpired'
import Subscribe from '../pages/subscriptions/Subscribe';
import Subscriptions from '../pages/subscriptions/Subscriptions'
import SubscriptionRequestView from '../pages/subscriptions/SubscriptionRequestView'
import UserSubscription from '../pages/subscriptions/UserSubscription'
import SubscriptionRequestList from '../pages/subscriptions/SubscriptionRequestList'
import AdminViewAllSubscriptionRequestPage from '../pages/subscriptions/AdminViewAllSubscriptionRequestPage'
import UserSubscriptionRequestsPage from '../pages/subscriptions/UserSubscriptionRequestsPage'
import UserEditSubscriptionRequest from '../pages/subscriptions/UserEditSubscriptionRequest'
import SubscriptionRequestApprovePage from '../pages/subscriptions/SubscriptionRequestApprovePage'
import UserViewCompanySubscription from '../pages/subscriptions/UserViewCompanySubscription'
import AdminCreateSubscriptionRequest from '../pages/subscriptions/AdminCreateSubscriptionRequest'
import AdminEditSubscriptionRequest from '../pages/subscriptions/AdminEditSubscriptionRequest'
import AdminViewSubscriptionRequest from '../pages/subscriptions/AdminViewSubscriptionRequest'
import AdminEditSubscription from '../pages/subscriptions/AdminEditSubscription'
import AdminAllSubscriptions from '../pages/subscriptions/AdminAllSubscriptions'
import AdminViewSubscription from '../pages/subscriptions/AdminViewSubscription'
import AdminCreateSubscription from '../pages/subscriptions/AdminCreateSubscription'
import CompaniesPage from '../pages/companies/CompaniesPage'
import AdminAllCompanies from '../pages/companies/AdminAllCompanies'
import AdminViewCompany from '../pages/companies/AdminViewCompany'
import UserAllCompanies from '../pages/companies/UserAllCompanies'
import UserViewCompany from '../pages/companies/UserViewCompany'
import UserEditCompany from '../pages/companies/UserEditCompany'

import View from '../pages/subscriptions/View'

import SubscriptionPage from '../pages/subscriptions/SubscriptionPage'
import AdminCreateCompany from '../pages/companies/AdminCreateCompany'
import AdminEditCompany from '../pages/companies/AdminEditCompany'
import AdminUserList from '../pages/user/AdminUserList'
import AdminAddUser from '../pages/user/AdminAddUser';
import AdminEditUser from '../pages/user/AdminEditUser';
import AdminViewUser from '../pages/user/AdminViewUser';

import AdminAllProduct from '../pages/products/AdminAllProduct';
import AdminAddProduct from '../pages/products/AdminAddProduct';
import AdminEditProduct from '../pages/products/AdminEditProduct';
import AdminViewProduct from '../pages/products/AdminViewProduct';

import AdminAllInternalUser from '../pages/user/AdminAllInternalUser';
import AdminAddInternalUser from '../pages/user/AdminAddInternalUser'
import AdminViewInternalUser from '../pages/user/AdminViewInternalUser'



import { CorporateModel } from '../pages/corporatemodel/CorporateModel'
import { CorporateModelBursa } from '../pages/corporatemodel/CorporateModelBursa'
import CorporateModelCompute from '../pages/corporatemodel/CorporateModelCompute'
import CorporateModelResult from '../pages/corporatemodel/CorporateModelResult'
import { CorporateModelBursaMidTier } from '../pages/corporatemodel/CorporateModelBursaMidTier'
import { CorporateModelMu } from '../pages/corporatemodel/CorporateModelMu'

const PrivateRoutes = () => {
  // const ProfilePage = lazy(() => import('../modules/profile/ProfilePage'))
  // const WizardsPage = lazy(() => import('../modules/wizards/WizardsPage'))
  // const AccountPage = lazy(() => import('../modules/accounts/AccountPage'))
  // const WidgetsPage = lazy(() => import('../modules/widgets/WidgetsPage'))
  // const ChatPage = lazy(() => import('../modules/apps/chat/ChatPage'))
  // const UsersPage = lazy(() => import('../modules/apps/user-management/UsersPage'))

  return (
    <Routes>
      <Route path='auth/*' element={<AuthPage />} />
      <Route path='verify-email' element={<VerifyEmail />} />
      <Route path='verified-email' element={<VerifiedEmail />} />
      <Route path='link-expired' element={<LinkExpired />} />
      <Route element={<MasterLayout />}>
        {/* Redirect to Dashboard after success login/registartion */}
        {/* Pages */}
        <Route path='dashboard' element={<DashboardWrapper />} />

        <Route path='/my-page' element={<MyPage />} />
        <Route path='/sme-model' element={<SmeModel />} />
        <Route path='/sme-model/compute' element={<SmeModelCompute />} />
        <Route path='/sme-model/compute-ex' element={<SmeModelComputeEx />} />
        <Route path='/sme-model/result' element={<SmeModelResult />} />
        <Route path='/subscriptions/subscribe' element={<Subscribe />} />
        <Route path='/subscriptions/subscriptions' element={<Subscriptions />} />
        <Route path='/subscriptions/subscription-request-view' element={<SubscriptionRequestView />} />
        <Route path='/subscriptions/user-subscription' element={<UserSubscription />} />
        <Route path='/subscriptions/admin-subscription-request' element={<AdminViewAllSubscriptionRequestPage />} />
        <Route path='/subscriptions/user-subscription-request' element={<UserSubscriptionRequestsPage />} />
        <Route path='/subscriptions/edit-subscription-request' element={<UserEditSubscriptionRequest />} />
        <Route path='/subscriptions/approve-subscription-request' element={<SubscriptionRequestApprovePage />} />
        <Route path='/subscriptions/admin-create-subscription-request' element={<AdminCreateSubscriptionRequest />} />
        <Route path='/subscriptions/admin-view-subscription-request' element={<AdminViewSubscriptionRequest />} />
        <Route path='/subscriptions/admin-edit-subscription-request' element={<AdminEditSubscriptionRequest />} />
        <Route path='/subscriptions/user-view-company-subscription' element={<UserViewCompanySubscription />} />


        <Route path='/subscriptions/admin-all-subscriptions' element={<AdminAllSubscriptions />} />
        <Route path='/subscriptions/admin-create-subscription' element={<AdminCreateSubscription />} />
        <Route path='/subscriptions/admin-edit-subscription' element={<AdminEditSubscription />} />
        <Route path='/subscriptions/admin-view-subscription' element={<AdminViewSubscription />} />

        <Route path='/subscriptions/user-view-subscription-request' element={<View />} />

        <Route path='/subscriptions' element={<SubscriptionPage />} />

        <Route path='/companies' element={<CompaniesPage />} />
        <Route path='/companies/admin-all-companies' element={<AdminAllCompanies />} />
        <Route path='/companies/admin-view-company' element={<AdminViewCompany />} />
        <Route path='/companies/admin-create-company' element={<AdminCreateCompany />} />
        <Route path='/companies/admin-edit-company' element={<AdminEditCompany />} />

        <Route path='/companies/user-all-companies' element={<UserAllCompanies />} />
        <Route path='/companies/user-view-company' element={<UserViewCompany />} />
        <Route path='/companies/user-edit-company' element={<UserEditCompany />} />


        {
          /** admin user */
        }
        <Route path='/user' element={<AdminUserList />} />
        <Route path='/user/admin-all-user' element={<AdminUserList />} />
        <Route path='/user/admin-add-user' element={<AdminAddUser />} />
        <Route path='/user/admin-edit-user' element={<AdminEditUser />} />
        <Route path='/user/admin-view-user' element={<AdminViewUser />} />
        <Route path='/user/admin-all-internal-user' element={<AdminAllInternalUser />} />
        <Route path='/user/admin-add-internal-user' element={<AdminAddInternalUser />} />
        <Route path='/user/admin-view-internal-user' element={<AdminViewInternalUser />} />
        {
          /* products */}
        <Route path='/product' element={<AdminAllProduct />} />
        <Route path='/product/admin-all-product' element={<AdminAllProduct />} />
        <Route path='/product/admin-add-product' element={<AdminAddProduct />} />
        <Route path='/product/admin-edit-product' element={<AdminEditProduct />} />
        <Route path='/product/admin-view-product' element={<AdminViewProduct />} />

        {
          /* subscriptions requests */
        }
        <Route path='/subscriptions/subscription-request-list' element={<SubscriptionRequestList />} />

        <Route path='/corporate-model-main' element={<CorporateModel />} />
        <Route path='/corporate-model-bursa' element={<CorporateModelBursa />} />
        <Route path='/corporate-model-mu' element={<CorporateModelMu />} />
        <Route path='/corporate-model-mid-tier' element={<CorporateModelBursaMidTier />} />

        <Route path='/corporate-model-main/compute' element={<CorporateModelCompute />} />
        <Route path='/corporate-model-bursa/compute' element={<CorporateModelCompute />} />
        <Route path='/corporate-model-mu/compute' element={<CorporateModelCompute />} />
        <Route path='/corporate-model-mid-tier/compute' element={<CorporateModelCompute />} />

        <Route path='/corporate-model-bursa/result' element={<CorporateModelResult />} />
        <Route path='/corporate-model-mu/result' element={<CorporateModelResult />} />
        <Route path='/corporate-model-mid-tier/result' element={<CorporateModelResult />} />


        <Route path='/user/settings' element={<UserSettings />} />
        {/* Page Not Found */}
        <Route path='*' element={<Navigate to='/error/404' />} />
      </Route>
    </Routes>
  )
}

export { PrivateRoutes }
