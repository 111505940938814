import React, { useCallback, useEffect, useState } from 'react';
import { useAuth } from '../../../hooks/auth';
import { useToast } from '../../components/ToastProvider';
import axios from '../../../lib/axios';
import LoadingSpinner from '../../../_metronic/helpers/components/LoadingSpinner';
import { KTSVG } from '../../../_metronic/helpers/components/KTSVG';
import { useLocation, useNavigate } from 'react-router-dom';
import { PageLink, PageTitle } from '../../../_metronic/layout/core';
import { Modal, Button, Table } from 'react-bootstrap';
import { Company, Subscription, Member } from './Companies';
import { confirm } from '../../../../src/app/modules/confirmation/BasicConfirmation';

type LocationState = {
  companyId: string;
};

interface User {
  id: string;
  name: string;
  email: string;
  roles: [{ permissions: { name: string }[] }];
  company_id: string;
  subscription: Subscription;
  role: string;
  is_organization?: boolean;
  is_personal?: boolean;
  created_at: string;
  updated_at: string;
  mode: 'personal' | 'organization';
  company?: string;
  limit: Limit;
  usage: Usage;
  check_limit: Boolean;
}

interface Limit {
  limit: number;
  is_limit_exceeded: boolean;
}

interface Usage {
  usage: number;
  is_limit_exceeded: boolean;
}

const companyBreadCrumbs: Array<PageLink> = [
  {
    title: 'All Companies',
    path: '/companies/user-all-companies',
    isSeparator: false,
    isActive: false,
  },
  {
    title: 'Company Details',
    path: '/companies/user-view-company',
    isSeparator: true,
    isActive: false,
  },
];

const UserViewCompany: React.FC = () => {

  const location = useLocation();
  const companyId = (location.state as LocationState)?.companyId;
  const navigate = useNavigate();
  const { loading } = useAuth('auth');
  const { showError } = useToast();

  const [company, setCompany] = useState<Company | null>(null);
  const [dataLoading, setDataLoading] = useState<boolean>(false);
  const [members, setMembers] = useState<Member[]>([]);
  const [allUsers, setAllUsers] = useState<User[]>([]);



  const fetchData = useCallback(() => {
    setDataLoading(true);
    axios
      .post('/api/companies/show', { company_id: companyId })
      .then((response) => {
        setCompany(response.data);
        setMembers(response.data.members || []);
      })
      .catch((error) => showError('Failed to fetch company details'))
      .finally(() => setDataLoading(false));
  }, [companyId, showError]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);



  function handleEditNavigation(companyId: number) {
    navigate('/companies/user-edit-company', { state: { companyId: companyId } });
  }

  const Title = () => (company?.name ? `Company Details for ${company.name}` : 'Company Details');

  return (
    <>
      <PageTitle breadcrumbs={companyBreadCrumbs}>{Title()}</PageTitle>
      <div className="app-main flex-column flex-row-fluid" id="k">
        <div className="d-flex flex-column flex-column-fluid">
          <div className="card-toolbar mb-5">
            <a href="/companies/user-all-companies" className="btn btn-sm btn-light-primary me-2">
              <KTSVG path="/media/icons/duotune/arrows/arr070.svg" className="svg-icon-2" />
              Back to List
            </a>
            <Button variant="light-system" className="btn btn-sm" onClick={fetchData}>
              <i className="fa fa-refresh"></i>
              Refresh
            </Button>
            <Button className="btn btn-sm btn-warning ms-2" onClick={() => handleEditNavigation(Number(companyId))}>
              <i className="fa fa-edit"></i>
              Edit Company
            </Button>
          </div>
          {(loading || dataLoading) ? <LoadingSpinner /> : (
            <>
              <div className="row gy-5 g-xl-10">
                <div className="col-xl-4 mb-xl-10">
                  <div className="card card-flush h-xl-100">
                    <div className="card-header">
                      <h3 className="card-title">
                        <i className="fa-solid fa-shop bg-light-info"></i>&nbsp;&nbsp;&nbsp;{Title()}
                      </h3>
                    </div>
                    <div className="card-body">
                      {company ? (
                        <div>
                          <p><strong>ID:</strong> {company.id}</p>
                          <p><strong>Name:</strong> {company.name}</p>
                          <p><strong>Address:</strong> {company.address}</p>
                          <p><strong>Email:</strong> {company.email}</p>
                          <p><strong>Phone:</strong> {company.phone}</p>
                          <p><strong>Website:</strong> {company.website}</p>
                          <p><strong>Note:</strong> <span dangerouslySetInnerHTML={{ __html: company?.note?.toString() ?? "" }} /></p>
                          <p><strong>Status:</strong> {company.status}</p>
                        </div>
                      ) : (
                        <p>No company details available.</p>
                      )}
                    </div>
                  </div>
                </div>
                <div className="col-xl-8">
                  <div className="card card-flush h-xl-100">
                    <div className="card-header">
                      <h4 className="card-title">Subscriptions</h4>
                    </div>
                    <div className="card-body">
                      {company?.subscriptions?.length ? (
                        <Table striped bordered hover>
                          <thead>
                            <tr>
                              <th>Subscription ID</th>
                              <th>Status</th>
                              <th>Usage</th>
                            </tr>
                          </thead>
                          <tbody>
                            {company.subscriptions.map((subscription) => (
                              <tr key={subscription.id}>
                                <td>{subscription.id}</td>
                                <td>{subscription.status.toString()}</td>
                                <td>
                                  {subscription.subscription_usage?.map((usage) => (
                                    <div key={usage.id}>
                                      <p><strong>Usage:</strong> {usage.usage}</p>
                                      <p><strong>Limit Exceeded:</strong> {usage.is_limit_exceeded?.toString() ?? 'No'}</p>
                                      <p><strong>Is Default? :{subscription.company_subscription?.[0]?.is_default?.toString() === '0' ? 'No' : 'Yes'}</strong></p>
                                    </div>
                                  ))}
                                </td>
                              </tr>
                            ))}
                          </tbody>
                        </Table>
                      ) : (
                        <p>No subscriptions available.</p>
                      )}
                    </div>
                  </div>
                </div>
                <div className="col-xl-12">
                  <div className="card card-flush h-xl-100">
                    <div className="card-header">
                      <h3 className="card-title">Members</h3>
                    </div>
                    <div className="card-body">
                      {members.length ? (
                        <Table striped bordered hover>
                          <thead>
                            <tr>
                              <th>ID</th>
                              <th>Name</th>
                              <th>Email</th>
                              <th>Role</th>
                            </tr>
                          </thead>
                          <tbody>
                            {members.map((member) => (
                              <tr key={member.id}>
                                <td>{member.id}</td>
                                <td>{member.user_name}</td>
                                <td>{member.user_email}</td>
                                <td>{member.role}</td>
                              </tr>
                            ))}
                          </tbody>
                        </Table>
                      ) : (
                        <p>No members available.</p>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </>
          )}
        </div>
      </div >
    </>
  );
};

export default UserViewCompany;
