import Axios from 'axios'


const axios = Axios.create({
  baseURL: process.env.REACT_APP_BACKEND_URL,
  headers: {
    'X-Requested-With': 'XMLHttpRequest'
  },
  withCredentials: true
})

// Add a response interceptor
axios.interceptors.response.use(
  response => response,
  error => {

    // Handle error here
    console.error('An error occurred:', error)
    return Promise.reject(error)
  }
)

export default axios