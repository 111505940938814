import React, { useEffect, useState, useMemo } from 'react';
import { useForm, Controller } from 'react-hook-form';
import { Button, Form } from 'react-bootstrap';
import { useToast } from '../../components/ToastProvider';
import DataHandler from '../../util/dataHandler';
import Slider from 'react-slick';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Select from 'react-select';
import { useAuth } from '../../../hooks/auth';
import PeriodDropDown from './components/PeriodDropDown';
import { Link, useNavigate } from 'react-router-dom'
import { InfoMessage, SuccessMessage, WarningMessage, ErrorMessage } from '../../components/messageAlertComponents';
import axios from '../../../lib/axios';

const slider_settings = {
  dots: true,
  infinite: true,
  speed: 500,
  slidesToShow: 3,
  slidesToScroll: 1,
  draggable: true
};



interface Product {
  id: number;
  name: string;
  description: string;
  type: string;
  active?: number;
  price: number;
  quantity: number;
  created_at: string;
  updated_at: string;
  prices?: Price[];
  features?: Feature[];
}

interface Price {
  id: number;
  product_id: number;
  amount: number; // Fix: Change the type from string to number
  currency: string;
  default_price: number;
  active: number;
  created_at: string;
  updated_at: string;
  deleted_at: string | null;
}

interface Feature {
  id: number;
  product_id: number;
  feature_name: string;
  feature_value: string;
  active: number;
  created_at: string;
  updated_at: string;
  deleted_at: string | null;
}
interface SubscriptionOptions {
  [key: string]: string; // Any string key with a string value
}
interface products extends Array<Product> { }

//accessing product data 
const Subscribe: React.FC = () => {
  const navigate = useNavigate()

  const URL_POST = '/api/subscription-requests/store';

  const [products, setProducts] = useState<Product[]>([]);
  const [selectedProducts, setSelectedProducts] = useState<Product[]>([]);

  const { register, handleSubmit, setValue, control, formState: { errors } } = useForm();

  // ... form state management ...
  const [isLoading, setIsLoading] = useState(false);
  const [submissionStatus, setSubmissionStatus] = useState<string | null>(null); // Store success/error state


  const {
    user,
  } = useAuth('auth');


  const productOptions = useMemo(() => products.map(product => ({
    value: product.id,
    label: product.name
  })), [products]);

  const handleSelectProduct = product => {
    const index = selectedProducts.findIndex(p => p.id === product.id);
    const updatedSelectedProducts = [...selectedProducts];
    if (index >= 0) {
      updatedSelectedProducts.splice(index, 1);
    } else {
      updatedSelectedProducts.push(product);
    }
    setSelectedProducts(updatedSelectedProducts);
    setValue('product_list', updatedSelectedProducts.map(p => ({ value: p.id, label: p.name })));
  };
  const onSelectProduct = (product: Product) => {
    handleSelectProduct(product);
  };
  const onSubmit = async (data) => {
    setIsLoading(true);
    setSubmissionStatus(null);

    console.log(data);
    try {
      const response = await axios.post(URL_POST, data);
      console.log(response);
      // if response 200 OK

      if (response.status === 201) {
        // show success message
        showSuccess('Form submitted successfully');
        // clear form
        setValue('name', '');
        setValue('email', '');
        setValue('telephone', '');
        setValue('organization_name', '');
        setValue('organization_phone', '');
        setValue('organization_email', '');
        setValue('organization_address', '');
        setValue('organization_website', '');
        setSelectedProducts([]);
        setValue('product_list', []);
        setValue('subscription_period', '');

        //reset loading 
        setIsLoading(false);
        setSubmissionStatus('success');
        //navigate to  subscriptions/user-subscription-request
        navigate('/subscriptions/user-subscription-request');

      } else {
        // show error message
        showError('An error occurred while submitting the form');
        //reset loading
        setIsLoading(false);
        setSubmissionStatus('error');
      }
    } catch (error: any) {
      console.error('Error:', error);
      if (error.isAxiosError && error.response) {
        // Handle HTTP errors specifically
        showError(`Submission failed: ${error.response.status} ${error.response.statusText}`);


      } else {
        // Generic error message for other types of errors (network issue, etc.)
        showError('An error occurred while submitting the form');
      }
    }
  };
  const {
    showInfo,
    showSuccess,
    showError } = useToast();


  const fetchData = async () => {
    try {
      // Call the API to fetch product list
      const dataHandler = new DataHandler(process.env.REACT_APP_BACKEND_URL || 'http://localhost:8000');
      const response = await dataHandler.get<products>('/api/products'); // Specify the type of the response data
      setProducts(response.data); // Access the data property of the response

      showSuccess('Data fetched successfully');

      console.log('Product Data:', products);

      // Update the state or perform any other necessary actions with the processed data

      // Filter product id and names for options
      const options = response.data.map((product: Product) => ({
        value: product.id,
        label: product.name
      }));

      console.log('Options:', options);

      // ...

      // ...
    } catch (error) {
      // Handle any errors that occur during the data fetching process
      console.error('Error fetching data:', error);
      showError('Error fetching data' + (error as Error).message);
    }
  };

  // Call the fetchData function inside the useEffect hook
  useEffect(() => {
    fetchData();

  }, []);


  // Access user data in the form
  setValue('name', user?.name);
  setValue('email', user?.email);



  return (
    <>

      <div className="container py-4">
        <h1>Request Subscribe</h1>
        {
          /** slider component using slick  */
        }

        <Slider {...slider_settings}>
          {products.map(product => (
            <div key={product.id} className="card card-custom gutter-b">
              <div className="card-header">
                <div className="card-title">
                  <h3 className="card-label">{product.name}</h3>
                </div>
                <div className="card-toolbar">
                  <button
                    type="button"
                    className="btn btn-sm btn-info font-weight-bold"
                    onClick={() => onSelectProduct(product)}
                  >
                    <i className="fa-solid fa-bolt"></i> Select
                  </button>
                </div>
              </div>
              <div className="card-body">
                Price: {product.price} {product.price?.[0]?.currency || 'MYR'}
                <table>
                  <thead>
                    <tr>
                      <th>Name</th>
                      <th>Features Included</th>
                    </tr>
                  </thead>
                  <tbody>
                    {product.features?.map((feature: any) => (
                      <tr key={feature.id}>
                        <td>{feature.feature_name}</td>
                        <td>
                          {feature.feature_value === 'Yes' ? (
                            <span style={{ color: 'green' }}>
                              <i className="fa-solid fa-circle-check"></i>
                            </span>
                          ) : (
                            <span style={{ color: 'red' }}>
                              <i className="fa-solid fa-circle-xmark"></i>
                            </span>
                          )}
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
          ))}
        </Slider>
        <Form onSubmit={handleSubmit(onSubmit)}>
          {/* Product selection  dropdown */}|

          <Form.Group className="mb-3">
            <Form.Label>Product List</Form.Label>
            <Controller
              name="product_list"
              control={control}
              rules={{ required: true }}
              render={({ field }) => (
                <Select
                  {...field}
                  options={products.map(product => ({ value: product.id, label: product.name }))}
                  isMulti
                />
              )}
            />
            {errors.product && <div className="invalid-feedback">Please select a product.</div>}
          </Form.Group>
          <PeriodDropDown register={register} />

          <Form.Group className="mb-3">
            <Form.Label>Name</Form.Label>
            <Form.Control
              type="text"
              {...register('name', { required: true, value: user?.name })}
              isInvalid={!!errors.name}
            />
            <Form.Control.Feedback type="invalid">
              Name is required.
            </Form.Control.Feedback>
          </Form.Group>

          <Form.Group className="mb-3">
            <Form.Label>Email</Form.Label>
            <Form.Control
              type="email"
              {
              ...register('email',
                {
                  required: true, pattern: /^\S+@\S+$/i,
                  value: user?.email
                }
              )
              }
              isInvalid={!!errors.email}
            />
            <Form.Control.Feedback type="invalid">
              Please enter a valid email.
            </Form.Control.Feedback>
          </Form.Group>

          <Form.Group className="mb-3">
            <Form.Label>Telephone</Form.Label>
            <Form.Control
              type="tel"
              {...register('telephone',
                {
                  required: true, pattern: /^\+?(?:\d{1,3})?[-. (]?\d{3}[-. )]?\d{3}[-. ]?\d{4}$/,

                })}
              isInvalid={!!errors.telephone}
            />
            <Form.Control.Feedback type="invalid">
              Please enter a valid telephone number please use Malaysia phone number.
            </Form.Control.Feedback>
          </Form.Group>

          {/** telephone  */}

          <Form.Group className="mb-3">
            <Form.Label>Organization</Form.Label>
            <Form.Control
              type="text"
              {
              ...register('organization_name',
                {
                  required: true,
                  minLength: 3,
                  maxLength: 255

                }


              )}
              isInvalid={!!errors.organization}

            />
            <Form.Control.Feedback type="invalid">
              Please enter a organization name.
            </Form.Control.Feedback>
          </Form.Group>

          <Form.Group className="mb-3">
            <Form.Label>Organization Telephone</Form.Label>
            <Form.Control
              type="tel"
              {...register('organization_phone',
                { required: true, pattern: /^\+?(?:\d{1,3})?[-. (]?\d{3}[-. )]?\d{3}[-. ]?\d{4}$/ })}
              isInvalid={!!errors.telephone}
            />
            <Form.Control.Feedback type="invalid">
              Please enter a valid organization telephone number please use Malaysia phone number.
            </Form.Control.Feedback>
          </Form.Group>

          <Form.Group className="mb-3">
            <Form.Label>Organization Email</Form.Label>
            <Form.Control
              type="email"
              {
              ...register('organization_email',
                {
                  required: true, pattern: /^\S+@\S+$/i
                }
              )
              }
              isInvalid={!!errors.email}
            />
            <Form.Control.Feedback type="invalid">
              Please enter a valid email.
            </Form.Control.Feedback>
          </Form.Group>

          <Form.Group className="mb-3">
            <Form.Label>Organization Address</Form.Label>
            <Form.Control
              type="text"
              {
              ...register('organization_address',
                {
                  required: true,
                  minLength: 3,
                  maxLength: 255

                }
              )}
              isInvalid={!!errors.organization_address}
            />
            <Form.Control.Feedback type="invalid">
              Please enter a organization address.
            </Form.Control.Feedback>
          </Form.Group>
          {
            /** organization_address */

          }

          <Form.Group className="mb-3">
            <Form.Label>Organization Website</Form.Label>
            <Form.Control
              type="text"
              {
              ...register('organization_website',
                {
                  required: true,
                  minLength: 3,
                  maxLength: 255,
                  pattern: /^(http|https):\/\/[^ "]+$/


                }
              )}
              isInvalid={!!errors.organization_website}
            />
            <Form.Control.Feedback type="invalid">
              Please enter a organization website must include https://.
            </Form.Control.Feedback>
          </Form.Group>
          {
            /** organization website  */
          }




          <Form.Group className="mb-3">
            <Form.Check
              type="checkbox"
              label="I agree to the terms and conditions"
              {...register('agreeToTerms', { required: true })}
              isInvalid={!!errors.agreeToTerms}
              feedback="You must agree before submitting."
              feedbackType="invalid"
            />
          </Form.Group>

          <Button variant="primary" type="submit">
            Subscribe
          </Button>
          <Button variant="secondary" type="reset">
            Reset
          </Button>
        </Form>
      </div >
    </>
  );
};

export default Subscribe;

