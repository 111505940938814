import React from 'react'
import {IRequestComputeForm} from '../model'
import {ICompanyFinancialAnalysis} from '../model/SmeModelModel'
import clsx from 'clsx'
import {OverlayTrigger, Tooltip} from 'react-bootstrap'
import {KTIcon} from '../../../../_metronic/helpers'

type TProps = {
  name?: string
  label?: string
  type?: string
  param1?: string
  param2?: string
  param3?: string
  param4?: string
  value?: IRequestComputeForm | ICompanyFinancialAnalysis
  error?: string
  required?: boolean
  disabled?: boolean
  onchange?: (
    value: string | number,
    param1: string,
    param2: string,
    param3?: string,
    param4?: string
  ) => void
  tooltip?: any
}

export function LineInput({
  name,
  label,
  type,
  param1,
  param2,
  param3,
  param4,
  value,
  error,
  required,
  disabled,
  onchange,
  tooltip,
}: TProps) {
  const styles = {
    inputNumberStyle: {
      textAlign: 'right' as const,
    },
    inputDefaultStyle: {},
  }

  const getValue = (p1, p2, p3, p4) => {
    if (p1 && p2 && p3 && p4) {
      return value![p1][p2][p3][p4]
    } else if (p1 && p2 && p3) {
      return value![p1][p2][p3]
    } else if (p1 && p2) {
      return value![p1][p2]
    } else if (p1) {
      return value![p1]
    }
  }

  const getName = (p1, p2, p3, p4) => {
    if (p1 && p2 && p3 && p4) {
      return p1 + '.' + p2 + '.' + p3 + '.' + p4
    } else if (p1 && p2 && p3) {
      return p1 + '.' + p2 + '.' + p3
    } else if (p1 && p2) {
      return p1 + '.' + p2
    } else if (p1) {
      return p1
    }
  }

  const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>, type: string | undefined) => {
    if (type === 'number') {
      // List of key codes for arrow keys
      const arrowKeys = ['ArrowUp', 'ArrowDown']

      // Check if the pressed key is an arrow key
      if (arrowKeys.includes(e.key)) {
        // Prevent the default behavior
        e.preventDefault()
      }
    }
  }

  return (
    <>
      <div className='row mb-2'>
        {/*can add class required here later*/}
        <label
          htmlFor={getName(param1, param2, param3, param4)}
          className={clsx('col-lg-6', 'col-form-label', 'fw-bold', 'fs-6', {
            'bg-light-primary': disabled,
            required: required,
          })}
        >
          {label}
          {tooltip && (
            <>
              <OverlayTrigger
                placement='right'
                overlay={
                  <Tooltip id='tooltip-right' className='fs-8'>
                    {tooltip}
                  </Tooltip>
                }
              >
                <span className=''>
                  <KTIcon iconType='solid' iconName='question-2' className='fs-3' />
                </span>
              </OverlayTrigger>
            </>
          )}
        </label>
        <div className='col-lg-6 fv-row'>
          <input
            id={getName(param1, param2, param3, param4)}
            type={type}
            className='form-control form-control-lg form-control-solid'
            value={getValue(param1!, param2!, param3!, param4!) ?? ''}
            required={required}
            disabled={disabled}
            onChange={(e) => {
              let value: number | string = e.target.value
              if (type === 'number') {
                value = Number(e.target.value)
              }
              onchange!(value, param1!, param2!, param3!, param4!)
            }}
            onKeyDown={(e) => handleKeyDown(e, type)}
            style={type === 'number' ? styles.inputNumberStyle : styles.inputDefaultStyle}
          />
          <div className='fv-plugins-message-container'>
            <div className='fv-help-block'>{error}</div>
          </div>
        </div>
      </div>
    </>
  )
}
