import { Link, useNavigate } from 'react-router-dom'
import { useAuth } from '../../../../hooks/auth'
import { KTIcon } from '../../../helpers'
import { HeaderUserMenu } from '../../../partials'
import { useEffect } from 'react'

/* eslint-disable jsx-a11y/anchor-is-valid */
const AsideToolbar = () => {
  const navigate = useNavigate()
  const { user } = useAuth('auth')

  useEffect(() => {
    if (!user?.name && user !== undefined) {
      navigate('/login')
    }
  }, [navigate, user])

  const getRoleName = (user) => {
    if (user && Array.isArray(user.roles) && user.roles.length > 0) {
      const roleName = user.roles[0]?.name || '';
      if (user.is_internal_user === false) {
        return roleName;
      } else if (user.is_internal_user === true) {
        return `${roleName}\ninternal user`;
      }
    }
    return ''; // Return an empty string if no roles are found
  };


  return (
    <>
      <div className='aside-user d-flex align-items-sm-center justify-content-center py-5'>
        <div className='symbol symbol-50px'>
          <div className='symbol-label fs-2 fw-bold bg-danger text-inverse-danger'>
            {user?.name?.charAt!(0)}
          </div>

        </div>

        <div className='aside-user-info flex-row-fluid flex-wrap ms-5'>
          <div className='d-flex'>
            <div className='flex-grow-1 me-2'>
              <Link to={'/user/settings'} className='text-white text-hover-primary fs-6 fw-bold'>
                {user?.is_company_admin === true ? (<KTIcon iconName='verify' className='text-muted fs-1' />) : null}
                {user?.is_internal_user === true ? (<KTIcon iconName='flash-circle' className='text-muted fs-1' />) : null}
                {user?.name?.length! > 12 ? user?.name?.substring!(0, 12) + '...' : user?.name}
              </Link>

              <span className='text-gray-600 fw-bold d-block fs-8 mb-1'>
                {getRoleName(user)}
              </span>

              <div className='d-flex align-items-center text-success fs-9'></div>
            </div>

            <div className='me-n2'>
              <a
                href='#'
                className='btn btn-icon btn-sm btn-active-color-primary mt-n2'
                data-kt-menu-trigger='click'
                data-kt-menu-placement='bottom-start'
                data-kt-menu-overflow='false'
              >
                <KTIcon iconName='setting-2' className='text-muted fs-1' />
              </a>

              <HeaderUserMenu />
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export { AsideToolbar }