import React, { useEffect, useState } from 'react';
import axios from '../../../lib/axios';
import { SubscriptionRequest, SubscriptionsRequestResponse } from './UserSubscriptionRequest';
import { useToast } from '../../components/ToastProvider';
import { useNavigate } from 'react-router-dom';
import LoadingSpinner from '../../../_metronic/helpers/components/LoadingSpinner';
import { useAuth } from '../../../hooks/auth';
import { KTCard, KTCardBody, KTSVG } from '../../../_metronic/helpers';
import { PageLink, PageTitle } from '../../../_metronic/layout/core';
import { Button, Collapse } from "react-bootstrap";

const AdminViewAllSubscriptionRequestPage: React.FC = () => {
  const [dataLoading, setDataLoading] = useState(true);
  const navigate = useNavigate();
  const { showError } = useToast();
  const [subscriptions, setSubscriptions] = useState<SubscriptionRequest[]>([]);
  const [expandedRows, setExpandedRows] = useState<Set<number>>(new Set());
  const URL_SUBSCRIPTION_REQUESTS = '/api/subscription-requests';
  const ADD_SUBSCRIPTION_PAGE = '/subscriptions/admin-create-subscription-request';
  const { loading } = useAuth('auth');
  const [searchParams, setSearchParams] = useState({
    id: '',
    name: '',
    email: '',
    organization_name: '',
    organization_phone: '',
    status: '',
    created_at: '',
    updated_at: ''
  });

  const requestBreadCrumb: Array<PageLink> = [
    {
      title: 'All Subscription Requests',
      path: '/subscriptions/admin-subscription-request',
      isSeparator: false,
      isActive: false,
    },
    {
      title: '',
      path: '',
      isSeparator: true,
      isActive: false,
    },
  ];

  const handleSearchChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchParams({ ...searchParams, [e.target.name]: e.target.value });
  };

  useEffect(() => {
    const fetchSubscriptions = async () => {
      try {
        const params = new URLSearchParams(searchParams as any).toString();
        const response = await axios.get<SubscriptionsRequestResponse>(`${URL_SUBSCRIPTION_REQUESTS}?${params}`);
        setSubscriptions(response.data.subscriptions);
      } catch (error) {
        console.error('Error fetching subscriptions:', error);
        showError('Failed to fetch subscription requests.');
      } finally {
        setDataLoading(false);
      }
    };

    fetchSubscriptions();
  }, [searchParams, showError]);

  const getSeverity = (status: string) => {
    return {
      pending: 'warning',
      rejected: 'danger',
      approved: 'success'
    }[status] || 'info';
  };

  const statusWrapper = (status: string) => {
    const classNameString = `bg-light-${getSeverity(status)} text-${getSeverity(status)}`;
    return <div className={classNameString}>{status}</div>;
  };

  const navigateSubscriptionPage = () => navigate(ADD_SUBSCRIPTION_PAGE);

  const toggleRow = (rowId: number) => {
    setExpandedRows(prev => {
      const newExpandedRows = new Set(prev);
      if (newExpandedRows.has(rowId)) {
        newExpandedRows.delete(rowId);
      } else {
        newExpandedRows.add(rowId);
      }
      return newExpandedRows;
    });
  };

  const Title = () => 'List Of Subscription Requests';

  return (
    <>
      <PageTitle breadcrumbs={requestBreadCrumb}>{Title()}</PageTitle>
      <KTCard>
        <div className="card-header">
          <h1 className="card-title">User Subscription Requests</h1>
          <div className="card-toolbar">
            <Button type="button" className="btn btn-sm btn-primary" onClick={navigateSubscriptionPage}>
              <KTSVG path="/media/icons/duotune/arrows/arr075.svg" className="svg-icon-2" />
              Add Subscription Request
            </Button>
          </div>
        </div>
        <KTCardBody>
          {(loading || dataLoading) ? (
            <LoadingSpinner />
          ) : (
            <div className="table-responsive">
              <table className="table table-bordered">
                <thead>
                  <tr>
                    {['id', 'name', 'email', 'organization_name', 'organization_phone', 'status', 'created_at', 'updated_at', 'Actions'].map((field) => (
                      <th key={field}>
                        {field !== 'Actions' && (
                          <input
                            type="text"
                            className="form-control"
                            placeholder={`Search ${field.replace('_', ' ')}`}
                            name={field}
                            value={(searchParams as any)[field]}
                            onChange={handleSearchChange}
                          />
                        )}
                      </th>
                    ))}
                  </tr>
                </thead>
                <tbody>
                  {subscriptions.map((subscription) => (
                    <React.Fragment key={subscription.id}>
                      <tr>
                        {['id', 'name', 'email', 'organization_name', 'organization_phone', 'status', 'created_at', 'updated_at'].map((key) => (
                          key === 'status' ? (
                            <td key={key}>{statusWrapper(subscription[key])}</td>
                          ) : (
                            <td key={key}>{subscription[key]}</td>
                          )
                        ))}
                        <td>
                          <div className='btn-group'>
                            {subscription.status === 'pending' ? (
                              <Button
                                className="btn btn-sm btn-warning"
                                onClick={() => navigate('/subscriptions/approve-subscription-request', {
                                  state: { subscriptionRequestId: subscription.id }
                                })}
                              >
                                <i className="fa-solid fa-square-check"></i>
                              </Button>
                            ) : null}

                            <Button
                              className="btn btn-sm btn-success"
                              onClick={() => navigate('/subscriptions/admin-edit-subscription-request', {
                                state: { subscriptionRequestId: subscription.id }
                              })}
                            >
                              <i className='fa fa-pen'></i>
                            </Button>
                            <Button
                              className="btn btn-sm btn-info"
                              onClick={() => navigate('/subscriptions/admin-view-subscription-request', {
                                state: { subscriptionRequestId: subscription.id }
                              })}
                            >
                              <i className='fa fa-eye'></i>
                            </Button>
                            <Button
                              className="btn btn-sm btn-secondary"
                              onClick={() => toggleRow(subscription.id)}
                            >
                              {expandedRows.has(subscription.id) ? 'Hide Notes' : 'Show Notes'}
                            </Button>
                          </div>
                        </td>
                      </tr>
                      {expandedRows.has(subscription.id) && (
                        <tr>
                          <td colSpan={9}>
                            <Collapse in={expandedRows.has(subscription.id)}>
                              <div>
                                {subscription.notes.map(note => (
                                  <div key={note.id}>{note.note}</div>
                                ))}
                              </div>
                            </Collapse>
                          </td>
                        </tr>
                      )}
                    </React.Fragment>
                  ))}
                </tbody>
              </table>
            </div>
          )}
        </KTCardBody>
      </KTCard>
    </>
  );
};

export default AdminViewAllSubscriptionRequestPage;
