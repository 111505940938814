import React from 'react';
import { useIntl } from 'react-intl';
import { useNavigate } from 'react-router-dom';

type CompanySubscriptionComponentProps = {
  companyName: string | null | undefined;
  companyId: string | null | undefined;
};

const CompanySubscriptionComponent: React.FC<CompanySubscriptionComponentProps> = ({ companyName, companyId }) => {
  // Implement your component logic here
  let navigate = useNavigate();
  const intl = useIntl()

  const handleNavigation = () => {
    if (companyName && companyId) {
      navigate('/subscriptions/user-view-company-subscription', {
        state: {
          companyName: companyName,
          companyId: companyId,
        }
      });
    } else {
      console.error('Company name or ID is missing.');
    }
  };
  if (companyName && companyName?.length >= 0 && companyName !== null && companyName !== undefined) {
    return (

      <div className="d-flex align-items-center bg-light-info rounded p-5">
        <i className="ki-duotone ki-abstract-26 text-info fs-1 me-5">
          <span className="path1"></span><span className="path2"></span></i>
        <div className="flex-grow-1 me-2">
          <p className="fw-bold text-gray-800 text-hover-primary fs-6">{intl.formatMessage({ id: 'DASHBOARD.LOGGED_IN_AS' })} : {companyName}</p>

          <span className="text-muted fw-semibold d-block">{intl.formatMessage({ id: 'DASHBOARD.CLICK_VIEW_SUBSCRIPTIONS' })}</span>
        </div>
        <button type="button" className="btn btn-sm btn-light" onClick={() => handleNavigation()}>
          <i className='fa fa-eye me-2'></i>
          {intl.formatMessage({ id: 'DASHBOARD.VIEW_ALL_SUBSCRIPTIONS' })}
        </button>
      </div>
    );
  } else {
    return null;
  }

};

export default CompanySubscriptionComponent;