import React, {Component, useEffect, useState} from 'react'
import {PageLink, PageTitle} from '../../../_metronic/layout/core'
import {KTIcon, toAbsoluteUrl} from '../../../_metronic/helpers'
import {useFormik} from 'formik'
import * as Yup from 'yup'
import axios from '../../../lib/axios'
import clsx from 'clsx'
import moment from 'moment'
import { useIntl } from 'react-intl'

export interface IUpdateEmail {
  newEmail: string
  confirmPassword: string
}

export interface IUpdatePassword {
  currentPassword: string
  newPassword: string
  passwordConfirmation: string
}


type TUser = {
  id: number
  email: string
  name: string
  email_verified_at: string | null
  created_at: string
  updated_at: string
  roles: []
}

type TTokenList = {
  name: string
  last_used_at: string
  created_at: string
  expires_at: string
}

async function getUser(): Promise<TUser> {
  // try {
  const url = '/api/user'
  const response = await axios.get(url)
  return response.data
  // } catch (err) {
  //   console.log(err);
  //   return [];
  // }
}

async function changePassword(payload) {
  // try {
  const url = '/api/user/change-password'
  const response = await axios.post(url, {
    old_password: payload.currentPassword,
    new_password: payload.newPassword,
    new_password_confirmation: payload.passwordConfirmation,
  })
  return response.data
  // } catch (err) {
  //   console.log(err);
  //   return [];
  // }
}

async function updateUser(payload) {
  // try {
  const url = '/api/user/update-user'
  const response = await axios.post(url, payload)
  return response.data
  // } catch (err) {
  //   console.log(err);
  //   return [];
  // }
}

async function getNewToken() {
  // try {
  const url = '/api/user/create-token'
  const response = await axios.get(url)
  return response.data
  // } catch (err) {
  //   console.log(err);
  //   return [];
  // }
}

async function getTokenList(): Promise<TTokenList[]> {
  // try {
  const url = '/api/user/list-token'
  const response = await axios.get(url)
  return response.data.data
  // } catch (err) {
  //   console.log(err);
  //   return [];
  // }
}

export function UserSettings() {
  const intl = useIntl()
  const [user, setUser] = useState<TUser>({
    id: 1,
    name: '',
    email: '',
    email_verified_at: null,
    created_at: '',
    updated_at: '',
    roles: [],
  })
  const [tokenList, setTokenList] = useState<[] | TTokenList[]>([])
  const [newApiToken, setNewApiToken] = useState<string | null>('')
  const [passwordUpdateData, setPasswordUpdateData] = useState<IUpdatePassword>({
    currentPassword: '',
    newPassword: '',
    passwordConfirmation: '',
  })
  const [copyToken, setCopyToken] = useState<boolean>(false)
  const [showPasswordForm, setPasswordForm] = useState<boolean>(false)
  const [userMessage, setUserMessage] = useState<string>('')
  const [passwordMessage, setPasswordMessage] = useState<string>(
    'Password must be at least 8 character and contain symbols'
  )

  const [loading, setLoading] = useState(false)
  const [loading2, setLoading2] = useState(false)
  const [loadingToken, setLoadingToken] = useState<boolean>(false)

  const passwordFormValidationSchema = Yup.object().shape({
    currentPassword: Yup.string()
      .min(8, intl.formatMessage({ id: 'SETTING.MINIMUM_CHARACTERS' }))
      .max(50, intl.formatMessage({ id: 'SETTING.MAXIMUM_CHARACTERS' }))
      .required(intl.formatMessage({ id: 'SETTING.PASSWORD_REQUIRED' })),
    newPassword: Yup.string()
      .min(8, intl.formatMessage({ id: 'SETTING.MINIMUM_CHARACTERS' }))
      .max(50, intl.formatMessage({ id: 'SETTING.MAXIMUM_CHARACTERS' }))
      .required(intl.formatMessage({ id: 'SETTING.PASSWORD_REQUIRED' })),
    passwordConfirmation: Yup.string()
      .min(8, intl.formatMessage({ id: 'SETTING.MINIMUM_CHARACTERS' }))
      .max(50, intl.formatMessage({ id: 'SETTING.MAXIMUM_CHARACTERS' }))
      .required(intl.formatMessage({ id: 'SETTING.PASSWORD_REQUIRED' }))
      .oneOf([Yup.ref('newPassword')], 'Passwords must match'),
  })

  const formik2 = useFormik<IUpdatePassword>({
    initialValues: {
      ...passwordUpdateData,
    },
    validationSchema: passwordFormValidationSchema,
    onSubmit: (values, {resetForm}) => {
      setLoading2(true)
      console.log(values)
      setPasswordUpdateData((prevState) => {
        return {...values}
      })
      changePassword(values)
        .then((response) => {
          console.log('response')
          console.log(response)
          setLoading2(false)
          setPasswordForm(false)
          resetForm()
        })
        .catch(function (error) {
          console.log('error')
          setPasswordMessage(error.response.data.message)
          setLoading2(false)
        })
    },
  })

  useEffect(() => {
    ;(async () => {
      const User = await getUser()
      console.log(User)
      setUser(User)
      const tokens = await getTokenList()
      setTokenList(tokens)
    })()
  }, [])

  const handleUpdateUser = () => {
    setLoading(true)
    updateUser(user)
      .then((response) => {
        console.log('response')
        console.log(response)
        setUser(response)
        setLoading(false)
      })
      .catch(function (error) {
        console.log(error)
        setUserMessage(error.response.data.message)
        setLoading(false)
      })
  }

  const handleCopy = (copy) => {
    navigator.clipboard.writeText(copy)
    setCopyToken(true)
  }

  const handleGetNewToken = () => {
    setLoadingToken(true)
    getNewToken()
      .then((response) => {
        console.log('response')
        console.log(response)
        setNewApiToken(response.data.token)
        setLoadingToken(false)
        getTokenList().then((response) => {
          console.log('response')
          console.log(response)
          setTokenList(response)
        })
      })
      .catch((error) => {
        console.log(error.response.data.message)
        setLoadingToken(false)
      })
  }

  const userSettingsBreadCrumbs: Array<PageLink> = [
    {
      title: intl.formatMessage({ id: 'SETTING.USER_SETTINGS' }),
      path: '/user/settings',
      isSeparator: false,
      isActive: false,
    },
    {
      title: '',
      path: '',
      isSeparator: true,
      isActive: false,
    },
  ]

  return (
    <>
      <PageTitle breadcrumbs={userSettingsBreadCrumbs}>{intl.formatMessage({ id: 'SETTING.USER_SETTINGS' })}</PageTitle>
      {/* PROFILE DETAILS START */}
      <div className='card mb-5 mb-xl-10'>
        <div
          className='card-header border-0 cursor-pointer'
          role='button'
          data-bs-toggle='collapse'
          data-bs-target='#kt_account_profile_details'
          aria-expanded='true'
          aria-controls='kt_account_profile_details'
        >
          <div className='card-title m-0'>
            <h3 className='fw-bolder m-0'>{intl.formatMessage({ id: 'SETTING.PROFILE_DETAILS' })}</h3>
          </div>
        </div>

        <div id='kt_account_profile_details' className='collapse show'>
          <div className='card-body border-top p-9'>
            <div className='row mb-6'>{userMessage}</div>
            <div className='row mb-6'>
              <label className='col-lg-4 col-form-label required fw-bold fs-6'>{intl.formatMessage({ id: 'SETTING.FULL_NAME' })}</label>

              <div className='col-lg-8 fv-row'>
                <input
                  type='text'
                  className='form-control form-control-lg form-control-solid'
                  placeholder='Full Name'
                  value={user?.name}
                  onChange={(e) => setUser({...user, name: e.target.value})}
                />
                <div className='fv-plugins-message-container'>
                  <div className='fv-help-block'></div>
                </div>
              </div>
            </div>
          </div>

          <div className='card-footer d-flex justify-content-end py-6 px-9'>
            <button
              onClick={() => handleUpdateUser()}
              className='btn btn-primary'
              disabled={loading}
            >
              {!loading && intl.formatMessage({ id: 'GENERAL.SAVE' })}
              {loading && (
                <span className='indicator-progress' style={{display: 'block'}}>
                  {intl.formatMessage({ id: 'SETTING.PLEASE_WAIT' })}...{' '}
                  <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                </span>
              )}
            </button>
          </div>
        </div>
      </div>
      {/* PROFILE DETAILS END */}

      {/* SIGN IN METHOD START */}
      <div className='card mb-5 mb-xl-10'>
        <div
          className='card-header border-0 cursor-pointer'
          role='button'
          data-bs-toggle='collapse'
          data-bs-target='#kt_account_signin_method'
        >
          <div className='card-title m-0'>
            <h3 className='fw-bolder m-0'>{intl.formatMessage({ id: 'SETTING.SIGN_IN_METHOD' })}</h3>
          </div>
        </div>

        <div id='kt_account_signin_method' className='collapse show'>
          <div className='card-body border-top p-9'>
            <div className='d-flex flex-wrap align-items-center'>
              <div id='kt_signin_email' className={' '}>
                <div className='fs-6 fw-bolder mb-1'>{intl.formatMessage({ id: 'SETTING.EMAIL_ADDRESS' })}</div>
                <div className='fw-bold text-gray-600'>{user?.email}</div>
              </div>
            </div>

            <div className='separator separator-dashed my-6'></div>

            <div className='d-flex flex-wrap align-items-center mb-10'>
              <div id='kt_signin_password' className={' ' + (showPasswordForm && 'd-none')}>
                <div className='fs-6 fw-bolder mb-1'>{intl.formatMessage({ id: 'SETTING.PASSWORD' })}</div>
                <div className='fw-bold text-gray-600'>************</div>
              </div>

              <div
                id='kt_signin_password_edit'
                className={'flex-row-fluid ' + (!showPasswordForm && 'd-none')}
              >
                <form
                  onSubmit={formik2.handleSubmit}
                  id='kt_signin_change_password'
                  className='form'
                  noValidate
                >
                  <div className='row mb-1'>
                    <div className='col-lg-4'>
                      <div className='fv-row mb-0'>
                        <label htmlFor='currentpassword' className='form-label fs-6 fw-bolder mb-3'>
                        {intl.formatMessage({ id: 'SETTING.CURRENT_PASSWORD' })}
                        </label>
                        <input
                          type='password'
                          className='form-control form-control-lg form-control-solid '
                          id='currentpassword'
                          {...formik2.getFieldProps('currentPassword')}
                        />
                        {formik2.touched.currentPassword && formik2.errors.currentPassword && (
                          <div className='fv-plugins-message-container'>
                            <div className='fv-help-block'>{formik2.errors.currentPassword}</div>
                          </div>
                        )}
                      </div>
                    </div>

                    <div className='col-lg-4'>
                      <div className='fv-row mb-0'>
                        <label htmlFor='newpassword' className='form-label fs-6 fw-bolder mb-3'>
                        {intl.formatMessage({ id: 'SETTING.NEW_PASSWORD' })}
                        </label>
                        <input
                          type='password'
                          className='form-control form-control-lg form-control-solid '
                          id='newpassword'
                          {...formik2.getFieldProps('newPassword')}
                        />
                        {formik2.touched.newPassword && formik2.errors.newPassword && (
                          <div className='fv-plugins-message-container'>
                            <div className='fv-help-block'>{formik2.errors.newPassword}</div>
                          </div>
                        )}
                      </div>
                    </div>

                    <div className='col-lg-4'>
                      <div className='fv-row mb-0'>
                        <label htmlFor='confirmpassword' className='form-label fs-6 fw-bolder mb-3'>
                        {intl.formatMessage({ id: 'SETTING.CONFIRM_NEW_PASSWORD' })}
                        </label>
                        <input
                          type='password'
                          className='form-control form-control-lg form-control-solid '
                          id='confirmpassword'
                          {...formik2.getFieldProps('passwordConfirmation')}
                        />
                        {formik2.touched.passwordConfirmation &&
                          formik2.errors.passwordConfirmation && (
                            <div className='fv-plugins-message-container'>
                              <div className='fv-help-block'>
                                {formik2.errors.passwordConfirmation}
                              </div>
                            </div>
                          )}
                      </div>
                    </div>
                  </div>

                  <div className='form-text mb-5'>{intl.formatMessage({ id: 'SETTING.' + passwordMessage })}</div>

                  <div className='d-flex'>
                    <button
                      id='kt_password_submit'
                      type='submit'
                      className='btn btn-primary me-2 px-6'
                    >
                      {!loading2 && intl.formatMessage({ id: 'SETTING.UPDATE_PASSWORD' })}
                      {loading2 && (
                        <span className='indicator-progress' style={{display: 'block'}}>
                          {intl.formatMessage({ id: 'SETTING.PLEASE_WAIT' })}...{' '}
                          <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                        </span>
                      )}
                    </button>
                    <button
                      onClick={() => {
                        setPasswordForm(false)
                      }}
                      id='kt_password_cancel'
                      type='button'
                      className='btn btn-color-gray-400 btn-active-light-primary px-6'
                    >
                      {intl.formatMessage({ id: 'POPUP.CANCEL' })}
                    </button>
                  </div>
                </form>
              </div>

              <div
                id='kt_signin_password_button'
                className={'ms-auto ' + (showPasswordForm && 'd-none')}
              >
                <button
                  onClick={() => {
                    setPasswordForm(true)
                  }}
                  className='btn btn-light btn-active-light-primary'
                >
                  Reset Password
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className='card mb-5 mb-xl-10'>
        <div
          className='card-header border-0 cursor-pointer'
          role='button'
          data-bs-toggle='collapse'
          data-bs-target='#api_section'
        >
          <div className='card-title m-0'>
            <h3 className='fw-bolder m-0'>API Token</h3>
          </div>
        </div>
        <div id='api_section' className='collapse show'>
          <div className='card-body border-top p-9'>
            <div className='row'>
              {' '}
              <button
                className='btn btn-primary btn-sm px-6'
                type='button'
                onClick={() => handleGetNewToken()}
              >
                <span
                  className={clsx('spinner-border', 'spinner-border-sm', {
                    'visually-hidden': !loadingToken,
                  })}
                ></span>
                {intl.formatMessage({ id: 'SETTING.API_TOKEN' })}
              </button>
            </div>

            <div className={clsx('row mb-4 mt-4', {'visually-hidden': !newApiToken})}>{newApiToken}</div>
            <div className={clsx('row', {'visually-hidden': !newApiToken})}>
              {' '}
              <button
                className='btn btn-primary btn-sm px-2'
                onClick={() => handleCopy(newApiToken)}
              >
                {intl.formatMessage({ id: 'SETTING.COPY_API_TOKEN' })}
                <KTIcon
                  className={clsx('fs-2', {
                    'visually-hidden': !copyToken,
                  })}
                  iconName='check'
                  iconType='solid'
                />
              </button>
            </div>

            <div className='d-flex flex-wrap align-items-center'>
              <table className='table border table-row-bordered table-row-gray-300 gy-2'>
                <thead className='fw-bolder fs-6 text-gray-800'>
                  <tr>
                    <th>{intl.formatMessage({ id: 'SETTING.TOKEN_NAME' })}</th>
                    <th>{intl.formatMessage({ id: 'SETTING.LAST_USED_AT' })}</th>
                    <th>{intl.formatMessage({ id: 'SETTING.CREATED_AT' })}</th>
                    <th>{intl.formatMessage({ id: 'SETTING.EXPIRES_AT' })}</th>
                  </tr>
                </thead>
                <tbody>
                  {tokenList?.map((token, index) => (
                    <tr key={index}>
                      <td>{token.name}</td>
                      <td>{token.last_used_at && moment(token.last_used_at).format('DD-MM-YYYY hh:mm A')}</td>
                      <td>{token.created_at && moment(token.created_at).format('DD-MM-YYYY hh:mm A')}</td>
                      <td>{token.expires_at && moment(token.expires_at).format('DD-MM-YYYY hh:mm A')}</td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}
