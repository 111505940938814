import React, { useRef, useEffect, useState } from 'react';
import axios from '../../../lib/axios';
import { ISubscriptionRequest, ISubscriptionsRequestList } from '../subscriptions/Subscription';

const SubscriptionRequestList: React.FC = () => {
  // Initialize with null and update type to possibly be null
  const [subscriptionsRequestList, setSubscriptionsRequestList] = useState<ISubscriptionsRequestList | null>(null);
  const subscriptionRequestRef = useRef<HTMLInputElement>(null);

  useEffect(() => {
    fetchSubscriptionRequests();
  }, []);

  const fetchSubscriptionRequests = async () => {
    try {
      const response = await axios.get<ISubscriptionsRequestList>('/api/subscription-requests'); // Using axios for consistency
      setSubscriptionsRequestList(response.data); // Assuming the API directly returns the ISubscriptionsRequestList format
    } catch (error) {
      console.error('Error fetching subscription requests:', error);
    }
  };

  const handleSubmit = async (event: React.FormEvent) => {
    event.preventDefault();
    const subscriptionRequest = subscriptionRequestRef.current?.value;
    if (subscriptionRequest) {
      try {
        const response = await axios.post('/api/subscription-requests', { subscriptionRequest });
        if (response.status === 200) {
          fetchSubscriptionRequests(); // Refresh the list
          subscriptionRequestRef.current!.value = ''; // Clear the input field
        } else {
          console.error('Failed to create subscription request');
        }
      } catch (error) {
        console.error('Error creating subscription request:', error);
      }
    }
  };

  return (
    <div>
      <h1>Subscription Request List</h1>
      <form onSubmit={handleSubmit}>
        <input type="text" ref={subscriptionRequestRef} />
        <button type="submit">Submit</button>
      </form>
      {subscriptionsRequestList ? (
        <table className="table">
          <thead>
            <tr>
              <th>ID</th>
              <th>User ID</th>
              <th>Name</th>
              <th>Email</th>
              <th>Organization Name</th>
              <th>Organization Phone</th>
              <th>Organization Address</th>
              <th>Organization Website</th>
              <th>Organization Note</th>
              <th>Status</th>
              <th>Created At</th>
              <th>Updated At</th>
            </tr>
          </thead>
          <tbody>
            {subscriptionsRequestList.subscriptions.map((subscriptionRequest: ISubscriptionRequest) => (
              <tr key={subscriptionRequest.id}>
                <td>{subscriptionRequest.id}</td>
                <td>{subscriptionRequest.userId}</td>
                <td>{subscriptionRequest.name}</td>
                <td>{subscriptionRequest.email}</td>
                <td>{subscriptionRequest.organization_name}</td>
                <td>{subscriptionRequest.organization_phone}</td>
                <td>{subscriptionRequest.organization_address}</td>
                <td>{subscriptionRequest.organization_website}</td>
                <td>{subscriptionRequest.organization_note}</td>
                <td>{subscriptionRequest.status}</td>
                <td>{subscriptionRequest.created_at}</td>
                <td>{subscriptionRequest.updated_at}</td>
              </tr>
            ))}
          </tbody>
        </table>
      ) : (
        <p>Loading...</p>
      )}
    </div>
  );
};

export default SubscriptionRequestList;
