/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react'
import {KTIcon} from '../../../../_metronic/helpers'
import { useIntl } from 'react-intl'
type TProps = {
    filter: string
    onclick?: (column: string, filter: string) => void
}
export default function SearchFilterDropDown({filter, onclick} : TProps) {
  const intl = useIntl()
  return (
    <>
      <a
        href='#'
        className='btn btn-light btn-active-light-primary btn-sm w-150px'
        data-kt-menu-trigger='click'
        data-kt-menu-placement='bottom-start'
      >
        {filter}
        <KTIcon iconName='down' className='fs-5 m-0' />
      </a>
      {/* begin::Menu */}
      <div
        className='menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-600 menu-state-bg-light-primary fw-bold fs-7 w-150px py-4'
        data-kt-menu='true'
      >
        {/* begin::Menu item */}
        <div className='menu-item px-3'>
          <a className='menu-link px-3' onClick={() => onclick!('sme_model_no', intl.formatMessage({ id: 'GENERAL.REPORT_NO' }))}>{intl.formatMessage({ id: 'GENERAL.REPORT_NO' })}</a>
        </div>
        {/* end::Menu item */}

        {/* begin::Menu item */}
        <div className='menu-item px-3'>
          <span className='menu-link px-3'onClick={() => onclick!('company_name', intl.formatMessage({ id: 'GENERAL.COMPANY_NAME' }))}>{intl.formatMessage({ id: 'GENERAL.COMPANY_NAME' })}</span>
        </div>
        {/* end::Menu item */}
      </div>
      {/* end::Menu */}
    </>
  )
}
