import { useEffect, useState } from 'react';
import { useForm, SubmitHandler, Controller } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { Button, Spinner, Form } from 'react-bootstrap';
import Select from 'react-select';
import axios from '../../../lib/axios';
import { useToast } from '../../components/ToastProvider';
import { useAuth } from '../../../hooks/auth';
import { PageLink, PageTitle } from '../../../_metronic/layout/core';
import { KTSVG } from '../../../_metronic/helpers';
import { Subscription } from '../companies/Companies';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import LoadingSpinner from '../../../_metronic/helpers/components/LoadingSpinner';
import Slider from 'react-slick';
import DatePicker from "react-datepicker";
import 'react-datepicker/dist/react-datepicker.css';

type FormInputs = {
  name: string;
  address?: string;
  email?: string;
  website?: string;
  phone?: string;
  note?: string;
  status: string;
  original_admin_id?: number;
  create_subscription: boolean;
  duration: string;
  request_limit: number;
  description: string;
  start_date: Date;
  end_date: Date;
  product_list: { value: number; label: string }[];
  is_shared: boolean;
};




interface User {
  id: string;
  name: string;
  email: string;
  roles: [{ permissions: { name: string }[] }];
  company_id: string;
  subscription: Subscription;
  role: string;
  is_organization?: boolean;
  is_personal?: boolean;
  created_at: string;
  updated_at: string;
  mode: 'personal' | 'organization';
  company?: string;
  limit: limit;
  usage: usage;
  check_limit: Boolean;

}

interface limit {
  limit: number;
  is_limit_exceeded: boolean;
}

interface usage {
  usage: number;
  is_limit_exceeded: boolean;
}
type Product = {
  id: number;
  name: string;
  description: string;
  type: string;
  active?: number;
  price: number;
  quantity: number;
  created_at: string;
  updated_at: string;
  prices?: Price[];
  features?: Feature[];
};


type Price = {
  id: number;
  product_id: number;
  amount: number; // Fix: Change the type from string to number
  currency: string;
  default_price: number;
  active: number;
  created_at: string;
  updated_at: string;
  deleted_at: string | null;
};

type Feature = {
  id: number;
  product_id: number;
  feature_name: string;
  feature_value: string;
  active: number;
  created_at: string;
  updated_at: string;
  deleted_at: string | null;

};
const AdminCreateCompany: React.FC = () => {
  const { setLoading, loading } = useAuth('auth');
  const { showError, showSuccess } = useToast();
  const { register, handleSubmit, control, reset, formState: { errors }, setValue, watch, getValues } = useForm<FormInputs>({
    defaultValues: {
      start_date: undefined,
      end_date: undefined,
    },
  });
  const [users, setUsers] = useState<User[]>([]);
  const navigate = useNavigate();
  const [note, setNote] = useState<string>('');
  const [panelSubscriptionVisible, setPanelSubscriptionVisible] = useState(false);
  const [products, setProducts] = useState<Product[]>([]);
  const [selectedProducts, setSelectedProducts] = useState<Product[]>([]);
  const [pageLoading, setPageLoading] = useState(false);

  const slider_settings = {
    dots: true,
    infinite: false,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 3,
  };

  useEffect(() => {
    const fetchUsers = async () => {
      try {
        const response = await axios.get('/api/user/get-user-list');
        setUsers(response.data.data);
      } catch (error) {
        showError('Failed to fetch users');
      }
    };

    const fetchProducts = async () => {
      try {
        setPageLoading(true);
        const response = await axios.get('/api/products'); // Specify the type of the response data
        setProducts(response.data); // Access the data property of the response
        setPageLoading(false);
      } catch (error) {
        setPageLoading(false);
        showError('Failed to fetch products');
      }
    };
    fetchUsers();
    fetchProducts();
  }, [showError]);

  const handleSelectProduct = (product: Product) => {
    const index = selectedProducts.findIndex(p => p.id === product.id);
    const updatedSelectedProducts = [...selectedProducts];
    if (index >= 0) {
      updatedSelectedProducts.splice(index, 1);
    } else {
      updatedSelectedProducts.push(product);
    }
    setSelectedProducts(updatedSelectedProducts);
    setValue('product_list', updatedSelectedProducts.map(p => ({ value: p.id, label: p.name })));
  };

  const onSubmit: SubmitHandler<FormInputs> = async (data) => {
    setLoading(true);
    data.note = note;

    data.create_subscription = panelSubscriptionVisible;

    if (data.create_subscription === true) {
      //get value duration
      data.duration = getValues('duration');
      data.request_limit = getValues('request_limit');
    }

    try {
      await axios.post('/api/companies/store', data);
      showSuccess('Company created successfully');
      navigate('/companies/admin-all-companies');
    } catch (error) {
      showError('Failed to create company');
    } finally {
      setLoading(false);
    }

  };
  const start_date = watch("start_date");
  const end_date = watch("end_date");
  const duration = watch("duration");

  useEffect(() => {
    if (start_date && duration !== 'manual') {
      const start = new Date(start_date);
      switch (duration) {
        case 'quarterly':
          start.setMonth(start.getMonth() + 3);
          break;
        case 'weekly':
          start.setDate(start.getDate() + 7);
          break;
        case 'monthly':
          start.setMonth(start.getMonth() + 1);
          break;
        case 'annually':
          start.setFullYear(start.getFullYear() + 1);
          break;
        case 'biannually':
          start.setFullYear(start.getFullYear() + 2);
          break;
        default:
          break;
      }
      setValue('end_date', start);
    } else if (start_date && duration === 'manual') {
      setValue('end_date', end_date);
    }
  }, [start_date, end_date, duration, setValue]);

  const handleStartDateChange = (date: Date | null) => {
    if (date) {
      setValue('start_date', date);
    }
  };

  const handleEndDateChange = (date: Date | null) => {
    if (date) {
      setValue('end_date', date);
    }
  };

  if (pageLoading) {
    return <LoadingSpinner />;
  }

  return (
    <>
      <PageTitle breadcrumbs={companyBreadCrumbs}>Add New Company </PageTitle>
      <div className="app-main flex-column flex-row-fluid" id="k">
        <div className="d-flex flex-column flex-column-fluid">
          <div className="card-toolbar">
            <a href="/companies/admin-all-companies" className="btn btn-sm btn-light-primary">
              <KTSVG path="/media/icons/duotune/arrows/arr070.svg" className="svg-icon-2" />
              Back to List
            </a>
          </div>
          <div className="card mb-2">
            <div className="card-header">
              <h3 className="card-title">Add Company</h3>
            </div>
            <div className="card-body">
              <Form onSubmit={handleSubmit(onSubmit)}>
                <Form.Group className="mb-3">
                  <Form.Label>Company Name</Form.Label>
                  <Form.Control
                    type="text"
                    {...register('name', { required: 'Company name is required' })}
                    isInvalid={!!errors.name}
                  />
                  <Form.Control.Feedback type="invalid">
                    {errors.name?.message}
                  </Form.Control.Feedback>
                </Form.Group>

                <Form.Group className="mb-3">
                  <Form.Label>Address</Form.Label>
                  <Form.Control
                    type="text"
                    {...register('address')}
                  />
                </Form.Group>

                <Form.Group className="mb-3">
                  <Form.Label>Email</Form.Label>
                  <Form.Control
                    type="email"
                    {...register('email', {
                      pattern: {
                        value: /^\S+@\S+$/i,
                        message: 'Invalid email address'
                      }
                    })}
                    isInvalid={!!errors.email}
                  />
                  <Form.Control.Feedback type="invalid">
                    {errors.email?.message}
                  </Form.Control.Feedback>
                </Form.Group>

                <Form.Group className="mb-3">
                  <Form.Label>Website</Form.Label>
                  <Form.Control
                    type="text"
                    {...register('website')}
                  />
                </Form.Group>

                <Form.Group className="mb-3">
                  <Form.Label>Phone</Form.Label>
                  <Form.Control
                    type="text"
                    {...register('phone')}
                  />
                </Form.Group>

                <Form.Group className="mb-3">
                  <Form.Label>Notes</Form.Label>
                  <Controller
                    name="note"
                    control={control}
                    render={({ field }) => (
                      <ReactQuill value={note} onChange={(e) => {
                        if (e.length <= 255) {
                          setNote(e);
                          field.onChange(e);
                        }
                      }} />
                    )}
                  />
                </Form.Group>
                <Form.Group className="mb-3">
                  <Form.Label>Status</Form.Label>
                  <Form.Control
                    as="select"
                    {...register('status', { required: true })}
                    isInvalid={!!errors.status}
                  >
                    <option value="">Select Status</option>
                    <option value="active">Active</option>
                    <option value="deactivated">Deactivated</option>
                    <option value="dormant">Dormant</option>
                  </Form.Control>
                  <Form.Control.Feedback type="invalid">
                    {errors.status?.message}
                  </Form.Control.Feedback>
                </Form.Group>

                <Form.Group className="mb-3">
                  <Form.Label>Company Owner</Form.Label>
                  <Select
                    options={users.map((user) => ({ value: user.id, label: user.name + ' ,' + user.email }))}
                    onChange={(selectedOption) => setValue('original_admin_id', Number(selectedOption?.value))}
                  />
                </Form.Group>

                <Form.Group className="mb-3">
                  <Form.Check
                    type="checkbox"
                    label="Show Subscription Panel"
                    onChange={(e) => {
                      setPanelSubscriptionVisible(e.target.checked)
                      setValue('create_subscription', e.target.checked)
                    }}
                  />
                </Form.Group>

                {panelSubscriptionVisible && (
                  <>
                    <Form.Group className="mb-3">
                      <Form.Check
                        type="checkbox"
                        label="Create Subscription"
                        {...register('create_subscription')}
                      />
                    </Form.Group>
                    <div className="mb-3">
                      <label htmlFor="description" className="form-label">Description:</label>
                      <textarea className="form-control" id="description" {...register('description')} />
                    </div>
                    <Form.Group className="mb-3">
                      <Form.Label>Duration</Form.Label>
                      <Form.Select {...register('duration', { required: true })}>
                        <option value="quarterly">Quarterly</option>
                        <option value="weekly">Weekly</option>
                        <option value="monthly">Monthly</option>
                        <option value="annually">Annually</option>
                        <option value="biannually">Biannually</option>
                        <option value="manual">Manual</option>
                      </Form.Select>
                      <Form.Control.Feedback type="invalid">
                        {errors.duration?.message}
                      </Form.Control.Feedback>
                    </Form.Group>
                    <Form.Group className="mb-3">
                      <Form.Label>Start Date</Form.Label>
                      <Controller
                        control={control}
                        name="start_date"
                        render={({ field }) => (
                          <DatePicker
                            selected={field.value}
                            onChange={(date) => handleStartDateChange(date)}
                            className="form-control"
                          />
                        )}
                      />
                      <Form.Control.Feedback type="invalid">
                        {errors.start_date?.message}
                      </Form.Control.Feedback>
                    </Form.Group>

                    <Form.Group className="mb-3">
                      <Form.Label>End Date</Form.Label>
                      <Controller
                        control={control}
                        name="end_date"
                        render={({ field }) => (
                          <DatePicker
                            selected={field.value}
                            onChange={(date) => handleEndDateChange(date)}
                            className="form-control"
                          />
                        )}
                      />
                    </Form.Group>
                    <Form.Group className="mb-3">
                      <div className="mb-3">
                        <label htmlFor="request_limit" className="form-label">Request Limit:</label>
                        <input className="form-control" type="number" id="request_limit" {...register('request_limit', {
                          valueAsNumber: true,
                          min: 0,
                          required: panelSubscriptionVisible
                        })} />
                      </div>
                    </Form.Group>
                    <Form.Group className="mb-3">
                      <Form.Check
                        type="checkbox"
                        label="Is Shared"
                        {...register('is_shared')}
                      />
                    </Form.Group>
                    <Slider {...slider_settings}>
                      {products.map(product => (
                        <div key={product.id} className="card card-custom gutter-b">
                          <div className="card-header">
                            <div className="card-title">
                              <h3 className="card-label">{product.name}</h3>
                            </div>
                            <div className="card-toolbar">
                              <button
                                type="button"
                                className="btn btn-sm btn-info font-weight-bold"
                                onClick={() => handleSelectProduct(product)}
                              >
                                <i className="fa-solid fa-bolt"></i> Select
                              </button>
                            </div>
                          </div>
                          <div className="card-body">
                            Price: {product.price} {product.price?.[0]?.currency || 'MYR'}
                            <table>
                              <thead>
                                <tr>
                                  <th>Name</th>
                                  <th>Features Included</th>
                                </tr>
                              </thead>
                              <tbody>
                                {product.features?.map((feature: any) => (
                                  <tr key={feature.id}>
                                    <td>{feature.feature_name}</td>
                                    <td>
                                      {feature.feature_value === 'Yes' ? (
                                        <span style={{ color: 'green' }}>
                                          <i className="fa-solid fa-circle-check"></i>
                                        </span>
                                      ) : (
                                        <span style={{ color: 'red' }}>
                                          <i className="fa-solid fa-circle-xmark"></i>
                                        </span>
                                      )}
                                    </td>
                                  </tr>
                                ))}
                              </tbody>
                            </table>
                          </div>
                        </div>
                      ))}
                    </Slider>
                    <div className='mb-3'>
                      <label htmlFor="product_list" className="form-label">Product List:</label>
                      <Controller
                        name="product_list"
                        control={control}
                        rules={{ required: true }}
                        render={({ field }) => (
                          <Select
                            {...field}
                            options={products.map(product => ({ value: product.id, label: product.name }))}
                            isMulti
                            isSearchable
                          />
                        )}
                      />
                      {errors.product_list && <div className="invalid-feedback">Please select a product.</div>}
                    </div>
                  </>
                )}

                <Button variant="primary" type="submit" disabled={loading}>
                  {loading ? <Spinner animation="border" size="sm" /> : 'Submit'}
                </Button>
                <Button variant="primary" type="button" className="btn btn-secondary" onClick={() => reset()}>
                  Reset
                </Button>
              </Form>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

const companyBreadCrumbs: PageLink[] = [
  {
    title: 'Companies',
    path: '/companies/admin-all-companies',
    isSeparator: false,
    isActive: false,
  },
  {
    title: '',
    path: '',
    isSeparator: true,
    isActive: false,
  },
];

export default AdminCreateCompany;