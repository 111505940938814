import React from 'react'
import {Outlet} from 'react-router-dom'

function TransposeTable({children}) {
  return (
    <table className='transpose-table'>
      <tbody>{children}</tbody>
    </table>
  )
}

export default TransposeTable
