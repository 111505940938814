import React, { useEffect, useRef, useState } from 'react'
import { PageLink, PageTitle } from '../../../_metronic/layout/core'
import { KTIcon } from '../../../_metronic/helpers'
import { IRequestComputeForm, RequestComputeFormInit } from './model'
import axios from '../../../lib/axios'
import { numericFormatter } from 'react-number-format'
import clsx from 'clsx'
import { useLocation, useNavigate } from 'react-router-dom'
import ReactToPrint from 'react-to-print'
import styled from 'styled-components'
import MarcLogo from '../../components/marcLogo'
import '../../../_metronic/assets/css/animateProgressBar.css'
import '../../../_metronic/assets/css/printPage.css'
import { Accordion, Table } from 'react-bootstrap'
import LineChart from './components/compute/components/LineChart'
import ReactSpeedometer from 'react-d3-speedometer'
import { useIntl } from 'react-intl'

type LocationState = {
  corporate_model_no: string
}
type PageState = {
  loading: boolean
  alert: boolean
  alertTitle: string
  alertMessage: string
}

async function getReports(corporate_model_no: string): Promise<IRequestComputeForm> {
  const url = '/api/model/corporate/view'
  const response = await axios.post(url, { corporate_model_no })
  return response.data.data
}

function CorporateModelResult() {
  const intl = useIntl()
  const location = useLocation()
  let navigate = useNavigate()
  const [ComputeForm, setComputeForm] = useState<IRequestComputeForm>(RequestComputeFormInit)
  const result = ComputeForm.companyResult.company_results
  const ref = useRef<HTMLDivElement>(null)
  const [allChart, setAllChart] = useState<boolean>(false)

  const PrintButton = styled.button`
    @media print {
      display: none;
    }
  `

  useEffect(() => {
    ; (async () => {
      const corporate_model_no = (location.state as LocationState)?.corporate_model_no
      const report = await getReports(corporate_model_no)
      setComputeForm(report)
    })()
  }, [location.state])

  const getModelUri = (data_source) => {
    if (data_source === 'klci') {
      return `/corporate-model-bursa/compute`
    }
    if (data_source === 'mu') {
      return `/corporate-model-mu/compute`
    }
    if (data_source === 'klci-mid-tier') {
      return `/corporate-model-mid-tier/compute`
    }
    return `/corporate-model-bursa/compute`
  }
    
  const handleBreadCrumbs = (data_source) => {
    if (data_source === 'klci') {
      return CorpModelBursaBreadCrumbs
    }

    if (data_source === 'mu') {
      return CorpModelMuBreadCrumbs
    }

    if (data_source === 'klci-mid-tier') {
      return CorpModelMidTierBreadCrumbs
    }
  }

  
const CorpModelBursaBreadCrumbs: Array<PageLink> = [
  {
    title: intl.formatMessage({ id: 'CM.CORP_MODEL_BURSA' }),
    path: '/corporate-model-bursa',
    isSeparator: false,
    isActive: false,
  },
  {
    title: '',
    path: '',
    isSeparator: true,
    isActive: false,
  },
  {
    title: intl.formatMessage({ id: 'CM.COMPUTE' }),
    path: '#',
    isSeparator: false,
    isActive: true,
  },
  {
    title: '',
    path: '',
    isSeparator: true,
    isActive: false,
  },
]
const CorpModelMuBreadCrumbs: Array<PageLink> = [
  {
    title: intl.formatMessage({ id: 'CM.CORP_MODEL_MU' }),
    path: '/corporate-model-mu',
    isSeparator: false,
    isActive: false,
  },
  {
    title: '',
    path: '',
    isSeparator: true,
    isActive: false,
  },
  {
    title: intl.formatMessage({ id: 'CM.COMPUTE' }),
    path: '#',
    isSeparator: false,
    isActive: true,
  },
  {
    title: '',
    path: '',
    isSeparator: true,
    isActive: false,
  },
]

const CorpModelMidTierBreadCrumbs: Array<PageLink> = [
  {
    title: intl.formatMessage({ id: 'CM.CORP_MODEL_MID_TIER' }),
    path: '/corporate-model-mid-tier',
    isSeparator: false,
    isActive: false,
  },
  {
    title: '',
    path: '',
    isSeparator: true,
    isActive: false,
  },
  {
    title: intl.formatMessage({ id: 'CM.COMPUTE' }),
    path: '#',
    isSeparator: false,
    isActive: true,
  },
  {
    title: '',
    path: '',
    isSeparator: true,
    isActive: false,
  },
]
  console.log('result render ----------------------------------', result)
  return (
    <>
      <PageTitle breadcrumbs={handleBreadCrumbs(ComputeForm.companyInformation.data_source)}>
        {intl.formatMessage({ id: 'GENERAL.RESULT' })}
      </PageTitle>
      <div ref={ref} className='card mb-5 mb-xl-10'>
        <div className='card-body pb-0'>
          <div className='d-flex flex-wrap flex-sm-nowrap'>
            <div className=''>
              <div className='d-flex justify-content-between align-items-start flex-wrap'>
                <div className='d-flex flex-column'>
                  <button
                    className='btn btn-light-primary btn-sm'
                    onClick={() => {
                      console.log('click:' + ComputeForm.corporate_model_no)
                      let corporate_model_no = ComputeForm.corporate_model_no
                      navigate(getModelUri(ComputeForm.companyInformation.data_source), { state: { corporate_model_no } })
                    }}
                  >
                    <KTIcon iconName='left' iconType='outline' className='fs-3 text-default' />
                  </button>
                </div>
                <div className='d-flex flex-column'>
                  <ReactToPrint
                    documentTitle='corporate Model Result'
                    bodyClass='text-gray-400 text-primary'
                    content={() => ref.current}
                    trigger={() => (
                      <PrintButton type='button' className='btn btn-light-primary btn-sm'>
                        <KTIcon
                          iconName='printer'
                          iconType='outline'
                          className='fs-3 text-default'
                        />
                      </PrintButton>
                    )}
                  />
                </div>
              </div>
            </div>
            <div className='card-body pt-9 pb-0' id='for-print'>
              {/*********************** For print **************************** */}
              <div>
                <div className='row'>
                  <div className='d-flex justify-content-between align-items-center'>
                    <div>
                      <h1 style={{ fontSize: '32px' }}>{intl.formatMessage({ id: 'RESULT.CM_TITLE' })}</h1>
                      <div>
                        <p>
                          <i>{intl.formatMessage({ id: 'RESULT.POWERED_BY' })}</i>
                        </p>
                      </div>
                    </div>
                    <MarcLogo />
                  </div>
                </div>
                <div className='row'>
                  <div className='col-6'>
                    <div className='row row-cols-1'>
                      <div className='col'>
                        {/** 1 */}
                        <div className='d-flex flex-column'>
                          <div>
                            <h2 className='h2ClassTop'>{intl.formatMessage({id: 'GENERAL.COMPANY_NAME'})}</h2>
                          </div>
                          <span>
                            <h1 style={{ fontStyle: 'italic' }}>
                              {result.company_data.company.company_name}
                            </h1>
                          </span>
                          <div className='d-flex flex-column' style={{ marginTop: '20px' }}>
                            <div>
                              <h2 className='h2ClassTop'>{intl.formatMessage({id: 'AFS.BUSINESS_INFORMATION'})}</h2>
                            </div>
                            <table style={{ fontSize: '14px', margin: '0rem' }}>
                              <tr>
                                <td>
                                  {intl.formatMessage({id: 'COMPANY.REFERENCE_NO'})}</td>
                                <td>
                                  : <span className='fw-bold' style={{ fontSize: '14px' }}>
                                    {ComputeForm.corporate_model_no}
                                  </span>
                                </td>
                              </tr>
                              <tr>
                                <td>
                                  {intl.formatMessage({id: 'COMPANY.ISSUE_DATE_TIME'})}</td>
                                <td>
                                  : <span className='fw-bold' style={{ fontSize: '14px' }}>
                                    {new Date(ComputeForm.companyResult.updated_at).toLocaleString(
                                      'en-GB',
                                      {
                                        day: '2-digit',
                                        month: '2-digit',
                                        year: 'numeric',
                                        hour: '2-digit',
                                        minute: '2-digit',
                                        second: '2-digit',
                                      }
                                    )}
                                  </span>
                                </td>
                              </tr>
                            </table>
                            
                          </div>
                        </div>
                        {/** 1 */}
                      </div>
                      <div className='col'>
                        {/** 2 */}
                        <div className='row'>
                          <div style={{ height: '30px' }}></div>
                        </div>
                        <div className='row'>
                          <div className='d-flex flex-wrap'>
                            <div className='flex flex-column'>
                              <div>
                                <h2 className='h2ClassTop'>{intl.formatMessage({ id: 'RESULT.BUSINESS_SUMMARY' })}</h2>
                              </div>
                              <table style={{ fontSize: '14px', margin: '0rem', whiteSpace: 'normal', width: '500px' }}>

                              {ComputeForm.companyInformation.peer_type === 'peer_company' ? (
                                <>
                                  <tr>
                                    <td style={{ width: '30%' }}>
                                      {intl.formatMessage({ id: 'RESULT.PEER_SECTOR' })}
                                    </td>
                                    <td>
                                      : <span className='fw-bold' style={{ fontSize: '14px' }}>
                                      {intl.formatMessage({ id: 'RESULT.REFER_APPENDIX' })}*
                                      </span>
                                    </td>
                                  </tr>
                                </>
                              ) : (
                                <>
                                  <tr>
                                    <td style={{ width: '30%' }}>
                                      {intl.formatMessage({ id: 'RESULT.PEER_SECTOR' })}
                                    </td>
                                    <td>
                                       : <span className='fw-bold' style={{ fontSize: '14px' }}>
                                        {ComputeForm.companyAnalysis.peer_sector.map(
                                          (sector, index) => (
                                            <>
                                              {intl.formatMessage({ id: 'CM.' + sector})}
                                              {ComputeForm.companyAnalysis.peer_sector?.length !==
                                                index + 1
                                                ? ', '
                                                : null}
                                            </>
                                          )
                                        )}
                                      </span>
                                    </td>
                                  </tr>
                                </>
                              )}

                              <tr>
                                <td>
                                  {intl.formatMessage({ id: 'RESULT.NUMBER_OF_PEERS' })}
                                </td>
                                <td>
                                   : <span className='fw-bold' style={{ fontSize: '14px' }}>
                                    {result.company_data.peers.total_company}
                                  </span>
                                </td>
                              </tr>
                              <tr>
                                <td>
                                  {intl.formatMessage({ id: 'RESULT.DATA_VALIDATION' })}
                                </td>
                                <td>
                                   : <span className='fw-bold' style={{ fontSize: '14px' }}>
                                    {ComputeForm.companyAnalysis.data_validation === 1
                                      ? 'Trimmed'
                                      : 'Original'}
                                  </span>
                                </td>
                              </tr>
                              <tr>
                                <td>
                                  {intl.formatMessage({ id: 'RESULT.DEBT_RANGE_MILLION' })}
                                </td>
                                <td>
                                   : <span className='fw-bold' style={{ fontSize: '14px' }}>
                                    {result.company_data.peers.debt_range_min} -{' '}
                                    {numericFormatter(
                                      Math.ceil(
                                        result.company_data.peers.debt_range_max
                                      )!.toString(),
                                      { thousandSeparator: ',' }
                                    )}
                                    { }
                                  </span>
                                </td>
                              </tr>
                              </table>
                              
                            </div>
                          </div>
                        </div>
                        {/** 2 */}
                      </div>
                    </div>
                  </div>

                  <div className='col-6'>
                    <div className='row mt-20'>
                      <div className='col-6'>
                        <div style={{ width: '300px', height: '160px' }}>
                          <ReactSpeedometer
                            forceRender={true}
                            maxSegmentLabels={0}
                            maxValue={100}
                            value={Math.floor(result.score?.final_score / 10) * 10}
                            customSegmentStops={[
                              0,
                              Math.floor(result.score?.final_score / 10) * 10,
                              100,
                            ]}
                            segmentColors={['#36486b', '#9fa9a3']}
                            currentValueText={''}
                            textColor={'#000000'}
                            minValue={0}
                            needleTransitionDuration={1000}
                          />
                        </div>
                        <div className='text-center ms-8 mb-4'>{`${intl.formatMessage({ id: 'RESULT.YOUR_SCORE_IS' })} \r\n ${Math.floor(result.score?.final_score / 10) * 10
                          } \r\n ${intl.formatMessage({ id: 'RESULT.OUT_OFF_100' })}`}</div>
                      </div>
                      <div className='col-6'>
                        <p>{intl.formatMessage({ id: 'RESULT.FINAL_SCORE' })} : {Math.floor(result.score?.final_score / 10) * 10}</p>
                        <p> {intl.formatMessage({ id: 'RESULT.CM_GRADE' })} : {result.score?.final_band} </p>
                        <p>
                          {intl.formatMessage({ id: 'RESULT.RISK_PROFILE' })}: {result.result_matrix[result.score?.final_band]?.profile}
                        </p>
                      </div>
                    </div>
                    <div className='row'>
                      <h5>{intl.formatMessage({ id: 'RESULT.DESCRIPTION' })}:</h5>
                      <p>{intl.formatMessage({ id: 'RESULT.' + result.result_matrix[result.score?.final_band]?.description})}</p>
                    </div>
                  </div>
                </div>
                <div className='row'>
                  <Accordion defaultActiveKey='1'>
                    <Accordion.Item eventKey='1'>
                      <Accordion.Header className='text-center'>
                      {intl.formatMessage({ id: 'RESULT.COMPANY_SCORE_BY_SPECIFIC_RATIO' })}
                      </Accordion.Header>
                      <Accordion.Body>
                        <div className='m-auto'>
                          <h3>{intl.formatMessage({ id: 'RESULT.FINANCIAL' })}</h3>
                          <Table
                            striped
                            bordered
                            hover
                            className='w-700px text-center'
                            style={{ fontSize: '10px' }}
                          >
                            <thead>
                              <tr>
                                <th className='align-middle'>{intl.formatMessage({ id: 'RESULT.FINANCIAL_RATIOS' })}</th>
                                <th>
                                  {intl.formatMessage({ id: 'CM.OPERATING_MARGIN' })} <br />
                                  (%)
                                </th>
                                <th>
                                  {intl.formatMessage({ id: 'CM.EBITDA_MARGIN' })} <br />
                                  (%)
                                </th>
                                <th>
                                  {intl.formatMessage({ id: 'CM.DEBT_TO_EQUITY' })} <br />
                                  (x)
                                </th>
                                <th>
                                  {intl.formatMessage({ id: 'CM.DEBT_TO_EBITDA' })} <br />
                                  (x)
                                </th>
                                <th>
                                  {intl.formatMessage({ id: 'CM.EBITDA_TO_INTEXP' })} <br />
                                  (x)
                                </th>
                                <th>
                                  {intl.formatMessage({ id: 'CM.CFO_TO_DEBT' })} <br />
                                  (x)
                                </th>
                                <th>
                                  {intl.formatMessage({ id: 'CM.FCF_TO_DEBT' })} <br />
                                  (x)
                                </th>
                                <th>
                                  {intl.formatMessage({ id: 'CM.CFO_TO_INTEXP' })} <br />
                                  (x)
                                </th>
                                <th>
                                  {intl.formatMessage({ id: 'CM.CASH_RATIO' })}  <br />
                                  (x)
                                </th>
                                <th>
                                  {intl.formatMessage({ id: 'CM.CFO_GROWTH' })} <br />
                                  (%)
                                </th>
                              </tr>
                            </thead>
                            <tbody>
                              <tr>
                                <td>{intl.formatMessage({ id: 'CM.RATIO' })}</td>
                                <td>
                                  {(
                                    result.company_data.ratios.operating_margin_latest_year * 100
                                  ).toFixed(2)}
                                </td>
                                <td>
                                  {(
                                    result.company_data.ratios.ebitda_margin_latest_year * 100
                                  ).toFixed(2)}
                                </td>
                                <td>
                                  {result.company_data.ratios.debt_to_equity_latest_year.toFixed(2)}
                                </td>
                                <td>
                                  {result.company_data.ratios.debt_to_ebitda_latest_year.toFixed(2)}
                                </td>
                                <td>
                                  {result.company_data.ratios.ebitda_to_intexp_latest_year.toFixed(
                                    2
                                  )}
                                </td>
                                <td>
                                  {result.company_data.ratios.cfo_to_debt_latest_year.toFixed(2)}
                                </td>
                                <td>
                                  {result.company_data.ratios.fcf_to_debt_latest_year.toFixed(2)}
                                </td>
                                <td>
                                  {result.company_data.ratios.cfo_to_intexp_latest_year.toFixed(2)}
                                </td>
                                <td>
                                  {result.company_data.ratios.cash_ratio_latest_year.toFixed(2)}
                                </td>
                                <td>
                                  {(
                                    result.company_data.ratios.cfo_growth_latest_year * 100
                                  ).toFixed(2)}
                                </td>
                              </tr>
                              <tr>
                                <td>{intl.formatMessage({ id: 'RESULT.SCORE' })}</td>
                                <td>
                                  {result.company_data.ratios.operating_margin_score.toFixed(2)}
                                </td>
                                <td>{result.company_data.ratios.ebitda_margin_score.toFixed(2)}</td>
                                <td>
                                  {result.company_data.ratios.debt_to_equity_score.toFixed(2)}
                                </td>
                                <td>
                                  {result.company_data.ratios.debt_to_ebitda_score.toFixed(2)}
                                </td>
                                <td>
                                  {result.company_data.ratios.ebitda_to_intexp_score.toFixed(2)}
                                </td>
                                <td>{result.company_data.ratios.cfo_to_debt_score.toFixed(2)}</td>
                                <td>{result.company_data.ratios.fcf_to_debt_score.toFixed(2)}</td>
                                <td>{result.company_data.ratios.cfo_to_intexp_score.toFixed(2)}</td>
                                <td>{result.company_data.ratios.cash_ratio_score.toFixed(2)}</td>
                                <td>{result.company_data.ratios.cfo_growth_score.toFixed(2)}</td>
                              </tr>
                              <tr>
                                <td>{intl.formatMessage({ id: 'RESULT.GRADE' })}</td>
                                <td>{result.company_data.ratios.operating_margin_grade}</td>
                                <td>{result.company_data.ratios.ebitda_margin_grade}</td>
                                <td>{result.company_data.ratios.debt_to_equity_grade}</td>
                                <td>{result.company_data.ratios.debt_to_ebitda_grade}</td>
                                <td>{result.company_data.ratios.ebitda_to_intexp_grade}</td>
                                <td>{result.company_data.ratios.cfo_to_debt_grade}</td>
                                <td>{result.company_data.ratios.fcf_to_debt_grade}</td>
                                <td>{result.company_data.ratios.cfo_to_intexp_grade}</td>
                                <td>{result.company_data.ratios.cash_ratio_grade}</td>
                                <td>{result.company_data.ratios.cfo_growth_grade}</td>
                              </tr>
                              <tr>
                                <td>{intl.formatMessage({ id: 'RESULT.PROFILE' })}</td>
                                <td>{intl.formatMessage({ id: 'RESULT.' + result.company_data.ratios.operating_margin_profile})}</td>
                                <td>{intl.formatMessage({ id: 'RESULT.' + result.company_data.ratios.ebitda_margin_profile})}</td>
                                <td>{intl.formatMessage({ id: 'RESULT.' + result.company_data.ratios.debt_to_equity_profile})}</td>
                                <td>{intl.formatMessage({ id: 'RESULT.' + result.company_data.ratios.debt_to_ebitda_profile})}</td>
                                <td>{intl.formatMessage({ id: 'RESULT.' + result.company_data.ratios.ebitda_to_intexp_profile})}</td>
                                <td>{intl.formatMessage({ id: 'RESULT.' + result.company_data.ratios.cfo_to_debt_profile})}</td>
                                <td>{intl.formatMessage({ id: 'RESULT.' + result.company_data.ratios.fcf_to_debt_profile})}</td>
                                <td>{intl.formatMessage({ id: 'RESULT.' + result.company_data.ratios.cfo_to_intexp_profile})}</td>
                                <td>{intl.formatMessage({ id: 'RESULT.' + result.company_data.ratios.cash_ratio_profile})}</td>
                                <td>{intl.formatMessage({ id: 'RESULT.' + result.company_data.ratios.cfo_growth_profile})}</td>
                              </tr>
                            </tbody>
                          </Table>
                          <h3>{intl.formatMessage({ id: 'RESULT.NON_FINANCIAL' })}</h3>
                          <Table striped bordered hover style={{ fontSize: '10px' }}>
                            <thead>
                              <tr>
                                <th>{intl.formatMessage({ id: 'CM.CRITERION' })}</th>
                                <th>{intl.formatMessage({ id: 'CM.CATEGORY' })}</th>
                                <th className='text-center'>Score</th>
                              </tr>
                            </thead>
                            <tbody>
                              <tr>
                                <td>{intl.formatMessage({ id: 'CM.LAWSUIT_LEGAL_DISPUTES' })}</td>
                                <td>{intl.formatMessage({ id: 'CM.' + ComputeForm.companyAnalysis.lawsuit_legal})}</td>
                                <td className='text-center'>
                                  {ComputeForm.companyAnalysis.lawsuit_legal_score}
                                </td>
                              </tr>
                              <tr>
                                <td>{intl.formatMessage({ id: 'CM.MANAGEMENT_STYLE_SUCCESSION_PLANNING' })}</td>
                                <td>{intl.formatMessage({ id: 'CM.' + ComputeForm.companyAnalysis.management_planning})}</td>
                                <td className='text-center'>
                                  {ComputeForm.companyAnalysis.management_planning_score}
                                </td>
                              </tr>
                              <tr>
                                <td>{intl.formatMessage({ id: 'CM.KEY_MANAGEMENT_YEARS_OF_EXPERIENCE' })}</td>
                                <td>{intl.formatMessage({ id: 'CM.' + ComputeForm.companyAnalysis.management_experience})}</td>
                                <td className='text-center'>
                                  {ComputeForm.companyAnalysis.management_experience_score}
                                </td>
                              </tr>
                              <tr>
                                <td>{intl.formatMessage({ id: 'CM.NUMBER_OF_YEARS_IN_OPERATION' })} </td>
                                <td>{intl.formatMessage({ id: 'CM.' + ComputeForm.companyAnalysis.operation_year})}</td>
                                <td className='text-center'>
                                  {ComputeForm.companyAnalysis.operation_year_score}
                                </td>
                              </tr>
                            </tbody>
                          </Table>
                        </div>
                      </Accordion.Body>
                    </Accordion.Item>
                  </Accordion>
                </div>
                <div className='row'>
                  <Accordion defaultActiveKey='1'>
                    <Accordion.Item eventKey='1'>
                      <Accordion.Header>{intl.formatMessage({ id: 'RESULT.PEER_STATISTICAL_ANALYSIS' })}</Accordion.Header>
                      <Accordion.Body>
                        <div className='col-12'>
                          <Table
                            striped
                            bordered
                            hover
                            className='w-700px text-center'
                            style={{ fontSize: '10px' }}
                          >
                            <thead>
                              <tr>
                                <th className='align-middle'>{intl.formatMessage({ id: 'RESULT.FINANCIAL_RATIOS' })}</th>
                                <th>
                                  {intl.formatMessage({ id: 'CM.OPERATING_MARGIN' })} <br />
                                  (%)
                                </th>
                                <th>
                                  {intl.formatMessage({ id: 'CM.EBITDA_MARGIN' })} <br />
                                  (%)
                                </th>
                                <th>
                                  {intl.formatMessage({ id: 'CM.DEBT_TO_EQUITY' })} <br />
                                  (x)
                                </th>
                                <th>
                                  {intl.formatMessage({ id: 'CM.DEBT_TO_EBITDA' })} <br />
                                  (x)
                                </th>
                                <th>
                                  {intl.formatMessage({ id: 'CM.EBITDA_TO_INTEXP' })} <br />
                                  (x)
                                </th>
                                <th>
                                  {intl.formatMessage({ id: 'CM.CFO_TO_DEBT' })} <br />
                                  (x)
                                </th>
                                <th>
                                  {intl.formatMessage({ id: 'CM.FCF_TO_DEBT' })} <br />
                                  (x)
                                </th>
                                <th>
                                  {intl.formatMessage({ id: 'CM.CFO_TO_INTEXP' })} <br />
                                  (x)
                                </th>
                                <th>
                                  {intl.formatMessage({ id: 'CM.CASH_RATIO' })}  <br />
                                  (x)
                                </th>
                                <th>
                                  {intl.formatMessage({ id: 'CM.CFO_GROWTH' })} <br />
                                  (%)
                                </th>
                              </tr>
                            </thead>
                            <tbody>
                              <tr>
                                <td>{intl.formatMessage({ id: 'RESULT.MEDIAN' })}</td>
                                <td>
                                  {(result.sectors_ratios.operating_margin.median * 100).toFixed(2)}
                                </td>
                                <td>
                                  {(result.sectors_ratios.ebitda_margin.median * 100).toFixed(2)}
                                </td>
                                <td>{result.sectors_ratios.debt_to_equity.median.toFixed(2)}</td>
                                <td>{result.sectors_ratios.debt_to_ebitda.median.toFixed(2)}</td>
                                <td>{result.sectors_ratios.ebitda_to_intexp.median.toFixed(2)}</td>
                                <td>{result.sectors_ratios.cfo_to_debt.median.toFixed(2)}</td>
                                <td>{result.sectors_ratios.fcf_to_debt.median.toFixed(2)}</td>
                                <td>{result.sectors_ratios.cfo_to_intexp.median.toFixed(2)}</td>
                                <td>{result.sectors_ratios.cash_ratio.median.toFixed(2)}</td>
                                <td>
                                  {(result.sectors_ratios.cfo_growth.median * 100).toFixed(2)}
                                </td>
                              </tr>
                              <tr>
                                <td>{intl.formatMessage({ id: 'RESULT.BEST' })}</td>
                                <td>
                                  {(result.sectors_ratios.operating_margin.max * 100).toFixed(2)}
                                </td>
                                <td>
                                  {(result.sectors_ratios.ebitda_margin.max * 100).toFixed(2)}
                                </td>
                                <td>{result.sectors_ratios.debt_to_equity.max.toFixed(2)}</td>
                                <td>{result.sectors_ratios.debt_to_ebitda.max.toFixed(2)}</td>
                                <td>{result.sectors_ratios.ebitda_to_intexp.max.toFixed(2)}</td>
                                <td>{result.sectors_ratios.cfo_to_debt.max.toFixed(2)}</td>
                                <td>{result.sectors_ratios.fcf_to_debt.max.toFixed(2)}</td>
                                <td>{result.sectors_ratios.cfo_to_intexp.max.toFixed(2)}</td>
                                <td>{result.sectors_ratios.cash_ratio.max.toFixed(2)}</td>
                                <td>{(result.sectors_ratios.cfo_growth.max * 100).toFixed(2)}</td>
                              </tr>
                              <tr>
                                <td>{intl.formatMessage({ id: 'RESULT.WORST' })}</td>
                                <td>
                                  {(result.sectors_ratios.operating_margin.min * 100).toFixed(2)}
                                </td>
                                <td>
                                  {(result.sectors_ratios.ebitda_margin.min * 100).toFixed(2)}
                                </td>
                                <td>{result.sectors_ratios.debt_to_equity.min.toFixed(2)}</td>
                                <td>{result.sectors_ratios.debt_to_ebitda.min.toFixed(2)}</td>
                                <td>{result.sectors_ratios.ebitda_to_intexp.min.toFixed(2)}</td>
                                <td>{result.sectors_ratios.cfo_to_debt.min.toFixed(2)}</td>
                                <td>{result.sectors_ratios.fcf_to_debt.min.toFixed(2)}</td>
                                <td>{result.sectors_ratios.cfo_to_intexp.min.toFixed(2)}</td>
                                <td>{result.sectors_ratios.cash_ratio.min.toFixed(2)}</td>
                                <td>{(result.sectors_ratios.cfo_growth.min * 100).toFixed(2)}</td>
                              </tr>
                            </tbody>
                          </Table>
                        </div>
                      </Accordion.Body>
                    </Accordion.Item>
                  </Accordion>
                </div>
                <div className='row'>
                  <button
                    className='btn btn-light-primary btn-sm'
                    onClick={() => {
                      if (allChart === true) {
                        setAllChart(false)
                      } else {
                        setAllChart(true)
                      }
                    }}
                  >
                    {allChart === false ? intl.formatMessage({ id: 'RESULT.HIDE_ALL_CHARTS' }) : intl.formatMessage({ id: 'RESULT.VIEW_ALL_CHARTS' })}
                  </button>
                  <div></div>

                  <div className={clsx('', { 'visually-hidden': allChart })}>
                    <Accordion defaultActiveKey='1'>
                      <Accordion.Item eventKey='1'>
                        <Accordion.Header> {intl.formatMessage({ id: 'CM.OPERATING_MARGIN' }).toUpperCase()}</Accordion.Header>
                        <Accordion.Body>
                          <div className='w-700px m-auto '>
                            <LineChart
                              companyName={result.company_data.company.company_name}
                              grade={result.company_data.ratios.operating_margin_grade}
                              chartTitle={intl.formatMessage({ id: 'CM.OPERATING_MARGIN' })}
                              ratioName='operating_margin'
                              data={result.sectors_ratios.operating_margin.band_medians}
                            />
                          </div>{' '}
                        </Accordion.Body>
                      </Accordion.Item>
                    </Accordion>
                    <Accordion defaultActiveKey='1'>
                      <Accordion.Item eventKey='1'>
                        <Accordion.Header>{intl.formatMessage({ id: 'CM.EBITDA_MARGIN' }).toUpperCase()}</Accordion.Header>
                        <Accordion.Body>
                          <div className='w-700px m-auto '>
                            <LineChart
                              companyName={result.company_data.company.company_name}
                              grade={result.company_data.ratios.ebitda_margin_grade}
                              chartTitle={intl.formatMessage({ id: 'CM.EBITDA_MARGIN' })}
                              ratioName='ebitda_margin'
                              data={result.sectors_ratios.ebitda_margin.band_medians}
                            />
                          </div>
                        </Accordion.Body>
                      </Accordion.Item>
                    </Accordion>
                    <Accordion defaultActiveKey='1'>
                      <Accordion.Item eventKey='1'>
                        <Accordion.Header>{intl.formatMessage({ id: 'CM.DEBT_TO_EQUITY' }).toUpperCase()}</Accordion.Header>
                        <Accordion.Body>
                          <div className='w-700px m-auto '>
                            <LineChart
                              companyName={result.company_data.company.company_name}
                              grade={result.company_data.ratios.debt_to_equity_grade}
                              chartTitle={intl.formatMessage({ id: 'CM.DEBT_TO_EQUITY' })}
                              ratioName='debt_to_equity'
                              data={result.sectors_ratios.debt_to_equity.band_medians}
                            />
                          </div>
                        </Accordion.Body>
                      </Accordion.Item>
                    </Accordion>
                    <Accordion defaultActiveKey='1'>
                      <Accordion.Item eventKey='1'>
                        <Accordion.Header>{intl.formatMessage({ id: 'CM.DEBT_TO_EBITDA' }).toUpperCase()}</Accordion.Header>
                        <Accordion.Body>
                          <div className='w-700px m-auto '>
                            <LineChart
                              companyName={result.company_data.company.company_name}
                              grade={result.company_data.ratios.debt_to_ebitda_grade}
                              chartTitle={intl.formatMessage({ id: 'CM.DEBT_TO_EBITDA' })}
                              ratioName='debt_to_ebitda'
                              data={result.sectors_ratios.debt_to_ebitda.band_medians}
                            />
                          </div>
                        </Accordion.Body>
                      </Accordion.Item>
                    </Accordion>
                    <Accordion defaultActiveKey='1'>
                      <Accordion.Item eventKey='1'>
                        <Accordion.Header>{intl.formatMessage({ id: 'CM.EBITDA_TO_INTEXP' }).toUpperCase()}</Accordion.Header>
                        <Accordion.Body>
                          <div className='w-700px m-auto '>
                            <LineChart
                              companyName={result.company_data.company.company_name}
                              grade={result.company_data.ratios.ebitda_to_intexp_grade}
                              chartTitle={intl.formatMessage({ id: 'CM.EBITDA_TO_INTEXP' })}
                              ratioName='ebitda_to_intexp'
                              data={result.sectors_ratios.ebitda_to_intexp.band_medians}
                            />
                          </div>
                        </Accordion.Body>
                      </Accordion.Item>
                    </Accordion>
                    <Accordion defaultActiveKey='1'>
                      <Accordion.Item eventKey='1'>
                        <Accordion.Header>{intl.formatMessage({ id: 'CM.CFO_TO_DEBT' }).toUpperCase()}</Accordion.Header>
                        <Accordion.Body>
                          <div className='w-700px m-auto '>
                            <LineChart
                              companyName={result.company_data.company.company_name}
                              grade={result.company_data.ratios.cfo_to_debt_grade}
                              chartTitle={intl.formatMessage({ id: 'CM.CFO_TO_DEBT' })}
                              ratioName='cfo_to_debt'
                              data={result.sectors_ratios.cfo_to_debt.band_medians}
                            />
                          </div>
                        </Accordion.Body>
                      </Accordion.Item>
                    </Accordion>
                    <Accordion defaultActiveKey='1'>
                      <Accordion.Item eventKey='1'>
                        <Accordion.Header>{intl.formatMessage({ id: 'CM.FCF_TO_DEBT' }).toUpperCase()}</Accordion.Header>
                        <Accordion.Body>
                          <div className='w-700px m-auto '>
                            <LineChart
                              companyName={result.company_data.company.company_name}
                              grade={result.company_data.ratios.fcf_to_debt_grade}
                              chartTitle={intl.formatMessage({ id: 'CM.FCF_TO_DEBT' })}
                              ratioName='fcf_to_debt'
                              data={result.sectors_ratios.fcf_to_debt.band_medians}
                            />
                          </div>
                        </Accordion.Body>
                      </Accordion.Item>
                    </Accordion>
                    <Accordion defaultActiveKey='1'>
                      <Accordion.Item eventKey='1'>
                        <Accordion.Header>{intl.formatMessage({ id: 'CM.CFO_TO_INTEXP' }).toUpperCase()}</Accordion.Header>
                        <Accordion.Body>
                          <div className='w-700px m-auto '>
                            <LineChart
                              companyName={result.company_data.company.company_name}
                              grade={result.company_data.ratios.cfo_to_intexp_grade}
                              chartTitle={intl.formatMessage({ id: 'CM.CFO_TO_INTEXP' })}
                              ratioName='cfo_to_intexp'
                              data={result.sectors_ratios.cfo_to_intexp.band_medians}
                            />
                          </div>
                        </Accordion.Body>
                      </Accordion.Item>
                    </Accordion>
                    <Accordion defaultActiveKey='1'>
                      <Accordion.Item eventKey='1'>
                        <Accordion.Header>{intl.formatMessage({ id: 'CM.CASH_RATIO' }).toUpperCase()}</Accordion.Header>
                        <Accordion.Body>
                          <div className='w-700px m-auto '>
                            <LineChart
                              companyName={result.company_data.company.company_name}
                              grade={result.company_data.ratios.cash_ratio_grade}
                              chartTitle={intl.formatMessage({ id: 'CM.CASH_RATIO' })}
                              ratioName='cash_ratio'
                              data={result.sectors_ratios.cash_ratio.band_medians}
                            />
                          </div>
                        </Accordion.Body>
                      </Accordion.Item>
                    </Accordion>
                    <Accordion defaultActiveKey='1'>
                      <Accordion.Item eventKey='1'>
                        <Accordion.Header>{intl.formatMessage({ id: 'CM.CFO_GROWTH' }).toUpperCase()}</Accordion.Header>
                        <Accordion.Body>
                          <div className='w-700px m-auto '>
                            <LineChart
                              companyName={result.company_data.company.company_name}
                              grade={result.company_data.ratios.cfo_growth_grade}
                              chartTitle={intl.formatMessage({ id: 'CM.CFO_GROWTH' })}
                              ratioName='cfo_growth'
                              data={result.sectors_ratios.cfo_growth.band_medians}
                            />
                          </div>
                        </Accordion.Body>
                      </Accordion.Item>
                    </Accordion>
                  </div>
                </div>
                <div className='row'>
                  <Accordion defaultActiveKey='1'>
                    <Accordion.Item eventKey='1'>
                      <Accordion.Header>{intl.formatMessage({ id: 'RESULT.OVERVIEW_SELECTED_BENCHMARKS' })}</Accordion.Header>
                      <Accordion.Body>
                        <div className='col-12'>
                          <Table
                            striped
                            bordered
                            hover
                            className='w-700px text-center'
                            style={{ fontSize: '10px' }}
                          >
                            <thead>
                              <tr>
                                <th className='align-middle'>{intl.formatMessage({ id: 'RESULT.CM_GRADE' })}</th>
                                <th className='align-middle'>{intl.formatMessage({ id: 'RESULT.SCORE' })}</th>
                                <th>
                                  {intl.formatMessage({ id: 'CM.OPERATING_MARGIN' })} <br />
                                  (%)
                                </th>
                                <th>
                                  {intl.formatMessage({ id: 'CM.EBITDA_MARGIN' })} <br />
                                  (%)
                                </th>
                                <th>
                                  {intl.formatMessage({ id: 'CM.DEBT_TO_EQUITY' })} <br />
                                  (x)
                                </th>
                                <th>
                                  {intl.formatMessage({ id: 'CM.DEBT_TO_EBITDA' })} <br />
                                  (x)
                                </th>
                                <th>
                                  {intl.formatMessage({ id: 'CM.EBITDA_TO_INTEXP' })} <br />
                                  (x)
                                </th>
                                <th>
                                  {intl.formatMessage({ id: 'CM.CFO_TO_DEBT' })} <br />
                                  (x)
                                </th>
                                <th>
                                  {intl.formatMessage({ id: 'CM.FCF_TO_DEBT' })} <br />
                                  (x)
                                </th>
                                <th>
                                  {intl.formatMessage({ id: 'CM.CFO_TO_INTEXP' })} <br />
                                  (x)
                                </th>
                                <th>
                                  {intl.formatMessage({ id: 'CM.CASH_RATIO' })}  <br />
                                  (x)
                                </th>
                                <th>
                                  {intl.formatMessage({ id: 'CM.CFO_GROWTH' })} <br />
                                  (%)
                                </th>
                              </tr>
                            </thead>
                            <tbody>
                              {Object.keys(result.benchmark).map((grade, index) => (
                                <tr key={index}>
                                  <td>{grade}</td>
                                  <td>{result.benchmark[grade].score}</td>
                                  <td>
                                    {(result.benchmark[grade].operating_margin * 100).toFixed(2)}
                                  </td>
                                  <td>
                                    {(result.benchmark[grade].ebitda_margin * 100).toFixed(2)}
                                  </td>
                                  <td>{result.benchmark[grade].debt_to_equity?.toFixed(2)}</td>
                                  <td>{result.benchmark[grade].debt_to_ebitda?.toFixed(2)}</td>
                                  <td>{result.benchmark[grade].ebitda_to_intexp?.toFixed(2)}</td>
                                  <td>{result.benchmark[grade].cfo_to_debt?.toFixed(2)}</td>
                                  <td>{result.benchmark[grade].fcf_to_debt?.toFixed(2)}</td>
                                  <td>{result.benchmark[grade].cfo_to_intexp?.toFixed(2)}</td>
                                  <td>{result.benchmark[grade].cash_ratio?.toFixed(2)}</td>
                                  <td>{(result.benchmark[grade].cfo_growth * 100).toFixed(2)}</td>
                                </tr>
                              ))}
                            </tbody>
                          </Table>
                        </div>
                      </Accordion.Body>
                    </Accordion.Item>
                  </Accordion>
                </div>
                <div className='row'>
                  <Accordion defaultActiveKey='1'>
                    <Accordion.Item eventKey='1'>
                      <Accordion.Header>{intl.formatMessage({ id: 'RESULT.CM_MARC_SCORE_DEFINITIONS' })}</Accordion.Header>
                      <Accordion.Body>
                        <Table
                          striped
                          bordered
                          hover
                          className='w-700px'
                          style={{fontSize: '10px'}}
                        >
                          <thead>
                            <tr>
                              <th>{intl.formatMessage({ id: 'RESULT.GRADE' })}</th>
                              <th>{intl.formatMessage({ id: 'RESULT.SCORE' })}</th>
                              <th>{intl.formatMessage({ id: 'RESULT.PROFILE' })}</th>
                              <th>{intl.formatMessage({ id: 'RESULT.DESCRIPTION' })}</th>
                            </tr>
                          </thead>
                          <tbody>
                            {Object.keys(result.result_matrix).map((grade, index) => (
                              <tr key={index}>
                                <td>{grade}</td>
                                <td className='text-center'>{result.result_matrix[grade].score}</td>
                                <td>{intl.formatMessage({ id: 'RESULT.' + result.result_matrix[grade].profile})}</td>
                                <td>{intl.formatMessage({ id: 'RESULT.' + result.result_matrix[grade].description})}</td>
                              </tr>
                            ))}
                          </tbody>
                        </Table>
                      </Accordion.Body>
                    </Accordion.Item>
                  </Accordion>
                </div>
                <div className='row'>
                  <Accordion defaultActiveKey='1'>
                    <Accordion.Item eventKey='1'>
                      <Accordion.Header> {intl.formatMessage({ id: 'RESULT.APPENDIX_COMPANY_LIST' })} </Accordion.Header>
                      <Accordion.Body>
                        <ul>
                          {result.company_data.peers.company_list?.map((peer, index) => (
                            <li className='list-group-item' key={index}>
                              {peer}
                            </li>
                          ))}
                        </ul>
                      </Accordion.Body>
                    </Accordion.Item>
                  </Accordion>
                </div>
                <div className='row'>
                  <Accordion defaultActiveKey='1'>
                    <Accordion.Item eventKey='1'>
                      <Accordion.Header> {intl.formatMessage({ id: 'RESULT.METHODOLOGY' })} </Accordion.Header>
                      <Accordion.Body>
                        <div
                          className='text-gray-600 fs-6 mb-2'
                          style={{textAlign: 'justify', display: 'flex', flexDirection: 'column'}}
                        >
                          {intl.formatMessage({ id: 'RESULT.CM_METHODOLOGY_ONE' })}
                        </div>
                      </Accordion.Body>
                    </Accordion.Item>
                  </Accordion>
                </div>
                <div className='row'>
                  <Accordion defaultActiveKey='1'>
                    <Accordion.Item eventKey='1'>
                      <Accordion.Header> {intl.formatMessage({ id: 'RESULT.DISCLAIMER' })}</Accordion.Header>
                      <Accordion.Body>
                        <div
                          className='text-gray-600 fs-6 mb-2'
                          style={{textAlign: 'justify', display: 'flex', flexDirection: 'column'}}
                        >
                          {intl.formatMessage({ id: 'RESULT.CM_DISCLAIMER_ONE' })}
                        </div>
                        <div
                          className='text-gray-600 fs-6 mb-2'
                          style={{textAlign: 'justify', display: 'flex', flexDirection: 'column'}}
                        >
                          {intl.formatMessage({ id: 'RESULT.CM_DISCLAIMER_TWO' })}
                        </div>
                        <div
                          className='text-gray-600 fs-6 mb-2'
                          style={{textAlign: 'justify', display: 'flex', flexDirection: 'column'}}
                        >
                          {intl.formatMessage({ id: 'RESULT.CM_DISCLAIMER_THREE' })}
                        </div>
                      </Accordion.Body>
                    </Accordion.Item>
                  </Accordion>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default CorporateModelResult