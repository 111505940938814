import React, { useCallback, useRef } from 'react'
import { PageLink, PageTitle } from '../../../_metronic/layout/core'
import axios from '../../../lib/axios'
import { useEffect, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import { RequestComputeFormInit, IRequestComputeForm } from './model'
import clsx from 'clsx'
import { LineInput } from './components/LineInput'
import ErrorAlert from './components/ErrorAlert'
import { KTIcon } from '../../../_metronic/helpers'
import { IRequestMatrix, IRequestResource } from './model/SmeModelModel'
import LineSelect from './components/LineSelect'
import SubmitButtonExternal from '../dashboard/component/SubmitButtonExternal'
import ResultButtonExternal from '../dashboard/component/ResultButtonExternal'
import './SmeModelCompute.css'
import { TransposeLineInput } from './components/TransposeLineInput'
import { TransposeLineDate } from './components/TransposeLineDate'
import { confirm } from '../../modules/confirmation/BasicConfirmation'
import { alert } from '../../modules/confirmation/BasicAlert'
import TransposeTable from './components/TransposeTable'
import { Form, OverlayTrigger, Tooltip } from 'react-bootstrap'
import moment from 'moment'
import { LineViewer } from './components/LineViewer'
import TableEqualLine from './components/TableEqualLine'
import styled from 'styled-components'
import ReactToPrint from 'react-to-print'
import AFSPrintingHeader from './components/AFSPrintingHeader'
import { useIntl } from 'react-intl'

type LocationState = {
  sme_model_no: string
}

type PageState = {
  loading: boolean
  alert: boolean
  alertTitle: string
  alertMessage: string
  isAutosaving: boolean
}

async function getReports(sme_model_no: string): Promise<IRequestComputeForm> {
  // try {
  console.log('location.stateX :: ' + sme_model_no)
  const url = '/api/model/sme/view'
  const response = await axios.post(url, { sme_model_no })
  console.log(response.data.data)
  return response.data.data
  // } catch (err) {
  //   console.log(err);
  //   return [];
  // }
}

async function getResource(name: string | ''): Promise<IRequestResource> {
  // try {
  console.log('getResource :: ' + name)
  const url = '/api/model/sme/resource'
  const response = await axios.post(url, { name })
  console.log(response.data.data)
  return response.data.data
  // } catch (err) {
  //   console.log(err);
  //   return [];
  // }
}

async function getMatrix(name: string | ''): Promise<IRequestMatrix> {
  // try {
  console.log('getResource :: ' + name)
  const url = '/api/model/sme/matrix'
  const response = await axios.post(url, { name })
  console.log(response.data.data)
  return response.data.data
  // } catch (err) {
  //   console.log(err);
  //   return [];
  // }
}

async function getComputeReport(payload: IRequestComputeForm) {
  // try {
  const url = '/api/model/sme/compute'
  const response = await axios.post(url, payload)
  return response.data.data
  // } catch (err) {
  //   console.log(err);
  //   return [];
  // }
}

async function addFinancialAnalysis(payload) {
  // try {
  const url = '/api/model/sme/add-financial-analysis'
  const response = await axios.post(url, payload)
  return response.data.data
  // } catch (err) {
  //   console.log(err);
  //   return [];
  // }
}
//add uploadDocument function to call an api for the file upload
async function uploadDocument(formData: FormData) {
  console.log('formData', formData);
  const url = '/api/manage-file/upload';
  const response = await axios.post(url, formData, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  });
  return response.data.data;
}

const PrintButton = styled.button`
@media print {
  display: none;
},
@page {
  size: A4 landscape;
}
body {
  transform: scale(0.8);
}

 .print-black-font {
    color: #000 !important;
  }
}  `

const FSTableData = styled.td`
  font-weight: 400;
  text-align: right;
  padding: 3px;
  min-height: 25px;
`
const FSTableDataHead = styled.td`
  font-weight: 400;
  text-align: left;
  padding: 3px;
  min-height: 25px;
  border: 0px solid white;
`
export function SmeModelComputeEx() {
  console.log('SmeModelCompute')
  const intl = useIntl()
  const location = useLocation()
  let navigate = useNavigate()
  const [ComputeForm, setComputeForm] = useState<IRequestComputeForm>(RequestComputeFormInit)
  const ComputeFormRef = useRef(ComputeForm)
  const [PageState, setPageState] = useState<PageState>({
    loading: false,
    alert: false,
    alertMessage: '',
    alertTitle: '',
    isAutosaving: false,
  })
  const [typeIsCorporate, setTypeIsCorporate] = useState(false)
  const [Resource, setResource] = useState<IRequestResource>({
    BusinessType: [{ name: '' }],
    BumiputeraStatus: [{ name: '' }],
  })
  const [Matrix, setMatrix] = useState<IRequestMatrix>({
    Litigation: [{ name: '' }],
    ManagementExperience: [{ name: '' }],
    YearsInCurrentBusiness: [{ name: '' }],
    AccountingFirm: [{ name: '' }],
  })
  const autoGenTooltip = intl.formatMessage({id: 'TOOLTIP.COMPUTED_BY_THE_SYSTEM'})
  const consolidated_income_statement_ref = useRef<HTMLDivElement>(null)
  const consolidated_balance_sheet_ref = useRef<HTMLDivElement>(null)
  const cash_flow_statement_ref = useRef<HTMLDivElement>(null)
  const financial_highlights_ref = useRef<HTMLDivElement>(null)

  // Autosave
  const handleAutosave = useCallback(() => {
    if (ComputeFormRef.current.companyInformation.autosave === 1 && ComputeFormRef.current.companyInformation.status_c !== 'retrieved') {
      setPageState({
        ...PageState,
        alert: false,
        alertTitle: '',
        alertMessage: '',
        isAutosaving: true,
      })
      getComputeReport(ComputeFormRef.current)
        .then((response) => {
          // Set the new state
          // setComputeForm(response)
          handleChange(response.companyInformation.updated_at, 'companyInformation', 'updated_at')
          setPageState({
            ...PageState,
            alert: false,
            alertTitle: '',
            alertMessage: '',
            isAutosaving: false,
          })
        })
        .catch((error) => {
          console.log(error.response.data.message)
          setPageState({
            ...PageState,
            alert: false,
            alertTitle: '',
            alertMessage: '',
            isAutosaving: false,
          })
        })
    }
  }, [])

  //Activate On Load Page
  useEffect(() => {
    ; (async () => {
      const sme_model_no = (location.state as LocationState)?.sme_model_no
      console.log('location.state :: ' + sme_model_no)
      const report = await getReports(sme_model_no)
      setComputeForm(report)
      const resource = await getResource('')
      setResource(resource)
      const matrix = await getMatrix('')
      setMatrix(matrix)

      console.log('--- sme model no ---')
      console.log(sme_model_no)
      console.log('--- sme model no ---')
    })()

    // Autosave
    const interval = setInterval(() => {
      handleAutosave()
    }, 1 * 60 * 1000)

    return () => clearInterval(interval)
  }, [location.state, handleAutosave])

  // Autosave
  useEffect(() => {
    ComputeFormRef.current = ComputeForm
  }, [ComputeForm])

  const buttonDisable = (
    status: string | undefined,
    company_classification: string | undefined,
    typeCorporate: boolean
  ): boolean => {
    //if must disable is true then return true
    if (!status || !company_classification) {
      return true
    }

    if (typeCorporate) {
      if (status === 'retrieved') {
        return false
      } else if (status === 'created') {
        return true
      } else {
        return true
      }
    }

    /**
     *  if user is corporate SME modal not applicable, hence button result must disable
     *
     */

    if (company_classification === 'SME' && status === 'retrieved') {
      return false
    } else if (company_classification === 'SME' && status === 'created') {
      return true
    } else if (company_classification === 'SME' && status === 'result') {
      return false
    } else if (company_classification === 'Corporate' && status === 'result') {
      return true
    } else if (company_classification === 'Corporate' && status === 'created') {
      return true
    } else if (company_classification === 'Corporate' && status === 'retrieved') {
      return false
    } else {
      return true
    }
  }

  const handleSubmit = () => {
    console.log('Request')
    console.log(ComputeForm)
    setPageState({ ...PageState, loading: true, alert: false, alertTitle: '', alertMessage: '' })
    getComputeReport(ComputeForm)
      .then((response) => {
        console.log('response')
        console.log(response)
        setComputeForm(response)
        setPageState({ ...PageState, loading: false, alert: false })
      })
      .catch(function (error) {
        console.log(error)
        setPageState({
          ...PageState,
          alert: true,
          alertTitle: 'Error',
          alertMessage: error.response.data.message,
        })
      })
  }

  const handleChange = (value: string | number, param1: string, param2: string) => {
    // console.log('=== handle   change ===')

    // console.log('=== handle   change  param1 ===')

    // console.log(param1)

    // console.log('=== handle   change  value ===')

    // console.log(value)

    if (value === 'Corporate') {
      setTypeIsCorporate(true)
    } else {
      setTypeIsCorporate(false)
    }
    setComputeForm((prevState) => {
      return {
        ...prevState,
        [param1]: {
          ...prevState[param1],
          [param2]: value,
        },
      }
    })
    console.log(value)
  }

  const handleYearlyDataChange = (
    value: string | number,
    key: number,
    param1: string = '',
    param2: string = '',
    param3: string = '',
    param4: string = ''
  ) => {
    console.log('handleYearlyDataChange')
    console.log(key)
    console.log(param1)
    console.log(param2)
    console.log(param3)
    console.log(value)
    setComputeForm((prevState) => {
      let arr = prevState.companyFinancialAnalysis?.map((data, index) => {
        if (index === key) {
          if (param1 && param2 && param3 && param4) {
            return {
              ...data,
              [param1]: {
                ...data[param1],
                [param2]: {
                  ...data[param1][param2],
                  [param3]: {
                    ...data[param1][param2][param3],
                    [param4]: value,
                  },
                },
              },
            }
          } else if (param1 && param2 && param3) {
            return {
              ...data,
              [param1]: {
                ...data[param1],
                [param2]: {
                  ...data[param1][param2],
                  [param3]: value,
                },
              },
            }
          } else if (param1 && param2) {
            return {
              ...data,
              [param1]: {
                ...data[param1],
                [param2]: value,
              },
            }
          } else if (param1) {
            return {
              ...data,
              [param1]: value,
            }
          }
        }
        return data
      })

      return {
        ...prevState,
        companyFinancialAnalysis: arr,
      }
    })
    console.log(value)
  }

  /**
   * trigger action when user click view result button
   */

  const ReportResultViewed = (smeModelNo: string): void => {
    console.log('ReportResultViewed')
    console.log(smeModelNo)
    console.log('ReportResultViewed')

    const apiUrl = '/api/model/sme/report-retrieved'

    const data = {
      sme_model_no: smeModelNo,
    }

    axios
      .post(apiUrl, data)
      .then((res) => {
        console.log(res.data)
      })
      .catch((err) => {
        console.log(err)
      })
  }

  const handleViewResult = async (sme_model_no: string) => {
    console.log('click:' + sme_model_no)
    if (ComputeForm.companyInformation.status_c === 'result') {
      if (
        await confirm(
          intl.formatMessage({id: 'POPUP.CONFIRMATION_VIEW_RESULT'}),
          intl.formatMessage({id: 'POPUP.YES_PROCEED'}),
          intl.formatMessage({id: 'POPUP.CANCEL'}),
          { title: intl.formatMessage({id: 'POPUP.CONFIRMATION'}) }
        )
      ) {
        ReportResultViewed(sme_model_no)
        navigate(`/sme-model/result`, { state: { sme_model_no } })
      }
    } else {
      navigate(`/sme-model/result`, { state: { sme_model_no } })
    }
  }

  const handleConfirm = async () => {
    if (
      await confirm(
        intl.formatMessage({id: 'POPUP.CONFIRMATION_ACTION_IS_PERMANENT'}),
        intl.formatMessage({id: 'POPUP.YES_PROCEED'}),
        intl.formatMessage({id: 'POPUP.CANCEL'}),
        { title: intl.formatMessage({id: 'POPUP.CONFIRMATION'}) }
      )
    ) {
      let i = ComputeForm.companyFinancialAnalysis?.length! - 1
      getComputeReport(ComputeForm)
        .then((response) => {
          addFinancialAnalysis({
            sme_model_no: ComputeForm.sme_model_no,
            fy_start_date: ComputeForm.companyFinancialAnalysis![i].fy_start_date,
            fy_end_date: ComputeForm.companyFinancialAnalysis![i].fy_end_date,
          })
            .then((response) => {
              console.log('response')
              console.log(response)
                ; (async () => {
                  const report = await getReports(ComputeForm.sme_model_no)
                  setComputeForm(report)
                })()
            })
            .catch(function (error) {
              console.log(error)
              alert(intl.formatMessage({ id: error.response.data.message }),
                intl.formatMessage({ id: 'POPUP.OKAY' }),
                {title: intl.formatMessage({id: 'POPUP.ALERT'})}
              )
            })
        })
        .catch((error) => {
          console.log(error)
          alert(intl.formatMessage({ id: error.response.data.message }),
            intl.formatMessage({ id: 'POPUP.OKAY' }),
            {title: intl.formatMessage({id: 'POPUP.ALERT'})}
          )
        })
    } else {
      console.log('Cancel Add FY')
    }
  }

  //get current time using moment.js
  const [CurrentTime, setCurrentTime] = useState(moment().format('DD/MM/YYYY, HH:mm:ss'))

  const handleBeforeGetContent = () => {
    setCurrentTime(moment().format('DD/MM/YYYY, HH:mm:ss'))
    return Promise.resolve()
  }

  const smemodelComputeBreadCrumbs: Array<PageLink> = [
    {
      title: intl.formatMessage({ id: 'SME_M.SME_MODEL' }),
      path: '/sme-model',
      isSeparator: false,
      isActive: false,
    },
    {
      title: '',
      path: '',
      isSeparator: true,
      isActive: false,
    },
  ]
  
  const [selectedFile, setSelectedFile] = useState<File | null>(null)

  const handleFileUpload = async () => {
    if (!selectedFile) {
      alert(intl.formatMessage({ id: 'Please select a file first' }),
            intl.formatMessage({ id: 'POPUP.OKAY' }),
            {title: intl.formatMessage({id: 'POPUP.ALERT'})}
      )
      return;
    }

    // Confirmation before uploading file
    if (
      await confirm(
        intl.formatMessage({ id: 'POPUP.CONFIRMATION_UPLOAD_DOCUMENT' }),
        intl.formatMessage({ id: 'POPUP.YES_PROCEED' }),
        intl.formatMessage({ id: 'POPUP.CANCEL' }),
        { title: intl.formatMessage({id: 'POPUP.CONFIRMATION'}) }
      )
    ) {
      const formData = new FormData();
      formData.append('file', selectedFile);
      formData.append('report_no', ComputeForm.sme_model_no);
      formData.append('report_type', 'sme');
      formData.append('company_name', ComputeForm.companyInformation.company_name ?? '');
      formData.append('register_number', ComputeForm.companyInformation.register_number ?? '');
      
      uploadDocument(formData).then((response) => {
        console.log('File uploaded successfully:', response);
        alert('File uploaded successfully!',
              intl.formatMessage({ id: 'POPUP.OKAY' }),
              {title: intl.formatMessage({id: 'POPUP.ALERT'})}
        )
        setSelectedFile(null)      // Reset the file input
        const fileInput = document.getElementById('fileInput') as HTMLInputElement;
        if (fileInput) {
          fileInput.value = '';
        }
        ; (async () => {
          const report = await getReports(ComputeForm.sme_model_no)
          setComputeForm(report)
        })()
      }).catch((error) => {
        console.error('Error uploading file:', error);
        alert(intl.formatMessage({ id: error.response.data.message }),
              intl.formatMessage({ id: 'POPUP.OKAY' }),
              {title: intl.formatMessage({id: 'POPUP.ALERT'})}
        )
      })
    } else {
      console.log('Cancel uploading file')
    }
  }

  useEffect(() => {
    let interval: NodeJS.Timeout | null = null;

    if (ComputeForm.companyInformation.status_c === 'processing') {
      interval = setInterval(() => {
        (async () => {
          const report = await getReports(ComputeForm.sme_model_no);
          setComputeForm(report);
          
          // If status is no longer 'processing', clear the interval
          if (report.companyInformation.status_c !== 'processing') {
            alert('Your data is ready to view!')
            if (interval) clearInterval(interval);
          }
        })();
      }, 5000); // 5000 milliseconds = 5 seconds
    }

    // Cleanup function to clear the interval when component unmounts or status changes
    return () => {
      if (interval) clearInterval(interval);
    };
  }, [ComputeForm.companyInformation.status_c, ComputeForm.sme_model_no]);

  return (
    <>
      <PageTitle breadcrumbs={smemodelComputeBreadCrumbs}>{intl.formatMessage({ id: 'SME_M.COMPUTE' })}</PageTitle>

      <div className={clsx('spinner-container', { 'visually-hidden': !PageState.isAutosaving })}>
        <span className='spinner-border spinner-border-sm'></span> Autosaving...
      </div>
      <div className='d-flex justify-content-between align-items-start flex-wrap  '>
        <div className='d-flex flex-column'>
          <div className='d-flex align-items-center'>
            <SubmitButtonExternal
              loading={PageState.loading}
              companyStatus={ComputeForm.companyInformation.status_c}
              onClick={handleSubmit}
            />
            <span
              className='text-gray-500 fw-bolder ms-2 fs-8 pb-4 px-3'
              style={{ fontStyle: 'italic' }}
            >
              {ComputeForm.companyInformation.updated_at &&
                intl.formatMessage({ id: 'GENERAL.UPDATED_AT' }) + ' : ' +
                moment(ComputeForm.companyInformation.updated_at).format('DD/MM/YYYY hh:mm A')}
            </span>
          </div>
        </div>
        <div className='d-flex'>
          {/* View Result Button */}
          <ResultButtonExternal
            buttonText={intl.formatMessage({ id: 'GENERAL.VIEW_RESULT' })}
            onClick={() => handleViewResult(ComputeForm.sme_model_no)}
            CompanyInformation={ComputeForm.companyInformation}
            pageLoading={PageState.loading}
            buttonDisabled={buttonDisable(
              ComputeForm.companyInformation.status_c,
              ComputeForm.companyInformation.company_classification_c,
              typeIsCorporate
            )}
          />
        </div>
      </div>
      <div className='d-flex justify-content-between align-items-start flex-wrap mb-2'>
        <div className='d-flex flex-column'>
          <div className='d-flex align-items-center mb-2'>
            <div className='form-check form-switch form-check-custom form-check-solid ms-2'>
              <input
                className='form-check-input h-20px w-30px'
                type='checkbox'
                value={ComputeForm.companyInformation.autosave}
                checked={ComputeForm.companyInformation.autosave === 1}
                onChange={(e) =>
                  handleChange(e.target.checked ? 1 : 0, 'companyInformation', 'autosave')
                }
                id='flexSwitch20x30'
              />
              <label className='form-check-label' htmlFor='flexSwitch20x30'>
              {intl.formatMessage({ id: 'GENERAL.AUTOSAVE' })} {ComputeForm.companyInformation.autosave === 1 ? intl.formatMessage({ id: 'GENERAL.ON' }) : intl.formatMessage({ id: 'GENERAL.OFF' })}
                <OverlayTrigger
                  placement='right'
                  overlay={
                    <Tooltip id='tooltip-right' className='fs-8'>
                      • {intl.formatMessage({ id: 'GENERAL.SWITCH_ENABLE_DISABLE' })}
                      <br />
                      • {intl.formatMessage({ id: 'GENERAL.AUTOSAVE_EFFECT' })}
                      <br />
                      • {intl.formatMessage({ id: 'GENERAL.AUTOSAVE_IF_SUCCESS' })}
                      <br />
                      • {intl.formatMessage({ id: 'GENERAL.FOR_CALCULATED_CLICK_SAVE' })}
                      <br />
                    </Tooltip>
                  }
                >
                  <span className={clsx('mt-5 mx-3', {})}>
                    <KTIcon iconType='solid' iconName='question-2' className='fs-5' />
                  </span>
                </OverlayTrigger>
              </label>
            </div>
          </div>
        </div>
      </div>
      <ErrorAlert
        title={intl.formatMessage({ id: PageState.alertTitle === '' ? 'NA' : PageState.alertTitle })}
        message={intl.formatMessage({ id: PageState.alertMessage === '' ? 'NA' : PageState.alertMessage })}
        enable={PageState.alert}
        dismiss={() => setPageState({ ...PageState, alert: false })}
      />

      <div
        className={clsx('alert-message-wrapper', {
          'visually-hidden':
            ComputeForm.companyInformation.company_classification !== 'Corporate' &&
            ComputeForm.companyInformation.company_classification_c !== 'Corporate',
        })}
      >
        <div className='alert d-flex bg-light-danger rounded border-danger border border-dashed p-6'>
          <KTIcon iconName='shield-cross' className='fs-2tx text-danger me-4' />
          <div className='d-flex flex-column text-light pe-0 pe-sm-10'>
            <h5 className='mb-1'>Attention</h5>
            <span className='fs-6 text-danger pe-7'>
              Based on the revenue figure you entered, this company does not meet the criteria to be
              classified as an SME for FINTEL SME. Therefore, you cannot proceed to use FINTEL SME
              in this case. If you have any questions or need further assistance, please feel free
              to reach out to us at &nbsp;
              <a href='mailto:data@marc.com.my?subject=Query%20on%20SME%20Classification'>
                data@marc.com.my
              </a>
              .
            </span>
          </div>
        </div>
      </div>

      <div
        className={clsx('alert-message-wrapper', {
          'visually-hidden': ComputeForm.companyInformation.status_c !== 'created',
        })}
      >
        <div className='alert d-flex bg-light-danger rounded border-danger border border-dashed p-6'>
          <KTIcon iconName='shield-cross' className='fs-2tx text-danger me-4' />
          <div className='d-flex flex-column text-light pe-0 pe-sm-10'>
            <h5 className='mb-1 text-danger'>{intl.formatMessage({id: 'ALERT.SME_DEFINITION_ONE'})}</h5>
            <span className='fs-6 text-danger pe-7'>
            {intl.formatMessage({id: 'ALERT.SME_DEFINITION_TWO'})}
            </span>
            <div className='separator border-danger my-5'></div>
            <span className='fs-6 text-gray-700 pe-7'>{intl.formatMessage({id: 'ALERT.SME_DEFINITION_THREE'})}</span>
            <div className='border border-gray-600 text-gray-700 my-3 p-3'>
              {intl.formatMessage({id: 'ALERT.SME_DEFINITION_FOUR'})}<br />
              {intl.formatMessage({id: 'ALERT.SME_DEFINITION_FIVE'})}
            </div>
            <span className='fs-6 text-gray-700 pe-7 mb-2'>
              {intl.formatMessage({id: 'ALERT.SME_DEFINITION_SIX'})}
            </span>
            <span className='fs-6 text-gray-700 pe-7'>
              {intl.formatMessage({id: 'ALERT.SME_DEFINITION_SEVEN'})}
            </span>
          </div>
        </div>
      </div>

      {/*COMPANY INFORMATION TAB*/}
      <div className='card mb-5 mb-xl-10'>
        <div
          className='card-header border-0 cursor-pointer'
          role='button'
          data-bs-toggle='collapse'
          data-bs-target='#company_information_tab'
        >
          <div className='card-title m-0'>
            <h3 className='fw-bolder m-0'>{intl.formatMessage({id: 'COMPANY.COMPANY_INFORMATION'})}</h3>
          </div>
        </div>

        <div id='company_information_tab' className='collapse show'>
          <div className='card-body border-top p-9'>
            {/* <LineInput
              label='Financial Year'
              type='number'
              param1='companyInformation'
              param2='financial_year'
              value={ComputeForm}
              onchange={(e, p1, p2) => handleChange(e, p1, p2)}
            /> */}
            <LineInput
              label={intl.formatMessage({id: 'COMPANY.COMPANY_NAME'})}
              type='text'
              param1='companyInformation'
              param2='company_name'
              value={ComputeForm}
              required={true}
              onchange={(e, p1, p2) => handleChange(e, p1, p2)}
            />
            <LineInput
              label={intl.formatMessage({id: 'COMPANY.REGISTER_NUMBER'})}
              type='text'
              param1='companyInformation'
              param2='register_number'
              value={ComputeForm}
              required={true}
              onchange={(e, p1, p2) => handleChange(e, p1, p2)}
            />
            <LineInput
              label={intl.formatMessage({id: 'COMPANY.INCORPORATE_YEAR'})}
              type='text'
              param1='companyInformation'
              param2='incorporate_year'
              value={ComputeForm}
              onchange={(e, p1, p2) => handleChange(e, p1, p2)}
            />
            <LineSelect
              label={intl.formatMessage({id: 'COMPANY.BUMIPUTERA_STATUS'})}
              param1='companyInformation'
              param2='bumiputera_status'
              value={ComputeForm}
              required={true}
              onchange={(e, p1, p2) => handleChange(e, p1, p2)}
              options={Resource?.BumiputeraStatus!}
            />
            <LineSelect
              label={intl.formatMessage({id: 'COMPANY.BUSINESS_TYPE'})}
              param1='companyInformation'
              param2='business_type'
              value={ComputeForm}
              required={true}
              onchange={(e, p1, p2) => handleChange(e, p1, p2)}
              options={Resource?.BusinessType!}
            />
            <LineSelect
              label={intl.formatMessage({id: 'COMPANY.COMPANY_CLASSIFICATION'})}
              param1='companyInformation'
              param2='company_classification'
              value={ComputeForm}
              required={true}
              onchange={(e, p1, p2) => handleChange(e, p1, p2)}
              options={[{ name: 'SME' }, { name: 'Corporate' }]}
            />
          </div>
        </div>
      </div>

      {/*COMPANY NON FINANCIAL ANALYSIS TAB*/}
      <div className='card mb-5 mb-xl-10'>
        <div
          className='card-header border-0 cursor-pointer'
          role='button'
          data-bs-toggle='collapse'
          data-bs-target='#non_financial_analysis_tab'
        >
          <div className='card-title m-0'>
            <h3 className='fw-bolder m-0'>{intl.formatMessage({id: 'COMPANY.NON_FINANCIAL_ANALYSIS'})}</h3>
          </div>
        </div>

        <div id='non_financial_analysis_tab' className='collapse show'>
          <div className='card-body border-top p-9'>
            {/*   companyNonFinancialAnalysis: {
                      litigation_legal: '',
                      management_experience: '',
                      years_in_current_business: '',
                      accounting_firm: '',
            }, */}
            <LineSelect
              label={intl.formatMessage({id: 'COMPANY.LITIGATION_LEGAL_STATUS'})}
              param1='companyNonFinancialAnalysis'
              param2='litigation_legal'
              value={ComputeForm}
              required
              onchange={(e, p1, p2) => handleChange(e, p1, p2)}
              options={Matrix?.Litigation!}
              tooltip={intl.formatMessage({id: 'TOOLTIP.LITIGATION_LEGAL_STATUS'})}
            />
            <LineSelect
              label={intl.formatMessage({id: 'COMPANY.MANAGEMENT_EXPERIENCE'})}
              param1='companyNonFinancialAnalysis'
              param2='management_experience'
              value={ComputeForm}
              required
              onchange={(e, p1, p2) => handleChange(e, p1, p2)}
              options={Matrix?.ManagementExperience!}
              tooltip={intl.formatMessage({id: 'TOOLTIP.MANAGEMENT_EXPERIENCE'})}
            />
            <LineSelect
              label={intl.formatMessage({id: 'COMPANY.YEARS_IN_CURRENT_BUSINESS'})}
              param1='companyNonFinancialAnalysis'
              param2='years_in_current_business'
              value={ComputeForm}
              required
              onchange={(e, p1, p2) => handleChange(e, p1, p2)}
              options={Matrix?.YearsInCurrentBusiness!}
              tooltip={intl.formatMessage({id: 'TOOLTIP.YEARS_IN_CURRENT_BUSINESS'})}
            />
            <LineSelect
              label={intl.formatMessage({id: 'COMPANY.ACCOUNTING_FIRM'})}
              param1='companyNonFinancialAnalysis'
              param2='accounting_firm'
              value={ComputeForm}
              required
              onchange={(e, p1, p2) => handleChange(e, p1, p2)}
              options={Matrix?.AccountingFirm!}
              tooltip={intl.formatMessage({id: 'TOOLTIP.ACCOUNTING_FIRM'})}
            />
          </div>
        </div>
      </div>

      {/* AI ASSISTANCE FILL FINANCIAL STATEMENT*/}
      <div className='card mb-5 mb-xl-10 p-10'>
        <div className="col-md-6">
        <Form.Group controlId="formFile" className="mb-3">
            <Form.Label>Upload Financial Statement Report</Form.Label>
            <Form.Control 
              type="file" 
              id='fileInput'
              className=''
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                const files = e.target.files;
                if (files && files.length > 0) {
                  setSelectedFile(files[0]);
                }
              }}
              accept='.pdf'
              disabled={ComputeForm.companyInformation.status_c === 'processing'}
            />
          </Form.Group>
          <button className='btn btn-primary' disabled={ComputeForm.companyInformation.status_c === 'processing'} onClick={handleFileUpload}>Upload</button>
        </div>

          <div className={clsx('spinner-container', { 'visually-hidden': ComputeForm.companyInformation.status_c !== 'processing' })}>
            <span className='spinner-border spinner-border-sm'></span> We are reading your file and filling up your financial analysis.
          </div>
      </div>

      {/*COMPANY FINANCIAL ANALYSIS TAB*/}
      <div className='card mb-5 mb-xl-10'>
        <div
          className='card-header border-0 cursor-pointer'
          role='button'
          data-bs-toggle='collapse'
          data-bs-target='#company_financial_analysis_tab'
        >
          <div className='card-title m-0'>
            <h3 className='fw-bolder m-0'>{intl.formatMessage({id: 'COMPANY.FINANCIAL_ANALYSIS'})}</h3>
          </div>
        </div>

        <div id='company_financial_analysis_tab' className='collapse show'>
          <div className='card-body border-top pt-0'>
            <div className='card card-custom shadow'>
              <div className='card-body pt-0'>
                <div className='row' id='myTabContent'>
                  <div className='d-flex justify-content-between align-items-start flex-wrap mb-2'>
                    <div className='d-flex my-4'></div>

                    <div className='d-flex my-4'>
                      <OverlayTrigger
                        placement='left'
                        overlay={
                          <Tooltip id='tooltip-left' className='fs-8'>
                            {intl.formatMessage({id: 'TOOLTIP.ADD_FINANCIAL_YEARS'})}
                          </Tooltip>
                        }
                      >
                        <span
                          className={clsx('mt-3 mx-3', {
                            'visually-hidden': ComputeForm.companyFinancialAnalysis!.length > 2,
                          })}
                        >
                          <KTIcon iconType='solid' iconName='question-2' className='fs-3' />
                        </span>
                      </OverlayTrigger>
                      {/* Add Year Button */}

                      <button
                        className={clsx('btn btn-success btn-sm', {
                          'visually-hidden': ComputeForm.companyFinancialAnalysis!.length > 2,
                        })}
                        onClick={() => handleConfirm()}
                      >
                        <i className='ki-duotone ki-plus fs-4'></i>
                      </button>
                    </div>
                  </div>
                  <div className='row' style={{ overflow: 'auto' }}>
                    <TransposeTable>
                      {ComputeForm.companyFinancialAnalysis?.map((companyFA, i) => (
                        <tr
                          className={clsx('cell-offset transpose-table', { 'w-500px': i === 0 })}
                          key={i}
                        >
                          <td>
                            <div className='row h-50px'>
                              <div className='col-lg-6'></div>
                              <div className='col-lg-6'>
                                <div
                                  className={clsx('alert-message-wrapper', {
                                    'visually-hidden': i !== 0,
                                  })}
                                >
                                  <div className='alert d-flex bg-light-success rounded border-success border border-solid p-2'>
                                    <div className='d-flex flex-column text-light'>
                                      <span className='fs-8 text-success'>
                                        <KTIcon
                                          iconName='information-5'
                                          className='text-success me-2'
                                        />
                                        {intl.formatMessage({id: 'AFS.INSERT_LATEST_FINANCIAL_YEAR_HERE'})}
                                      </span>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </td>
                          <TransposeLineInput
                            label={intl.formatMessage({id: 'AFS.FINANCIAL_YEAR'})}
                            type='text'
                            param1='financial_year_c'
                            value={companyFA}
                            list={i}
                            disabled
                            onchange={(d, p1) => handleYearlyDataChange(d, i, p1)}
                            tooltip={autoGenTooltip}
                          />
                          <TransposeLineDate
                            label={intl.formatMessage({id: 'AFS.START_DATE'})}
                            param1='fy_start_date'
                            value={companyFA}
                            list={i}
                            required
                            onchange={(d, p1) => handleYearlyDataChange(d, i, p1)}
                          />
                          <TransposeLineDate
                            label={intl.formatMessage({id: 'AFS.END_DATE'})}
                            param1='fy_end_date'
                            value={companyFA}
                            list={i}
                            required
                            onchange={(d, p1) => handleYearlyDataChange(d, i, p1)}
                          />
                          <TransposeLineInput
                            label={intl.formatMessage({id: 'AFS.NUMBER_OF_MONTHS'})}
                            type='number'
                            param1='number_of_months_c'
                            value={companyFA}
                            list={i}
                            disabled
                            onchange={(e, p1) => handleYearlyDataChange(e, i, p1)}
                            tooltip={autoGenTooltip}
                          />
                        </tr>
                      ))}
                    </TransposeTable>
                  </div>

                  {/*INCOME STATEMENT*/}
                  <div className='income-statement-wrapper border-default border p-6 mb-6'>
                    <div className='d-flex justify-content-between align-items-start flex-wrap mb-2'>
                      <div className='d-flex my-4'>
                        <KTIcon
                          iconName='questionnaire-tablet'
                          className='fs-2tx text-primary me-4'
                        />
                        <div className='d-flex flex-column'>
                          <h3 className='mb-1'>{intl.formatMessage({id: 'AFS.CONSOLIDATED_INCOME_STATEMENT'})}</h3>
                          <div className='fs-6 fw-bold text-gray-400'>
                            {intl.formatMessage({id: 'AFS.DATA_REQUIRED_FOR_CORRECT_RESULT'})}
                          </div>
                        </div>
                      </div>
                      <div className='d-flex my-4'>
                        <ReactToPrint
                          documentTitle='SME Model Result'
                          bodyClass='print-black-font'
                          content={() => consolidated_income_statement_ref.current}
                          trigger={() => (
                            <PrintButton type='button' className='btn btn-primary btn-sm'>
                              <KTIcon
                                iconName='printer'
                                iconType='outline'
                                className='fs-3 text-default'
                              />
                            </PrintButton>
                          )}
                          onBeforeGetContent={() => handleBeforeGetContent()}
                        />
                      </div>
                    </div>

                    <div className='row' style={{ overflow: 'auto' }}>
                      <TransposeTable>
                        {ComputeForm.companyFinancialAnalysis?.map((companyFA, i) => (
                          <tr
                            className={clsx('cell-offset transpose-table', { 'w-500px': i === 0 })}
                            key={i}
                          >
                            <TransposeLineInput
                              label={intl.formatMessage({id: 'AFS.REVENUE'})}
                              type='number'
                              param1='incomeStatement'
                              param2='revenue'
                              value={companyFA}
                              onchange={(e, p1, p2, p3) => handleYearlyDataChange(e, i, p1, p2, p3)}
                              list={i}
                            />
                            <TransposeLineInput
                              label={intl.formatMessage({id: 'AFS.COST_OF_SALES'})}
                              type='number'
                              param1='incomeStatement'
                              param2='cost_of_sales'
                              value={companyFA}
                              onchange={(e, p1, p2) => handleYearlyDataChange(e, i, p1, p2)}
                              list={i}
                            />
                            <TransposeLineInput
                              label={intl.formatMessage({id: 'AFS.GROSS_PROFIT'})}
                              type='number'
                              param1='incomeStatement'
                              param2='is_gross_profit_c'
                              value={companyFA}
                              disabled
                              onchange={(e, p1, p2) => handleYearlyDataChange(e, i, p1, p2)}
                              list={i}
                              tooltip={autoGenTooltip}
                            />
                            <TransposeLineInput
                              label={intl.formatMessage({id: 'AFS.SGA_EXPENSES'})}
                              type='number'
                              param1='incomeStatement'
                              param2='sga_expenses'
                              value={companyFA}
                              onchange={(e, p1, p2) => handleYearlyDataChange(e, i, p1, p2)}
                              list={i}
                            />
                            <TransposeLineInput
                              label={intl.formatMessage({id: 'AFS.DEPRECIATION_AND_AMORTIZATION'})}
                              type='number'
                              param1='incomeStatement'
                              param2='is_depreciation_amortization'
                              value={companyFA}
                              onchange={(e, p1, p2) => handleYearlyDataChange(e, i, p1, p2)}
                              list={i}
                            />
                            <TransposeLineInput
                              label={intl.formatMessage({id: 'AFS.OTHER_INCOME'})}
                              type='number'
                              param1='incomeStatement'
                              param2='other_income'
                              value={companyFA}
                              onchange={(e, p1, p2) => handleYearlyDataChange(e, i, p1, p2)}
                              list={i}
                            />
                            <TransposeLineInput
                              label={intl.formatMessage({id: 'AFS.OTHER_EXPENSES'})}
                              type='number'
                              param1='incomeStatement'
                              param2='other_expenses'
                              value={companyFA}
                              onchange={(e, p1, p2) => handleYearlyDataChange(e, i, p1, p2)}
                              list={i}
                            />
                            <TransposeLineInput
                              label={intl.formatMessage({id: 'AFS.EBIT'})}
                              type='number'
                              param1='incomeStatement'
                              param2='ebit_c'
                              value={companyFA}
                              disabled
                              onchange={(e, p1, p2) => handleYearlyDataChange(e, i, p1, p2)}
                              list={i}
                              tooltip={autoGenTooltip}
                            />
                            <TransposeLineInput
                              label={intl.formatMessage({id: 'AFS.INTEREST_INCOME_EXPENSE_NET'})}
                              type='number'
                              param1='incomeStatement'
                              param2='interest_expense'
                              value={companyFA}
                              onchange={(e, p1, p2) => handleYearlyDataChange(e, i, p1, p2)}
                              list={i}
                              long
                              tooltip={intl.formatMessage({id: 'TOOLTIP.INTEREST_INCOME_EXPENSE_NET'})}
                            />
                            <TransposeLineInput
                              label={intl.formatMessage({id: 'AFS.EBT'})}
                              type='number'
                              param1='incomeStatement'
                              param2='ebt_c'
                              value={companyFA}
                              disabled
                              onchange={(e, p1, p2) => handleYearlyDataChange(e, i, p1, p2)}
                              list={i}
                              tooltip={autoGenTooltip}
                            />
                            <TransposeLineInput
                              label={intl.formatMessage({id: 'AFS.TAX_INCOME_EXPENSE_NET'})}
                              type='number'
                              param1='incomeStatement'
                              param2='tax_expense'
                              value={companyFA}
                              onchange={(e, p1, p2) => handleYearlyDataChange(e, i, p1, p2)}
                              list={i}
                              long
                              tooltip={intl.formatMessage({id: 'TOOLTIP.TAX_INCOME_EXPENSE_NET'})}
                            />
                            <TransposeLineInput
                              label={intl.formatMessage({id: 'AFS.NET_PROFIT_LOSS_NET'})}
                              type='number'
                              param1='incomeStatement'
                              param2='net_profit_c'
                              value={companyFA}
                              disabled
                              onchange={(e, p1, p2) => handleYearlyDataChange(e, i, p1, p2)}
                              list={i}
                              long
                              tooltip={autoGenTooltip}
                            />
                            <TransposeLineInput
                              label={intl.formatMessage({id: 'AFS.OTHER_COMPREHENSIVE_INCOME'})}
                              type='number'
                              param1='incomeStatement'
                              param2='other_comprehensive_income'
                              value={companyFA}
                              onchange={(e, p1, p2) => handleYearlyDataChange(e, i, p1, p2)}
                              list={i}
                              tooltip={intl.formatMessage({id: 'TOOLTIP.OTHER_COMPREHENSIVE_INCOME'})}
                            />
                            <TransposeLineInput
                              label={intl.formatMessage({id: 'AFS.TOTAL_COMPREHENSIVE_INCOME_EXPENSE'})}
                              type='number'
                              param1='incomeStatement'
                              param2='total_comprehensive_income_c'
                              value={companyFA}
                              disabled
                              onchange={(e, p1, p2) => handleYearlyDataChange(e, i, p1, p2)}
                              list={i}
                              long
                              tooltip={autoGenTooltip}
                            />
                          </tr>
                        ))}
                      </TransposeTable>
                    </div>
                    {/* Printable Consolidated Income Statement */}
                    <div className='row'>
                      <div
                        ref={consolidated_income_statement_ref}
                        className='print-only card-body p-9'
                      >
                        <AFSPrintingHeader
                          Title={intl.formatMessage({id: 'AFS.CONSOLIDATED_INCOME_STATEMENT'})}
                          ComputeForm={ComputeForm}
                          CurrentTime={CurrentTime}
                        />
                        <div className='row mb-2'>
                          <table className='transpose-table'>
                            <tbody>
                              <tr className='min-w-50'>
                                <FSTableDataHead className='white-text-on-blue-bg fw-bold text-white'>
                                  &nbsp;
                                </FSTableDataHead>
                                <FSTableDataHead className='fw-bold cell-highlight'>
                                  {intl.formatMessage({id: 'AFS.REVENUE'})}
                                </FSTableDataHead>
                                <FSTableDataHead>{intl.formatMessage({id: 'AFS.COST_OF_SALES'})}</FSTableDataHead>
                                <TableEqualLine lineType='single' offset />
                                <FSTableDataHead className='fw-bold cell-highlight'>
                                  {intl.formatMessage({id: 'AFS.GROSS_PROFIT'})}
                                </FSTableDataHead>
                                <FSTableDataHead>
                                  {intl.formatMessage({id: 'AFS.SGA_EXPENSES_FULL'})}
                                </FSTableDataHead>
                                <FSTableDataHead>{intl.formatMessage({id: 'AFS.DEPRECIATION_AND_AMORTIZATION'})}</FSTableDataHead>
                                <FSTableDataHead>{intl.formatMessage({id: 'AFS.OTHER_INCOME'})}</FSTableDataHead>
                                <FSTableDataHead>{intl.formatMessage({id: 'AFS.OTHER_EXPENSES'})}</FSTableDataHead>
                                <TableEqualLine lineType='single' offset />
                                <FSTableDataHead className='fw-bold cell-highlight'>
                                  {intl.formatMessage({id: 'AFS.EBIT'})}
                                </FSTableDataHead>
                                <FSTableDataHead>{intl.formatMessage({id: 'AFS.INTEREST_INCOME_EXPENSE_NET'})}</FSTableDataHead>
                                <TableEqualLine lineType='single' offset />
                                <FSTableDataHead className='fw-bold cell-highlight'>
                                  {intl.formatMessage({id: 'AFS.EBT'})}
                                </FSTableDataHead>
                                <FSTableDataHead>{intl.formatMessage({id: 'AFS.TAX_INCOME_EXPENSE_NET'})}</FSTableDataHead>
                                <TableEqualLine lineType='single' offset />
                                <FSTableDataHead className='fw-bold cell-highlight'>
                                {intl.formatMessage({id: 'AFS.NET_PROFIT_LOSS_NET'})}
                                </FSTableDataHead>
                                <FSTableDataHead>{intl.formatMessage({id: 'AFS.OTHER_COMPREHENSIVE_INCOME'})}</FSTableDataHead>
                                <TableEqualLine lineType='single' offset />
                                <FSTableDataHead className='fw-bold cell-highlight'>
                                  {intl.formatMessage({id: 'AFS.TOTAL_COMPREHENSIVE_INCOME_EXPENSE'})}
                                </FSTableDataHead>
                              </tr>
                              {ComputeForm.companyFinancialAnalysis?.map((companyFA, i) => (
                                <tr style={{ minWidth: '15%' }} key={i}>
                                  <FSTableData
                                    className='white-text-on-blue-bg fw-bold text-white'
                                    style={{
                                      backgroundColor: '#36486b',
                                    }}
                                  >
                                    {companyFA.financial_year_c}
                                  </FSTableData>
                                  <FSTableData className='fw-bold cell-highlight'>
                                    <LineViewer
                                      param1='incomeStatement'
                                      param2='revenue'
                                      value={companyFA}
                                    />
                                  </FSTableData>

                                  <FSTableData>
                                    <LineViewer
                                      param1='incomeStatement'
                                      param2='cost_of_sales'
                                      value={companyFA}
                                    />
                                  </FSTableData>
                                  <TableEqualLine lineType='single' />
                                  <FSTableData className='fw-bold cell-highlight'>
                                    <LineViewer
                                      param1='incomeStatement'
                                      param2='is_gross_profit_c'
                                      value={companyFA}
                                    />
                                  </FSTableData>

                                  <FSTableData>
                                    <LineViewer
                                      param1='incomeStatement'
                                      param2='sga_expenses'
                                      value={companyFA}
                                    />
                                  </FSTableData>

                                  <FSTableData>
                                    <LineViewer
                                      param1='incomeStatement'
                                      param2='is_depreciation_amortization'
                                      value={companyFA}
                                    />
                                  </FSTableData>

                                  <FSTableData>
                                    <LineViewer
                                      param1='incomeStatement'
                                      param2='other_income'
                                      value={companyFA}
                                    />
                                  </FSTableData>

                                  <FSTableData>
                                    <LineViewer
                                      param1='incomeStatement'
                                      param2='other_expenses'
                                      value={companyFA}
                                    />
                                  </FSTableData>
                                  <TableEqualLine lineType='single' />
                                  <FSTableData className='fw-bold cell-highlight'>
                                    <LineViewer
                                      param1='incomeStatement'
                                      param2='ebit_c'
                                      value={companyFA}
                                    />
                                  </FSTableData>

                                  <FSTableData>
                                    <LineViewer
                                      param1='incomeStatement'
                                      param2='interest_expense'
                                      value={companyFA}
                                    />
                                  </FSTableData>
                                  <TableEqualLine lineType='single' />
                                  <FSTableData className='fw-bold cell-highlight'>
                                    <LineViewer
                                      param1='incomeStatement'
                                      param2='ebt_c'
                                      value={companyFA}
                                    />
                                  </FSTableData>

                                  <FSTableData>
                                    <LineViewer
                                      param1='incomeStatement'
                                      param2='tax_expense'
                                      value={companyFA}
                                    />
                                  </FSTableData>
                                  <TableEqualLine lineType='single' />
                                  <FSTableData className='fw-bold cell-highlight'>
                                    <LineViewer
                                      param1='incomeStatement'
                                      param2='net_profit_c'
                                      value={companyFA}
                                    />
                                  </FSTableData>

                                  <FSTableData>
                                    <LineViewer
                                      param1='incomeStatement'
                                      param2='other_comprehensive_income'
                                      value={companyFA}
                                    />
                                  </FSTableData>
                                  <TableEqualLine lineType='single' />
                                  <FSTableData className='fw-bold cell-highlight'>
                                    <LineViewer
                                      param1='incomeStatement'
                                      param2='total_comprehensive_income_c'
                                      value={companyFA}
                                    />
                                  </FSTableData>
                                </tr>
                              ))}
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>
                  </div>

                  {/*CASH FLOW ANALYSIS*/}
                  <div className='cash-flow-analysis-wrapper border-default border p-6 mb-6'>
                    <div className='d-flex justify-content-between align-items-start flex-wrap mb-2'>
                      <div className='d-flex'>
                        <KTIcon
                          iconName='questionnaire-tablet'
                          className='fs-2tx text-primary me-4'
                        />
                        <div className='d-flex flex-column'>
                          <h3 className='mb-1'>{intl.formatMessage({id: 'AFS.CASH_FLOW_STATEMENT'})}</h3>
                          <div className='fs-6 fw-bold text-gray-400'>
                            {intl.formatMessage({id: 'AFS.DATA_REQUIRED_FOR_CORRECT_RESULT'})}
                          </div>
                        </div>
                      </div>
                      <div className='d-flex my-4'>
                        <ReactToPrint
                          documentTitle='SME Model Result'
                          bodyClass='print-black-font'
                          content={() => cash_flow_statement_ref.current}
                          trigger={() => (
                            <PrintButton type='button' className='btn btn-primary btn-sm'>
                              <KTIcon
                                iconName='printer'
                                iconType='outline'
                                className='fs-3 text-default'
                              />
                            </PrintButton>
                          )}
                          onBeforeGetContent={() => handleBeforeGetContent()}
                        />
                      </div>
                    </div>
                    <div className='row' style={{ overflow: 'auto' }}>
                      <TransposeTable>
                        {ComputeForm.companyFinancialAnalysis?.map((companyFA, i) => (
                          <tr
                            className={clsx('cell-offset transpose-table', { 'w-500px': i === 0 })}
                            key={i}
                          >
                            <TransposeLineInput
                              label={intl.formatMessage({id: 'AFS.PROFIT_LOSS_BEFORE_TAXATION'})}
                              type='number'
                              param1='cashFlowAnalysis'
                              param2='profit_before_taxation_c'
                              value={companyFA}
                              disabled
                              onchange={(e, p1, p2) => handleYearlyDataChange(e, i, p1, p2)}
                              list={i}
                              tooltip={autoGenTooltip}
                            />
                          </tr>
                        ))}
                      </TransposeTable>
                    </div>
                    {/*ADJUSTMENT*/}
                    <div className='adjustments-wrapper border-default border p-6'>
                      <div className='d-flex'>
                        <KTIcon
                          iconName='questionnaire-tablet'
                          className='fs-2tx text-primary me-4'
                        />
                        <div className='d-flex flex-column'>
                          <h3 className='mb-1'>{intl.formatMessage({id: 'AFS.ADJUSTMENTS'})}</h3>
                          <div className='fs-6 fw-bold text-gray-400'>
                            {intl.formatMessage({id: 'AFS.DATA_REQUIRED_FOR_CORRECT_RESULT'})}
                          </div>
                        </div>
                      </div>
                      <div className='row' style={{ overflow: 'auto' }}>
                        <TransposeTable>
                          {ComputeForm.companyFinancialAnalysis?.map((companyFA, i) => (
                            <tr
                              className={clsx('cell-offset transpose-table', { 'w-500px': i === 0 })}
                              key={i}
                            >
                              <TransposeLineInput
                                label={intl.formatMessage({id: 'AFS.DEPRECIATION_AND_AMORTIZATION'})}
                                type='number'
                                param1='cashFlowAnalysis'
                                param2='adjustments'
                                param3='cf_depreciation_amortization'
                                value={companyFA}
                                list={i}
                                onchange={(e, p1, p2, p3) =>
                                  handleYearlyDataChange(e, i, p1, p2, p3)
                                }
                              />
                              <TransposeLineInput
                                label={intl.formatMessage({id: 'AFS.INTEREST_EXPENSE_INCOME'})}
                                type='number'
                                param1='cashFlowAnalysis'
                                param2='adjustments'
                                param3='interest_expense_income'
                                value={companyFA}
                                list={i}
                                onchange={(e, p1, p2, p3) =>
                                  handleYearlyDataChange(e, i, p1, p2, p3)
                                }
                              />
                              <TransposeLineInput
                                label={intl.formatMessage({id: 'AFS.DIVIDEND_INCOME'})}
                                type='number'
                                param1='cashFlowAnalysis'
                                param2='adjustments'
                                param3='dividend_income'
                                value={companyFA}
                                list={i}
                                onchange={(e, p1, p2, p3) =>
                                  handleYearlyDataChange(e, i, p1, p2, p3)
                                }
                              />
                              <TransposeLineInput
                                label={intl.formatMessage({id: 'AFS.OTHER_ADJUSTMENTS'})}
                                type='number'
                                param1='cashFlowAnalysis'
                                param2='adjustments'
                                param3='other_adjustments'
                                value={companyFA}
                                list={i}
                                onchange={(e, p1, p2, p3) =>
                                  handleYearlyDataChange(e, i, p1, p2, p3)
                                }
                              />
                            </tr>
                          ))}
                        </TransposeTable>
                      </div>
                    </div>
                    <div className='row' style={{ overflow: 'auto' }}>
                      <TransposeTable>
                        {ComputeForm.companyFinancialAnalysis?.map((companyFA, i) => (
                          <tr
                            className={clsx('cell-offset transpose-table', { 'w-500px': i === 0 })}
                            key={i}
                          >
                            <TransposeLineInput
                              label={intl.formatMessage({id: 'AFS.OPERATING_PROFIT_LOSS_BEFORE_WORKING_CAPITAL_CHANGES'})}
                              type='number'
                              param1='cashFlowAnalysis'
                              param2='operating_profit_before_wc_changes_c'
                              value={companyFA}
                              list={i}
                              long
                              disabled
                              onchange={(e, p1, p2) => handleYearlyDataChange(e, i, p1, p2)}
                              tooltip={autoGenTooltip}
                            />
                          </tr>
                        ))}
                      </TransposeTable>
                    </div>
                    {/*WORKING CAPITAL CHANGES*/}
                    <div className='working-capital-changes-wrapper border-default border p-6'>
                      <div className='d-flex'>
                        <KTIcon
                          iconName='questionnaire-tablet'
                          className='fs-2tx text-primary me-4'
                        />
                        <div className='d-flex flex-column'>
                          <h3 className='mb-1'>{intl.formatMessage({id: 'AFS.WORKING_CAPITAL_CHANGES'})}</h3>
                          <div className='fs-6 fw-bold text-gray-400'>
                            {intl.formatMessage({id: 'AFS.DATA_REQUIRED_FOR_CORRECT_RESULT'})}
                          </div>
                        </div>
                      </div>
                      <div className='row' style={{ overflow: 'auto' }}>
                        <TransposeTable>
                          {ComputeForm.companyFinancialAnalysis?.map((companyFA, i) => (
                            <tr
                              className={clsx('cell-offset transpose-table', { 'w-500px': i === 0 })}
                              key={i}
                            >
                              <TransposeLineInput
                                label={intl.formatMessage({id: 'AFS.INVENTORIES'})}
                                type='number'
                                param1='cashFlowAnalysis'
                                param2='working_capital_changes'
                                param3='cf_inventories'
                                value={companyFA}
                                list={i}
                                onchange={(e, p1, p2, p3) =>
                                  handleYearlyDataChange(e, i, p1, p2, p3)
                                }
                              />
                              <TransposeLineInput
                                label={intl.formatMessage({id: 'AFS.TRADE_RECEIVABLES'})}
                                type='number'
                                param1='cashFlowAnalysis'
                                param2='working_capital_changes'
                                param3='cf_trade_receivables'
                                value={companyFA}
                                list={i}
                                onchange={(e, p1, p2, p3) =>
                                  handleYearlyDataChange(e, i, p1, p2, p3)
                                }
                              />
                              <TransposeLineInput
                                label={intl.formatMessage({id: 'AFS.OTHER_RECEIVABLES_PREPAYMENTS'})}
                                type='number'
                                param1='cashFlowAnalysis'
                                param2='working_capital_changes'
                                param3='cf_other_receivables_prepayment'
                                value={companyFA}
                                list={i}
                                onchange={(e, p1, p2, p3) =>
                                  handleYearlyDataChange(e, i, p1, p2, p3)
                                }
                              />
                              <TransposeLineInput
                                label={intl.formatMessage({id: 'AFS.TRADE_PAYABLES'})}
                                type='number'
                                param1='cashFlowAnalysis'
                                param2='working_capital_changes'
                                param3='cf_trade_payables'
                                value={companyFA}
                                list={i}
                                onchange={(e, p1, p2, p3) =>
                                  handleYearlyDataChange(e, i, p1, p2, p3)
                                }
                              />
                              <TransposeLineInput
                                label={intl.formatMessage({id: 'AFS.OTHER_PAYABLES_ACCRUALS'})}
                                type='number'
                                param1='cashFlowAnalysis'
                                param2='working_capital_changes'
                                param3='cf_trade_payables_accruals'
                                value={companyFA}
                                list={i}
                                onchange={(e, p1, p2, p3) =>
                                  handleYearlyDataChange(e, i, p1, p2, p3)
                                }
                              />
                              <TransposeLineInput
                                label={intl.formatMessage({id: 'AFS.AMOUNT_DUE_DIRECTORS_RELATED_PARTIES'})}
                                type='number'
                                param1='cashFlowAnalysis'
                                param2='working_capital_changes'
                                param3='cf_amount_due_to_directors_related_parties'
                                value={companyFA}
                                list={i}
                                long
                                onchange={(e, p1, p2, p3) =>
                                  handleYearlyDataChange(e, i, p1, p2, p3)
                                }
                              />
                              <TransposeLineInput
                                label={intl.formatMessage({id: 'AFS.OTHER_CHANGES_WORKING_CAPITAL'})}
                                type='number'
                                param1='cashFlowAnalysis'
                                param2='working_capital_changes'
                                param3='other_changes_working_capital'
                                value={companyFA}
                                list={i}
                                onchange={(e, p1, p2, p3) =>
                                  handleYearlyDataChange(e, i, p1, p2, p3)
                                }
                              />
                            </tr>
                          ))}
                        </TransposeTable>
                      </div>
                    </div>
                    <div className='row' style={{ overflow: 'auto' }}>
                      <TransposeTable>
                        {ComputeForm.companyFinancialAnalysis?.map((companyFA, i) => (
                          <tr
                            className={clsx('cell-offset transpose-table', { 'w-500px': i === 0 })}
                            key={i}
                          >
                            <TransposeLineInput
                              label={intl.formatMessage({id: 'AFS.CASH_GENERATED_USED_OPERATING_ACTIVITIES'})}
                              type='number'
                              param1='cashFlowAnalysis'
                              param2='cash_generated_used_operating_activities_c'
                              value={companyFA}
                              list={i}
                              long
                              disabled
                              onchange={(e, p1, p2) => handleYearlyDataChange(e, i, p1, p2)}
                              tooltip={autoGenTooltip}
                            />
                            <TransposeLineInput
                              label={intl.formatMessage({id: 'AFS.TAX_PAID_REFUND'})}
                              type='number'
                              param1='cashFlowAnalysis'
                              param2='tax_paid_refund'
                              value={companyFA}
                              list={i}
                              onchange={(e, p1, p2) => handleYearlyDataChange(e, i, p1, p2)}
                            />
                            <TransposeLineInput
                              label={intl.formatMessage({id: 'AFS.OTHER_OPERATING_ACTIVITIES'})}
                              type='number'
                              param1='cashFlowAnalysis'
                              param2='other_operating_activities'
                              value={companyFA}
                              list={i}
                              onchange={(e, p1, p2) => handleYearlyDataChange(e, i, p1, p2)}
                            />
                            <TransposeLineInput
                              label={intl.formatMessage({id: 'AFS.NET_CASH_GENERATED_USED_OPERATING_ACTIVITIES'})}
                              type='number'
                              param1='cashFlowAnalysis'
                              param2='net_cash_generated_used_operating_activities_c'
                              value={companyFA}
                              list={i}
                              long
                              disabled
                              onchange={(e, p1, p2) => handleYearlyDataChange(e, i, p1, p2)}
                              tooltip={autoGenTooltip}
                            />
                          </tr>
                        ))}
                      </TransposeTable>
                    </div>
                    {/*INVESTING ACTIVITIES*/}
                    <div className='investing-activities-wrapper border-default border p-6'>
                      <div className='d-flex'>
                        <KTIcon
                          iconName='questionnaire-tablet'
                          className='fs-2tx text-primary me-4'
                        />
                        <div className='d-flex flex-column'>
                          <h3 className='mb-1'>{intl.formatMessage({id: 'AFS.CASH_FLOW_FROM_INVESTING_ACTIVITIES'})}</h3>
                          <div className='fs-6 fw-bold text-gray-400'>
                            {intl.formatMessage({id: 'AFS.DATA_REQUIRED_FOR_CORRECT_RESULT'})}
                          </div>
                        </div>
                      </div>
                      <div className='row' style={{ overflow: 'auto' }}>
                        <TransposeTable>
                          {ComputeForm.companyFinancialAnalysis?.map((companyFA, i) => (
                            <tr
                              className={clsx('cell-offset transpose-table', { 'w-500px': i === 0 })}
                              key={i}
                            >
                              <TransposeLineInput
                                label={intl.formatMessage({id: 'AFS.DISPOSAL_ACQUISITION_PPE'})}
                                type='number'
                                param1='cashFlowAnalysis'
                                param2='investingActivities'
                                param3='disposal_acquisition_ppe'
                                value={companyFA}
                                list={i}
                                onchange={(e, p1, p2, p3) =>
                                  handleYearlyDataChange(e, i, p1, p2, p3)
                                }
                              />
                              <TransposeLineInput
                                label={intl.formatMessage({id: 'AFS.DISPOSAL_ACQUISITION_EQUITY_DEBT'})}
                                type='number'
                                param1='cashFlowAnalysis'
                                param2='investingActivities'
                                param3='disposal_acquisition_equity_debt'
                                value={companyFA}
                                list={i}
                                long
                                onchange={(e, p1, p2, p3) =>
                                  handleYearlyDataChange(e, i, p1, p2, p3)
                                }
                              />
                              <TransposeLineInput
                                label={intl.formatMessage({id: 'AFS.DIVIDENDS_RECEIVED'})}
                                type='number'
                                param1='cashFlowAnalysis'
                                param2='investingActivities'
                                param3='dividends_received'
                                value={companyFA}
                                list={i}
                                onchange={(e, p1, p2, p3) =>
                                  handleYearlyDataChange(e, i, p1, p2, p3)
                                }
                              />
                              <TransposeLineInput
                                label={intl.formatMessage({id: 'AFS.INTEREST_RECEIVED'})}
                                type='number'
                                param1='cashFlowAnalysis'
                                param2='investingActivities'
                                param3='interest_received'
                                value={companyFA}
                                list={i}
                                onchange={(e, p1, p2, p3) =>
                                  handleYearlyDataChange(e, i, p1, p2, p3)
                                }
                              />
                              <TransposeLineInput
                                label={intl.formatMessage({id: 'AFS.OTHER_INVESTING_ACTIVITIES'})}
                                type='number'
                                param1='cashFlowAnalysis'
                                param2='investingActivities'
                                param3='other_investing_activities'
                                value={companyFA}
                                list={i}
                                onchange={(e, p1, p2, p3) =>
                                  handleYearlyDataChange(e, i, p1, p2, p3)
                                }
                              />
                              <TransposeLineInput
                                label={intl.formatMessage({id: 'AFS.NET_CASH_GENERATED_FROM_INVESTING_ACTIVITIES'})}
                                type='number'
                                param1='cashFlowAnalysis'
                                param2='investingActivities'
                                param3='net_cash_generated_used_investing_activities_c'
                                value={companyFA}
                                list={i}
                                long
                                disabled
                                onchange={(e, p1, p2, p3) =>
                                  handleYearlyDataChange(e, i, p1, p2, p3)
                                }
                                tooltip={autoGenTooltip}
                              />
                            </tr>
                          ))}
                        </TransposeTable>
                      </div>
                    </div>

                    {/*FINANCING ACTIVITIES*/}
                    <div className='financing-activities-wrapper border-default border p-6'>
                      <div className='d-flex'>
                        <KTIcon
                          iconName='questionnaire-tablet'
                          className='fs-2tx text-primary me-4'
                        />
                        <div className='d-flex flex-column'>
                          <h3 className='mb-1'>{intl.formatMessage({id: 'AFS.CASH_FLOW_FROM_FINANCING_ACTIVITIES'})}</h3>
                          <div className='fs-6 fw-bold text-gray-400'>
                            {intl.formatMessage({id: 'AFS.DATA_REQUIRED_FOR_CORRECT_RESULT'})}
                          </div>
                        </div>
                      </div>
                      <div className='row' style={{ overflow: 'auto' }}>
                        <TransposeTable>
                          {ComputeForm.companyFinancialAnalysis?.map((companyFA, i) => (
                            <tr
                              className={clsx('cell-offset transpose-table', { 'w-500px': i === 0 })}
                              key={i}
                            >
                              <TransposeLineInput
                                label={intl.formatMessage({id: 'AFS.ISSUANCE_REPURCHASE_SHARES'})}
                                type='number'
                                param1='cashFlowAnalysis'
                                param2='financingActivities'
                                param3='issuance_repurchase_shares'
                                value={companyFA}
                                list={i}
                                onchange={(e, p1, p2, p3) =>
                                  handleYearlyDataChange(e, i, p1, p2, p3)
                                }
                              />
                              <TransposeLineInput
                                label={intl.formatMessage({id: 'AFS.ISSUANCE_REPAYMENT_DEBT'})}
                                type='number'
                                param1='cashFlowAnalysis'
                                param2='financingActivities'
                                param3='issuance_repayment_debt'
                                value={companyFA}
                                list={i}
                                onchange={(e, p1, p2, p3) =>
                                  handleYearlyDataChange(e, i, p1, p2, p3)
                                }
                              />
                              {/* interest_paid
                              dividends_paid */}
                              <TransposeLineInput
                                label={intl.formatMessage({id: 'AFS.DIVIDENDS_PAID'})}
                                type='number'
                                param1='cashFlowAnalysis'
                                param2='financingActivities'
                                param3='dividends_paid'
                                value={companyFA}
                                list={i}
                                onchange={(e, p1, p2, p3) =>
                                  handleYearlyDataChange(e, i, p1, p2, p3)
                                }
                              />
                              <TransposeLineInput
                                label={intl.formatMessage({id: 'AFS.INTEREST_PAID'})}
                                type='number'
                                param1='cashFlowAnalysis'
                                param2='financingActivities'
                                param3='interest_paid'
                                value={companyFA}
                                list={i}
                                onchange={(e, p1, p2, p3) =>
                                  handleYearlyDataChange(e, i, p1, p2, p3)
                                }
                              />
                              <TransposeLineInput
                                label={intl.formatMessage({id: 'AFS.OTHER_FINANCING_ACTIVITIES'})}
                                type='number'
                                param1='cashFlowAnalysis'
                                param2='financingActivities'
                                param3='other_financing_activities'
                                value={companyFA}
                                list={i}
                                onchange={(e, p1, p2, p3) =>
                                  handleYearlyDataChange(e, i, p1, p2, p3)
                                }
                              />
                              <TransposeLineInput
                                label={intl.formatMessage({id: 'AFS.CASH_AND_CASH_EQUIVALENTS_AT_END_OF_FINANCIAL_YEAR'})}
                                type='number'
                                param1='cashFlowAnalysis'
                                param2='financingActivities'
                                param3='net_cash_generated_used_financing_activities_c'
                                value={companyFA}
                                list={i}
                                long
                                disabled
                                onchange={(e, p1, p2, p3) =>
                                  handleYearlyDataChange(e, i, p1, p2, p3)
                                }
                                tooltip={autoGenTooltip}
                              />
                            </tr>
                          ))}
                        </TransposeTable>
                      </div>
                    </div>
                    <div className='row' style={{ overflow: 'auto' }}>
                      <TransposeTable>
                        {ComputeForm.companyFinancialAnalysis?.map((companyFA, i) => (
                          <tr
                            className={clsx('cell-offset transpose-table', { 'w-500px': i === 0 })}
                            key={i}
                          >
                            <TransposeLineInput
                              label={intl.formatMessage({id: 'AFS.NET_CHANGE_IN_CASH_AND_CASH_EQUIVALENTS'})}
                              type='number'
                              param1='cashFlowAnalysis'
                              param2='net_cce_change_c'
                              value={companyFA}
                              list={i}
                              long
                              disabled
                              onchange={(e, p1, p2) => handleYearlyDataChange(e, i, p1, p2)}
                              tooltip={autoGenTooltip}
                            />
                            <TransposeLineInput
                              label={intl.formatMessage({id: 'AFS.OTHER_ADJUSTMENTS_TO_ENDING_CASH'})}
                              type='number'
                              param1='cashFlowAnalysis'
                              param2='other_adjustments_to_ending_cash'
                              value={companyFA}
                              list={i}
                              onchange={(e, p1, p2) => handleYearlyDataChange(e, i, p1, p2)}
                            />
                            <TransposeLineInput
                              label={intl.formatMessage({id: 'AFS.CASH_AND_CASH_EQUIVALENTS_AT_BEGINNING_OF_FINANCIAL_YEAR'})}
                              type='number'
                              param1='cashFlowAnalysis'
                              param2='cce_start_fin_year'
                              value={companyFA}
                              list={i}
                              long
                              onchange={(e, p1, p2) => handleYearlyDataChange(e, i, p1, p2)}
                            />
                            <TransposeLineInput
                              label={intl.formatMessage({id: 'AFS.CASH_AND_CASH_EQUIVALENTS_AT_END_OF_FINANCIAL_YEAR'})}
                              type='number'
                              param1='cashFlowAnalysis'
                              param2='cce_end_fin_year_c'
                              value={companyFA}
                              list={i}
                              long
                              disabled
                              onchange={(e, p1, p2) => handleYearlyDataChange(e, i, p1, p2)}
                              tooltip={autoGenTooltip}
                            />
                          </tr>
                        ))}
                      </TransposeTable>
                    </div>
                    <div className='row'>
                      {/* Printable Cash Flow Statement */}
                      <div ref={cash_flow_statement_ref} className='print-only card-body p-9'>
                        <AFSPrintingHeader
                          Title={intl.formatMessage({id: 'AFS.CASH_FLOW_STATEMENT'})}
                          ComputeForm={ComputeForm}
                          CurrentTime={CurrentTime}
                        />

                        <div className='row mb-2'>
                          <table className='transpose-table'>
                            <tbody>
                              <tr className='min-w-50'>
                                <FSTableDataHead className='white-text-on-blue-bg fw-bold text-white'>
                                  &nbsp;
                                </FSTableDataHead>
                                <FSTableDataHead className='fw-bold cell-highlight'>
                                  {intl.formatMessage({id: 'AFS.PROFIT_LOSS_BEFORE_TAXATION'})}
                                </FSTableDataHead>
                                {/* Adjustment */}
                                <FSTableDataHead>{intl.formatMessage({id: 'AFS.ADJUSTMENTS'})}</FSTableDataHead>
                                <FSTableDataHead>{intl.formatMessage({id: 'AFS.DEPRECIATION_AND_AMORTIZATION'})}</FSTableDataHead>
                                <FSTableDataHead>{intl.formatMessage({id: 'AFS.INTEREST_EXPENSE_INCOME'})}</FSTableDataHead>
                                <FSTableDataHead>{intl.formatMessage({id: 'AFS.DIVIDEND_INCOME'})}</FSTableDataHead>
                                <FSTableDataHead>{intl.formatMessage({id: 'AFS.OTHER_ADJUSTMENTS'})}</FSTableDataHead>
                                <TableEqualLine lineType='single' offset />
                                <FSTableDataHead className='fw-bold cell-highlight'>
                                  {intl.formatMessage({id: 'AFS.OPERATING_PROFIT_LOSS_BEFORE_WORKING_CAPITAL_CHANGES'})}
                                </FSTableDataHead>
                                <TableEqualLine lineType='double' offset />
                                {/* Change in working capital */}
                                <FSTableDataHead className='fw-bold'>
                                  {intl.formatMessage({id: 'AFS.WORKING_CAPITAL_CHANGES'})}
                                </FSTableDataHead>
                                <FSTableDataHead>{intl.formatMessage({id: 'AFS.INVENTORIES'})}</FSTableDataHead>
                                <FSTableDataHead>{intl.formatMessage({id: 'AFS.TRADE_RECEIVABLES'})}</FSTableDataHead>
                                <FSTableDataHead>{intl.formatMessage({id: 'AFS.OTHER_RECEIVABLES_PREPAYMENTS'})}</FSTableDataHead>
                                <FSTableDataHead>{intl.formatMessage({id: 'AFS.TRADE_PAYABLES'})}</FSTableDataHead>
                                <FSTableDataHead>{intl.formatMessage({id: 'AFS.OTHER_PAYABLES_ACCRUALS'})}</FSTableDataHead>
                                <FSTableDataHead>
                                  {intl.formatMessage({id: 'AFS.AMOUNT_DUE_DIRECTORS_RELATED_PARTIES'})}
                                </FSTableDataHead>
                                <FSTableDataHead>{intl.formatMessage({id: 'AFS.OTHER_CHANGES_WORKING_CAPITAL'})}</FSTableDataHead>
                                <TableEqualLine lineType='single' offset />
                                <FSTableDataHead className='fw-bold cell-highlight'>
                                  {intl.formatMessage({id: 'AFS.CASH_GENERATED_USED_OPERATING_ACTIVITIES'})}
                                </FSTableDataHead>
                                <FSTableDataHead>{intl.formatMessage({id: 'AFS.TAX_PAID_REFUND'})}</FSTableDataHead>
                                <FSTableDataHead>{intl.formatMessage({id: 'AFS.OTHER_OPERATING_ACTIVITIES'})}</FSTableDataHead>
                                <TableEqualLine lineType='single' offset />
                                <FSTableDataHead className='fw-bold cell-highlight'>
                                  {intl.formatMessage({id: 'AFS.NET_CASH_GENERATED_USED_OPERATING_ACTIVITIES'})}
                                </FSTableDataHead>
                                <TableEqualLine lineType='double' offset />
                                <FSTableDataHead className='fw-bold'>
                                  {intl.formatMessage({id: 'AFS.CASH_FLOW_FROM_INVESTING_ACTIVITIES'})}
                                </FSTableDataHead>
                                <FSTableDataHead>{intl.formatMessage({id: 'AFS.DISPOSAL_ACQUISITION_PPE'})}</FSTableDataHead>
                                <FSTableDataHead>
                                  {intl.formatMessage({id: 'AFS.DISPOSAL_ACQUISITION_EQUITY_DEBT'})}
                                </FSTableDataHead>
                                <FSTableDataHead>{intl.formatMessage({id: 'AFS.DIVIDENDS_RECEIVED'})}</FSTableDataHead>
                                <FSTableDataHead>{intl.formatMessage({id: 'AFS.INTEREST_RECEIVED'})}</FSTableDataHead>
                                <FSTableDataHead>{intl.formatMessage({id: 'AFS.OTHER_INVESTING_ACTIVITIES'})}</FSTableDataHead>
                                <TableEqualLine lineType='single' offset />
                                <FSTableDataHead className='fw-bold'>
                                  {intl.formatMessage({id: 'AFS.NET_CASH_GENERATED_FROM_INVESTING_ACTIVITIES'})}
                                </FSTableDataHead>
                                <TableEqualLine lineType='double' offset />
                                <FSTableDataHead className='fw-bold cell-highlight'>
                                  {intl.formatMessage({id: 'AFS.CASH_FLOW_FROM_FINANCING_ACTIVITIES'})}
                                </FSTableDataHead>
                                <FSTableDataHead>{intl.formatMessage({id: 'AFS.ISSUANCE_REPURCHASE_SHARES'})}</FSTableDataHead>
                                <FSTableDataHead>{intl.formatMessage({id: 'AFS.ISSUANCE_REPAYMENT_DEBT'})}</FSTableDataHead>
                                <FSTableDataHead>{intl.formatMessage({id: 'AFS.DIVIDENDS_PAID'})}</FSTableDataHead>
                                <FSTableDataHead>{intl.formatMessage({id: 'AFS.INTEREST_PAID'})}</FSTableDataHead>
                                <FSTableDataHead>{intl.formatMessage({id: 'AFS.OTHER_FINANCING_ACTIVITIES'})}</FSTableDataHead>
                                <TableEqualLine lineType='single' offset />
                                <FSTableDataHead className='fw-bold cell-highlight'>
                                  {intl.formatMessage({id: 'AFS.NET_CASH_GENERATED_FROM_FINANCING_ACTIVITIES'})}
                                </FSTableDataHead>
                                <TableEqualLine lineType='double' offset />
                                <FSTableDataHead className='fw-bold'>
                                  {intl.formatMessage({id: 'AFS.NET_INCREASE_DECREASE_CASH_AND_CASH_EQUIVALENTS'})}
                                </FSTableDataHead>
                                <FSTableDataHead>{intl.formatMessage({id: 'AFS.OTHER_ADJUSTMENTS_TO_ENDING_CASH'})}</FSTableDataHead>
                                <FSTableDataHead>
                                  {intl.formatMessage({id: 'AFS.CASH_AND_CASH_EQUIVALENTS_AT_BEGINNING_OF_FINANCIAL_YEAR'})}
                                </FSTableDataHead>
                                <TableEqualLine lineType='single' offset />
                                <FSTableDataHead className='fw-bold'>
                                  {intl.formatMessage({id: 'AFS.CASH_AND_CASH_EQUIVALENTS_AT_END_OF_FINANCIAL_YEAR'})}
                                </FSTableDataHead>
                                <TableEqualLine lineType='double' offset />
                              </tr>
                              {ComputeForm.companyFinancialAnalysis?.map((companyFA, i) => (
                                <tr style={{ minWidth: '15%' }} key={i}>
                                  <FSTableData
                                    className='white-text-on-blue-bg fw-bold text-white'
                                    style={{
                                      backgroundColor: '#36486b',
                                    }}
                                  >
                                    {companyFA.financial_year_c}
                                  </FSTableData>
                                  <FSTableData className='fw-bold cell-highlight'>
                                    <LineViewer
                                      param1='cashFlowAnalysis'
                                      param2='profit_before_taxation_c'
                                      value={companyFA}
                                    />
                                  </FSTableData>
                                  <FSTableData>&nbsp;</FSTableData>
                                  {/* Adjustment */}
                                  <FSTableData>
                                    <LineViewer
                                      param1='cashFlowAnalysis'
                                      param2='adjustments'
                                      param3='cf_depreciation_amortization'
                                      value={companyFA}
                                    />
                                  </FSTableData>

                                  <FSTableData>
                                    <LineViewer
                                      param1='cashFlowAnalysis'
                                      param2='adjustments'
                                      param3='interest_expense_income'
                                      value={companyFA}
                                    />
                                  </FSTableData>

                                  <FSTableData>
                                    <LineViewer
                                      param1='cashFlowAnalysis'
                                      param2='adjustments'
                                      param3='dividend_income'
                                      value={companyFA}
                                    />
                                  </FSTableData>

                                  <FSTableData>
                                    <LineViewer
                                      param1='cashFlowAnalysis'
                                      param2='adjustments'
                                      param3='other_adjustments'
                                      value={companyFA}
                                    />
                                  </FSTableData>
                                  <TableEqualLine lineType='single' />
                                  <FSTableData className='fw-bold cell-highlight'>
                                    <LineViewer
                                      param1='cashFlowAnalysis'
                                      param2='operating_profit_before_wc_changes_c'
                                      value={companyFA}
                                    />
                                  </FSTableData>
                                  <TableEqualLine lineType='double' />
                                  <FSTableData>&nbsp;</FSTableData>
                                  {/* Change in working capital */}
                                  <FSTableData>
                                    <LineViewer
                                      param1='cashFlowAnalysis'
                                      param2='working_capital_changes'
                                      param3='cf_inventories'
                                      value={companyFA}
                                    />
                                  </FSTableData>

                                  <FSTableData>
                                    <LineViewer
                                      param1='cashFlowAnalysis'
                                      param2='working_capital_changes'
                                      param3='cf_trade_receivables'
                                      value={companyFA}
                                    />
                                  </FSTableData>

                                  <FSTableData>
                                    <LineViewer
                                      param1='cashFlowAnalysis'
                                      param2='working_capital_changes'
                                      param3='cf_other_receivables_prepayment'
                                      value={companyFA}
                                    />
                                  </FSTableData>

                                  <FSTableData>
                                    <LineViewer
                                      param1='cashFlowAnalysis'
                                      param2='working_capital_changes'
                                      param3='cf_trade_payables'
                                      value={companyFA}
                                    />
                                  </FSTableData>

                                  <FSTableData>
                                    <LineViewer
                                      param1='cashFlowAnalysis'
                                      param2='working_capital_changes'
                                      param3='cf_trade_payables_accruals'
                                      value={companyFA}
                                    />
                                  </FSTableData>

                                  <FSTableData>
                                    <LineViewer
                                      param1='cashFlowAnalysis'
                                      param2='working_capital_changes'
                                      param3='cf_amount_due_to_directors_related_parties'
                                      value={companyFA}
                                    />
                                  </FSTableData>

                                  <FSTableData>
                                    <LineViewer
                                      param1='cashFlowAnalysis'
                                      param2='working_capital_changes'
                                      param3='other_changes_working_capital'
                                      value={companyFA}
                                    />
                                  </FSTableData>
                                  <TableEqualLine lineType='single' />
                                  <FSTableData className='fw-bold cell-highlight'>
                                    <LineViewer
                                      param1='cashFlowAnalysis'
                                      param2='cash_generated_used_operating_activities_c'
                                      value={companyFA}
                                    />
                                  </FSTableData>

                                  <FSTableData>
                                    <LineViewer
                                      param1='cashFlowAnalysis'
                                      param2='tax_paid_refund'
                                      value={companyFA}
                                    />
                                  </FSTableData>

                                  <FSTableData>
                                    <LineViewer
                                      param1='cashFlowAnalysis'
                                      param2='other_operating_activities'
                                      value={companyFA}
                                    />
                                  </FSTableData>

                                  <TableEqualLine lineType='single' />
                                  <FSTableData className='fw-bold cell-highlight'>
                                    <LineViewer
                                      param1='cashFlowAnalysis'
                                      param2='net_cash_generated_used_operating_activities_c'
                                      value={companyFA}
                                    />
                                  </FSTableData>
                                  <TableEqualLine lineType='double' />

                                  <FSTableData>&nbsp;</FSTableData>

                                  <FSTableData>
                                    <LineViewer
                                      param1='cashFlowAnalysis'
                                      param2='investingActivities'
                                      param3='disposal_acquisition_ppe'
                                      value={companyFA}
                                    />
                                  </FSTableData>

                                  <FSTableData>
                                    <LineViewer
                                      param1='cashFlowAnalysis'
                                      param2='investingActivities'
                                      param3='disposal_acquisition_equity_debt'
                                      value={companyFA}
                                    />
                                  </FSTableData>

                                  <FSTableData>
                                    <LineViewer
                                      param1='cashFlowAnalysis'
                                      param2='investingActivities'
                                      param3='dividends_received'
                                      value={companyFA}
                                    />
                                  </FSTableData>

                                  <FSTableData>
                                    <LineViewer
                                      param1='cashFlowAnalysis'
                                      param2='investingActivities'
                                      param3='interest_received'
                                      value={companyFA}
                                    />
                                  </FSTableData>

                                  <FSTableData>
                                    <LineViewer
                                      param1='cashFlowAnalysis'
                                      param2='investingActivities'
                                      param3='other_investing_activities'
                                      value={companyFA}
                                    />
                                  </FSTableData>
                                  <TableEqualLine lineType='single' />
                                  <FSTableData className='fw-bold'>
                                    <LineViewer
                                      param1='cashFlowAnalysis'
                                      param2='investingActivities'
                                      param3='net_cash_generated_used_investing_activities_c'
                                      value={companyFA}
                                    />
                                  </FSTableData>
                                  <TableEqualLine lineType='double' />

                                  <FSTableData className='fw-bold cell-highlight'>
                                    &nbsp;
                                  </FSTableData>
                                  <FSTableData>
                                    <LineViewer
                                      param1='cashFlowAnalysis'
                                      param2='financingActivities'
                                      param3='issuance_repurchase_shares'
                                      value={companyFA}
                                    />
                                  </FSTableData>

                                  <FSTableData>
                                    <LineViewer
                                      param1='cashFlowAnalysis'
                                      param2='financingActivities'
                                      param3='issuance_repayment_debt'
                                      value={companyFA}
                                    />
                                  </FSTableData>

                                  <FSTableData>
                                    <LineViewer
                                      param1='cashFlowAnalysis'
                                      param2='financingActivities'
                                      param3='dividends_paid'
                                      value={companyFA}
                                    />
                                  </FSTableData>

                                  <FSTableData>
                                    <LineViewer
                                      param1='cashFlowAnalysis'
                                      param2='financingActivities'
                                      param3='interest_paid'
                                      value={companyFA}
                                    />
                                  </FSTableData>

                                  <FSTableData>
                                    <LineViewer
                                      param1='cashFlowAnalysis'
                                      param2='financingActivities'
                                      param3='other_financing_activities'
                                      value={companyFA}
                                    />
                                  </FSTableData>
                                  <TableEqualLine lineType='single' />
                                  <FSTableData className='fw-bold cell-highlight'>
                                    <LineViewer
                                      param1='cashFlowAnalysis'
                                      param2='financingActivities'
                                      param3='net_cash_generated_used_financing_activities_c'
                                      value={companyFA}
                                    />
                                  </FSTableData>
                                  <TableEqualLine lineType='double' />

                                  <FSTableData className='fw-bold'>
                                    <LineViewer
                                      param1='cashFlowAnalysis'
                                      param2='net_cce_change_c'
                                      value={companyFA}
                                    />
                                  </FSTableData>

                                  <FSTableData>
                                    <LineViewer
                                      param1='cashFlowAnalysis'
                                      param2='other_adjustments_to_ending_cash'
                                      value={companyFA}
                                    />
                                  </FSTableData>
                                  <FSTableData>
                                    <LineViewer
                                      param1='cashFlowAnalysis'
                                      param2='cce_start_fin_year'
                                      value={companyFA}
                                    />
                                  </FSTableData>
                                  <TableEqualLine lineType='single' />
                                  <FSTableData className='fw-bold'>
                                    <LineViewer
                                      param1='cashFlowAnalysis'
                                      param2='cce_end_fin_year_c'
                                      value={companyFA}
                                    />
                                  </FSTableData>
                                  <TableEqualLine lineType='double' />
                                </tr>
                              ))}
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>
                  </div>

                  {/*CONSOLIDATED BALANCE SHEET*/}
                  <div className='consolidated-balance-sheet-wrapper border-default border p-6 mb-6'>
                    <div className='d-flex justify-content-between align-items-start flex-wrap mb-2'>
                      <div className='d-flex my-4'>
                        <KTIcon
                          iconName='questionnaire-tablet'
                          className='fs-2tx text-primary me-4'
                        />
                        <div className='d-flex flex-column'>
                          <h3 className='mb-1'>{intl.formatMessage({id: 'AFS.CONSOLIDATED_BALANCE_SHEET'})}</h3>
                          <div className='fs-6 fw-bold text-gray-400'>
                            {intl.formatMessage({id: 'AFS.DATA_REQUIRED_FOR_CORRECT_RESULT'})}
                          </div>
                        </div>
                      </div>
                      <div className='d-flex my-4'>
                        <ReactToPrint
                          documentTitle='SME Model Result'
                          bodyClass='print-black-font'
                          content={() => consolidated_balance_sheet_ref.current}
                          trigger={() => (
                            <PrintButton type='button' className='btn btn-primary btn-sm'>
                              <KTIcon
                                iconName='printer'
                                iconType='outline'
                                className='fs-3 text-default'
                              />
                            </PrintButton>
                          )}
                          onBeforeGetContent={() => handleBeforeGetContent()}
                        />
                      </div>
                    </div>

                    {/*ASSETS*/}
                    <div className='assets-wrapper border-default border p-6'>
                      <div className='d-flex'>
                        <KTIcon
                          iconName='questionnaire-tablet'
                          className='fs-2tx text-primary me-4'
                        />
                        <div className='d-flex flex-column'>
                          <h3 className='mb-1'>{intl.formatMessage({id: 'AFS.ASSETS'})}</h3>
                          <div className='fs-6 fw-bold text-gray-400'>
                            {intl.formatMessage({id: 'AFS.DATA_REQUIRED_FOR_CORRECT_RESULT'})}
                            </div>
                        </div>
                      </div>
                      <div className='row' style={{ overflow: 'auto' }}>
                        <TransposeTable>
                          {ComputeForm.companyFinancialAnalysis?.map((companyFA, i) => (
                            <tr
                              className={clsx('cell-offset transpose-table', { 'w-500px': i === 0 })}
                              key={i}
                            >
                              <TransposeLineInput
                                label={intl.formatMessage({id: 'AFS.INVENTORIES'})}
                                type='number'
                                param1='consolidatedBalanceSheet'
                                param2='assets'
                                param3='ast_inventories'
                                value={companyFA}
                                list={i}
                                onchange={(e, p1, p2, p3) =>
                                  handleYearlyDataChange(e, i, p1, p2, p3)
                                }
                              />
                              <TransposeLineInput
                                label={intl.formatMessage({id: 'AFS.TRADE_RECEIVABLES'})}
                                type='number'
                                param1='consolidatedBalanceSheet'
                                param2='assets'
                                param3='ast_trade_receivables'
                                value={companyFA}
                                list={i}
                                onchange={(e, p1, p2, p3) =>
                                  handleYearlyDataChange(e, i, p1, p2, p3)
                                }
                              />
                              <TransposeLineInput
                                label={intl.formatMessage({id: 'AFS.OTHER_RECEIVABLES_PREPAYMENTS'})}
                                type='number'
                                param1='consolidatedBalanceSheet'
                                param2='assets'
                                param3='ast_other_receivables_prepayments'
                                value={companyFA}
                                list={i}
                                long
                                onchange={(e, p1, p2, p3) =>
                                  handleYearlyDataChange(e, i, p1, p2, p3)
                                }
                                tooltip={intl.formatMessage({id: 'TOOLTIP.OTHER_RECEIVABLES_PREPAYMENTS'})}
                              />
                              <TransposeLineInput
                                label={intl.formatMessage({id: 'AFS.CASH_AND_CASH_EQUIVALENTS'})}
                                type='number'
                                param1='consolidatedBalanceSheet'
                                param2='assets'
                                param3='ast_cash_and_cash_equivalents'
                                value={companyFA}
                                list={i}
                                onchange={(e, p1, p2, p3) =>
                                  handleYearlyDataChange(e, i, p1, p2, p3)
                                }
                              />
                              <TransposeLineInput
                                label={intl.formatMessage({id: 'AFS.OTHER_CURRENT_ASSETS'})}
                                type='number'
                                param1='consolidatedBalanceSheet'
                                param2='assets'
                                param3='other_current_assets'
                                value={companyFA}
                                list={i}
                                onchange={(e, p1, p2, p3) =>
                                  handleYearlyDataChange(e, i, p1, p2, p3)
                                }
                              />
                              <TransposeLineInput
                                label={intl.formatMessage({id: 'AFS.TOTAL_CURRENT_ASSETS'})}
                                type='number'
                                param1='consolidatedBalanceSheet'
                                param2='assets'
                                param3='total_current_assets_c'
                                value={companyFA}
                                list={i}
                                disabled
                                onchange={(e, p1, p2, p3) =>
                                  handleYearlyDataChange(e, i, p1, p2, p3)
                                }
                                tooltip={autoGenTooltip}
                              />
                              <TransposeLineInput
                                label={intl.formatMessage({id: 'AFS.PROPERTY_PLANT_AND_EQUIPMENT_NET'})}
                                type='number'
                                param1='consolidatedBalanceSheet'
                                param2='assets'
                                param3='property_plant_equipment_net'
                                value={companyFA}
                                list={i}
                                onchange={(e, p1, p2, p3) =>
                                  handleYearlyDataChange(e, i, p1, p2, p3)
                                }
                                tooltip={intl.formatMessage({id: 'TOOLTIP.PROPERTY_PLANT_AND_EQUIPMENT_NET'})}
                              />
                              <TransposeLineInput
                                label={intl.formatMessage({id: 'AFS.INTANGIBLE_ASSETS_NET'})}
                                type='number'
                                param1='consolidatedBalanceSheet'
                                param2='assets'
                                param3='intangible_assets_net'
                                value={companyFA}
                                list={i}
                                onchange={(e, p1, p2, p3) =>
                                  handleYearlyDataChange(e, i, p1, p2, p3)
                                }
                                tooltip={intl.formatMessage({id: 'TOOLTIP.INTANGIBLE_ASSETS_NET'})}
                              />
                              <TransposeLineInput
                                label={intl.formatMessage({id: 'AFS.DEFERRED_TAX_ASSETS'})}
                                type='number'
                                param1='consolidatedBalanceSheet'
                                param2='assets'
                                param3='deferred_tax_assets'
                                value={companyFA}
                                list={i}
                                onchange={(e, p1, p2, p3) =>
                                  handleYearlyDataChange(e, i, p1, p2, p3)
                                }
                              />
                              <TransposeLineInput
                                label={intl.formatMessage({id: 'AFS.OTHER_NON_CURRENT_ASSETS'})}
                                type='number'
                                param1='consolidatedBalanceSheet'
                                param2='assets'
                                param3='other_non_current_assets'
                                value={companyFA}
                                list={i}
                                onchange={(e, p1, p2, p3) =>
                                  handleYearlyDataChange(e, i, p1, p2, p3)
                                }
                              />
                              <TransposeLineInput
                                label={intl.formatMessage({id: 'AFS.TOTAL_ASSETS'})}
                                type='number'
                                param1='consolidatedBalanceSheet'
                                param2='assets'
                                param3='ast_total_asset_c'
                                value={companyFA}
                                list={i}
                                disabled
                                onchange={(e, p1, p2, p3) =>
                                  handleYearlyDataChange(e, i, p1, p2, p3)
                                }
                                tooltip={autoGenTooltip}
                              />
                            </tr>
                          ))}
                        </TransposeTable>
                      </div>
                    </div>

                    {/*LIABILITY AND EQUITY*/}
                    <div className='liabilities-and-equity-wrapper border-default border p-6'>
                      <div className='d-flex'>
                        <KTIcon
                          iconName='questionnaire-tablet'
                          className='fs-2tx text-primary me-4'
                        />
                        <div className='d-flex flex-column'>
                          <h3 className='mb-1'>{intl.formatMessage({id: 'AFS.LIABILITIES_AND_EQUITY'})}</h3>
                          <div className='fs-6 fw-bold text-gray-400'>
                            {intl.formatMessage({id: 'AFS.DATA_REQUIRED_FOR_CORRECT_RESULT'})}
                          </div>
                        </div>
                      </div>
                      <div className='row' style={{ overflow: 'auto' }}>
                        <TransposeTable>
                          {ComputeForm.companyFinancialAnalysis?.map((companyFA, i) => (
                            <tr
                              className={clsx('cell-offset transpose-table', { 'w-500px': i === 0 })}
                              key={i}
                            >
                              <TransposeLineInput
                                label={intl.formatMessage({id: 'AFS.TRADE_PAYABLES'})}
                                type='number'
                                param1='consolidatedBalanceSheet'
                                param2='liabilitiesAndEquity'
                                param3='lieq_trade_payables'
                                value={companyFA}
                                list={i}
                                onchange={(e, p1, p2, p3) =>
                                  handleYearlyDataChange(e, i, p1, p2, p3)
                                }
                              />
                              <TransposeLineInput
                                label={intl.formatMessage({id: 'AFS.SHORT_TERM_BORROWINGS'})}
                                type='number'
                                param1='consolidatedBalanceSheet'
                                param2='liabilitiesAndEquity'
                                param3='short_term_borrowings'
                                value={companyFA}
                                list={i}
                                onchange={(e, p1, p2, p3) =>
                                  handleYearlyDataChange(e, i, p1, p2, p3)
                                }
                              />
                              <TransposeLineInput
                                label={intl.formatMessage({id: 'AFS.AMOUNT_DUE_DIRECTORS_RELATED_PARTIES'})}
                                type='number'
                                param1='consolidatedBalanceSheet'
                                param2='liabilitiesAndEquity'
                                param3='lieq_amount_due_to_directors_related_parties'
                                value={companyFA}
                                list={i}
                                long
                                onchange={(e, p1, p2, p3) =>
                                  handleYearlyDataChange(e, i, p1, p2, p3)
                                }
                              />
                              <TransposeLineInput
                                label={intl.formatMessage({id: 'AFS.OTHER_PAYABLES_ACCRUALS'})}
                                type='number'
                                param1='consolidatedBalanceSheet'
                                param2='liabilitiesAndEquity'
                                param3='lieq_other_payables_accruals'
                                value={companyFA}
                                list={i}
                                onchange={(e, p1, p2, p3) =>
                                  handleYearlyDataChange(e, i, p1, p2, p3)
                                }
                                tooltip={intl.formatMessage({id: 'TOOLTIP.OTHER_PAYABLES_ACCRUALS'})}
                              />
                              <TransposeLineInput
                                label={intl.formatMessage({id: 'AFS.OTHER_CURRENT_LIABILITIES'})}
                                type='number'
                                param1='consolidatedBalanceSheet'
                                param2='liabilitiesAndEquity'
                                param3='lieq_other_current_liabilities'
                                value={companyFA}
                                list={i}
                                onchange={(e, p1, p2, p3) =>
                                  handleYearlyDataChange(e, i, p1, p2, p3)
                                }
                              />
                              <TransposeLineInput
                                label={intl.formatMessage({id: 'AFS.TOTAL_CURRENT_LIABILITIES'})}
                                type='number'
                                param1='consolidatedBalanceSheet'
                                param2='liabilitiesAndEquity'
                                param3='total_current_liabilities_c'
                                value={companyFA}
                                list={i}
                                disabled
                                onchange={(e, p1, p2, p3) =>
                                  handleYearlyDataChange(e, i, p1, p2, p3)
                                }
                                tooltip={autoGenTooltip}
                              />
                              <TransposeLineInput
                                label={intl.formatMessage({id: 'AFS.LONG_TERM_BORROWINGS'})}
                                type='number'
                                param1='consolidatedBalanceSheet'
                                param2='liabilitiesAndEquity'
                                param3='long_term_borrowings'
                                value={companyFA}
                                list={i}
                                onchange={(e, p1, p2, p3) =>
                                  handleYearlyDataChange(e, i, p1, p2, p3)
                                }
                              />
                              <TransposeLineInput
                                label={intl.formatMessage({id: 'AFS.DEFERRED_TAX_LIABILITIES'})}
                                type='number'
                                param1='consolidatedBalanceSheet'
                                param2='liabilitiesAndEquity'
                                param3='deferred_tax_liabilities'
                                value={companyFA}
                                list={i}
                                onchange={(e, p1, p2, p3) =>
                                  handleYearlyDataChange(e, i, p1, p2, p3)
                                }
                              />
                              <TransposeLineInput
                                label={intl.formatMessage({id: 'AFS.OTHER_NON_CURRENT_LIABILITIES'})}
                                type='number'
                                param1='consolidatedBalanceSheet'
                                param2='liabilitiesAndEquity'
                                param3='other_non_current_liabilities'
                                value={companyFA}
                                list={i}
                                onchange={(e, p1, p2, p3) =>
                                  handleYearlyDataChange(e, i, p1, p2, p3)
                                }
                              />
                              <TransposeLineInput
                                label={intl.formatMessage({id: 'AFS.TOTAL_LIABILITIES'})}
                                type='number'
                                param1='consolidatedBalanceSheet'
                                param2='liabilitiesAndEquity'
                                param3='lieq_total_liabilities_c'
                                value={companyFA}
                                list={i}
                                disabled
                                onchange={(e, p1, p2, p3) =>
                                  handleYearlyDataChange(e, i, p1, p2, p3)
                                }
                                tooltip={autoGenTooltip}
                              />
                              <TransposeLineInput
                                label={intl.formatMessage({id: 'AFS.SHARE_CAPITAL'})}
                                type='number'
                                param1='consolidatedBalanceSheet'
                                param2='liabilitiesAndEquity'
                                param3='share_capital'
                                value={companyFA}
                                list={i}
                                onchange={(e, p1, p2, p3) =>
                                  handleYearlyDataChange(e, i, p1, p2, p3)
                                }
                              />
                              <TransposeLineInput
                                label={intl.formatMessage({id: 'AFS.RETAINED_EARNINGS_LOSSES'})}
                                type='number'
                                param1='consolidatedBalanceSheet'
                                param2='liabilitiesAndEquity'
                                param3='retained_earnings_losses'
                                value={companyFA}
                                list={i}
                                onchange={(e, p1, p2, p3) =>
                                  handleYearlyDataChange(e, i, p1, p2, p3)
                                }
                                tooltip={intl.formatMessage({id: 'TOOLTIP.RETAINED_EARNINGS_LOSSES'})}
                              />
                              <TransposeLineInput
                                label={intl.formatMessage({id: 'AFS.OTHER_EQUITY'})}
                                type='number'
                                param1='consolidatedBalanceSheet'
                                param2='liabilitiesAndEquity'
                                param3='other_equity'
                                value={companyFA}
                                list={i}
                                onchange={(e, p1, p2, p3) =>
                                  handleYearlyDataChange(e, i, p1, p2, p3)
                                }
                              />
                              <TransposeLineInput
                                label={intl.formatMessage({id: 'AFS.ACCUMULATED_OTHER_COMPREHENSIVE_INCOME_LOSSES'})}
                                type='number'
                                param1='consolidatedBalanceSheet'
                                param2='liabilitiesAndEquity'
                                param3='accumulated_oci'
                                value={companyFA}
                                list={i}
                                long
                                onchange={(e, p1, p2, p3) =>
                                  handleYearlyDataChange(e, i, p1, p2, p3)
                                }
                                tooltip={intl.formatMessage({id: 'TOOLTIP.ACCUMULATED_OTHER_COMPREHENSIVE_INCOME_LOSSES'})}
                              />

                              <TransposeLineInput
                                label={intl.formatMessage({id: 'AFS.TOTAL_EQUITY'})}
                                type='number'
                                param1='consolidatedBalanceSheet'
                                param2='liabilitiesAndEquity'
                                param3='lieq_total_equity_c'
                                value={companyFA}
                                list={i}
                                disabled
                                onchange={(e, p1, p2, p3) =>
                                  handleYearlyDataChange(e, i, p1, p2, p3)
                                }
                                tooltip={autoGenTooltip}
                              />
                              <TransposeLineInput
                                label={intl.formatMessage({id: 'AFS.TOTAL_LIABILITIES_EQUITY'})}
                                type='number'
                                param1='consolidatedBalanceSheet'
                                param2='liabilitiesAndEquity'
                                param3='total_liabilities_equity_c'
                                value={companyFA}
                                list={i}
                                disabled
                                onchange={(e, p1, p2, p3) =>
                                  handleYearlyDataChange(e, i, p1, p2, p3)
                                }
                                tooltip={autoGenTooltip}
                              />
                            </tr>
                          ))}
                        </TransposeTable>
                      </div>
                    </div>
                    <div className='row'>
                      {/* Printable Consolidated Balance Sheet */}
                      <div
                        ref={consolidated_balance_sheet_ref}
                        className='print-only card-body p-9'
                      >
                        <AFSPrintingHeader
                          Title={intl.formatMessage({id: 'AFS.CONSOLIDATED_BALANCE_SHEET'})}
                          ComputeForm={ComputeForm}
                          CurrentTime={CurrentTime}
                        />
                        <div className='row mb-2'>
                          <table className='transpose-table'>
                            <tbody>
                              <tr className='min-w-50'>
                                <FSTableDataHead className='white-text-on-blue-bg fw-bold text-white'>
                                  &nbsp;
                                </FSTableDataHead>
                                <FSTableDataHead className='fw-bold cell-highlight'>
                                  {intl.formatMessage({id: 'AFS.ASSETS'})}
                                </FSTableDataHead>
                                <FSTableDataHead>{intl.formatMessage({id: 'AFS.INVENTORIES'})}</FSTableDataHead>
                                <FSTableDataHead>{intl.formatMessage({id: 'AFS.TRADE_RECEIVABLES'})}</FSTableDataHead>
                                <FSTableDataHead>{intl.formatMessage({id: 'AFS.OTHER_RECEIVABLES_PREPAYMENTS'})}</FSTableDataHead>
                                <FSTableDataHead>{intl.formatMessage({id: 'AFS.CASH_AND_CASH_EQUIVALENTS'})}</FSTableDataHead>
                                <FSTableDataHead>{intl.formatMessage({id: 'AFS.OTHER_CURRENT_ASSETS'})}</FSTableDataHead>
                                {/* Single Equal to line offset*/}
                                <TableEqualLine lineType='single' offset />
                                <FSTableDataHead className='fw-bold cell-highlight'>
                                  {intl.formatMessage({id: 'AFS.TOTAL_CURRENT_ASSETS'})}
                                </FSTableDataHead>
                                <FSTableDataHead>
                                  {intl.formatMessage({id: 'AFS.PROPERTY_PLANT_AND_EQUIPMENT_NET'})}
                                </FSTableDataHead>
                                <FSTableDataHead>{intl.formatMessage({id: 'AFS.INTANGIBLE_ASSETS_NET'})}</FSTableDataHead>
                                <FSTableDataHead>{intl.formatMessage({id: 'AFS.DEFERRED_TAX_ASSETS'})}</FSTableDataHead>
                                <FSTableDataHead>{intl.formatMessage({id: 'AFS.OTHER_NON_CURRENT_ASSETS'})}</FSTableDataHead>
                                <TableEqualLine lineType='single' offset />
                                <FSTableDataHead className='fw-bold cell-highlight'>
                                  {intl.formatMessage({id: 'AFS.TOTAL_ASSETS'})}
                                </FSTableDataHead>
                                <TableEqualLine lineType='double' offset />
                                <FSTableDataHead className='fw-bold'>
                                  {intl.formatMessage({id: 'AFS.LIABILITIES_AND_EQUITY'})}
                                </FSTableDataHead>
                                <FSTableDataHead>{intl.formatMessage({id: 'AFS.TRADE_PAYABLES'})}</FSTableDataHead>
                                <FSTableDataHead>{intl.formatMessage({id: 'AFS.SHORT_TERM_BORROWINGS'})}</FSTableDataHead>
                                <FSTableDataHead>
                                  {intl.formatMessage({id: 'AFS.AMOUNT_DUE_DIRECTORS_RELATED_PARTIES'})}
                                </FSTableDataHead>
                                <FSTableDataHead>{intl.formatMessage({id: 'AFS.OTHER_PAYABLES_ACCRUALS'})}</FSTableDataHead>
                                <FSTableDataHead>{intl.formatMessage({id: 'AFS.OTHER_CURRENT_LIABILITIES'})}</FSTableDataHead>
                                <TableEqualLine lineType='single' offset />
                                <FSTableDataHead className='fw-bold'>
                                  {intl.formatMessage({id: 'AFS.TOTAL_CURRENT_LIABILITIES'})}
                                </FSTableDataHead>
                                <FSTableDataHead>{intl.formatMessage({id: 'AFS.LONG_TERM_BORROWINGS'})}</FSTableDataHead>
                                <FSTableDataHead>{intl.formatMessage({id: 'AFS.DEFERRED_TAX_LIABILITIES'})}</FSTableDataHead>
                                <FSTableDataHead>{intl.formatMessage({id: 'AFS.OTHER_NON_CURRENT_LIABILITIES'})}</FSTableDataHead>
                                <TableEqualLine lineType='single' offset />
                                <FSTableDataHead className='fw-bold'>
                                  {intl.formatMessage({id: 'AFS.TOTAL_LIABILITIES'})}
                                </FSTableDataHead>
                                <FSTableDataHead>{intl.formatMessage({id: 'AFS.SHARE_CAPITAL'})}</FSTableDataHead>
                                <FSTableDataHead>{intl.formatMessage({id: 'AFS.RETAINED_EARNINGS_LOSSES'})}</FSTableDataHead>
                                <FSTableDataHead>{intl.formatMessage({id: 'AFS.OTHER_EQUITY'})}</FSTableDataHead>
                                <FSTableDataHead>{intl.formatMessage({id: 'AFS.ACCUMULATED_OTHER_COMPREHENSIVE_INCOME_LOSSES'})}</FSTableDataHead>
                                <TableEqualLine lineType='single' offset />
                                <FSTableDataHead className='fw-bold'>{intl.formatMessage({id: 'AFS.TOTAL_EQUITY'})}</FSTableDataHead>
                                <TableEqualLine lineType='single' offset />
                                <FSTableDataHead className='fw-bold'>
                                  {intl.formatMessage({id: 'AFS.TOTAL_LIABILITIES_EQUITY'})}
                                </FSTableDataHead>
                              </tr>
                              {ComputeForm.companyFinancialAnalysis?.map((companyFA, i) => (
                                <tr style={{ minWidth: '15%' }} key={i}>
                                  <FSTableData
                                    className='white-text-on-blue-bg fw-bold text-white'
                                    style={{
                                      backgroundColor: '#36486b',
                                    }}
                                  >
                                    {companyFA.financial_year_c}
                                  </FSTableData>
                                  <FSTableData className='fw-bold cell-highlight'>
                                    &nbsp;
                                  </FSTableData>
                                  <FSTableData>
                                    <LineViewer
                                      param1='consolidatedBalanceSheet'
                                      param2='assets'
                                      param3='ast_inventories'
                                      value={companyFA}
                                    />
                                  </FSTableData>
                                  <FSTableData>
                                    <LineViewer
                                      param1='consolidatedBalanceSheet'
                                      param2='assets'
                                      param3='ast_trade_receivables'
                                      value={companyFA}
                                    />
                                  </FSTableData>
                                  <FSTableData>
                                    <LineViewer
                                      param1='consolidatedBalanceSheet'
                                      param2='assets'
                                      param3='ast_other_receivables_prepayments'
                                      value={companyFA}
                                    />
                                  </FSTableData>
                                  <FSTableData>
                                    <LineViewer
                                      param1='consolidatedBalanceSheet'
                                      param2='assets'
                                      param3='ast_cash_and_cash_equivalents'
                                      value={companyFA}
                                    />
                                  </FSTableData>
                                  <FSTableData>
                                    <LineViewer
                                      param1='consolidatedBalanceSheet'
                                      param2='assets'
                                      param3='other_current_assets'
                                      value={companyFA}
                                    />
                                  </FSTableData>
                                  {/* Single Equal to line */}
                                  <TableEqualLine lineType='single' />
                                  <FSTableData className='fw-bold cell-highlight'>
                                    <LineViewer
                                      param1='consolidatedBalanceSheet'
                                      param2='assets'
                                      param3='total_current_assets_c'
                                      value={companyFA}
                                    />
                                  </FSTableData>
                                  <FSTableData>
                                    <LineViewer
                                      param1='consolidatedBalanceSheet'
                                      param2='assets'
                                      param3='property_plant_equipment_net'
                                      value={companyFA}
                                    />
                                  </FSTableData>
                                  <FSTableData>
                                    <LineViewer
                                      param1='consolidatedBalanceSheet'
                                      param2='assets'
                                      param3='intangible_assets_net'
                                      value={companyFA}
                                    />
                                  </FSTableData>
                                  <FSTableData>
                                    <LineViewer
                                      param1='consolidatedBalanceSheet'
                                      param2='assets'
                                      param3='deferred_tax_assets'
                                      value={companyFA}
                                    />
                                  </FSTableData>
                                  <FSTableData>
                                    <LineViewer
                                      param1='consolidatedBalanceSheet'
                                      param2='assets'
                                      param3='other_non_current_assets'
                                      value={companyFA}
                                    />
                                  </FSTableData>
                                  <TableEqualLine lineType='single' />
                                  <FSTableData className='fw-bold cell-highlight'>
                                    <LineViewer
                                      param1='consolidatedBalanceSheet'
                                      param2='assets'
                                      param3='ast_total_asset_c'
                                      value={companyFA}
                                    />
                                  </FSTableData>
                                  <TableEqualLine lineType='double' />
                                  <FSTableData>&nbsp;</FSTableData>
                                  <FSTableData>
                                    <LineViewer
                                      param1='consolidatedBalanceSheet'
                                      param2='liabilitiesAndEquity'
                                      param3='lieq_trade_payables'
                                      value={companyFA}
                                    />
                                  </FSTableData>
                                  <FSTableData>
                                    <LineViewer
                                      param1='consolidatedBalanceSheet'
                                      param2='liabilitiesAndEquity'
                                      param3='short_term_borrowings'
                                      value={companyFA}
                                    />
                                  </FSTableData>
                                  <FSTableData>
                                    <LineViewer
                                      param1='consolidatedBalanceSheet'
                                      param2='liabilitiesAndEquity'
                                      param3='lieq_amount_due_to_directors_related_parties'
                                      value={companyFA}
                                    />
                                  </FSTableData>
                                  <FSTableData>
                                    <LineViewer
                                      param1='consolidatedBalanceSheet'
                                      param2='liabilitiesAndEquity'
                                      param3='lieq_other_payables_accruals'
                                      value={companyFA}
                                    />
                                  </FSTableData>

                                  <FSTableData>
                                    <LineViewer
                                      param1='consolidatedBalanceSheet'
                                      param2='liabilitiesAndEquity'
                                      param3='lieq_other_current_liabilities'
                                      value={companyFA}
                                    />
                                  </FSTableData>
                                  <TableEqualLine lineType='single' />
                                  <FSTableData className='fw-bold'>
                                    <LineViewer
                                      param1='consolidatedBalanceSheet'
                                      param2='liabilitiesAndEquity'
                                      param3='total_current_liabilities_c'
                                      value={companyFA}
                                    />
                                  </FSTableData>
                                  <FSTableData>
                                    <LineViewer
                                      param1='consolidatedBalanceSheet'
                                      param2='liabilitiesAndEquity'
                                      param3='long_term_borrowings'
                                      value={companyFA}
                                    />
                                  </FSTableData>
                                  <FSTableData>
                                    <LineViewer
                                      param1='consolidatedBalanceSheet'
                                      param2='liabilitiesAndEquity'
                                      param3='deferred_tax_liabilities'
                                      value={companyFA}
                                    />
                                  </FSTableData>
                                  <FSTableData>
                                    <LineViewer
                                      param1='consolidatedBalanceSheet'
                                      param2='liabilitiesAndEquity'
                                      param3='other_non_current_liabilities'
                                      value={companyFA}
                                    />
                                  </FSTableData>
                                  <TableEqualLine lineType='single' />
                                  <FSTableData className='fw-bold'>
                                    <LineViewer
                                      param1='consolidatedBalanceSheet'
                                      param2='liabilitiesAndEquity'
                                      param3='lieq_total_liabilities_c'
                                      value={companyFA}
                                    />
                                  </FSTableData>
                                  <FSTableData>
                                    <LineViewer
                                      param1='consolidatedBalanceSheet'
                                      param2='liabilitiesAndEquity'
                                      param3='share_capital'
                                      value={companyFA}
                                    />
                                  </FSTableData>
                                  <FSTableData>
                                    <LineViewer
                                      param1='consolidatedBalanceSheet'
                                      param2='liabilitiesAndEquity'
                                      param3='retained_earnings_losses'
                                      value={companyFA}
                                    />
                                  </FSTableData>
                                  <FSTableData>
                                    <LineViewer
                                      param1='consolidatedBalanceSheet'
                                      param2='liabilitiesAndEquity'
                                      param3='other_equity'
                                      value={companyFA}
                                    />
                                  </FSTableData>
                                  <FSTableData>
                                    <LineViewer
                                      param1='consolidatedBalanceSheet'
                                      param2='liabilitiesAndEquity'
                                      param3='accumulated_oci'
                                      value={companyFA}
                                    />
                                  </FSTableData>
                                  <TableEqualLine lineType='single' />
                                  <FSTableData className='fw-bold'>
                                    <LineViewer
                                      param1='consolidatedBalanceSheet'
                                      param2='liabilitiesAndEquity'
                                      param3='lieq_total_equity_c'
                                      value={companyFA}
                                    />
                                  </FSTableData>
                                  <TableEqualLine lineType='single' />
                                  <FSTableData className='fw-bold'>
                                    <LineViewer
                                      param1='consolidatedBalanceSheet'
                                      param2='liabilitiesAndEquity'
                                      param3='total_liabilities_equity_c'
                                      value={companyFA}
                                    />
                                  </FSTableData>
                                </tr>
                              ))}
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>
                  </div>
                  {/* KEY RATIOS */}
                  <div className='consolidated-balance-sheet-wrapper border-default border p-6 mb-6'>
                    <div className='d-flex justify-content-between align-items-start flex-wrap mb-2'>
                      <div className='d-flex'>
                        <h3 className='mt-6'>{intl.formatMessage({id: 'AFS.KEY_RATIOS'})}</h3>
                      </div>
                      <div className='d-flex my-4'>
                        <ReactToPrint
                          documentTitle='SME Model Result'
                          bodyClass='print-black-font'
                          content={() => financial_highlights_ref.current}
                          trigger={() => (
                            <PrintButton type='button' className='btn btn-primary btn-sm'>
                              <KTIcon
                                iconName='printer'
                                iconType='outline'
                                className='fs-3 text-default'
                              />
                            </PrintButton>
                          )}
                          onBeforeGetContent={() => handleBeforeGetContent()}
                        />
                      </div>
                    </div>
                    {/*PROFITABILITY*/}
                    <div className='profitability-efficiency-ratio-wrapper border-default border p-6 mb-6'>
                      <div className='d-flex'>
                        <KTIcon
                          iconName='questionnaire-tablet'
                          className='fs-2tx text-primary me-4'
                        />
                        <div className='d-flex flex-column'>
                          <h3 className='mb-1'>{intl.formatMessage({id: 'AFS.PROFITABILITY'})}</h3>
                          <div className='fs-6 fw-bold text-gray-400'>
                            {intl.formatMessage({id: 'AFS.DATA_REQUIRED_FOR_CORRECT_RESULT'})}
                          </div>
                        </div>
                      </div>
                      <div className='row' style={{ overflow: 'auto' }}>
                        <TransposeTable>
                          {ComputeForm.companyFinancialAnalysis?.map((companyFA, i) => (
                            <tr
                              className={clsx('cell-offset transpose-table', { 'w-500px': i === 0 })}
                              key={i}
                            >
                              <TransposeLineInput
                                label={intl.formatMessage({id: 'AFS.GROSS_PROFIT_MARGIN'})}
                                type='number'
                                param1='profitabilityEfficiencyRatio'
                                param2='gross_profit_margin_c'
                                value={companyFA}
                                list={i}
                                disabled
                                onchange={(e, p1, p2) => handleYearlyDataChange(e, i, p1, p2)}
                                tooltip={autoGenTooltip}
                              />

                              <TransposeLineInput
                                label={intl.formatMessage({id: 'AFS.EBITDA_MARGIN'})}
                                type='number'
                                param1='profitabilityEfficiencyRatio'
                                param2='ebitda_margin_c'
                                value={companyFA}
                                list={i}
                                disabled
                                onchange={(e, p1, p2) => handleYearlyDataChange(e, i, p1, p2)}
                                tooltip={autoGenTooltip}
                              />
                              <TransposeLineInput
                                label={intl.formatMessage({id: 'AFS.NET_PROFIT_MARGIN'})}
                                type='number'
                                param1='profitabilityEfficiencyRatio'
                                param2='net_profit_margin_c'
                                value={companyFA}
                                list={i}
                                disabled
                                onchange={(e, p1, p2) => handleYearlyDataChange(e, i, p1, p2)}
                                tooltip={autoGenTooltip}
                              />
                              <TransposeLineInput
                                label={intl.formatMessage({id: 'AFS.RETURN_ON_ASSET'})}
                                type='number'
                                param1='profitabilityEfficiencyRatio'
                                param2='return_on_asset_c'
                                value={companyFA}
                                list={i}
                                disabled
                                onchange={(e, p1, p2) => handleYearlyDataChange(e, i, p1, p2)}
                                tooltip={autoGenTooltip}
                              />
                              <TransposeLineInput
                                label={intl.formatMessage({id: 'AFS.RETURN_ON_EQUITY'})}
                                type='number'
                                param1='profitabilityEfficiencyRatio'
                                param2='return_on_equity_c'
                                value={companyFA}
                                list={i}
                                disabled
                                onchange={(e, p1, p2) => handleYearlyDataChange(e, i, p1, p2)}
                                tooltip={autoGenTooltip}
                              />
                            </tr>
                          ))}
                        </TransposeTable>
                      </div>
                    </div>
                    {/*LIQUIDITY*/}
                    <div className='cashflow-management-wrapper border-default border p-6 mb-6'>
                      <div className='d-flex'>
                        <KTIcon
                          iconName='questionnaire-tablet'
                          className='fs-2tx text-primary me-4'
                        />
                        <div className='d-flex flex-column'>
                          <h3 className='mb-1'>{intl.formatMessage({id: 'AFS.LIQUIDITY'})}</h3>
                          <div className='fs-6 fw-bold text-gray-400'>
                            Data required for accuracy of results.
                          </div>
                        </div>
                      </div>
                      <div className='row' style={{ overflow: 'auto' }}>
                        <TransposeTable>
                          {ComputeForm.companyFinancialAnalysis?.map((companyFA, i) => (
                            <tr
                              className={clsx('cell-offset transpose-table', { 'w-500px': i === 0 })}
                              key={i}
                            >
                              <TransposeLineInput
                                label={intl.formatMessage({id: 'AFS.CASH_RATIO'})}
                                type='number'
                                param1='cashFlowManagement'
                                param2='cash_ratio_c'
                                value={companyFA}
                                list={i}
                                disabled
                                onchange={(e, p1, p2) => handleYearlyDataChange(e, i, p1, p2)}
                                tooltip={autoGenTooltip}
                              />
                              <TransposeLineInput
                                label={intl.formatMessage({id: 'AFS.QUICK_RATIO'})}
                                type='number'
                                param1='cashFlowManagement'
                                param2='quick_ratio_c'
                                value={companyFA}
                                list={i}
                                disabled
                                onchange={(e, p1, p2) => handleYearlyDataChange(e, i, p1, p2)}
                                tooltip={autoGenTooltip}
                              />
                              <TransposeLineInput
                                label={intl.formatMessage({id: 'AFS.CURRENT_RATIO'})}
                                type='number'
                                param1='cashFlowManagement'
                                param2='current_ratio_c'
                                value={companyFA}
                                list={i}
                                disabled
                                onchange={(e, p1, p2) => handleYearlyDataChange(e, i, p1, p2)}
                                tooltip={autoGenTooltip}
                              />
                            </tr>
                          ))}
                        </TransposeTable>
                      </div>
                    </div>
                    {/*LEVERAGE*/}
                    <div className='capital-structure-wrapper border-default border p-6 mb-6'>
                      <div className='d-flex'>
                        <KTIcon
                          iconName='questionnaire-tablet'
                          className='fs-2tx text-primary me-4'
                        />
                        <div className='d-flex flex-column'>
                          <h3 className='mb-1'>{intl.formatMessage({id: 'AFS.LEVERAGE'})}</h3>
                          <div className='fs-6 fw-bold text-gray-400'>
                            {intl.formatMessage({id: 'AFS.DATA_REQUIRED_FOR_CORRECT_RESULT'})}
                          </div>
                        </div>
                      </div>
                      <div className='row' style={{ overflow: 'auto' }}>
                        <TransposeTable>
                          {ComputeForm.companyFinancialAnalysis?.map((companyFA, i) => (
                            <tr
                              className={clsx('cell-offset transpose-table', { 'w-500px': i === 0 })}
                              key={i}
                            >
                              <TransposeLineInput
                                label={intl.formatMessage({id: 'AFS.DEBT_TO_EQUITY_RATIO'})}
                                type='number'
                                param1='capitalStructure'
                                param2='debt_to_equity_ratio_c'
                                value={companyFA}
                                list={i}
                                disabled
                                onchange={(e, p1, p2) => handleYearlyDataChange(e, i, p1, p2)}
                                tooltip={autoGenTooltip}
                              />
                              <TransposeLineInput
                                label={intl.formatMessage({id: 'AFS.DEBT_SERVICE_RATIO'})}
                                type='number'
                                param1='capitalStructure'
                                param2='debt_service_ratio_c'
                                value={companyFA}
                                list={i}
                                disabled
                                onchange={(e, p1, p2) => handleYearlyDataChange(e, i, p1, p2)}
                                tooltip={autoGenTooltip}
                              />
                              <TransposeLineInput
                                label={intl.formatMessage({id: 'AFS.GEARING_RATIO'})}
                                type='number'
                                param1='capitalStructure'
                                param2='gearing_ratio_c'
                                value={companyFA}
                                list={i}
                                disabled
                                onchange={(e, p1, p2) => handleYearlyDataChange(e, i, p1, p2)}
                                tooltip={autoGenTooltip}
                              />
                            </tr>
                          ))}
                        </TransposeTable>
                      </div>
                    </div>
                    {/* EFFICIENCY */}
                    <div className='cashflow-management-wrapper border-default border p-6 mb-6'>
                      <div className='d-flex'>
                        <KTIcon
                          iconName='questionnaire-tablet'
                          className='fs-2tx text-primary me-4'
                        />
                        <div className='d-flex flex-column'>
                          <h3 className='mb-1'>{intl.formatMessage({id: 'AFS.EFFICIENCY'})}</h3>
                          <div className='fs-6 fw-bold text-gray-400'>
                            {intl.formatMessage({id: 'AFS.DATA_REQUIRED_FOR_CORRECT_RESULT'})}
                          </div>
                        </div>
                      </div>
                      <div className='row' style={{ overflow: 'auto' }}>
                        <TransposeTable>
                          {ComputeForm.companyFinancialAnalysis?.map((companyFA, i) => (
                            <tr
                              className={clsx('cell-offset transpose-table', { 'w-500px': i === 0 })}
                              key={i}
                            >
                              <TransposeLineInput
                                label={intl.formatMessage({id: 'AFS.DAY_RECEIVABLES'})}
                                type='number'
                                param1='cashFlowManagement'
                                param2='day_receivables_c'
                                value={companyFA}
                                list={i}
                                disabled
                                onchange={(e, p1, p2) => handleYearlyDataChange(e, i, p1, p2)}
                                tooltip={autoGenTooltip}
                              />
                              <TransposeLineInput
                                label={intl.formatMessage({id: 'AFS.DAY_PAYABLES'})}
                                type='number'
                                param1='cashFlowManagement'
                                param2='day_payables_c'
                                value={companyFA}
                                list={i}
                                disabled
                                onchange={(e, p1, p2) => handleYearlyDataChange(e, i, p1, p2)}
                                tooltip={autoGenTooltip}
                              />
                              <TransposeLineInput
                                label={intl.formatMessage({id: 'AFS.INVENTORY_TURNOVER'})}
                                type='number'
                                param1='cashFlowManagement'
                                param2='inventory_turnover_c'
                                value={companyFA}
                                list={i}
                                disabled
                                onchange={(e, p1, p2) => handleYearlyDataChange(e, i, p1, p2)}
                                tooltip={autoGenTooltip}
                              />
                              <TransposeLineInput
                                label={intl.formatMessage({id: 'AFS.CASH_CONVERSION_CYCLE'})}
                                type='number'
                                param1='cashFlowManagement'
                                param2='cash_conversion_cycle_c'
                                value={companyFA}
                                list={i}
                                disabled
                                onchange={(e, p1, p2) => handleYearlyDataChange(e, i, p1, p2)}
                                tooltip={autoGenTooltip}
                              />
                              <TransposeLineInput
                                label={intl.formatMessage({id: 'AFS.SALES_OVER_TOTAL_ASSETS'})}
                                type='number'
                                param1='profitabilityEfficiencyRatio'
                                param2='sales_over_total_assets_c'
                                value={companyFA}
                                list={i}
                                disabled
                                onchange={(e, p1, p2) => handleYearlyDataChange(e, i, p1, p2)}
                                tooltip={autoGenTooltip}
                              />
                            </tr>
                          ))}
                        </TransposeTable>
                      </div>
                    </div>
                    <div className='row'>
                      <div ref={financial_highlights_ref} className='print-only card-body p-9'>
                        <AFSPrintingHeader
                          Title={intl.formatMessage({id: 'AFS.KEY_RATIOS'})}
                          ComputeForm={ComputeForm}
                          CurrentTime={CurrentTime}
                        />
                        <div className='row mb-2'>
                          <table className='transpose-table'>
                            <tbody>
                              <tr className='min-w-50'>
                                <FSTableDataHead className='white-text-on-blue-bg fw-bold text-white'>
                                  &nbsp;
                                </FSTableDataHead>
                                <FSTableDataHead className='fw-bold'>{intl.formatMessage({id: 'AFS.PROFITABILITY'})}</FSTableDataHead>

                                <FSTableDataHead>{intl.formatMessage({id: 'AFS.GROSS_PROFIT_MARGIN'})}</FSTableDataHead>
                                <FSTableDataHead>{intl.formatMessage({id: 'AFS.EBITDA_MARGIN'})}</FSTableDataHead>
                                <FSTableDataHead>{intl.formatMessage({id: 'AFS.NET_PROFIT_MARGIN'})}</FSTableDataHead>
                                <FSTableDataHead>{intl.formatMessage({id: 'AFS.RETURN_ON_ASSET'})}</FSTableDataHead>
                                <FSTableDataHead>{intl.formatMessage({id: 'AFS.RETURN_ON_EQUITY'})}</FSTableDataHead>
                                <FSTableDataHead>&nbsp;</FSTableDataHead>
                                <FSTableDataHead className='fw-bold'>{intl.formatMessage({id: 'AFS.LIQUIDITY'})}</FSTableDataHead>
                                <FSTableDataHead>{intl.formatMessage({id: 'AFS.CASH_RATIO'})}</FSTableDataHead>
                                <FSTableDataHead>{intl.formatMessage({id: 'AFS.QUICK_RATIO'})}</FSTableDataHead>
                                <FSTableDataHead>{intl.formatMessage({id: 'AFS.CURRENT_RATIO'})}</FSTableDataHead>
                                <FSTableDataHead>&nbsp;</FSTableDataHead>
                                <FSTableDataHead className='fw-bold'>{intl.formatMessage({id: 'AFS.LEVERAGE'})}</FSTableDataHead>
                                <FSTableDataHead>{intl.formatMessage({id: 'AFS.DEBT_TO_EQUITY_RATIO'})}</FSTableDataHead>
                                <FSTableDataHead>{intl.formatMessage({id: 'AFS.DEBT_SERVICE_RATIO'})}</FSTableDataHead>
                                <FSTableDataHead>{intl.formatMessage({id: 'AFS.GEARING_RATIO'})}</FSTableDataHead>
                                <FSTableDataHead>&nbsp;</FSTableDataHead>
                                <FSTableDataHead className='fw-bold'>{intl.formatMessage({id: 'AFS.EFFICIENCY'})}</FSTableDataHead>
                                <FSTableDataHead>{intl.formatMessage({id: 'AFS.DAY_RECEIVABLES'})}</FSTableDataHead>
                                <FSTableDataHead>{intl.formatMessage({id: 'AFS.DAY_PAYABLES'})}</FSTableDataHead>
                                <FSTableDataHead>{intl.formatMessage({id: 'AFS.INVENTORY_TURNOVER'})}</FSTableDataHead>
                                <FSTableDataHead>{intl.formatMessage({id: 'AFS.CASH_CONVERSION_CYCLE'})}</FSTableDataHead>
                                <FSTableDataHead>{intl.formatMessage({id: 'AFS.SALES_OVER_TOTAL_ASSETS'})}</FSTableDataHead>
                              </tr>
                              {ComputeForm.companyFinancialAnalysis?.map((companyFA, i) => (
                                <tr style={{ minWidth: '15%' }} key={i}>
                                  <FSTableData
                                    className='white-text-on-blue-bg fw-bold text-white'
                                    style={{
                                      backgroundColor: '#36486b',
                                    }}
                                  >
                                    {companyFA.financial_year_c}
                                  </FSTableData>
                                  {/* PROFITABILITY */}
                                  <FSTableData>&nbsp;</FSTableData>

                                  <FSTableData>
                                    <LineViewer
                                      param1='profitabilityEfficiencyRatio'
                                      param2='gross_profit_margin_c'
                                      value={companyFA}
                                    />
                                  </FSTableData>

                                  <FSTableData>
                                    <LineViewer
                                      param1='profitabilityEfficiencyRatio'
                                      param2='ebitda_margin_c'
                                      value={companyFA}
                                    />
                                  </FSTableData>
                                  <FSTableData>
                                    <LineViewer
                                      param1='profitabilityEfficiencyRatio'
                                      param2='net_profit_margin_c'
                                      value={companyFA}
                                    />
                                  </FSTableData>
                                  <FSTableData>
                                    <LineViewer
                                      param1='profitabilityEfficiencyRatio'
                                      param2='return_on_asset_c'
                                      value={companyFA}
                                    />
                                  </FSTableData>
                                  <FSTableData>
                                    <LineViewer
                                      param1='profitabilityEfficiencyRatio'
                                      param2='return_on_equity_c'
                                      value={companyFA}
                                    />
                                  </FSTableData>
                                  <FSTableData>&nbsp;</FSTableData>
                                  <FSTableData>&nbsp;</FSTableData>
                                  {/* LIQUIDITY */}
                                  <FSTableData>
                                    <LineViewer
                                      param1='cashFlowManagement'
                                      param2='cash_ratio_c'
                                      value={companyFA}
                                    />
                                  </FSTableData>
                                  <FSTableData>
                                    <LineViewer
                                      param1='cashFlowManagement'
                                      param2='quick_ratio_c'
                                      value={companyFA}
                                    />
                                  </FSTableData>
                                  <FSTableData>
                                    <LineViewer
                                      param1='cashFlowManagement'
                                      param2='current_ratio_c'
                                      value={companyFA}
                                    />
                                  </FSTableData>
                                  <FSTableData>&nbsp;</FSTableData>
                                  <FSTableData>&nbsp;</FSTableData>

                                  {/* LEVERAGE */}

                                  <FSTableData>
                                    <LineViewer
                                      param1='capitalStructure'
                                      param2='debt_to_equity_ratio_c'
                                      value={companyFA}
                                    />
                                  </FSTableData>
                                  <FSTableData>
                                    <LineViewer
                                      param1='capitalStructure'
                                      param2='debt_service_ratio_c'
                                      value={companyFA}
                                    />
                                  </FSTableData>
                                  <FSTableData>
                                    <LineViewer
                                      param1='capitalStructure'
                                      param2='gearing_ratio_c'
                                      value={companyFA}
                                    />
                                  </FSTableData>

                                  <FSTableData>&nbsp;</FSTableData>
                                  <FSTableData>&nbsp;</FSTableData>

                                  {/* EFFICIENCY */}
                                  <FSTableData>
                                    <LineViewer
                                      param1='cashFlowManagement'
                                      param2='day_receivables_c'
                                      value={companyFA}
                                    />
                                  </FSTableData>

                                  <FSTableData>
                                    <LineViewer
                                      param1='cashFlowManagement'
                                      param2='day_payables_c'
                                      value={companyFA}
                                    />
                                  </FSTableData>

                                  <FSTableData>
                                    <LineViewer
                                      param1='cashFlowManagement'
                                      param2='inventory_turnover_c'
                                      value={companyFA}
                                    />
                                  </FSTableData>

                                  <FSTableData>
                                    <LineViewer
                                      param1='cashFlowManagement'
                                      param2='cash_conversion_cycle_c'
                                      value={companyFA}
                                    />
                                  </FSTableData>

                                  <FSTableData>
                                    <LineViewer
                                      param1='profitabilityEfficiencyRatio'
                                      param2='sales_over_total_assets_c'
                                      value={companyFA}
                                    />
                                  </FSTableData>
                                </tr>
                              ))}
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}