import React from 'react'
import ReactSelect from 'react-select'
import { TOption } from '../../../model'

type TProps = {
  options: TOption[]
  selectedValue: string
  param1?: string
  param2?: string
  handleChange: (value: string, param1: string, param2: string) => void
}

function InputSelect({ options, selectedValue, param1, param2, handleChange }) {
  const getSelected = (value) => {
    const selected: any = []

    for (let key in options) {
      if (options[key].value === value) {
        selected.push(options[key])
      }
    }
    return selected
  }

  const handleSelect = (item: TOption | unknown, p1, p2) => {
    // Cast the item to TOption type before accessing its properties
    const value = item as TOption
    console.log('handleSelect ::' + value.label)
    handleChange(value.value, p1, p2)
  }
  return (
    <>
      <ReactSelect
        className='react-select-styled react-select-solid'
        classNames={{
          control: () => 'border-default border',
        }}
        classNamePrefix='react-select'
        defaultValue={getSelected(selectedValue)}
        options={options}
        onChange={(option) => handleSelect(option, param1, param2)}
      />
    </>
  )
}

export default InputSelect
