import React, { useCallback, useEffect, useState } from 'react';
import { useToast } from '../../components/ToastProvider';
import axios from '../../../lib/axios';
import LoadingSpinner from '../../../_metronic/helpers/components/LoadingSpinner';
import { KTSVG } from '../../../_metronic/helpers/components/KTSVG';
import { useLocation, useNavigate } from 'react-router-dom';
import { PageLink, PageTitle } from '../../../_metronic/layout/core';
import { Button, Table } from 'react-bootstrap';
import { Company, Member } from './Companies';
import { confirm } from '../../../../src/app/modules/confirmation/BasicConfirmation';
import AdminSubscriptionFormComponent from '../subscriptions/components/AdminSubscriptionFormComponent';
import { IUserSubscription } from '../subscriptions/Subscription';
import ButtonSubscriptionView from './components/ButtonSubscriptionView';

type LocationState = {
  companyId: string;
};

interface User {
  id: string;
  name: string;
  email: string;
  roles: [{ permissions: { name: string }[] }];
  company_id: string;
  subscription: IUserSubscription;
  role: string;
  is_organization?: boolean;
  is_personal?: boolean;
  created_at: string;
  updated_at: string;
  mode: 'personal' | 'organization';
  company?: string;
  limit: Limit;
  usage: Usage;
  check_limit: boolean;
  subscription_active?: boolean;
}

interface Limit {
  limit: number;
  is_limit_exceeded: boolean;
}

interface Usage {
  usage: number;
  is_limit_exceeded: boolean;
}

const companyBreadCrumbs: Array<PageLink> = [
  {
    title: 'All Companies',
    path: '/companies/admin-all-companies',
    isSeparator: false,
    isActive: false,
  },
  {
    title: 'Company Details',
    path: '/companies/admin-view-company',
    isSeparator: true,
    isActive: false,
  },
];

const AdminViewCompany: React.FC = () => {

  const location = useLocation();
  const companyId = (location.state as LocationState)?.companyId;
  const navigate = useNavigate();
  const { showError, showSuccess, showWarning } = useToast();

  const [company, setCompany] = useState<Company | null>(null);
  const [dataLoading, setDataLoading] = useState<boolean>(false);
  const [members, setMembers] = useState<Member[]>([]);
  const [allUsers, setAllUsers] = useState<User[]>([]);
  const [showModal, setShowModal] = useState(false);

  const fetchData = useCallback(() => {
    setDataLoading(true);
    axios
      .post('/api/companies/show', { company_id: companyId })
      .then((response) => {
        setCompany(response.data);
        setMembers(response.data.members || []);
      })
      .catch(() => showError('Failed to fetch company details'))
      .finally(() => setDataLoading(false));
  }, [companyId, showError]);

  const fetchUsers = useCallback(() => {
    axios
      .get('/api/user/get-user-list')
      .then((response) => setAllUsers(response.data.data || []))
      .catch(() => showError('Failed to fetch users'));
  }, [showError]);

  useEffect(() => {
    const fetchUsers = () => {
      axios
        .get('/api/user/get-user-list')
        .then((response) => setAllUsers(response.data.data || []))
        .catch(() => showError('Failed to fetch users'));
    };
    const fetchData = () => {
      setDataLoading(true);
      axios
        .post('/api/companies/show', { company_id: companyId })
        .then((response) => {
          setCompany(response.data);
          setMembers(response.data.members || []);
        })
        .catch(() => showError('Failed to fetch company details'))
        .finally(() => setDataLoading(false));
    };

    fetchData();
    fetchUsers();
  }, [companyId, showError]);

  const handleAddMembers = () => {
    setShowModal(true);
    fetchUsers();
  };


  const setSubscriptionAsCompanyDefault = async (subscriptionId: string, companyId: string) => {
    const result = await confirm(`Are you sure you want to make the subscription as  default for this company?`);
    if (result) {
      if (!subscriptionId) {
        showError('No subscription id given');
        return;
      }
      if (!companyId) {
        showError('No company id given');
        return;
      }

      try {
        const payload = { subscription_id: subscriptionId, company_id: companyId };
        const response = await axios.post('/api/subscription/set-company-default-subscription', payload);

        if (response.status === 200) {
          showSuccess('Subscription ' + subscriptionId + ' set as company default');
        } else {
          showError('Subscription not set as  default');
        }
      } catch (error) {
        console.error('Error setting subscription as  default:', error);
        showError('Failed to set subscription as  default');
      }
    } else {
      showWarning('You have cancelled the action');
    }
  };


  const setSubscriptionAsNotCompanyDefault = async (subscriptionId: string, companyId: string) => {
    const result = await confirm(`Are you sure you want to make the subscription as not default for this company?`);
    if (result) {
      if (!subscriptionId) {
        showError('No subscription id given');
        return;
      }
      if (!companyId) {
        showError('No company id given');
        return;
      }

      try {
        const payload = { subscription_id: subscriptionId, company_id: companyId };
        const response = await axios.post('/api/subscription/unset-company-default-subscription', payload);

        if (response.status === 200) {
          showSuccess('Subscription ' + subscriptionId + ' set as company default');
        } else {
          showError('Subscription not set as  default');
        }
      } catch (error) {
        console.error('Error setting subscription as  default:', error);
        showError('Failed to set subscription as  default');
      }
    } else {
      showWarning('You have cancelled the action');
    }
  };

  const markSubscriptionForAdmin = async (subscriptionId: string) => {
    const result = await confirm(`Are you sure you want to make the subscription as default for the admin?`);
    if (result) {
      if (!subscriptionId) {
        showError('No subscription id given');
        return;
      }


      try {
        const payload = { subscription_id: subscriptionId };
        const response = await axios.post('/api/subscription/mark-subscription-admin', payload);

        if (response.status === 200) {
          showSuccess('Subscription ' + subscriptionId + ' set as admin default');
        } else {
          showError('Subscription not set as admin default');
        }
      } catch (error) {
        console.error('Error setting subscription a admin  default:', error);
        showError('Failed to set subscription as admin default');
      }
    } else {
      showWarning('You have cancelled the action');
    }
  };

  const unmarkSubscriptionForAdmin = async (subscriptionId: string) => {
    const result = await confirm(`Are you sure you want to make the subscription as not default for the admin?`);
    if (result) {
      if (!subscriptionId) {
        showError('No subscription id given');
        return;
      }


      try {
        const payload = { subscription_id: subscriptionId };
        const response = await axios.post('/api/subscription/unmark-subscription-admin', payload);

        if (response.status === 200) {
          showSuccess('Unset  Subscription ' + subscriptionId + ' as default for admin');
        } else {
          showError('Subscription set as not admin default subscription');
        }
      } catch (error) {
        console.error('Error setting subscription a admin  default:', error);
        showError('Failed to set subscription as admin default');
      }
    } else {
      showWarning('You have cancelled the action');
    }
  };

  const deleteCompany = async (companyName: string, companyId: string) => {
    const result = await confirm(`Are you sure you want to delete the company ${companyName}?`);
    if (result) {
      try {
        await axios.post('/api/companies/delete-company', { company_id: companyId });
        showSuccess('Company deleted successfully');
        navigate('/companies/admin-all-companies');
      } catch {
        showError('Failed to delete company');
      }
    } else {
      showWarning('You have cancelled the action');
      fetchData();
    }
  };

  const checkLimitUnlimited = (Limit: number) => {
    if (Limit === 0) {
      return true;
    } else {
      return false;
    }
  }

  const handleDeleteCompany = () => {
    if (company) deleteCompany(company.name, company.id.toString());
  };


  const Title = () => {
    return company ? company.name : 'Company Details';
  };

  const deleteTitle = () => {
    return company ? `Delete ${company.name}` : 'Delete Company';
  };

  const handleEditNavigation = (id: number) => {
    navigate('/companies/admin-edit-company', { state: { companyId: id } });
  };

  const handleDeleteUserFromCompany = async (id: number, company_id: number) => {
    const result = await confirm(`Are you sure you want to delete this user with ID ${id}?`);
    if (result) {
      axios
        .post('/api/companies/remove-member', {
          id: id, company_id: company_id
        })
        .then(() => {
          showSuccess('Member removed successfully');
          fetchData();
        })
        .catch(() => showError('Failed to remove member'));
    } else {
      showWarning('You have cancelled the action');
    }
  };



  const handleMakeUserAsAdminCompany = async (id: number, email: string, company_id: number) => {
    const result = await confirm(`Are you sure you want to make user ' ${email} as admin  ?`);
    if (result) {
      axios
        .post('/api/companies/make-admin-group', {
          id: id, company_id: company_id
        })
        .then(() => {
          showSuccess('Member made as admin successfully');
          fetchData();
        })
        .catch(() => showError('Failed to make member admin'));
    } else {
      showWarning('You have cancelled the action');
    }
  };

  const demoteAdmin = async (id: number, email: string, company_id: number) => {
    const result = await confirm(`Are you sure you want to demote user ' ${email}?`);
    if (result) {
      axios
        .post('/api/companies/demote-member', {
          id: id, company_id: company_id
        })
        .then(() => {
          showSuccess('Member made as admin successfully');
          fetchData();
        })
        .catch(() => showError('Failed to make member admin'));
    } else {
      showWarning('You have cancelled the action');
    }
  };

  const Limit = company?.subscriptions && company.subscriptions[0]?.subscription_usage[0]?.limit;

  const checkLimit = (limit: number) => {
    if (limit === 0) {
      return "unlimited";
    } else {

      return String(limit);
    }
  };

  return (
    <>
      <PageTitle breadcrumbs={companyBreadCrumbs}>{Title()}</PageTitle>
      <div className="app-main flex-column flex-row-fluid" id="k">
        <div className="d-flex flex-column flex-column-fluid">
          <div className="card-toolbar mb-5">
            <a href="/companies/admin-all-companies" className="btn btn-sm btn-light-primary me-2">
              <KTSVG path="/media/icons/duotune/arrows/arr070.svg" className="svg-icon-2" />
              Back to List
            </a>
            <Button variant="light-system" className="btn btn-sm" onClick={fetchData}>
              <i className="fa fa-refresh"></i>
              Refresh
            </Button>
            <Button variant="danger" className="btn btn-sm ms-auto" onClick={handleDeleteCompany}>
              <i className="fa fa-trash"></i>
              {deleteTitle()}
            </Button>
            <Button className="btn btn-sm btn-warning ms-2" onClick={() => handleEditNavigation(Number(companyId))}>
              <i className="fa fa-edit"></i>
              Edit Company
            </Button>
          </div>
          {(dataLoading === true) ? <LoadingSpinner /> : (
            <>
              <div className="row gy-5 g-xl-10">
                <div className="col-xl-4 mb-xl-10">
                  <div className="card card-flush h-xl-100">
                    <div className="card-header">
                      <h3 className="card-title">
                        <i className="fa-solid fa-shop bg-light-info"></i>&nbsp;&nbsp;&nbsp;{Title()}
                      </h3>
                    </div>
                    <div className="card-body">
                      {company ? (
                        <div>
                          <p><strong>ID:</strong> {company.id}</p>
                          <p><strong>Name:</strong> {company.name}</p>
                          <p><strong>Address:</strong> {company.address}</p>
                          <p><strong>Email:</strong> {company.email}</p>
                          <p><strong>Phone:</strong> {company.phone}</p>
                          <p><strong>Website:</strong> {company.website}</p>
                          <p><strong>Note:</strong> <span dangerouslySetInnerHTML={{ __html: company?.note?.toString() ?? "" }} /></p>
                          <p><strong>Status:</strong> {company.status}</p>
                        </div>
                      ) : (
                        <p>No company details available.</p>
                      )}
                    </div>
                  </div>
                </div>
                <div className="col-xl-8">
                  <div className="card card-flush h-xl-100">
                    <div className="card-header">
                      <h4 className="card-title">Company Subscriptions</h4>
                    </div>
                    <div className="card-body">
                      {company?.subscriptions?.length ? (
                        <Table striped bordered hover>
                          <thead>
                            <tr>
                              <th>Subscription ID</th>
                              <th>Status</th>
                              <th>Usage</th>
                              <th>Actions</th>
                            </tr>
                          </thead>
                          <tbody>
                            {company.subscriptions.map((subscription) => (
                              <tr key={subscription.id}>
                                <td>{subscription.id}</td>
                                <td>{subscription.status?.toString()}</td>
                                <td>

                                  {subscription.subscription_usage?.map((usage) => (
                                    <div key={usage.id}>
                                      {
                                        "default " + subscription.parent_subscription_id
                                      }
                                      <p><strong> Subscription is default owner subscription?:</strong>{subscription.is_subscription_admin ? 'Yes' : 'No'}</p>
                                      <p><strong> Is Child Subscription:</strong>{subscription.parent_subscription_id !== null ? 'Yes' : 'No'}</p>
                                      <p><strong>Usage:</strong> {usage.usage}</p>
                                      <p><strong>Limit Exceeded:</strong> {usage.is_limit_exceeded?.toString()}</p>
                                      <p><strong>Is Default? :{subscription.company_subscription?.[0]?.is_default?.toString() === '0' ? 'No' : 'Yes'}</strong></p>
                                      <p><strong>Limit Value :</strong>{checkLimit(subscription.subscription_usage?.[0].limit)}</p>

                                      <p><strong>Assigned To :</strong>{subscription.user.email}</p>

                                    </div>
                                  ))}
                                </td>
                                <td>
                                  <ButtonSubscriptionView
                                    flagParentSubscription={subscription.parent_subscription_id !== null}
                                    flagDefaultAdmin={Boolean(subscription.company_subscription?.[0]?.is_default)}
                                    flagDefaultOwnerSubscription={Boolean(subscription.is_subscription_admin)}
                                    subscriptionId={String(subscription.id)}
                                    companyId={companyId}
                                    setSubscriptionAsCompanyDefault={setSubscriptionAsCompanyDefault}
                                    setSubscriptionAsNotCompanyDefault={setSubscriptionAsNotCompanyDefault}
                                    markSubscriptionForAdmin={markSubscriptionForAdmin}
                                    unmarkSubscriptionForAdmin={unmarkSubscriptionForAdmin}
                                  />
                                </td>
                              </tr>
                            ))}
                          </tbody>
                        </Table>
                      ) : (
                        <p>No subscriptions available.</p>
                      )}
                    </div>
                  </div>
                </div>
                <div className="col-xl-12">
                  {showModal ? (<div className="card card-flush h-xl-100">
                    <div className="row">
                      <div className="card-header">
                        <h3 className="card-title">Add Member</h3>
                        <div className="card-toolbar">
                          <Button className="btn btn-sm btn-info" onClick={() => setShowModal(false)}>Close</Button>
                        </div>
                      </div>
                      <div className="card-body">
                        <AdminSubscriptionFormComponent
                          subscription_id={Number(company?.subscriptions?.[0]?.id) ?? 0}
                          company_id={Number(companyId)}
                          users={allUsers}
                          subscription_limit={Number(Limit) ?? 0}

                          initial_share_subscription_amount={0}
                        />
                      </div>
                    </div>
                  </div>
                  ) : null}

                  <div className="row">
                    <div className="card card-flush h-xl-100">
                      <div className="card-header">
                        <h3 className="card-title">Members</h3>
                        <div className="card-toolbar">
                          <Button variant="primary" onClick={handleAddMembers}>
                            <i className="fa fa-plus"></i>
                            Add Members
                          </Button>
                        </div>
                      </div>
                      <div className="card-body">
                        {members.length ? (
                          <Table striped bordered hover>
                            <thead>
                              <tr>
                                <th>ID</th>
                                <th>Name</th>
                                <th>Email</th>
                                <th>Role</th>
                                <th>Actions</th>
                              </tr>
                            </thead>
                            <tbody>
                              {members.map((member) => (
                                <tr key={member.id}>
                                  <td>{member.id}</td>
                                  <td>{member.user_name}</td>
                                  <td>{member.user_email}</td>
                                  <td>{member.role}</td>
                                  <td>
                                    {member.role !== 'admin' ? (
                                      <>
                                        {/* button remove member */}
                                        <Button variant="danger" size="sm" onClick={() => handleDeleteUserFromCompany(member.id, member.company_id)}>
                                          <i className="fa fa-trash"></i>
                                          Remove
                                        </Button>
                                        <Button variant="info" size="sm" onClick={() => handleMakeUserAsAdminCompany(member.id, member.user_email, member.company_id)}>
                                          <i className="fa fa-user"></i>
                                          Make Admin
                                        </Button>
                                      </>
                                    ) : null}

                                    {
                                      member.role === 'admin' && member.user_is_admin_subscription === false ? (
                                        <Button variant="success" size="sm" onClick={() => demoteAdmin(member.id, member.user_email, member.company_id)}>
                                          <i className="fa fa-user"></i>
                                          Demote Admin
                                        </Button>
                                      ) : null
                                    }

                                  </td>
                                </tr>
                              ))}
                            </tbody>
                          </Table>
                        ) : (
                          <p>No members available.</p>
                        )}
                      </div>
                    </div>
                  </div>

                </div>
              </div>
            </>
          )}

        </div>
      </div >
    </>
  );
};

export default AdminViewCompany;
