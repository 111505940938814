import React from 'react';
import { useIntl } from 'react-intl';

interface ReportTitleProps {
  // Define your component props here
}

const ReportTitle: React.FC<ReportTitleProps> = () => {
  // Implement your component logic here
  const intl = useIntl()
  
  return (
    // JSX code for your component's UI
    <div>
      <h1 style={{ fontSize: '32px' }}>
        {intl.formatMessage({ id: 'RESULT.TITLE' })}
      </h1>
      <div>
        <p><i>{intl.formatMessage({ id: 'RESULT.POWERED_BY' })}</i></p>
      </div>
    </div>

  );
};

export default ReportTitle;