import React, {useEffect, useState} from 'react'
import {useAuth} from '../../../../hooks/auth'
import {useParams} from 'react-router-dom'
import clsx from 'clsx'

function PasswordReset() {
  const params = useParams()
  const {resetPassword} = useAuth('guest', '/dashboard')

  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')
  const [password_confirmation, setPasswordConfirmation] = useState('')
  const [errors, setErrors] = useState([])
  const [status, setStatus] = useState(null)
  const [loading, setLoading] = useState(false)

  const submitForm = (event) => {
    event.preventDefault()
    setLoading(true)
    resetPassword({
      email,
      password,
      password_confirmation,
      setErrors,
      setStatus,
    })
  }

  useEffect(() => {
    setEmail(params.email || '')

    if (status || errors.length > 0) {
      setLoading(false)
    }
  }, [errors.length, params.email, status])
  return (
    <>
      <div className='text-center mb-10'>
        {/* begin::Title */}
        <h1 className='text-dark fw-bolder mb-3'>Reset Your Password.</h1>
        {/* end::Title */}
      </div>
      {errors.length > 0 && (
        <div className='mb-lg-15 alert alert-danger'>
          <div className='alert-text font-weight-bold'>
            <ul className='mt-3 list-disc list-inside text-sm'>
              {errors.map((error) => (
                <li key={error}>{error}</li>
              ))}
            </ul>
          </div>
        </div>
      )}
      {status && (
        <div className='mb-10 bg-light-info p-8 rounded'>
          <div className='text-info'>{status}</div>
        </div>
      )}
      <form onSubmit={submitForm}>
        {/* Email Address */}
        <div className='fv-row mb-8'>
          <label className='form-label fw-bolder text-gray-900 fs-6' htmlFor='email'>
            Email
          </label>
          <input
            id='email'
            type='email'
            value={email}
            className={clsx('form-control bg-transparent', {})}
            onChange={(event) => setEmail(event.target.value)}
            required
            disabled
          />
        </div>
        {/* Password */}
        <div className='fv-row mb-8'>
          <label className='form-label fw-bolder text-gray-900 fs-6' htmlFor='password'>
            Password
          </label>
          <input
            id='password'
            type='password'
            value={password}
            className={clsx('form-control bg-transparent', {})}
            onChange={(event) => setPassword(event.target.value)}
            required
            autoFocus
          />
        </div>
        {/* Confirm Password */}
        <div className='fv-row mb-8'>
          <label
            className='form-label fw-bolder text-gray-900 fs-6'
            htmlFor='password_confirmation'
          >
            Confirm Password
          </label>
          <input
            id='password_confirmation'
            type='password'
            value={password_confirmation}
            className={clsx('form-control bg-transparent', {})}
            onChange={(event) => setPasswordConfirmation(event.target.value)}
            required
          />
        </div>
        <div className='d-flex flex-wrap justify-content-center pb-lg-0'>
          <button disabled={loading} className='btn btn-primary me-4'>
            Reset Password{' '}
            <span
              className={clsx('spinner-border', 'spinner-border-sm', {
                'visually-hidden': !loading,
              })}
            ></span>
          </button>
        </div>
      </form>
    </>
  )
}

export default PasswordReset
