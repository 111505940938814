import React from 'react'
import { IRequestComputeForm } from '../model'
import { ICompanyFinancialAnalysis } from '../model/SmeModelModel'
import clsx from 'clsx'
import { OverlayTrigger, Tooltip } from 'react-bootstrap'
import { KTIcon } from '../../../../_metronic/helpers'
import { numericFormatter } from 'react-number-format'

type TProps = {
  name?: string
  label?: string
  type?: string
  param1?: string
  param2?: string
  param3?: string
  param4?: string
  value?: IRequestComputeForm | ICompanyFinancialAnalysis
  error?: string
  required?: boolean
  disabled?: boolean
  onchange?: (
    value: string | number,
    param1: string,
    param2: string,
    param3?: string,
    param4?: string
  ) => void
  tooltip?: any
}

export function LineViewer({
  name,
  label,
  type,
  param1,
  param2,
  param3,
  param4,
  value,
  error,
  required,
  disabled,
  onchange,
  tooltip,
}: TProps) {
  const getValue = (p1, p2, p3, p4) => {
    if (p1 && p2 && p3 && p4) {
      return value![p1][p2][p3][p4]
    } else if (p1 && p2 && p3) {
      return value![p1][p2][p3]
    } else if (p1 && p2) {
      return value![p1][p2]
    } else if (p1) {
      return value![p1]
    }
  }

  //Check if a value passed has '-' in it
  const hasDash = (value: string) => {
    return value.indexOf('-') > -1
  }

  //remove '-' from a string
  const removeDash = (value: string) => {
    return value.replace('-', '')
  }

  //if the number === '0' change it to '-'
  const changeZeroToDash = (value: string) => {
    if (value === '0') {
      return '-'
    } else {
      return value
    }
  }

  const formatValue = (value) => {
    return numericFormatter(Number(value) ? Number(value).toString() : '0', {
      thousandSeparator: ',',
    })
  }

  return (
    <>
      {hasDash(formatValue(getValue(param1!, param2!, param3!, param4!))) ? (
        <span style={{ color: 'red' }}>
          ({removeDash(formatValue(getValue(param1!, param2!, param3!, param4!)))})
        </span>
      ) : (
        <>{changeZeroToDash(formatValue(getValue(param1!, param2!, param3!, param4!) ?? ''))}</>
      )}
    </>
  )
}