import React from 'react'
import {IRequestComputeForm} from '../model'
import {ICompanyFinancialAnalysis} from '../model/SmeModelModel'
import clsx from 'clsx'
import Flatpickr from 'react-flatpickr'
import moment from 'moment'
import { useIntl } from 'react-intl'

type TProps = {
  name?: string
  label?: string
  type?: string
  param1?: string
  param2?: string
  param3?: string
  param4?: string
  value?: IRequestComputeForm | ICompanyFinancialAnalysis
  error?: string
  required?: boolean
  disabled?: boolean
  onchange?: (
    value: string,
    param1: string,
    param2: string,
    param3?: string,
    param4?: string
  ) => void
  list?: any
}

export function TransposeLineDate({
  name,
  label,
  type,
  param1,
  param2,
  param3,
  param4,
  value,
  error,
  required,
  disabled,
  onchange,
  list,
}: TProps) {
  const intl = useIntl()
  const getValue = (p1, p2, p3, p4) => {
    if (p1 && p2 && p3 && p4) {
      return value![p1][p2][p3][p4]
    } else if (p1 && p2 && p3) {
      return value![p1][p2][p3]
    } else if (p1 && p2) {
      return value![p1][p2]
    } else if (p1) {
      return value![p1]
    }
  }
  const getDate = (value) => {
    if (value) {
      return new Date(value)
    }
  }
  return (
    <>
      <td className='transpose-table'>
        <div className='row'>
          {/*can add class required here later*/}
          {list === 0 ? (
            <div className='col-lg-6'>
              <label
                className={clsx('col-lg-6', 'col-form-label', 'fw-bold', 'fs-6', {
                  'bg-light-primary': disabled,
                  required: required,
                })}
              >
                {label}
              </label>
            </div>
          ) : null}
          <div className={clsx('', {'col-lg-6': list === 0})}>
            <Flatpickr
              value={getDate(getValue(param1, param2, param3, param4))}
              onChange={([date]) => {
                onchange!(
                  moment(date).format('YYYY-MM-DD') + ' 00:00:00',
                  param1!,
                  param2!,
                  param3!,
                  param4!
                )
              }}
              onClose={([date]) => {
                onchange!(
                  moment(date).format('YYYY-MM-DD') + ' 00:00:00',
                  param1!,
                  param2!,
                  param3!,
                  param4!
                )
              }}
              options={{dateFormat: 'd-m-Y', allowInput: true}}
              className='form-control'
              placeholder={intl.formatMessage({id: 'SELECT.PICK_DATE'})}
            />
            <div className='fv-plugins-message-container'>
              <div className='fv-help-block'>{error}</div>
            </div>
          </div>
        </div>
      </td>
    </>
  )
}
