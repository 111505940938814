import React, { useState, useEffect } from 'react';
import { Form } from 'react-bootstrap';
import axios from '../../../../lib/axios';

//add intterface for the options

const PeriodDropDown = ({ register }) => {
  const [options, setOptions] = useState<{ key: string; value: string }[]>([]);
  const URL_SETTINGS = '/api/site-settings/get-setting';

  useEffect(() => {
    const fetchData = async () => {
      try {
        // Making a POST request to get specific setting based on the key
        const response = await axios.post(URL_SETTINGS, { key: "subscription_set" });
        const optionsData = JSON.parse(response.data.value); // Ensure that the data format here matches what you expect from the server

        // Assuming 'optionsData' is directly the object you described
        // Transform the received object into an array suitable for the dropdown
        const optionsArray = Object.entries(optionsData).map(([key, value]) => ({
          key, value: value as string // Ensuring value is treated as a string
        }));

        setOptions(optionsArray);
      } catch (error) {
        console.error('Failed to fetch options:', error);
      }
    };

    fetchData();
  }, []); // Ensures this effect runs only once on component mount


  return (
    <Form.Group className="mb-3">
      <Form.Label>Period</Form.Label>
      <Form.Control as="select"
        {
        ...register('subscription_period',
          {
            required: true,
            minLength: 3,

          }


        )}
      > {/* Replace "period_subscription" with "select" */}
        {(options as { key: string; value: unknown; }[]).map(option => (
          <option key={option.key} value={option.value as string}>
            {option.key}
          </option>
        ))}
      </Form.Control>
    </Form.Group>
  );
};

export default PeriodDropDown;
