import { ICompany } from "../app/pages/dashboard/DataDashboard";
import { IUserSubscription } from "../app/pages/subscriptions/Subscription";

interface Product {
  product_id: number;
  name: string;
  product_key: string;
  usage_count: number;
}

interface User {
  id: string;
  name: string;
  email: string;
  roles: [{ permissions: { name: string }[] }];
  company_id: string;
  subscription: IUserSubscription;
  role: string;
  is_organization?: boolean;
  is_personal?: boolean;
  created_at: string;
  updated_at: string;
  mode: 'personal' | 'organization';
  company?: string;
  limit: LimitData;
  usage: UsageData;
  usage_is_unlimited?: boolean;
  check_limit: boolean;
  subscription_active?: boolean;
  companies: ICompany[];
  default_subscription_id: string;
  is_company_admin?: boolean;
  is_internal_user?: boolean;
  analyst_subscription_active?: boolean;
  product_list: ProductData[];

}

interface ProductData {
  product_id: number;
  name: string;
  product_key: string;
  usage_count: number;
}

interface LimitData {
  total_limit: number;
  own_limit: number;
  is_split: boolean;
}

interface UsageData {
  is_split: boolean;
  usage_all: number;
  own_usage: number;
  usage_is_unlimited: boolean;
  is_consumed: boolean;
}

//type productName, is a string
type ProductName = string;


interface Usage {
  usage: number;
  is_limit_exceeded: boolean;
}

type UsageType = {
  usage: number;
  is_limit_exceeded: boolean;
}

interface ApiError {
  statusCode: number;
  message: string;
}

/**
 * Verify if the user has access to the expected product
 * 
 * @param user User
 * @param string originalName 
 * @param string expectedProductKey 
 * @returns boolean
 */
export function verifyProductAccessible(
  user: User | undefined,
  originalName: string,
  expectedProductKey: string
): boolean {
  if (!user || !user.product_list) {
    return false;
  }

  if (user.role === 'Super Admin') {
    return true;
  }

  // Transform the product name
  const transformedName = originalName.toUpperCase().replace(/_/g, ' ');

  // Check if the product exists with the correct product key
  if (user.product_list.hasOwnProperty(transformedName)) {
    const product = user.product_list[transformedName];
    return product.product_key === expectedProductKey;
  }

  return false;
}

export function getUsageCountByProductKey(user: User | undefined, targetProductKey: string): number | null {
  if (user === undefined) {
    return null;
  }
  // Iterate through the product list to find the matching product key
  for (const productName in user.product_list) {
    const product = user.product_list[productName];
    if (product.product_key === targetProductKey) {
      return product.usage_count;
    }
  }
  // Return null if no matching product key is found
  return null;
}

export function userCanAccessInternalReport(user: User) {
  //user is super admin or Data Analyst
  if (user.role === 'Super Admin' || user.role === 'Data Analyst') {
    return true;
  }
}

export function convertUnderscoreToSpaceAndConvertAllUppercase(string: ProductName) {
  // Convert all underscores to spaces
  const stringWithSpaces = string.replace(/_/g, ' ');

  // Convert the string to uppercase
  const uppercaseString = stringWithSpaces.toUpperCase();

  return uppercaseString;
}
export function processProductUsage(productList: ProductData) {
  const totalUsage = Object.values(productList).reduce((sum, product) => sum + product.usage_count, 0);
  const data = Object.entries(productList).map(([key, product]) => ({
    name: product.name,
    value: product.usage_count,
    percentage: totalUsage === 0 ? 0 : Number((product.usage_count / totalUsage * 100).toFixed(2))
  }));
  return { data, total_usage: totalUsage };
}