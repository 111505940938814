import React, { useEffect, useRef, useState } from 'react';
import { PageLink, PageTitle } from '../../../_metronic/layout/core';
import { KTIcon } from '../../../_metronic/helpers';

import { useLocation } from 'react-router-dom';

type LocationState = {
  request_id: string;
}

const ResultBreadCrumbs: Array<PageLink> = [
  {
    title: 'Subscriptions List',
    path: '/subscriptions/subscriptions',
    isSeparator: false,
    isActive: false,
  },
  {
    title: '',
    path: '',
    isSeparator: true,
    isActive: false,
  },
  {
    title: 'Subscription Requests View',
    path: '#',
    isSeparator: false,
    isActive: true,
  },
  {
    title: '',
    path: '',
    isSeparator: true,
    isActive: false,
  },
];

type SubscriptionRequest = {
  request_id: string;
  user_id: number | null;
  name: string;
  phone: string;
  email: string;
  organization_name: string | null;
  organization_phone: string | null;
  organization_address: string | null;
  organization_website: string | null;
  organization_note: string;
  status: string;
  created_at: string;
  updated_at: string;
}


const UploadPaymentCard = () => {
  return (
    <div className="card">
      <div className="card-header">
        <h5 className="card-title">Upload Payment Details</h5>
      </div>
      <div className="card-body">
        <p>Upload your payment details here.</p>
        <input type="file" />
      </div>
    </div>
  );
};
const Contact = ({
  sentEmail
}) => {
  const [email, setEmail] = useState<string>(sentEmail);

  return (
    <div className="card">
      <div className="card-header">
        <h5 className="card-title">Contact User</h5>
      </div>

      <div className="card-body">
        <form>
          <div className="form-group">
            <label htmlFor="email">Email</label>
            <input type="email" className="form-control" id="email" value={email} disabled />
          </div>
          <div className="form-group">
            <label htmlFor="message">Message</label>
            <textarea className="form-control" id="message" rows={3}></textarea>
          </div>
          <button type="submit" className="btn btn-primary">Send</button>
        </form>
      </div>
    </div>
  );
};

{
  /** component contact user  */
}



const SubscriptionRequestView: React.FC = () => {
  const location = useLocation();
  const [request_id, setRequestId] = useState<string | undefined>();
  const [showUploadCard, setShowUploadCard] = useState<boolean>(false);
  const [showContactUser, setShowContactUser] = useState<boolean>(false);




  useEffect(() => {
    (async () => {
      setRequestId((location.state as LocationState)?.request_id);
    })();
  }, [location.state]);
  const ref = useRef<HTMLDivElement>(null);
  const subscriptionRequests: SubscriptionRequest[] = require('../../../../src/mocks/subscription_requests.json');
  {/** handle upload payment card  */ }
  const handleUploadPaymentCard = () => {
    setShowUploadCard(!showUploadCard);
  };


  const handleContactUser = () => {
    setShowContactUser(!showContactUser);
  };

  //find data subscription request by id
  const subscriptionRequestData = subscriptionRequests.find(
    (subscriptionRequest) => subscriptionRequest.request_id == request_id
  );



  const RenderNewLine = (text: string | undefined) => {
    if (!text) return "No note";
    const newText = text.split('\n').map((str, index) => <p key={index}>{str}<br /></p>);
    return newText;
  }


  return (
    <div>
      <PageTitle breadcrumbs={ResultBreadCrumbs}>Subscription View</PageTitle>


      <div className="card">
        <div className="card-header">
          <span className="title">Subscription view page {request_id}</span>
        </div>
        <div className="card-body">

          <p>Name: {subscriptionRequestData?.name}</p>
          <p>Phone: {subscriptionRequestData?.phone}</p>
          <p>Email: {subscriptionRequestData?.email}</p>
          <p>Organization Name: {subscriptionRequestData?.organization_name}</p>
          <p>Organization Phone: {subscriptionRequestData?.organization_phone}</p>
          <p>Organization Address: {subscriptionRequestData?.organization_address}</p>
          <p>Organization Website: {subscriptionRequestData?.organization_website}</p>
          <p>Organization Note: {RenderNewLine(subscriptionRequestData?.organization_note)}</p>
          <p>Status: {subscriptionRequestData?.status}</p>
          <p>Created At: {subscriptionRequestData?.created_at}</p>
          <p>Updated At: {subscriptionRequestData?.updated_at}</p>
        </div>
        <div className="card-footer">
          {subscriptionRequestData?.status === 'rejected' && (
            <button className="btn btn-danger" onClick={() => handleContactUser()}>Contact User</button>
          )}
          {subscriptionRequestData?.status === 'approved' && (
            <>
              <button className="btn btn-secondary">Deactivate</button>
              <button className="btn btn-primary" onClick={() => handleUploadPaymentCard()}>Upload Payment Detail</button>
              <button className="btn btn-warning">Edit</button>
            </>
          )}
          {subscriptionRequestData?.status === 'pending' && (
            <>
              <button className="btn btn-success">Activate</button>
              <button className="btn btn-danger">Reject</button>
              <button className="btn btn-primary" onClick={() => handleUploadPaymentCard()}>Upload Payment Detail</button>
            </>
          )}
        </div>
      </div>

      {/* Render UploadPaymentCard based on showUploadCard state */}
      {showUploadCard && <UploadPaymentCard />}
      {/* Render contactUser based on showContactUser state */}

      {showContactUser && <Contact sentEmail={subscriptionRequestData?.email} />}
    </div>
  );
};

export default SubscriptionRequestView;