import {useEffect, useState} from 'react'
import clsx from 'clsx'
import {useAuth} from '../../../../hooks/auth'

export function ForgotPassword() {
  const {forgotPassword} = useAuth('guest', '/dashboard')

  const [email, setEmail] = useState('')
  const [errors, setErrors] = useState([])
  const [status, setStatus] = useState(null)
  const [loading, setLoading] = useState(false)
  //Theres code here
  useEffect(() => {
    if (status || errors.length > 0) {
      setLoading(false)
      setEmail('')
    }
  }, [errors.length, status])
  const submitForm = (event) => {
    event.preventDefault()
    forgotPassword({email, setErrors, setStatus})
    setLoading(true)
  }
  return (
    <>
      <div className='text-center mb-10'>
        {/* begin::Title */}
        <h1 className='text-dark fw-bolder mb-3'>Forgot Password?</h1>
        {/* end::Title */}

        {/* begin::Link */}
        <div className='text-gray-500 fw-semibold fs-6'>
          Forgot your password? No problem. Just let us know your email address and we will email
          you a password reset link that will allow you to choose a new one.
        </div>
        {/* end::Link */}
      </div>
      {errors.length > 0 && (
        <div className='mb-lg-15 alert alert-danger'>
          <div className='alert-text font-weight-bold'>
            <ul className='mt-3 list-disc list-inside text-sm'>
              {errors.map((error) => (
                <li key={error}>{error}</li>
              ))}
            </ul>
          </div>
        </div>
      )}
      {status && (
        <div className='mb-10 bg-light-info p-8 rounded'>
          <div className='text-info'>{status}</div>
        </div>
      )}

      <form className='form w-100 fv-plugins-bootstrap5 fv-plugins-framework' onSubmit={submitForm}>
        {/* Email Address */}
        <div className='fv-row mb-8'>
          <label className='form-label fw-bolder text-gray-900 fs-6' htmlFor='email'>
            Email
          </label>
          <input
            className={clsx('form-control bg-transparent', {})}
            id='email'
            type='email'
            name='email'
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            required
            autoFocus
          />
        </div>
        <div className='d-flex flex-wrap justify-content-center pb-lg-0'>
          <button disabled={loading} className='btn btn-primary me-4'>
            Email Password Reset Link{' '}
            <span
              className={clsx('spinner-border', 'spinner-border-sm', {
                'visually-hidden': !loading,
              })}
            ></span>
          </button>
        </div>
      </form>
    </>
  )
}
