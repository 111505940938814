import React from 'react';
import { Doughnut } from 'react-chartjs-2';
import { Chart as ChartJS, ArcElement, Tooltip, Legend, ChartOptions } from 'chart.js';
import { useIntl } from 'react-intl';

ChartJS.register(ArcElement, Tooltip, Legend);

interface ProcessedDataItem {
  name: string;
  value: number;
  percentage: string;
}

interface UsageData {
  [key: string]: number;
}

interface ReportBreakDown {
  usage: UsageData;
}

const TotalUsageBreakdownComponent: React.FC<ReportBreakDown> = ({ usage }) => {
  const intl = useIntl()
  const processData = (usageData: UsageData): ProcessedDataItem[] => {
    const total = Object.values(usageData).reduce((sum, value) => sum + value, 0);
    return Object.entries(usageData).map(([name, value]) => ({
      name,
      value,
      percentage: total > 0 ? ((value / total) * 100).toFixed(2) : '0.00',
    }));
  };

  const processedData = React.useMemo(() => processData(usage), [usage]);
  const hasData = processedData.some(item => item.value > 0);

  const totalUsage = React.useMemo(
    () => processedData.reduce((sum, item) => sum + item.value, 0),
    [processedData]
  );

  if (!hasData) {
    return (
      <div className="w-full max-w-md mx-auto text-center">
        <h2 className="text-xl font-bold mb-4">Total Team Product Usage Chart</h2>
        <p className="text-gray-600">No data available</p>
      </div>
    );
  }

  const chartData = {
    labels: processedData.map((item) => item.name),
    datasets: [
      {
        data: processedData.map((item) => item.value),
        backgroundColor: [
          'rgba(255, 99, 132, 0.8)',
          'rgba(54, 162, 235, 0.8)',
          'rgba(255, 206, 86, 0.8)',
          'rgba(75, 192, 192, 0.8)',
        ],
        borderColor: [
          'rgba(255, 99, 132, 1)',
          'rgba(54, 162, 235, 1)',
          'rgba(255, 206, 86, 1)',
          'rgba(75, 192, 192, 1)',
        ],
        borderWidth: 1,
      },
    ],
  };

  const options: ChartOptions<'doughnut'> = {
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      legend: {
        position: 'bottom' as const,
        display: false,
      },
      tooltip: {
        callbacks: {
          label: (context) => {
            const label = context.label || '';
            const value = context.parsed;
            const percentage = processedData[context.dataIndex].percentage;
            return `${label}: ${value} (${percentage}%)`;
          },
        },
      },
    },
  };

  return (
    <div className="w-full max-w-md mx-auto">
      <h2 className="text-xl font-bold mb-4 text-center">{intl.formatMessage({ id: 'DASHBOARD.TEAM_USAGE_BREAKDOWN' })}</h2>
      <div style={{ width: '300px', height: '300px', margin: '0 auto' }}>
        <Doughnut data={chartData} options={options} />
      </div>
      <p className="mt-4 text-center">{intl.formatMessage({ id: 'DASHBOARD.TOTAL_USAGE' })} {totalUsage}</p>
    </div>
  );
};

export default TotalUsageBreakdownComponent;
