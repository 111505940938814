import React, { useCallback, useEffect, useState } from 'react'
import { useIntl } from 'react-intl'
import { PageTitle } from '../../../_metronic/layout/core'
import TotalYTDWidget from './component/TotalYTDWidget'
import UserSubscriptionWidget from './component/UserSubscriptionWidget'
import BackOfficeSubscriptionRequestWidget from './component/BackOfficeSubscriptionRequestWidget'
import DashBoardTop from './component/DashBoardTop'
import CompanySubscriptionComponent from './component/CompanySubscriptionComponent'
import axios from '../../../lib/axios'
import { useAuth } from '../../../hooks/auth'
import LoadingSpinner from '../../../_metronic/helpers/components/LoadingSpinner'
import UsageBreakdownChartComponent from '../../components/UsageBreakdownChartComponent'
import TotalUsageBreakdownComponent from '../../components/TotalUsageBreakdownComponent'
import { useToast } from '../../components/ToastProvider'

interface TDashboard {
  report_created_ytd?: Record<string, unknown>
}
interface UsageData {
  [key: string]: number;
}

interface ReportBreakDown {
  usage: UsageData;
}


async function getDashboardData(): Promise<TDashboard> {
  const response = await axios.get('/api/user/dashboard')
  return response.data.data
}

async function getTotalUsageData(): Promise<ReportBreakDown> {
  const response = await axios.get('/api/user/total-team-usage')
  return response.data
}

export function DashboardWrapper() {
  const [dashboard, setDashboard] = useState<TDashboard>({})
  const [totalUsage, setTotalUsage] = useState<ReportBreakDown>({ usage: {} });
  const [error, setError] = useState<string | null>(null);
  const { showError, showSuccess } = useToast()

  const intl = useIntl()


  const {
    user,
    loading,
    accountState,
    companies,
    currentCompany,
    subscription,
  } = useAuth('auth')


  const fetchDashboardData = useCallback(async () => {
    try {
      // Combine all async operations into a single await using Promise.all
      const [data, totalUsageData] = await Promise.all([
        getDashboardData(),
        user?.is_company_admin ? getTotalUsageData() : Promise.resolve(null)
      ]);

      // Set the dashboard data
      setDashboard(data);

      console.log('--- is_company_admin ---');
      console.log(user?.is_company_admin);
      console.log('--- is_company_admin ---');

      // Log totalUsageData to see what is returned
      console.log('--- totalUsageData ---', totalUsageData, '--- totalUsageData ---');

      // If the user is a company admin, handle the total usage data
      if (totalUsageData) {
        if (totalUsageData.usage !== undefined) {
          setTotalUsage({ usage: totalUsageData.usage }); // Wrap the usage data in a ReportBreakDown object
        } else {
          console.error('totalUsageData is missing the "usage" property:', totalUsageData);
        }
      } else {
        console.warn('totalUsageData is null or undefined. Check getTotalUsageData function.');
      }

      showSuccess(intl.formatMessage({ id: 'TOAST.DASHBOARD_FETCH_SUCCESS' }));
    } catch (error) {
      console.error('Error fetching dashboard or total usage data:', error);
      showError('An error occurred while fetching dashboard or total usage data.');
    }
  }, [user?.is_company_admin, showError, showSuccess]);


  useEffect(() => {
    if (!loading && Object.keys(dashboard).length === 0) {
      fetchDashboardData()
    }
  }, [loading, dashboard, fetchDashboardData])


  if (loading) {
    return <LoadingSpinner />
  }

  const renderSubscriptionComponent = () => {
    if (user?.is_internal_user && ['Super Admin', 'Admin', 'Data Analyst'].includes(user.role)) {
      return <BackOfficeSubscriptionRequestWidget />
    } else if (accountState === 'personal' && subscription && user?.role !== 'Data Analyst') {
      return <UserSubscriptionWidget user_subscription={subscription} />
    } else {
      return <CompanySubscriptionComponent companyName={currentCompany} companyId={user?.company_id || null} />
    }
  }

  const renderTotalUsageComponent = () => {
    if (user?.role && ['Data Analyst', 'User Regular'].includes(user.role) && user?.mode === 'organization') {
      return (
        <>
          <div className='row g-5 g-xl-10 mb-5 mb-xl-10'>

            <div className='col-xxl-6 mb-5 mb-xl-10'>
              <div className="flex">
                <UsageBreakdownChartComponent
                  productList={user?.product_list || {}}
                  limit={user?.limit?.total_limit ?? 0}
                  unlimited={user?.usage_is_unlimited}
                />
              </div>
            </div>


            {(user?.is_company_admin) && totalUsage ? (
              <div className='col-xxl-6 mb-5 mb-xl-10'>
                <div className="flex">
                  <TotalUsageBreakdownComponent
                    usage={totalUsage.usage}
                  />
                </div>
              </div>
            ) : null}



          </div>

        </>
      )

    } else {
      return null
    }
  }

  return (
    <>


      <PageTitle breadcrumbs={[]}>{intl.formatMessage({ id: 'MENU.DASHBOARD' })}</PageTitle>
      <div className="container mt-5">
        <div className='row g-5 gx-xl-10 mb-5 mb-xl-10'>
          <div className="d-flex flex-column">
            {user && (
              <DashBoardTop
                User={user}
                subscription={user.subscription || null}
                companies={companies}
              />
            )}
            {renderSubscriptionComponent()}
          </div>
          {renderTotalUsageComponent()}
        </div>
        <button className='btn-info' onClick={fetchDashboardData}>Refresh Dashboard</button>
      </div >
    </>
  )
}