import { Button } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';

interface ButtonSubscriptionViewProps {
  flagParentSubscription: boolean;
  flagDefaultAdmin: boolean;
  flagDefaultOwnerSubscription: boolean;
  subscriptionId: string;
  companyId: string;
  setSubscriptionAsCompanyDefault: (subscriptionId: string, companyId: string) => void;
  setSubscriptionAsNotCompanyDefault: (subscriptionId: string, companyId: string) => void;
  markSubscriptionForAdmin: (subscriptionId: string) => void;
  unmarkSubscriptionForAdmin: (subscriptionId: string) => void;
}

const ButtonSubscriptionView = ({
  flagParentSubscription,
  flagDefaultAdmin,
  flagDefaultOwnerSubscription,
  subscriptionId,
  companyId,
  setSubscriptionAsCompanyDefault,
  setSubscriptionAsNotCompanyDefault,
  markSubscriptionForAdmin,
  unmarkSubscriptionForAdmin
}: ButtonSubscriptionViewProps) => {
  const navigate = useNavigate();
  const URL_EDIT_SUBSCRIPTION = '/subscriptions/admin-edit-subscription';

  return (
    <div className='btn-group-vertical'>
      <Button
        className='btn btn-sm btn-light-primary me-2'
        onClick={() => navigate(URL_EDIT_SUBSCRIPTION, {
          state: { subscriptionId: subscriptionId },
        })}
      >
        <i className='fa fa-wrench'></i>Edit
      </Button>
      {flagParentSubscription === false && (
        <>
          {flagDefaultOwnerSubscription === false ? (
            <Button
              className='btn btn-sm btn-light-warning me-2'
              onClick={() => markSubscriptionForAdmin(subscriptionId)}
            >
              <i className='fa fa-wrench'></i>Mark Default For Admin
            </Button>
          ) :
            (

              <Button
                className='btn btn-sm btn-light-warning me-2'
                onClick={() => unmarkSubscriptionForAdmin(subscriptionId)}
              >
                <i className='fa fa-wrench'></i>unmark Default For Admin
              </Button>
            )
          }

          <Button
            className='btn btn-sm btn-light-warning me-2'
            onClick={() =>
              flagDefaultAdmin
                ? setSubscriptionAsNotCompanyDefault(subscriptionId, companyId)
                : setSubscriptionAsCompanyDefault(subscriptionId, companyId)
            }
          >
            <i className='fa fa-wrench'></i>
            {flagDefaultAdmin ? 'Unset As Default Subscription' : 'Set As Default Subscription'}
          </Button>
        </>
      )}
    </div>
  );
}

export default ButtonSubscriptionView;
