import React, { useState } from 'react'
import MultiRangeSlider from '../../../../components/vendors/MultiRangeSlider'
import { Table } from 'react-bootstrap'
import Select, { components, OptionProps } from 'react-select'
import InputSelect from './components/InputSelect'
import { TOption } from '../../model'
import { useIntl } from 'react-intl'

const CustomOption: React.FC<OptionProps> = (props) => {
  return (
    <components.Option {...props}>
      <div style={{ whiteSpace: 'normal' }}>{props.children}</div>
    </components.Option>
  );
};

const customStyles = {
  menu: (base) => ({
    ...base,
    width: 'auto',
    minWidth: '100%',
  }),
  control: (base) => ({
    ...base,
    minWidth: '500px',
    maxWidth: '500px',
  }),
};

function Step5({ companyInformation, companyAnalysis, QualitativeFactors, handleChange }) {
  const intl = useIntl()
  const optionWeight = [
    { value: 0, label: '0%' },
    { value: 5, label: '5%' },
    { value: 10, label: '10%' },
    { value: 15, label: '15%' },
    { value: 20, label: '20%' },
    { value: 25, label: '25%' },
    { value: 30, label: '30%' },
    { value: 35, label: '35%' },
    { value: 40, label: '40%' },
    { value: 45, label: '45%' },
    { value: 50, label: '50%' },
    { value: 55, label: '55%' },
    { value: 60, label: '60%' },
    { value: 65, label: '65%' },
    { value: 70, label: '70%' },
    { value: 75, label: '75%' },
    { value: 80, label: '80%' },
    { value: 85, label: '85%' },
    { value: 90, label: '90%' },
    { value: 95, label: '95%' },
    { value: 100, label: '100%' },
  ]
  const optionScore = [
    { value: 20, label: 'Low' },
    { value: 40, label: 'Low To sufficient' },
    { value: 60, label: 'Fair' },
    { value: 80, label: 'Good' },
    { value: 100, label: 'Very Good' },
  ]

  const handleSelect = (item: TOption | unknown, option, p2) => {
    // Cast the item to TOption type before accessing its properties
    console.log('handleSelect option ::', option)
    const value = item as TOption
    console.log('handleSelect ::' + value.label)

    let label: any = ''
    const score = value.value
    for (let key in option) {
      if (option[key].score === score) {
        label = option[key].option
      }
    }

    handleChange(value.value, 'companyAnalysis', p2 + '_score')
    handleChange(label, 'companyAnalysis', p2)
  }

  const getSelected = (score, factor, option) => {
    console.log('getSelected ::', score, option)
    const defaultOption: TOption = { value: '', label: intl.formatMessage({ id: 'SELECT.Please Choose' }) }

    if (factor === null || factor === '') {
      return defaultOption
    }

    const selected: any = []
    for (let key in option) {
      if (option[key].score === score) {
        selected.push(option[key])
      }
    }
    return selected.map((item) => ({
      value: item.score,
      label: intl.formatMessage({ id: 'CM.' + item.option}),
    }))
  }

  return (
    <>
      <div className='w-100'>
        <div className='pb-10 pb-lg-15'>
          <h2 className='fw-bolder d-flex align-items-center text-dark'>{intl.formatMessage({ id: 'COMPANY.NON_FINANCIAL_ANALYSIS' })}:</h2>
        </div>
        <table className='table table-row-dashed table-row-gray-300' style={{ width: '200px' }}>
          <thead>
            <tr>
              <th>{intl.formatMessage({ id: 'CM.CRITERION' })}</th>
              <th>{intl.formatMessage({ id: 'CM.CATEGORY' })} </th>
            </tr>
          </thead>
          <tbody>
            {/* 
              Lawsuit/Legal Disputes		
              Management Style/Succession Planning		
              Key Management Years of Experience		
              Number of Years in Operation		
            */}
            <tr>
              <td>{intl.formatMessage({ id: 'CM.LAWSUIT_LEGAL_DISPUTES' })} </td>
              <td>
                <Select
                  className='react-select-styled react-select-solid'
                  classNames={{
                    control: () => 'border-default border',
                  }}
                  classNamePrefix='react-select'
                  defaultValue={getSelected(
                    companyAnalysis.lawsuit_legal_score,
                    companyAnalysis.lawsuit_legal,
                    QualitativeFactors.lawsuit_legal
                  )}
                  options={QualitativeFactors.lawsuit_legal.map((item) => ({
                    value: item.score,
                    label: intl.formatMessage({ id: 'CM.' + item.option}),
                  }))}
                  onChange={(item) =>
                    handleSelect(item, QualitativeFactors.lawsuit_legal, 'lawsuit_legal')
                  }
                  components={{ Option: CustomOption }}
                  styles={customStyles}

                />
              </td>
            </tr>

            <tr>
              <td>{intl.formatMessage({ id: 'CM.MANAGEMENT_STYLE_SUCCESSION_PLANNING' })}	</td>
              <td>
                <Select
                  className='react-select-styled react-select-solid'
                  classNames={{
                    control: () => 'border-default border',
                  }}
                  classNamePrefix='react-select'
                  defaultValue={getSelected(
                    companyAnalysis.management_planning_score,
                    companyAnalysis.management_planning,
                    QualitativeFactors.management_planning
                  )}
                  options={QualitativeFactors.management_planning.map((item) => ({
                    value: item.score,
                    label: intl.formatMessage({ id: 'CM.' + item.option}),
                  }))}
                  onChange={(item) =>
                    handleSelect(item, QualitativeFactors.management_planning, 'management_planning')
                  }
                  components={{ Option: CustomOption }}
                  styles={customStyles}

                />
              </td>
            </tr>

            <tr>
              <td>{intl.formatMessage({ id: 'CM.KEY_MANAGEMENT_YEARS_OF_EXPERIENCE' })}  </td>
              <td>
                <Select
                  className='react-select-styled react-select-solid'
                  classNames={{
                    control: () => 'border-default border',
                  }}
                  classNamePrefix='react-select'
                  defaultValue={getSelected(
                    companyAnalysis.management_experience_score,
                    companyAnalysis.management_experience,
                    QualitativeFactors.management_experience
                  )}
                  options={QualitativeFactors.management_experience.map((item) => ({
                    value: item.score,
                    label: intl.formatMessage({ id: 'CM.' + item.option}),
                  }))}
                  onChange={(item) =>
                    handleSelect(item, QualitativeFactors.management_experience, 'management_experience')
                  }
                />
              </td>
            </tr>

            <tr>
              <td>{intl.formatMessage({ id: 'CM.NUMBER_OF_YEARS_IN_OPERATION' })} </td>
              <td>
                <Select
                  className='react-select-styled react-select-solid'
                  classNames={{
                    control: () => 'border-default border',
                  }}
                  classNamePrefix='react-select'
                  defaultValue={getSelected(
                    companyAnalysis.operation_year_score,
                    companyAnalysis.operation_year,
                    QualitativeFactors.operation_year
                  )}
                  options={QualitativeFactors.operation_year.map((item) => ({
                    value: item.score,
                    label: intl.formatMessage({ id: 'CM.' + item.option}),
                  }))}
                  onChange={(item) =>
                    handleSelect(item, QualitativeFactors.operation_year, 'operation_year')
                  }
                />
              </td>
            </tr>

          </tbody>
        </table>
      </div>
    </>
  )
}

export default Step5
