import React from 'react';
import { useAuth } from '../../../hooks/auth';
import { useNavigate } from 'react-router-dom';
const CompaniesPage: React.FC = () => {
    // Add your code here

    let navigate = useNavigate();

    const { user } = useAuth('auth');


    if (user?.role === 'Super Admin')
        {
        navigate('/companies/admin-all-companies')
        } else {
        navigate('/companies/user-all-companies')

        }

    return (
        <div>
            {/* Add your content here */}
        </div>
    );
};

export default CompaniesPage;