import React, { useEffect } from 'react'
import MultiRangeSlider, { ChangeResult } from '../../../../components/vendors/MultiRangeSlider'
import Select from 'react-select'
import { TOption } from '../../model'
import clsx from 'clsx'
import { Accordion } from 'react-bootstrap'
import { useIntl } from 'react-intl'

function Step3({
  companyInformation,
  companyAnalysis,
  companyResult,
  CompanyList,
  SectorList,
  DebtRangeCompanyList,
  OriginalPeersDebtRange,
  handleChange,
  handleSubmit,
  handleDebtRange,
  handleOriginalDebtRange,
}) {
  const intl = useIntl()
  const total_peer_sector = companyAnalysis.peer_sector?.length
  console.log('total_peer_sector', total_peer_sector)
  const handleInput = (e) => {
    handleChange(e.minValue, 'companyAnalysis', 'debt_range_min')
    handleChange(e.maxValue, 'companyAnalysis', 'debt_range_max')
    // handleSubmit()
  }

  const handleSelect = (item: TOption | unknown) => {
    // Cast the item to TOption type before accessing its properties
    const value = item as TOption[]

    if (companyInformation.peer_type === 'peer_sector') {
      const idList = value.map((item) => item.value)
      handleChange(idList, 'companyAnalysis', 'peer_sector')
      handleChange('', 'companyAnalysis', 'peer_company')
      // After change take effect will send a handleSubmit() using useEffect() to detect the changes
    }
    if (companyInformation.peer_type === 'peer_company') {
      // loop value and get value.value and convert to comma separated string
      const idList = value.map((item) => item.value).join(',')
      handleChange(idList, 'companyAnalysis', 'peer_company')
      handleChange([], 'companyAnalysis', 'peer_sector')
      // After change take effect will send a handleSubmit() using useEffect() to detect the changes
    }
  }

  useEffect(() => {
    // This will be executed when the state changes
    //handleSubmit()
    handleDebtRange()
  }, [companyAnalysis.data_period, companyAnalysis.data_validation])

  useEffect(() => {
    // This will be executed when the state changes
    //handleSubmit()
    handleDebtRange()
    handleOriginalDebtRange()
  }, [companyAnalysis.peer_sector?.length, companyAnalysis.peer_company])
  useEffect(() => {
    // This will be executed when the state changes
    handleDebtRange()
  }, [companyAnalysis.debt_range_min, companyAnalysis.debt_range_max])

  useEffect(() => {
    // This will be executed when the state changes
    console.log('OriginalPeersDebtRange.debt_range_max', OriginalPeersDebtRange.debt_range_max)
    if (OriginalPeersDebtRange.debt_range_max === 0) {
      handleChange(100, 'companyAnalysis', 'debt_range_max')
    } else {
      handleChange(OriginalPeersDebtRange.debt_range_max, 'companyAnalysis', 'debt_range_max')
    }
  }, [OriginalPeersDebtRange.debt_range_max])

  useEffect(() => {
    // This will be executed when the state changes
    if (companyResult.company_results.company_data.original_peers.total_company !== 0) {
      handleChange(
        companyResult.company_results.company_data.original_peers.debt_range_max,
        'companyAnalysis',
        'debt_range_max'
      )
    }
  }, [companyResult.company_results.company_data.original_peers.debt_range_max])

  /* Converting peer_sector comma separated string from API and convert to react-select value-label format */
  const getSelectedPeerSector = () => {
    const selectedPeerSector: any = []
    const peer_sector_list = companyAnalysis.peer_sector
    for (let key in SectorList) {
      if (peer_sector_list?.includes(SectorList[key].sector)) {
        selectedPeerSector.push(SectorList[key])
      }
    }
    return selectedPeerSector.map((item) => ({ value: item.sector, label: intl.formatMessage({ id: 'CM.' + item.sector}) }))
  }

  const getSelectedPeerCompany = () => {
    const selectedPeerCompany: any = []
    const peer_company_id_list = companyAnalysis.peer_company?.toString().split(',')
    for (let key in CompanyList) {
      if (peer_company_id_list?.includes(CompanyList[key].id?.toString())) {
        selectedPeerCompany.push(CompanyList[key])
      }
    }
    return selectedPeerCompany.map((item) => ({ value: item.id, label: item.company_name }))
  }

  return (
    <>
      <div className='fv-row'>
        <div className='row mb-6'>
          <label className='col-lg-4 col-form-label required fw-bold fs-6'>{intl.formatMessage({ id: 'CM.PEER_TYPE' })}: </label>
          <div className='col-lg-8 fv-row'>
            <div className='row'>
              <div className='col-lg-6'>
                <input
                  type='radio'
                  className='btn-check'
                  name='peer_type'
                  id='kt_create_account_form_account_type_personal'
                  value='peer_sector'
                  checked={companyInformation.peer_type === 'peer_sector'}
                  onChange={(e) => {
                    handleChange(e.currentTarget.value, 'companyInformation', 'peer_type')
                  }}
                />
                <label
                  className='btn btn-outline btn-outline-danger p-7 d-flex align-items-center mb-10'
                  htmlFor='kt_create_account_form_account_type_personal'
                >
                  <span className='d-block fw-bold text-start'>
                    <span className='text-dark fw-bolder d-block fs-4'>{intl.formatMessage({ id: 'CM.SECTOR' })} </span>
                  </span>
                </label>
              </div>

              <div className='col-lg-6'>
                <input
                  type='radio'
                  className='btn-check btn-success'
                  name='peer_type'
                  id='kt_create_account_form_account_type_corporate'
                  value='peer_company'
                  checked={companyInformation.peer_type === 'peer_company'}
                  onChange={(e) => {
                    handleChange(e.currentTarget.value, 'companyInformation', 'peer_type')
                  }}
                />
                <label
                  className='btn btn-outline btn-outline-danger p-7 d-flex align-items-center'
                  htmlFor='kt_create_account_form_account_type_corporate'
                >
                  <span className='d-block fw-bold text-start'>
                    <span className='text-dark fw-bolder d-block fs-4'>{intl.formatMessage({ id: 'CM.COMPANY' })}</span>
                  </span>
                </label>
              </div>

              <div className='text-danger mt-2'></div>
            </div>

            <Select
              className={clsx('react-select-styled react-select-solid', {
                'visually-hidden': companyInformation.peer_type !== 'peer_company',
              })}
              classNames={{
                control: () => 'border-default border',
              }}
              classNamePrefix='react-select'
              value={getSelectedPeerCompany()}
              options={CompanyList.map((item) => ({ value: item.id, label: item.company_name }))}
              isMulti
              onChange={(option) => handleSelect(option)}
            />

            <Select
              className={clsx('react-select-styled react-select-solid', {
                'visually-hidden': companyInformation.peer_type !== 'peer_sector',
              })}
              classNames={{
                control: () => 'border-default border',
              }}
              classNamePrefix='react-select'
              value={getSelectedPeerSector()}
              options={SectorList.map((item) => ({ value: item.sector, label: intl.formatMessage({ id: 'CM.' + item.sector}) }))}
              isMulti
              onChange={(option) => handleSelect(option)}
            />
          </div>
        </div>
      </div>

      <div className='fv-row'>
        <div className='row mb-6'>
          <label className='col-lg-4 col-form-label required fw-bold fs-6'>{intl.formatMessage({ id: 'CM.DATA_PERIOD' })}: </label>
          <div className='col-lg-8 fv-row'>
            <div className='row'>
              <div className='col-lg-6'>
                <input
                  type='radio'
                  className='btn-check'
                  name='data_period'
                  value='3'
                  id='data_period_three'
                  checked={companyAnalysis.data_period.toString() === '3'}
                  onChange={(e) => {
                    handleChange(e.currentTarget.value, 'companyAnalysis', 'data_period')
                  }}
                />
                <label
                  className='btn btn-outline btn-outline-danger p-7 d-flex align-items-center mb-10'
                  htmlFor='data_period_three'
                >
                  <span className='d-block fw-bold text-start'>
                    <span className='text-dark fw-bolder d-block fs-4'>3 {intl.formatMessage({ id: 'CM.YEARS' })} </span>
                  </span>
                </label>
              </div>

              <div className='col-lg-6'>
                <input
                  type='radio'
                  className='btn-check btn-success'
                  name='data_period'
                  value='5'
                  id='data_period_five'
                  checked={companyAnalysis.data_period.toString() === '5'}
                  onChange={(e) => {
                    handleChange(e.currentTarget.value, 'companyAnalysis', 'data_period')
                  }}
                />
                <label
                  className='btn btn-outline btn-outline-danger p-7 d-flex align-items-center'
                  htmlFor='data_period_five'
                >
                  <span className='d-block fw-bold text-start'>
                    <span className='text-dark fw-bolder d-block fs-4'>5 {intl.formatMessage({ id: 'CM.YEARS' })}</span>
                  </span>
                </label>
              </div>

              <div className='text-danger mt-2'></div>
            </div>
          </div>
        </div>
      </div>

      <div className='fv-row'>
        <div className='row mb-6'>
          <label className='col-lg-4 col-form-label required fw-bold fs-6'>{intl.formatMessage({ id: 'CM.DATA_VALIDATION' })}: </label>
          <div className='col-lg-8 fv-row'>
            <div className='row'>
              <div className='col-lg-6'>
                <input
                  type='radio'
                  className='btn-check'
                  name='data_validation'
                  value='0'
                  id='data_validation_original'
                  checked={companyAnalysis.data_validation.toString() === '0'}
                  onChange={(e) => {
                    handleChange(e.currentTarget.value, 'companyAnalysis', 'data_validation')
                  }}
                />
                <label
                  className='btn btn-outline btn-outline-danger p-7 d-flex align-items-center mb-10'
                  htmlFor='data_validation_original'
                >
                  <span className='d-block fw-bold text-start'>
                    <span className='text-dark fw-bolder d-block fs-4'>{intl.formatMessage({ id: 'CM.ORIGINAL_DATA' })} </span>
                  </span>
                </label>
              </div>

              <div className='col-lg-6'>
                <input
                  type='radio'
                  className='btn-check btn-success'
                  name='data_validation'
                  value='1'
                  id='data_validation_trimmed'
                  checked={companyAnalysis.data_validation.toString() === '1'}
                  onChange={(e) => {
                    handleChange(e.currentTarget.value, 'companyAnalysis', 'data_validation')
                  }}
                />
                <label
                  className='btn btn-outline btn-outline-danger p-7 d-flex align-items-center'
                  htmlFor='data_validation_trimmed'
                >
                  <span className='d-block fw-bold text-start'>
                    <span className='text-dark fw-bolder d-block fs-4'>{intl.formatMessage({ id: 'CM.TRIMMED_DATA' })}</span>
                  </span>
                </label>
              </div>

              <div className='text-danger mt-2'></div>
            </div>
          </div>
        </div>
      </div>

      <div className='fv-row'>
        <div className='row mb-6'>
          <label className='col-lg-4 col-form-label required fw-bold fs-6'>
          {intl.formatMessage({ id: 'CM.TOTAL_DEBT_RANGE' })}:
          </label>
          <div className='col-lg-8 fv-row'>
            <MultiRangeSlider
              min={OriginalPeersDebtRange.debt_range_min}
              max={Math.ceil(OriginalPeersDebtRange.debt_range_max)}
              step={OriginalPeersDebtRange.debt_range_max / 20}
              minValue={companyAnalysis.debt_range_min}
              maxValue={companyAnalysis.debt_range_max}
              onChange={(e) => {
                handleInput(e)
              }}
            />
          </div>
        </div>
        <div className='row'>
          <div className='col-lg-4'></div>
          <div className='col-lg-8'>
            <Accordion defaultActiveKey=''>
              <Accordion.Item eventKey='0'>
                <Accordion.Header>
                  {intl.formatMessage({ id: 'CM.COMPANY_LIST' })} ({DebtRangeCompanyList?.length ?? 0})
                </Accordion.Header>
                <Accordion.Body>
                  <ul>
                    {DebtRangeCompanyList?.map((item) => (
                      <li key={item.id}>{item.name}</li>
                    ))}
                  </ul>
                </Accordion.Body>
              </Accordion.Item>
            </Accordion>
          </div>
        </div>
      </div>
    </>
  )
}

export default Step3