import React from 'react'
import { toAbsoluteUrl } from '../../../../../_metronic/helpers'
import { useIntl } from 'react-intl'

function Step1({ corporate_model_no, product_key, companyInformation, handleChange, handleSwitchDataSource }) {
  const intl = useIntl()

  const isReportKey = (key) => {
    return product_key === key ? true : false;
  }
  return (
    <>
      <div className='w-100'>
        <div className='pb-10 pb-lg-15'>
          <h2 className='fw-bolder d-flex align-items-center text-dark'>
          {intl.formatMessage({ id: 'CM.DATA_SOURCE' })}:
          </h2>

          <div className='text-gray-400 fw-bold fs-6'>
          {intl.formatMessage({ id: 'CM.IF_YOU_NEED_MORE_HELP' })} &nbsp;

            <a href='/dashboard' className='link-primary fw-bolder'>
              {intl.formatMessage({ id: 'CM.HELP_PAGE' })}
            </a>
            .
          </div>
        </div>

        <div className='fv-row'>
          <div className='row'>
            {(isReportKey('corporate-model-report-1723004859') || isReportKey('corporate-model-report-11112024')) && (
              <div className='col-lg-6 mx-auto'>
                <input
                  type='radio'
                  className='btn-check'
                  name='data_source'
                  value='klci'
                  id='data_source_bursa'
                  checked={companyInformation.data_source === 'klci'}
                  onChange={(e) => {
                    handleChange(e.currentTarget.value, 'companyInformation', 'data_source')
                    handleSwitchDataSource(e.currentTarget.value)
                  }}
                />
                <label
                  className='btn btn-outline btn-outline-danger p-7 d-flex align-items-center mb-10'
                  htmlFor='data_source_bursa'
                >
                  <img
                    alt='Logo'
                    src={toAbsoluteUrl('/media/logos/logo-ds-bursa.png')}
                    className='h-50px h-lg-50px me-5'
                  />
                  <span className='d-block fw-bold text-start'>
                    <span className='text-dark fw-bolder d-block fs-4 mb-2'>
                      {intl.formatMessage({ id: 'CM.BURSA_MALAYSIA_UNIVERSE' })}
                    </span>
                    <span className='text-gray-400 fw-bold fs-6'>
                      {intl.formatMessage({ id: 'CM.BURSA_MALAYSIA_UNIVERSE_DESC' })}
                    </span>
                  </span>
                </label>
              </div>
            )}
            {(isReportKey('corporate-model-report-marc-universe-1723878243') || isReportKey('corporate-model-report-11112024')) && (
              <div className='col-lg-6 mx-auto'>
                <input
                  type='radio'
                  className='btn-check btn-success'
                  name='data_source'
                  value='mu'
                  id='data_source_marc'
                  checked={companyInformation.data_source === 'mu'}
                  onChange={(e) => {
                    handleChange(e.currentTarget.value, 'companyInformation', 'data_source')
                    handleSwitchDataSource(e.currentTarget.value)
                  }}
                />
                <label
                  className='btn btn-outline btn-outline-danger p-7 d-flex align-items-center'
                  htmlFor='data_source_marc'
                >
                  <img
                    alt='Logo'
                    src={toAbsoluteUrl('/media/logos/logo-ds-marc.png')}
                    className='h-25px h-lg-25px me-5'
                  />
                  <span className='d-block fw-bold text-start'>
                    <span className='text-dark fw-bolder d-block fs-4 mb-2'>{intl.formatMessage({ id: 'CM.MARC_UNIVERSE' })}</span>
                    <span className='text-gray-400 fw-bold fs-6'>
                      {intl.formatMessage({ id: 'CM.MARC_UNIVERSE_DESC' })}
                    </span>
                  </span>
                </label>
              </div>
            )}
            {(isReportKey('mid-tier-report-1723872854') || isReportKey('corporate-model-report-11112024')) && (
              <div className='col-lg-6 mx-auto'>
                <input
                  type='radio'
                  className='btn-check'
                  name='data_source'
                  value='klci-mid-tier'
                  id='data_source_mid_tier'
                  checked={companyInformation.data_source === 'klci-mid-tier'}
                  onChange={(e) => {
                    handleChange(e.currentTarget.value, 'companyInformation', 'data_source')
                    handleSwitchDataSource(e.currentTarget.value)
                  }}
                />
                <label
                  className='btn btn-outline btn-outline-danger p-7 d-flex align-items-center mb-10'
                  htmlFor='data_source_mid_tier'
                >
                  <img
                    alt='Logo'
                    src={toAbsoluteUrl('/media/logos/logo-ds-bursa.png')}
                    className='h-50px h-lg-50px me-5'
                  />
                  <span className='d-block fw-bold text-start'>
                    <span className='text-dark fw-bolder d-block fs-4 mb-2'>
                      {intl.formatMessage({ id: 'CM.BURSA_MALAYSIA_MID_TIER' })}
                    </span>
                    <span className='text-gray-400 fw-bold fs-6'>
                      {intl.formatMessage({ id: 'CM.BURSA_MALAYSIA_MID_TIER_DESC' })}
                    </span>
                  </span>
                </label>
              </div>
            )}

            <div className='text-danger mt-2'></div>
          </div>
        </div>
      </div>
    </>
  )
}

export default Step1
