import useIntl from "react-intl/src/components/useIntl"

export default function DashBoardTop({ User, subscription, companies }) {
  const intl = useIntl()

  return (
    <>
      {/* begin::Row */}

      <div className={`card`}>
        {/* begin::Body */}
        <div className='card-body'>
          <span className={`card-title fw-bold text-primary fs-5 mb-3 d-block`}>
          {intl.formatMessage({ id: 'DASHBOARD.WELCOME' })}, {User?.name}!
          </span>
          {User?.roles && User?.roles.length > 0 && (User?.roles[0]?.name === 'Super Admin' || User?.roles[0]?.name === 'Data Analyst') ? (
            <span className={`text-muted fw-bold fs-5`}>
              {`${intl.formatMessage({ id: 'DASHBOARD.LOGGED_IN_AS' })} ${User?.roles[0]?.name}`}
            </span>
          ) : (
            <span className={`text-muted fw-bold fs-5`}>
              {companies?.length > 0 ? intl.formatMessage({ id: 'DASHBOARD.COMPANIES_IN_ACCOUNT' }) + ' ' + companies?.length : 'You have no companies in your account'}
              <br></br>

              {subscription ? (intl.formatMessage({ id: 'DASHBOARD.SUBSCRIPTION_ACTIVE UNTIL' }) + ' ' + subscription?.end_date) : 'You have no active subscription'}
            </span>
          )}
        </div>
      </div>
    </>
  )
}
