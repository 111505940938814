import React, { useEffect, useState } from 'react';
import { Table, Button, Spinner } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import axios from '../../../lib/axios';
import { KTCard, KTCardBody } from '../../../_metronic/helpers';
import { PageLink, PageTitle } from '../../../_metronic/layout/core';
import AdminViewAllSubscriptionRequestPage from '../subscriptions/AdminViewAllSubscriptionRequestPage';
interface Product {
  id: string;
  product_key:string;
  name: string;
  type: string;
  active: boolean;
  is_subscription: boolean;
}

const AdminAllProduct: React.FC = () => {
  const [products, setProducts] = useState<Product[]>([]);
  const [loading, setLoading] = useState<boolean>(true);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchProducts = async () => {
      try {
        const response = await axios.get('/api/products');
        setProducts(response.data);
        setLoading(false);
      } catch (error) {
        console.error('Error fetching products:', error);
        setLoading(false);
      }
    };

    fetchProducts();
  }, []);

  if (loading) {
    return <Spinner animation="border" />;
  }

  const handleView = (id: string) => {
    navigate(`/product/admin-view-product`, { state: { product_id: id } });
  };

  const handleEdit = (id: string) => {
    navigate(`/product/admin-edit-product`, { state: { product_id: id } });
  };

  const handleAddProduct = () => {
    navigate(`/product/admin-add-product`);
  };
  const handleAllList = () => {
    navigate(`/product/admin-all-product`);
  };

  const PageBreadCrumbs: Array<PageLink> = [
    {
      title: 'All Product',
      path: '/product/admin-all-product',
      isSeparator: false,
      isActive: false,
    },
    {
      title: '',
      path: '',
      isSeparator: true,
      isActive: false,
    }

  ];
  const Title = () => {
    return 'All Product '
  };



  return (
    <>
      <PageTitle breadcrumbs={PageBreadCrumbs}>{Title()}</PageTitle>
      <div className="flex-fill bg-light">
        {
          /** alert  */
        }
        <div className="d-flex align-items-center bg-light-warning rounded p-5 mb-7">
          <span className=" text-warning me-5"><i className="ki-duotone ki-abstract-26 text-warning fs-1 me-5"
          ><span className="path1"></span><span className="path2"></span></i></span><div className="flex-grow-1 me-2">
            <a href="#" className="fw-bold text-gray-800 text-hover-primary fs-6">List of product along with key identifier</a>
            <p>The key identifier will be used in subscription tracking</p>
            </div>
     </div>
        {

          /** alert  */
        }
        </div>
      <KTCard>
        <div className='card-header'>
          <div className='card-toolbar'>
            <Button className="btn btn-success btn-sm" onClick={handleAllList}><i className='fa fa-list'></i>List Product</Button>
            <Button className="btn btn-primary btn-sm" onClick={handleAddProduct}><i className='fa fa-plus'></i>Add Product</Button>
          </div>
        </div>
        <KTCardBody>
          <h3>Product List</h3>
          <Table striped bordered hover>
            <thead>
              <tr>
                <th>Name</th>
                <th>Product Key</th>
                <th>Type</th>
                <th>Active</th>
                <th>Subscription Service</th>
                <th>Actions</th>
              </tr>
            </thead>
            <tbody>
              {products.map(product => (
                <tr key={product.id}>
                  <td>{product.name}</td>
                  <td>{product.product_key}</td>

                  <td>{product.type}</td>
                  <td>{product.active ? 'Yes' : 'No'}</td>
                  <td>{product.is_subscription ? 'Yes' : 'No'}</td>
                  <td>
                    <Button
                      variant="info"
                      size="sm"
                      className="me-2"
                      onClick={() => handleView(product.id)}
                    >
                      View
                    </Button>
                    <Button
                      variant="warning"
                      size="sm"
                      onClick={() => handleEdit(product.id)}
                    >
                      Edit
                    </Button>
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>


        </KTCardBody>
      </KTCard>
    </>

  );
};

export default AdminAllProduct;
