import React, { useCallback, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Button, Spinner } from 'react-bootstrap';
import Select from 'react-select';
import axios from '../../../lib/axios';
import { useToast } from '../../components/ToastProvider';
import { Company, Companies } from './Companies';
import { PageLink, PageTitle } from '../../../_metronic/layout/core';

const companyBreadCrumbs: Array<PageLink> = [
  {
    title: 'All Companies',
    path: '/companies/admin-all-companies',
    isSeparator: false,
    isActive: false,
  }
];
const AdminAllCompanies = () => {
  const { showError } = useToast();
  const [companies, setCompanies] = useState<Company[]>([]);
  const [loadingCompanies, setLoadingCompanies] = useState(false);
  const [expandedRows, setExpandedRows] = useState<Set<number>>(new Set());
  const [search, setSearch] = useState('');
  const [filteredCompanies, setFilteredCompanies] = useState<Company[]>([]);

  const navigate = useNavigate();

  const getCompanies = useCallback(async () => {
    setLoadingCompanies(true);
    try {
      const response = await axios.get<Companies>('/api/companies', {
        params: { search }
      });
      setCompanies(response.data.companies);
      setFilteredCompanies(response.data.companies);
    } catch (error) {
      showError('Failed to fetch companies');
    } finally {
      setLoadingCompanies(false);
    }
  }, [showError, search]);

  const toggleRow = (id: number) => {
    const newExpandedRows = new Set(expandedRows);
    if (newExpandedRows.has(id)) {
      newExpandedRows.delete(id);
    } else {
      newExpandedRows.add(id);
    }
    setExpandedRows(newExpandedRows);
  };

  const handleViewCompany = (companyId: number) => {
    if (companyId) {
      navigate(`/companies/admin-view-company`, {
        state: { companyId: companyId }
      });
    } else {
      showError('Company not found');
    }
  };

  useEffect(() => {
    getCompanies();
  }, [getCompanies]);

  const handleEditClick = (id: number) => {
    navigate(`/companies/edit/${id}`);
  };

  const handleRefresh = () => {
    getCompanies();
  };

  const handleSearchChange = (value: any) => {
    setSearch(value?.value || '');
    getCompanies();
  };

  const searchOptions = companies && companies?.length > 0 ? (companies.map(company => ({
    value: company.name,
    label: company.name
  }))) : ([]);

  const TableHeaderCompany = () => (
    <thead>
      <tr className="fw-bold fs-6 text-gray-800 border-bottom border-gray-200">
        <th>ID</th>
        <th></th>
        <th>Name</th>
        <th>Address</th>
        <th>Email</th>
        <th>Phone</th>
        <th>Website</th>
        <th>Actions</th>
      </tr>
    </thead>
  );

  const TableBodyCompany = () => (
    <tbody>
      {filteredCompanies && filteredCompanies?.length > 0 ? (filteredCompanies.map((company: Company) => (
        <React.Fragment key={company.id}>
          <tr>
            <td>{company.id}</td>
            <td>
              <Button className="btn btn-sm btn-success" onClick={() => toggleRow(company.id)}>
                <i className="fa-solid fa-circle-info"></i>
              </Button>
            </td>
            <td>{company.name}</td>
            <td>{company.address}</td>
            <td>{company.email}</td>
            <td>{company.phone}</td>
            <td>{company.website}</td>
            <td>
              <Button variant="info" onClick={() => handleEditClick(company.id)}>
                Edit
              </Button>
            </td>
          </tr>
          {expandedRows.has(company.id) && (
            <tr>
              <td colSpan={8}>
                <div>
                  <strong>ID:</strong> {company.id}<br />
                  <strong>Name:</strong> {company.name}<br />
                  <strong>Address:</strong> {company.address}<br />
                  <strong>Email:</strong> {company.email}<br />
                  <strong>Phone:</strong> {company.phone}<br />
                  <strong>Website:</strong> {company.website}<br />
                  <strong>Has Multiple Active Subscription:</strong>{company.has_multiple ? 'Yes' : 'No'}
                </div>
                <div className='card'>
                  <div className='card-body'>
                    <Button className='btn btn-md btn-system' onClick={() => handleViewCompany(company.id)}>
                      <i className='fa fa-eye'></i>View Company
                    </Button>
                  </div>
                </div>
              </td>
            </tr>
          )}
        </React.Fragment>
      ))) : (<tr><td colSpan={8}>No companies found</td></tr>)}
    </tbody>
  );



  return (
    <>
      <PageTitle breadcrumbs={companyBreadCrumbs}>All Companies</PageTitle>
      <div className="card card-flush h-xl-100">

        <div className="card-header pt-5">
          <h3 className="card-title align-items-start flex-column">
            <span className="card-label fw-bold text-gray-900">All Companies</span>
            <span className="text-gray-500 mt-1 fw-semibold fs-6">List of all companies</span>
          </h3>
          <div className="card-toolbar">
            <div className='flex flex-row-fluid'>
              <a href="/companies/admin-create-company" className="btn btn-sm btn-light-primary">
                <i className="fa fa-plus"></i>
                Create New Company
              </a>
              <Button className="btn btn-sm btn-light-system" onClick={handleRefresh}>
                <i className="fa fa-refresh"></i>
                Refresh
              </Button>
              <div className="d-flex align-items-center ms-auto">
                <Select
                  className="basic-single"
                  classNamePrefix="select"
                  isClearable
                  isSearchable
                  name="companySearch"
                  options={searchOptions}
                  onChange={handleSearchChange}
                  placeholder="Search company..."
                />
              </div>
            </div>
          </div>
        </div>
        <div className="card-body">
          {loadingCompanies ? (
            <>
              <Spinner animation="border" />
            </>
          ) : (
            <div className="container" id="kt_docs_content_container">
              <div className="card mb-2">
                <div className="card-body fs-6 py-15 px-10 py-lg-15 px-lg-15">
                  <div className="table-responsive">
                    <table className="table table-striped gy-7 gs-7">
                      <TableHeaderCompany />
                      <TableBodyCompany />
                    </table>
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>

    </>
  );
};

export default AdminAllCompanies;
