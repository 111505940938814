import React, { useCallback, useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import axios from '../../../lib/axios';
import LoadingSpinner from '../../../_metronic/helpers/components/LoadingSpinner';
import { KTSVG } from '../../../_metronic/helpers';
import { useAuth } from '../../../hooks/auth';
import { SubmitHandler, useForm } from 'react-hook-form';
import { useToast } from '../../components/ToastProvider';
import { confirm } from '../../../../src/app/modules/confirmation/BasicConfirmation';

type LocationState = {
  subscriptionId: string
}

type Subscription = {
  id: number;
  user_id: number;
  start_date: string;
  end_date: string;
  name: string;
  description: string;
  is_subscription_admin: number;
  status: 'pending' | 'active' | 'cancelled' | 'deactivated' | 'suspended' | 'expired';
  parent_subscription_id: number | null;
  is_shared: boolean;
  created_at: string;
  updated_at: string;
  deleted_at: string | null;
  is_split: boolean;
  usage: Usage[];
  company_subscription: CompanySubscription[];
  histories: History[];
};

type Usage = {
  id: number;
  subscription_id: number;
  description: string | null;
  usage: number;
  date: string;
  created_at: string;
  updated_at: string;
  limit: number;
  is_limit_exceeded: boolean;
};

type CompanySubscription = {
  id: number;
  name: string;
  address: string;
  email: string;
  website: string;
  phone: string;
  created_at: string;
  updated_at: string;
  deleted_at: string | null;
  note: string;
  status: 'active' | 'inactive';
  original_admin_id: number | null;
  pivot: {
    subscription_id: number;
    company_id: number;
  };
  usage: Usage[];
};

type History = {
  id: number;
  subscription_id: number;
  subscription_status: 'pending' | 'active' | 'cancelled';
  user_id: number;
  start_date: string | null;
  end_date: string | null;
  event_type: string;
  description: string;
  event_date: string;
  product_ids: number;
  multiple_products: number;
  created_at: string;
  updated_at: string;
};

type FormData = {
  name: string;
  description: string;
  start_date: string;
  end_date: string;
  is_subscription_admin: boolean;
  status: 'pending' | 'active' | 'cancelled' | 'deactivated' | 'suspended' | 'expired';
  usage_id: number;
  is_shared: boolean;
  limit: number;
};

const AdminEditSubscription: React.FC = () => {
  const location = useLocation();
  const subscriptionId = (location.state as LocationState)?.subscriptionId;
  const { setLoading } = useAuth('auth');
  const [subscription, setSubscription] = useState<Subscription | null>(null);
  const navigate = useNavigate();

  const { register, handleSubmit, setValue, formState: { errors } } = useForm<FormData>();
  const { showError, showSuccess } = useToast();
  const [pageLoading, setPageLoading] = useState(false);

  const fetchSubscription = async () => {
    setPageLoading(true);
    try {
      const response = await axios.get(`/api/subscription/view/${subscriptionId}`);
      const subscriptionData = response.data.data;
      setSubscription(subscriptionData);

      setValue('name', subscriptionData.name ?? '');
      setValue('description', subscriptionData.description ?? '');
      setValue('start_date', subscriptionData.start_date ?? '');
      setValue('end_date', subscriptionData.end_date ?? '');
      setValue('is_subscription_admin', Boolean(subscriptionData.is_subscription_admin) ?? false);
      setValue('status', subscriptionData.status ?? 'pending');
      setValue('is_shared', Boolean(subscriptionData.is_shared) ?? false);

      // Set usage ID
      if (subscriptionData.usage.length > 0) {
        setValue('usage_id', subscriptionData.usage[0].id);
      }
    } catch (error) {
      console.error("Failed to fetch subscription:", error);
      showError('Failed to fetch subscription.');
    } finally {
      setPageLoading(false);
    }
  };

  const handleRefresh = () => {

    if (subscriptionId) {
      fetchSubscription();
    }
  };

  const handleCancelSubscription = async () => {
    setPageLoading(true);
    try {
      const payload = {
        'subscription_id': subscriptionId
      };
      const response = await axios.post(`/api/subscription/cancel`, payload);
      if (response.status === 200) {
        showSuccess('Subscription cancelled successfully!');
      } else {
        showError(response.data.message);
      }
    } catch (error) {
      console.error("Failed to cancel subscription:", error);
      showError('Failed to cancel subscription.');
    } finally {
      setPageLoading(false);
    }
  };

  const handleActivateSubscription = async () => {
    setPageLoading(true);
    try {
      const payload = {
        'subscription_id': subscriptionId
      };
      const response = await axios.post(`/api/subscription/activate`, payload);
      if (response.status === 200) {
        showSuccess('Subscription activated successfully!');
      } else {
        showError(response.data.message);
      }
    } catch (error) {
      console.error("Failed to activate subscription:", error);
      showError('Failed to activate subscription.');
    } finally {
      setPageLoading(false);
    }
  };

  const handleDeactivateSubscription = async () => {
    setPageLoading(true);
    try {
      const payload = {
        'subscription_id': subscriptionId
      };
      const response = await axios.post(`/api/subscription/deactivate`, payload);
      if (response.status === 200) {
        showSuccess('Subscription deactivated successfully!');
      } else {
        showError(response.data.message);
      }
    } catch (error) {
      console.error("Failed to deactivate subscription:", error);
      showError('Failed to deactivate subscription.');
    } finally {
      setPageLoading(false);
    }
  };

  const handleSuspendSubscription = async () => {
    setPageLoading(true);
    try {
      const payload = {
        'subscription_id': subscriptionId
      };
      const response = await axios.post(`/api/subscription/suspend`, payload);
      if (response.status === 200) {
        showSuccess('Subscription suspended successfully!');
      } else {
        showError(response.data.message);
      }
    } catch (error) {
      console.error("Failed to suspend subscription:", error);
      showError('Failed to suspend subscription.');
    } finally {
      setPageLoading(false);
    }
  };

  const deleteCompany = async () => {
    const result = await confirm(`Are you sure you want to delete the subscription ${subscriptionId}?`);
    if (result) {
      try {
        const payload = {
          'subscription_id': subscriptionId
        };
        const response = await axios.post(`/api/subscription/delete-subscription`, payload);
        if (response.status === 200) {
          showSuccess('Subscription deleted successfully!');
          navigate('/subscriptions/admin-all-subscriptions');
        } else {
          showError(response.data.message);
        }
      } catch (error) {
        console.error("Failed to delete subscription:", error);
        showError('Failed to delete subscription.');
      } finally {
        setPageLoading(false);
      }
    } else {
      showError('You have cancelled the action');
    }
  };

  const onSubmit: SubmitHandler<FormData> = async (data) => {
    setPageLoading(true);
    try {
      const response = await axios.post(`/api/subscription/update/${subscriptionId}`, data);
      if (response.status === 200) {
        showSuccess('Subscription updated successfully!');
      } else {
        showError(response.data.message);
      }
    } catch (error) {
      console.error("Failed to update subscription:", error);
      showError('Failed to update subscription.');
    } finally {
      setPageLoading(false);
    }
  };

  const handleResetForm = () => {
    if (subscription) {
      setValue('name', subscription.name ?? '');
      setValue('description', subscription.description ?? '');
      setValue('start_date', subscription.start_date ?? '');
      setValue('end_date', subscription.end_date ?? '');
      setValue('is_subscription_admin', Boolean(subscription.is_subscription_admin) ?? false);
      setValue('status', subscription.status ?? 'pending');
      setValue('is_shared', Boolean(subscription.is_shared) ?? false);
      setValue('limit', Number(subscription.usage[0].limit) ?? 0);

      // Reset usage ID
      if (subscription.usage.length > 0) {
        setValue('usage_id', subscription.usage[0].id);
      }
    }
  };

  useEffect(() => {
    if (subscriptionId) {
      fetchSubscription();
    }

    if (subscription?.usage?.[0]?.id ?? 0) {
      setValue('usage_id', subscription?.usage?.[0]?.id ?? 0);
    }

    if (subscription?.usage?.[0]?.limit) {
      setValue('limit', subscription?.usage?.[0]?.limit ?? 0);
    }
  }, [subscriptionId]);

  if (pageLoading) {
    return <LoadingSpinner />;
  }

  return (
    <>
      <div className="card mb-2">
        <div className="card-header">
          <h3 className="card-title">Subscription Details For ID: {subscriptionId}</h3>
          <div className="card-toolbar">
            <a href="/subscriptions/admin-all-subscriptions" className="btn btn-sm btn-light-primary">
              <KTSVG path="/media/icons/duotune/arrows/arr070.svg" className="svg-icon-2" />
              Back to List
            </a>
            <button className="btn btn-sm btn-light-system" onClick={handleRefresh}>
              <i className="fa fa-refresh"></i>
              Refresh
            </button>
          </div>
        </div>
        <div className="card-body">
          <form onSubmit={handleSubmit(onSubmit)} className="card p-4">
            <div className="mb-3">
              <label htmlFor="name" className="form-label">Name:</label>
              <input className="form-control" type="text" readOnly={true} id="name" {...register('name', { required: true })} />
              {errors.name && <div className="invalid-feedback">Name is required.</div>}
            </div>
            <div className="mb-3">
              <label htmlFor="description" className="form-label">Description:</label>
              <textarea className="form-control" id="description" {...register('description')} />
            </div>
            <div className="mb-3">
              <label htmlFor="start_date" className="form-label">Start Date:</label>
              <input className="form-control" type="date" id="start_date" {...register('start_date')} />
            </div>
            <div className="mb-3">
              <label htmlFor="end_date" className="form-label">End Date:</label>
              <input className="form-control" type="date" id="end_date" {...register('end_date')} />
            </div>
            <div className="mb-3">
              <label className="form-label">Status:</label>
              <select className="form-select" {...register('status', { required: true })}>
                <option value="pending">Pending</option>
                <option value="active">Active</option>
                <option value="cancelled">Cancelled</option>
                <option value="deactivated">Deactivated</option>
                <option value="suspended">Suspended</option>
                <option value="expired">Expired</option>
              </select>
              {errors.status && <div className="invalid-feedback">Status is required.</div>}
            </div>
            <div className="mb-3">
              <label htmlFor="limit" className="form-label">Limit:</label>
              <input className="form-control"
                type="number"
                id="limit"
                {...register('limit', { required: true })}

              />
              {errors.limit && <div className="invalid-feedback">Limit is required.</div>}
            </div>
            <div className="mb-3 form-check">
              <input className="form-check-input" type="checkbox" id="is_subscription_admin" {...register('is_subscription_admin')} />
              <label htmlFor="is_subscription_admin" className="form-check-label">Is Admin Subscription:</label>
            </div>
            {subscription?.parent_subscription_id === null ? (
              <div className="mb-3 form-check">
                <input className="form-check-input" type="checkbox" id="is_shared" {...register('is_shared')} />
                <label htmlFor="is_shared" className="form-check-label">Is Shared:</label>
              </div>
            ) : null}
            <input type="hidden" {...register('usage_id')} value={subscription?.usage?.[0]?.id} />
            <div className='col-md-12'>
              <button type="submit" className="btn btn-md btn-primary">Update</button>
              <button type="button" className="btn btn-md btn-warning" onClick={handleResetForm}>
                Cancel Edit
              </button>
              {subscription?.status === 'active' && (
                <>
                  <button type="button" className="btn btn-danger" onClick={handleCancelSubscription}>
                    Cancel Subscription
                  </button>
                  <button type="button" className="btn btn-danger" onClick={handleDeactivateSubscription}>
                    Deactivate
                  </button>
                  <button type="button" className="btn btn-danger" onClick={handleSuspendSubscription}>
                    Suspend
                  </button>
                </>
              )}
              {subscription?.status === 'pending' ||
                subscription?.status === 'suspended' ||
                subscription?.status === 'cancelled' ? (
                <button type="button" className="btn btn-success" onClick={handleActivateSubscription}>
                  <i className='fa fa-check'></i>
                  Activate
                </button>
              ) : null}
              <button type="button" className="btn btn-danger" onClick={() => deleteCompany()}>
                Delete
              </button>
            </div>
          </form>
        </div>
      </div>
    </>
  );
};

export default AdminEditSubscription;
