import React, { useEffect, useRef } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import useSWR from 'swr';
import axios from '../../../lib/axios';
import LoadingSpinner from '../../../_metronic/helpers/components/LoadingSpinner';
import { PageLink, PageTitle } from '../../../_metronic/layout/core';
import { Button } from 'primereact/button';
import { useAuth } from '../../../hooks/auth';
import { useToast } from '../../components/ToastProvider';
import { confirm } from '../../../../src/app/modules/confirmation/BasicConfirmation';
import Notes from './components/Notes';

const URL_ENDPOINT = '/api/subscription-requests/get-request';
const URL_DELETE = '/api/subscription-requests/delete-request';
const NAVIGATION_EDIT_PAGE = '/subscriptions/admin-edit-subscription-request';
const NAVIGATION_LIST_PAGE = '/subscriptions/admin-subscription-request';
const requestBreadCrumb: PageLink[] = [
  {
    title: 'All Subscription Requests',
    path: '/subscriptions/user-subscription-request',
    isSeparator: false,
    isActive: false,
  },
  {
    title: 'View Subscription Request',
    path: '',
    isSeparator: true,
    isActive: false,
  },
];

interface LocationState {
  subscriptionRequestId: string;
}

interface SubscriptionRequestData {
  request: {
    id: string;
    name: string;
    email: string;
    telephone: string;
    organization_name: string;
    organization_telephone: string;
    organization_email: string;
    organization_address: string;
    organization_website: string;
    status: string;
    notes: note[],
    company: [],
    user: {
      id: string;
      name: string;
      email: string;
      telephone: string;
      is_organization: string;
      is_personal: string;
      company_id: string;
    }
  }
}

interface note {
  id: string;
  user_id: string;
  subscription_request_id: string;
  note: string;
  created_at: string;
  updated_at: string;
}


const fetcher = async ([url, id]: [string, string]) => {
  const response = await axios.post(url, { id });
  return response.data;
};

const View: React.FC = () => {
  const { showError, showSuccess, showWarning } = useToast();
  const location = useLocation();
  const navigate = useNavigate();
  const { user } = useAuth('auth');
  const subscriptionRequestId = (location.state as LocationState)?.subscriptionRequestId;
  const successShownRef = useRef(false);

  const { data, error, isValidating } = useSWR(
    subscriptionRequestId ? [URL_ENDPOINT, subscriptionRequestId] : null,
    fetcher
  );

  useEffect(() => {
    if (error) {
      showError(`Error fetching data: ${error.message}`);
    }
    if (data && !successShownRef.current) {
      showSuccess('Data fetched successfully');
      successShownRef.current = true;
    }
  }, [data, error, showError, showSuccess]);


  const reject = () => {
    showWarning('You have rejected');
  };





  {
    /** handle confirm delete   */
  }


  const deleteRequest = async (creatorName: string, requestId: string) => {
    const result = await confirm(`Are you sure you want to delete the request made by  ${creatorName}?`);
    if (result) {
      try {
        const response = await axios.post(URL_DELETE, { id: requestId });
        if (response.status === 200) {
          showSuccess('You have deleted the request successfully');
          navigate(NAVIGATION_LIST_PAGE);
        } else {
          showError('Error deleting request');
        }
      } catch (error: any) {
        showError(`Error deleting data: ${error.message}`);
      }
    } else {
      reject();
    };
  }

  const handleDeleteRequest = (request) => {
    if (request) deleteRequest(request.user.name, request.id.toString());
  };

  const SubscriptionRequestDataDisplay = ({ data }: { data: SubscriptionRequestData }) => (
    <div className="card">
      <div className="card-body">

        <div className="details-item">
          <span className="font-weight-bold">ID:</span> {data.request.id}
        </div>
        <div className="details-item">
          <span className="font-weight-bold">Name:</span> {data.request.name}
        </div>
        <div className="details-item">
          <span className="font-weight-bold">Email:</span> {data.request.email}
        </div>
        <div className="details-item">
          <span className="font-weight-bold">Telephone:</span> {data.request.telephone}
        </div>
        <div className="details-item">
          <span className="font-weight-bold">Organization Name:</span> {data.request.organization_name}
        </div>
        <div className="details-item">
          <span className="font-weight-bold">Organization Telephone:</span> {data.request.organization_telephone}
        </div>
        <div className="details-item">
          <span className="font-weight-bold">Organization Email:</span> {data.request.organization_email}
        </div>
        <div className="details-item">
          <span className="font-weight-bold">Organization Address:</span> {data.request.organization_address}
        </div>
        <div className="details-item">
          <span className="font-weight-bold">Organization Website:</span> {data.request.organization_website}
        </div>
      </div>
      <div className="card-footer">
        {data.request.status === "approved" ? (null) :
          (
            <>
              <Button
                label="Edit"
                icon="pi pi-pencil"
                className="p-button-sm p-button-text"
                onClick={() => navigate(NAVIGATION_EDIT_PAGE, { state: { subscriptionRequestId: data.request.id } })}
              />
              <Button
                label="Delete"
                icon="pi pi-trash"
                className="p-button-sm p-button-text p-button-danger"
                onClick={() => handleDeleteRequest(data.request)}
              />
            </>
          )
        }

        <Button
          label="Back"
          icon="pi pi-arrow-left"
          className="p-button-sm p-button-text p-button-secondary"
          onClick={() => navigate(NAVIGATION_LIST_PAGE)}
        />
      </div>
    </div>
  );

  const ViewDashboard = () => (
    <>
      <PageTitle breadcrumbs={requestBreadCrumb}>View Subscription Request</PageTitle>
      <div className="container py-4">
        {isValidating ? (
          <LoadingSpinner />
        ) : error ? (
          <p>Error fetching data: {error.message}</p>
        ) : data ? (
          <>
            <div className="card card-custom shadow">
              <div className="card-header card-header-stretch">
                <h3 className="card-title">View Subscription Request</h3>
                <div className="card-toolbar">
                  <ul className="nav nav-tabs nav-line-tabs nav-stretch fs-6 border-0">
                    <li className="nav-item">
                      <a className="nav-link active" data-bs-toggle="tab" href="#kt_tab_pane_7">
                        Subscription Request Data
                      </a>
                    </li>
                    <li className="nav-item">
                      <a className="nav-link" data-bs-toggle="tab" href="#kt_tab_pane_8">
                        Notes
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
              <div className="card-body">
                <div className="tab-content" id="myTabContent">
                  <div className="tab-pane fade show active" id="kt_tab_pane_7" role="tabpanel">
                    <SubscriptionRequestDataDisplay data={data} />
                  </div>
                  <div className="tab-pane fade" id="kt_tab_pane_8" role="tabpanel">
                    <Notes userId={user?.id} subscriptionRequestId={subscriptionRequestId} Notes={data.request?.notes} showSuccess={showSuccess} showError={showError} />
                  </div>
                </div>
              </div>
            </div>
          </>
        ) : (
          <p>No data found.</p>
        )}
      </div>
    </>
  );

  return (
    <React.Suspense fallback={<LoadingSpinner />}>
      <ViewDashboard />
    </React.Suspense>
  );
};

export default View;
