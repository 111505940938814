import React, { useCallback, useEffect, useState } from 'react';
import { useForm, SubmitHandler, Controller } from 'react-hook-form';
import { useLocation, useNavigate } from 'react-router-dom';
import { Button, Spinner, Form } from 'react-bootstrap';
import Select from 'react-select';
import axios from '../../../lib/axios';
import { useToast } from '../../components/ToastProvider';
import { useAuth } from '../../../hooks/auth';
import { PageLink, PageTitle } from '../../../_metronic/layout/core';
import { KTSVG } from '../../../_metronic/helpers';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import LoadingSpinner from '../../../_metronic/helpers/components/LoadingSpinner';
import 'react-datepicker/dist/react-datepicker.css';
import { Company, Subscription, Member } from './Companies';

type FormInputs = {
  name: string;
  address?: string;
  email?: string;
  website?: string;
  phone?: string;
  note?: string;
  status: string;
  company_id: number;
};

type LocationState = {
  companyId: string;
};

interface User {
  id: string;
  name: string;
  email: string;
  roles: [{ permissions: { name: string }[] }];
  company_id: string;
  subscription: Subscription;
  role: string;
  is_organization?: boolean;
  is_personal?: boolean;
  created_at: string;
  updated_at: string;
  mode: 'personal' | 'organization';
  company?: string;
  limit: Limit;
  usage: Usage;
  check_limit: Boolean;
}

interface Limit {
  limit: number;
  is_limit_exceeded: boolean;
}

interface Usage {
  usage: number;
  is_limit_exceeded: boolean;
}

const UserEditCompany: React.FC = () => {
  const location = useLocation();
  const companyId = (location.state as LocationState)?.companyId;
  const navigate = useNavigate();

  const { loading } = useAuth('auth');
  const { showError, showSuccess, showWarning } = useToast();
  const { register, handleSubmit, control, reset, formState: { errors }, setValue } = useForm<FormInputs>();

  const [note, setNote] = useState<string>('');
  const [pageLoading, setPageLoading] = useState(false);
  const [company, setCompany] = useState<Company | null>(null);

  const fetchCompany = useCallback(() => {
    setPageLoading(true);
    axios
      .post('/api/companies/show', { company_id: companyId })
      .then((response) => {
        const companyData = response.data;
        setCompany(companyData);
        setValue('name', companyData.name);
        setValue('address', companyData.address);
        setValue('email', companyData.email);
        setValue('website', companyData.website);
        setValue('phone', companyData.phone);
        setValue('status', companyData.status);
        setNote(companyData.note);
      })
      .catch(() => showError('Failed to fetch company details'))
      .finally(() => setPageLoading(false));
  }, [companyId, setValue, showError]);

  useEffect(() => {

    fetchCompany();
  }, [fetchCompany, showError]);

  const onSubmit: SubmitHandler<FormInputs> = async (data) => {
    setPageLoading(true);
    data.note = note;
    data.company_id = parseInt(companyId);

    try {
      await axios.post('/api/companies/update', { ...data });
      showSuccess('Company updated successfully');
      navigate('/companies/user-all-companies');
    } catch (error) {
      showError('Failed to update company');
    } finally {
      setPageLoading(false);
    }
  };

  if (pageLoading) {
    return <LoadingSpinner />;
  }

  return (
    <>
      <PageTitle breadcrumbs={companyBreadCrumbs}>Edit Company</PageTitle>
      <div className="app-main flex-column flex-row-fluid" id="k">
        <div className="d-flex flex-column flex-column-fluid">
          <div className="card-toolbar">
            <a href="/companies/user-all-companies" className="btn btn-sm btn-light-primary">
              <KTSVG path="/media/icons/duotune/arrows/arr070.svg" className="svg-icon-2" />
              Back to List
            </a>
          </div>
          <div className="card mb-2">
            <div className="card-header">
              <h3 className="card-title">Edit Company</h3>
            </div>
            <div className="card-body">
              <Form onSubmit={handleSubmit(onSubmit)}>
                <Form.Group className="mb-3">
                  <Form.Label>Company Name</Form.Label>
                  <Form.Control
                    type="text"
                    {...register('name', { required: 'Company name is required' })}
                    isInvalid={!!errors.name}
                  />
                  <Form.Control.Feedback type="invalid">
                    {errors.name?.message}
                  </Form.Control.Feedback>
                </Form.Group>

                <Form.Group className="mb-3">
                  <Form.Label>Address</Form.Label>
                  <Form.Control
                    type="text"
                    {...register('address')}
                  />
                </Form.Group>

                <Form.Group className="mb-3">
                  <Form.Label>Email</Form.Label>
                  <Form.Control
                    type="email"
                    {...register('email', {
                      pattern: {
                        value: /^\S+@\S+$/i,
                        message: 'Invalid email address'
                      }
                    })}
                    isInvalid={!!errors.email}
                  />
                  <Form.Control.Feedback type="invalid">
                    {errors.email?.message}
                  </Form.Control.Feedback>
                </Form.Group>

                <Form.Group className="mb-3">
                  <Form.Label>Website</Form.Label>
                  <Form.Control
                    type="text"
                    {...register('website')}
                  />
                </Form.Group>

                <Form.Group className="mb-3">
                  <Form.Label>Phone</Form.Label>
                  <Form.Control
                    type="text"
                    {...register('phone')}
                  />
                </Form.Group>

                <Form.Group className="mb-3">
                  <Form.Label>Notes</Form.Label>
                  <Controller
                    name="note"
                    control={control}
                    render={({ field }) => (
                      <ReactQuill value={note} onChange={(e) => { setNote(e); field.onChange(e); }} />
                    )}
                  />
                </Form.Group>

                <Form.Group className="mb-3">
                  <Form.Label>Status</Form.Label>
                  <Form.Control
                    as="select"
                    {...register('status', { required: 'Status is required' })}
                    isInvalid={!!errors.status}
                  >
                    <option value="">Select Status</option>
                    <option value="active">Active</option>
                    <option value="deactivated">Deactivated</option>
                    <option value="dormant">Dormant</option>
                  </Form.Control>
                  <Form.Control.Feedback type="invalid">
                    {errors.status?.message}
                  </Form.Control.Feedback>
                </Form.Group>



                <Button variant="primary" type="submit" disabled={loading}>
                  {loading ? <Spinner animation="border" size="sm" /> : 'Submit'}
                </Button>
                <Button variant="secondary" type="button" className="btn btn-secondary" onClick={() => reset()}>
                  Reset
                </Button>
              </Form>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

const companyBreadCrumbs: PageLink[] = [
  {
    title: 'Companies',
    path: '/companies/user-all-companies',
    isSeparator: false,
    isActive: false,
  },
  {
    title: '',
    path: '',
    isSeparator: true,
    isActive: false,
  },
];

export default UserEditCompany;
