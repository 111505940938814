import React, { useEffect, useState, useCallback } from 'react';
import { Card, Col, Row, Spinner, Button } from 'react-bootstrap';
import axios from '../../../lib/axios';
import { useLocation, useNavigate } from 'react-router-dom';
import { KTCard, KTCardBody } from '../../../_metronic/helpers';
import { PageLink, PageTitle } from '../../../_metronic/layout/core';
import { confirm } from '../../modules/confirmation/BasicConfirmation';

type LocationState = {
  product_id: string;
};

interface Price {
  amount: number;
  currency: string;
  active: boolean;
  is_default: boolean;
}

interface Feature {
  feature_name: string;
  feature_value: string;
  active: boolean;
}

interface ProductDetail {
  id: string;
  name: string;
  type: string;
  active: boolean;
  is_subscription: boolean;
  subscription_period: string;
  prices: Price[];
  features: Feature[];
  has_subscription:string;
}

const AdminViewProduct: React.FC = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const product_id = (location.state as LocationState)?.product_id;

  const [product, setProduct] = useState<ProductDetail | null>(null);
  const [loading, setLoading] = useState<boolean>(true);

  const fetchProductDetails = useCallback(async () => {
    setLoading(true);
    try {
      const response = await axios.get(`/api/products/show/${product_id}`);
      setProduct(response.data);
      setLoading(false);
    } catch (error) {
      console.error('Error fetching product details:', error);
      setLoading(false);
    }
  }, [product_id]);


  const handleDeleteProduct = async (product_id: string) => {
    const result = await confirm('Are you sure you want to delete this product?');
    if (!result) {
      return;
    } else {
      console.log('Product deleted');
      setLoading(true);
      axios.post(`/api/products/delete-product`, { id: product_id })
        .then(response => {
          navigate('/product/admin-all-product');
        })
        .catch(error => {
          console.error('Error deleting product:', error);
        });
    }
 
  }

  const navigateEdit = (product_id: string) => {
    navigate('/product/admin-edit-product', {
      state: {
        product_id: product_id
      }
    });
  };

  useEffect(() => {
    fetchProductDetails();
  }, [fetchProductDetails]);

  if (loading) {
    return <Spinner animation="border" />;
  }

  if (!product) {
    return <div>Product not found</div>;
  }
  const PageBreadCrumbs: Array<PageLink> = [
    {
      title: 'Product List',
      path: '/product/admin-all-product',
      isSeparator: false,
      isActive: false,
    },
    {
      title: 'Product Details ' + product.name,
      path: '',
      isSeparator: true,
      isActive: false,
    },

  ];
  return (
    <>
      <PageTitle breadcrumbs={PageBreadCrumbs}>{'Product List'}</PageTitle>
      <div className="container">
        <KTCard>
          <div className='card-header'>
            <span className='title'>Product Details</span>
            <div className='card-toolbar'>
              {product.has_subscription === 'No' ? <Button onClick={() => handleDeleteProduct(product.id)} className="btn btn-danger btn-sm"><i className='fa fa-trash'></i>Delete</Button>
: null }
              <Button onClick={() => navigateEdit(product.id)} className="btn btn-warning btn-sm"><i className='fa fa-pen'></i>Edit</Button>

              <Button onClick={fetchProductDetails} className="btn btn-primary btn-sm"><i className='fa fa-refresh'></i>Refresh</Button>
            </div>

          </div>
          <KTCardBody>
            <Row>
              <Col md={6}>
                <Card.Text><strong>Name:</strong> {product.name}</Card.Text>
                <Card.Text><strong>Type:</strong> {product.type}</Card.Text>
                <Card.Text><strong>Active:</strong> {product.active ? 'Yes' : 'No'}</Card.Text>
                <Card.Text><strong>Subscription Service:</strong> {product.is_subscription ? 'Yes' : 'No'}</Card.Text>
                <Card.Text><strong>Subscription Period:</strong> {product.subscription_period || 'N/A'}</Card.Text>
              </Col>
              <Col md={6}>
                <h4>Prices</h4>
                {product.prices.map((price, index) => (
                  <Card key={index} className="mb-2">
                    <Card.Body>
                      <Card.Text><strong>Amount:</strong> {price.amount}</Card.Text>
                      <Card.Text><strong>Currency:</strong> {price.currency}</Card.Text>
                      <Card.Text><strong>Active:</strong> {price.active ? 'Yes' : 'No'}</Card.Text>
                      <Card.Text><strong>Default Price:</strong> {price.is_default ? 'Yes' : 'No'}</Card.Text>
                    </Card.Body>
                  </Card>
                ))}
                <h4>Features</h4>
                {product.features.map((feature, index) => (
                  <Card key={index} className="mb-2">
                    <Card.Body>
                      <Card.Text><strong>Name:</strong> {feature.feature_name}</Card.Text>
                      <Card.Text><strong>Value:</strong> {feature.feature_value}</Card.Text>
                      <Card.Text><strong>Active:</strong> {feature.active ? 'Yes' : 'No'}</Card.Text>
                    </Card.Body>
                  </Card>
                ))}
              </Col>
            </Row>
          </KTCardBody>
        </KTCard>
      </div>
    </>
  );
};

export default AdminViewProduct;
