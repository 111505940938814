import React from 'react'
import PropTypes from 'prop-types'
import Modal from 'react-bootstrap/Modal'
import Button from 'react-bootstrap/Button'
import {confirmable, createConfirmation} from 'react-confirm'

const BasicConfirmation = ({
  okLabel = 'Yes',
  cancelLabel = 'Cancel',
  proceedLabel = 'Proceed',
  title = 'Confirmation',
  confirmation,
  show,
  proceed,
  enableEscape = true,
}) => {
  return (
    <div className='static-modal'>
      <Modal
        animation={false}
        show={show}
        onHide={() => proceed(false)}
        backdrop={enableEscape ? true : 'static'}
        keyboard={enableEscape}
        centered
      >
        <Modal.Header>
          <Modal.Title>{title}</Modal.Title>
        </Modal.Header>
        <Modal.Body><h5>{confirmation}</h5></Modal.Body>
        <Modal.Footer>
          <Button className='btn-danger' onClick={() => proceed(false)}>{cancelLabel}</Button>
          <Button className='button-l' onClick={() => proceed(true)}>
            {okLabel}
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  )
}

BasicConfirmation.propTypes = {
  okLabel: PropTypes.string,
  cancelLabel: PropTypes.string,
  proceedLabel: PropTypes.string,
  title: PropTypes.string,
  confirmation: PropTypes.string,
  show: PropTypes.bool,
  proceed: PropTypes.func, // called when ok button is clicked.
  enableEscape: PropTypes.bool,
}

export function confirm(confirmation, okLabel = 'Yes', cancelLabel = 'Cancel', options = {}) {
  return createConfirmation(confirmable(BasicConfirmation))({
    confirmation,
    okLabel,
    cancelLabel,
    ...options,
  })
}
