import React from "react";
import {useIntl} from 'react-intl'
import {PageTitle} from '../../../_metronic/layout/core'
import { useAuth } from '../../../hooks/auth'

export function MyPage() {
    const intl = useIntl()
    const { user } = useAuth('auth')
    return <>
        <PageTitle breadcrumbs={[]}>My Page</PageTitle>
        <h1>Hello!</h1>
    </>
}