import React from 'react'
import {toAbsoluteUrl} from '../../../../_metronic/helpers'
import {useIntl} from 'react-intl'

function AFSPrintingHeader({Title, ComputeForm, CurrentTime}) {
  const intl = useIntl()
  return (
    <>
      <div className='d-flex justify-content-between align-items-start flex-wrap mb-2 fw-bold fs-4 mb-2'>
        <div className='d-flex flex-column my-4'>
          <div className='d-flex align-items-center mb-2' style={{fontSize: '32px'}}>
            {Title}
          </div>
        </div>

        <div className='d-flex my-4'>
          <img
            alt='Logo'
            src={toAbsoluteUrl('/media/logos/fintel-by-marc-logo.png')}
            className='h-100px h-lg-100px'
          />
        </div>
      </div>
      <div className='d-flex justify-content-between align-items-start flex-wrap mb-2'>
        <div className='d-flex flex-column my-4'>
          <div className='d-flex align-items-center'>
            <h2>{intl.formatMessage({id: 'COMPANY.COMPANY_NAME'})}</h2>
          </div>
          <div className='d-flex align-items-center mb-2'>
            <h1 style={{fontStyle: 'italic'}}>{ComputeForm.companyInformation.company_name}</h1>
          </div>
        </div>

        <div className='d-flex flex-column my-4 mr-4' style={{paddingRight: '200px'}}>
          <h2>{intl.formatMessage({id: 'AFS.BUSINESS_INFORMATION'})}</h2>
          <table>
            <tr>
              <td>{intl.formatMessage({id: 'COMPANY.REGISTER_NUMBER'})}</td>
              <td>
                : <strong>{ComputeForm.companyInformation.register_number}</strong>
              </td>
            </tr>
            <tr>
              <td>{intl.formatMessage({id: 'COMPANY.REFERENCE_NO'})}</td>
              <td>
                : <strong>{ComputeForm.sme_model_no}</strong>
              </td>
            </tr>
            <tr>
              <td>{intl.formatMessage({id: 'COMPANY.ISSUE_DATE_TIME'})}</td>
              <td>
                : <strong>{CurrentTime}</strong>
              </td>
            </tr>
          </table>
        </div>
      </div>
    </>
  )
}

export default AFSPrintingHeader
