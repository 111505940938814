import React, { useEffect, useState } from 'react';
import { useForm, SubmitHandler, useFieldArray } from 'react-hook-form';
import { useNavigate, useLocation } from 'react-router-dom';
import { Button, Form, Col, Row, Spinner } from 'react-bootstrap';
import axios from '../../../lib/axios';
import { PageLink, PageTitle } from '../../../_metronic/layout/core';
import { useToast } from '../../components/ToastProvider';
type LocationState = {
  product_id: string;
};

interface Feature {
  id?: string;
  feature_name: string;
  feature_value: string;
  active: boolean;
}

interface Price {
  id?: string;
  amount: number;
  currency: string;
  active: boolean;
  is_default: boolean;
}

interface ProductFormData {
  id: string;
  name: string;
  type: string;
  active: boolean;
  is_subscription: boolean;
  subscription_period: string;
  prices: Price[];
  features: Feature[];
}

const AdminEditProduct: React.FC = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const product_id = (location.state as LocationState)?.product_id;
  const { register, handleSubmit, setValue, control, formState: { errors } } = useForm<ProductFormData>();
  const { fields: featureFields, append: appendFeature, remove: removeFeature } = useFieldArray({ control, name: 'features' });
  const { fields: priceFields, append: appendPrice, remove: removePrice } = useFieldArray({ control, name: 'prices' });

  const [dataLoading, setDataLoading] = useState<boolean>(true);
  const { showError, showSuccess, showWarning } = useToast();

  useEffect(() => {
    const fetchProduct = async () => {
      try {
        const response = await axios.get(`/api/products/show/${product_id}`);
        const product = response.data;

        setValue('name', product.name);
        setValue('type', product.type);
        setValue('active', product.active);
        setValue('is_subscription', product.is_subscription);
        setValue('subscription_period', product.subscription_period);
        setValue('prices', product.prices);
        setValue('features', product.features);

        product.prices.forEach((price: Price) => appendPrice(price));
        product.features.forEach((feature: Feature) => appendFeature(feature));

        setDataLoading(false);
      } catch (error) {
        console.error('Error fetching product:', error);
        setDataLoading(false);
      }
    };

    fetchProduct();
  }, [product_id, setValue, appendPrice, appendFeature]);

  const onSubmit: SubmitHandler<ProductFormData> = async (data) => {
    setDataLoading(true);
    data.id = product_id;
    try {
      await axios.post(`/api/products/update`, data).then((response) => {
        if(response.status === 200 ){
          setDataLoading(false);
          showSuccess('Product updated successfully');
          setTimeout(() => {
            navigate(`/product/admin-all-product`);
          }, 2000); 
        } else {
          showError('Failed to update product');
          setDataLoading(false);

        }
      });

    } catch (error) {
      console.error('Error updating product:', error);
      setDataLoading(false);
    }
  };
  const PageBreadCrumbs: Array<PageLink> = [
    {
      title: 'All Product',
      path: '/product/admin-all-product',
      isSeparator: false,
      isActive: false,
    },
    {
      title: '',
      path: '',
      isSeparator: true,
      isActive: false,
    },
    {
      title: 'Edit Product',
      path: '/product/admin-edit-product',
      isSeparator: false,
      isActive: true,
    },
    {
      title: '',
      path: '',
      isSeparator: true,
      isActive: false,
    },

  ];

  const Title = () => {
    return 'Edit Product '
  };
  if (dataLoading) {
    return <Spinner animation="border" />;
  }


  return (
    <>
      <PageTitle breadcrumbs={PageBreadCrumbs}>{Title()}</PageTitle>
      <div className="container">
        <div className="card card-custom">
          <div className="card-header">
            <h3 className="card-title">Edit Product</h3>
          </div>
          <div className="card-body">
            <Form onSubmit={handleSubmit(onSubmit)}>
              <Form.Group controlId="productName">
                <Form.Label>Name</Form.Label>
                <Form.Control
                  type="text"
                  {...register('name', { required: 'Product name is required' })}
                  isInvalid={!!errors.name}
                />
                {errors.name && (
                  <Form.Control.Feedback type="invalid">
                    {errors.name.message}
                  </Form.Control.Feedback>
                )}
              </Form.Group>

              <Form.Group controlId="productType">
                <Form.Label>Type</Form.Label>
                <Form.Control as="select" {...register('type', { required: 'Product type is required' })}>
                  <option value="">Select type</option>
                  <option value="Business Report Service">Business Report Service</option>
                  <option value="Business Analytics">Business Analytics</option>

                </Form.Control>
                {errors.type && (
                  <Form.Control.Feedback type="invalid">
                    {errors.type.message}
                  </Form.Control.Feedback>
                )}
              </Form.Group>

              <Form.Group controlId="productSettings">
                <Form.Check
                  type="checkbox"
                  label="Product is active"
                  {...register('active')}
                />
                <Form.Check
                  type="checkbox"
                  label="Product is a subscription service"
                  {...register('is_subscription')}
                />
              </Form.Group>

              <Form.Group controlId="subscription_period">
                <Form.Label>Subscription Period</Form.Label>
                <Form.Control as="select" {...register('subscription_period')}>
                  <option value="">Select period</option>
                  <option value="quarterly">Quarterly</option>
                  <option value="weekly">Weekly</option>
                  <option value="monthly">Monthly</option>
                  <option value="annually">Annually</option>
                  <option value="biannually">Biannually</option>
                  <option value="manual">Manual</option>
                </Form.Control>
              </Form.Group>

              <h4>Prices</h4>
              {priceFields.map((field, index) => (
                <div key={field.id}>
                  <Row>
                    <Col md={6}>
                      <Form.Group controlId={`priceAmount-${index}`}>
                        <Form.Label>Amount</Form.Label>
                        <Form.Control
                          type="number"
                          {...register(`prices.${index}.amount`, { required: 'Price amount is required' })}
                          isInvalid={!!errors.prices?.[index]?.amount}
                        />
                        {errors.prices?.[index]?.amount && (
                          <Form.Control.Feedback type="invalid">
                            {errors.prices?.[index]?.amount?.message}
                          </Form.Control.Feedback>
                        )}
                      </Form.Group>
                    </Col>
                    <Col md={6}>
                      <Form.Group controlId={`priceCurrency-${index}`}>
                        <Form.Label>Currency</Form.Label>
                        <Form.Control
                          type="text"
                          {...register(`prices.${index}.currency`, { required: 'Currency is required' })}
                          isInvalid={!!errors.prices?.[index]?.currency}
                        />
                        {errors.prices?.[index]?.currency && (
                          <Form.Control.Feedback type="invalid">
                            {errors.prices?.[index]?.currency?.message}
                          </Form.Control.Feedback>
                        )}
                      </Form.Group>
                    </Col>
                  </Row>
                  <Form.Group controlId={`priceActive-${index}`}>
                    <Form.Check
                      type="checkbox"
                      label="Active"
                      {...register(`prices.${index}.active`)}
                    />
                    <Form.Check
                      type="checkbox"
                      label="Default Price"
                      {...register(`prices.${index}.is_default`)}
                    />
                  </Form.Group>
                  <Button variant="danger" onClick={() => removePrice(index)} className="mt-2">
                    Remove Price
                  </Button>
                </div>
              ))}
              <Button type="button" onClick={() => appendPrice({ amount: 0, currency: '', active: true, is_default: false })} className="mt-3 btn btn-success">
                Add price +
              </Button>

              <h4>Features</h4>
              {featureFields.map((field, index) => (
                <Row key={field.id}>
                  <Col md={6}>
                    <Form.Group controlId={`featureName-${index}`}>
                      <Form.Label>Feature Name</Form.Label>
                      <Form.Control
                        type="text"
                        {...register(`features.${index}.feature_name`, { required: 'Feature name is required' })}
                        isInvalid={!!errors.features?.[index]?.feature_name}
                      />
                      {errors.features?.[index]?.feature_name && (
                        <Form.Control.Feedback type="invalid">
                          {errors.features?.[index]?.feature_name?.message || ''}
                        </Form.Control.Feedback>
                      )}
                    </Form.Group>
                  </Col>
                  <Col md={6}>
                    <Form.Group controlId={`featureValue-${index}`}>
                      <Form.Label>Feature Value</Form.Label>
                      <Form.Control
                        type="text"
                        {...register(`features.${index}.feature_value`, { required: 'Feature value is required' })}
                        isInvalid={!!errors.features?.[index]?.feature_value}
                      />
                      {errors.features?.[index]?.feature_value && (
                        <Form.Control.Feedback type="invalid">
                          {errors.features?.[index]?.feature_value?.message || ''}
                        </Form.Control.Feedback>
                      )}
                    </Form.Group>
                  </Col>
                  <Form.Group controlId={`featureActive-${index}`}>
                    <Form.Check
                      type="checkbox"
                      label="Active"
                      {...register(`features.${index}.active`)}
                    />
                  </Form.Group>
                  <Button variant="danger" onClick={() => removeFeature(index)} className="mt-2">
                    Remove Feature
                  </Button>
                </Row>
              ))}
              <Button type="button" onClick={() => appendFeature({ feature_name: '', feature_value: '', active: true })} className="mt-3 btn btn-primary">
                Add feature +
              </Button>

              <div className="mt-3">
                <Button variant="primary" type="submit">
                  Save
                </Button>
                <Button variant="secondary" className="ms-2">
                  Cancel
                </Button>
              </div>
            </Form>
          </div>
        </div>
      </div>
    </>
  );
};

export default AdminEditProduct;
