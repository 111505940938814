import React, { useState, useEffect } from 'react';
import { useForm, SubmitHandler } from 'react-hook-form';
import { Form, Button, Col, Row } from 'react-bootstrap';
import Select from 'react-select';
import axios from '../../../lib/axios';
import { useToast } from '../../components/ToastProvider';
import { useNavigate } from 'react-router-dom';
import { PageLink, PageTitle } from '../../../_metronic/layout/core';

interface AdminAddUserData {
  userIdList: string[];
}

interface User {
  id: string;
  name: string;
}
const PageBreadCrumbs: Array<PageLink> = [
  {
    title: 'Internal User List',
    path: '/user/admin-all-internal-user',
    isSeparator: false,
    isActive: false,
  },
  {
    title: 'Add Internal User',
    path: '',
    isSeparator: true,
    isActive: false,
  },

];
const AdminAddInternalUser: React.FC = () => {
  const navigate = useNavigate();
  const { register, handleSubmit, formState: { errors }, reset, setValue } = useForm<AdminAddUserData>();
  const [users, setUsers] = useState<User[]>([]);
  const [isLoading, setIsLoading] = useState(true);
  const [selectedUserIds, setSelectedUserIds] = useState<string[]>([]);
  const [isSubmitting, setIsSubmitting] = useState(false);

  const { showSuccess, showError } = useToast();

  useEffect(() => {
    const fetchUsers = async () => {
      try {
        const response = await axios.get('/api/user/get-user-list?filter=internal_user&filter_value=');
        setUsers(response.data.data || []);
      } catch (error) {
        console.error('Failed to fetch users:', error);
      } finally {
        setIsLoading(false);
      }
    };

    fetchUsers();
  }, []);

  const onSubmit: SubmitHandler<AdminAddUserData> = async data => {
    setIsSubmitting(true);
    console.log('Form Data:', data);
    // Send data to server...
    try {
      // Simulate server request
      const result = await axios.post('/api/internal-users/create', {
        "userIdList": data.userIdList
      });
      console.log(result);

      if (result.status === 200) {
        showSuccess('Success add users');
        navigate(`/user/admin-all-internal-user`);
      } else {
        showError('Failed to add user');
        navigate(`/user/admin-all-internal-user`);
      }

    } catch (error) {
      console.error('Failed to submit form:', error);
    } finally {
      setIsSubmitting(false);
      handleReset();
    }
  };

  const handleUserChange = (selectedOptions: any) => {
    const userIdList = selectedOptions ? selectedOptions.map((option: any) => option.value) : [];
    setValue('userIdList', userIdList);
    setSelectedUserIds(userIdList);
  };

  const handleReset = () => {
    reset();
    setSelectedUserIds([]);
  };

  return (
    <>
      <PageTitle breadcrumbs={PageBreadCrumbs}>{'Add Internal User'}</PageTitle>
      <div className="container">
        <div className="card card-custom">
          <div className="card-header">
            <h3 className="card-title">Add New Internal User</h3>
          </div>
          <div className="card-body">
            {isLoading ? (
              <p>Loading users...</p>
            ) : (
              <Form onSubmit={handleSubmit(onSubmit)}>
                <Row>
                  <Col md={6}>
                    <Form.Group className="mb-3" controlId="userIdList">
                      <Form.Label>Select User</Form.Label>
                      <Select
                        isMulti
                        options={users.map(user => ({ value: user.id, label: user.name }))}
                        onChange={handleUserChange}
                        className={errors.userIdList ? 'is-invalid' : ''}
                        value={selectedUserIds.map(id => users.find(user => user.id === id)).filter(Boolean).map(user => ({ value: user!.id, label: user!.name }))}
                        isDisabled={isSubmitting}
                      />
                      {errors.userIdList && <Form.Control.Feedback type="invalid">{errors.userIdList.message}</Form.Control.Feedback>}
                    </Form.Group>
                  </Col>
                </Row>

                <div className="d-flex">
                  <Button variant="primary" type="submit" disabled={isSubmitting}>Submit</Button>
                  <Button variant="secondary" type="button" onClick={handleReset} className="ml-2" disabled={isSubmitting}>Reset</Button>
                </div>
              </Form>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default AdminAddInternalUser;
