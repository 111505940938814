import React, { useEffect, useState } from 'react';
import { Card, Col, Row, Spinner } from 'react-bootstrap';
import { useLocation } from 'react-router-dom';
import axios from '../../../lib/axios';
import { useNavigate } from 'react-router-dom';
import { KTCard } from '../../../_metronic/helpers';
import { PageLink, PageTitle } from '../../../_metronic/layout/core';

type LocationState = {
  userId: string
}
interface UserDetail {
  id: string;
  name: string;
  email: string;
  company: string;
  role: string;
}

const AdminViewUser: React.FC = () => {
  const location = useLocation();
  const userId = (location.state as LocationState)?.userId;

  const [user, setUser] = useState<UserDetail | null>(null);
  const [loading, setLoading] = useState<boolean>(true);


  const PageBreadCrumbs: Array<PageLink> = [
    {
      title: 'User List',
      path: '/user/admin-all-user',
      isSeparator: false,
      isActive: false,
    },
    {
      title: 'User detail for ' + user?.name,
      path: '',
      isSeparator: true,
      isActive: false,
    },

  ];

  useEffect(() => {
    const fetchUserDetails = async () => {
      try {
        const response = await axios.post(`/api/user/admin-view`, {
          id: userId
        });
        setUser(response.data);
        setLoading(false);
      } catch (error) {
        console.error('Error fetching user details:', error);
        setLoading(false);
      }
    };

    fetchUserDetails();
  }, [userId]);

  if (loading) {
    return <Spinner animation="border" />;
  }

  if (!user) {
    return <div>User not found</div>;
  }

  return (
    <>
      <PageTitle breadcrumbs={PageBreadCrumbs}>{'User Detail'}</PageTitle>
      <div className="container">
        <KTCard className="card-custom">
          <Card.Header>
            <h3 className="card-title">User Information</h3>
          </Card.Header>
          <Card.Body>
            <Row>
              <Col md={6}>
                <Card.Text><strong>Name:</strong> {user.name}</Card.Text>
              </Col>
              <Col md={6}>
                <Card.Text><strong>Email:</strong> {user.email}</Card.Text>
              </Col>
            </Row>
            <Row>
              <Col md={6}>
                <Card.Text><strong>Company:</strong> {user.company}</Card.Text>
              </Col>
              <Col md={6}>
                <Card.Text><strong>Role:</strong> {user.role}</Card.Text>
              </Col>
            </Row>
          </Card.Body>
        </KTCard>
      </div>
    </>
  );
};

export default AdminViewUser;
