import React, { useEffect, useRef, useState } from 'react';
import { PageLink, PageTitle } from '../../../_metronic/layout/core';
import { KTIcon } from '../../../_metronic/helpers';
import { IRequestComputeForm, RequestComputeFormInit } from './model';
import axios from '../../../lib/axios';
import { numericFormatter } from 'react-number-format';
import clsx from 'clsx';
import { useLocation } from 'react-router-dom';
import ReactToPrint from 'react-to-print';
import styled from 'styled-components';
import { IRequestMatrix } from './model/SmeModelModel';
import { Speedometer } from '../../components/Speedometer';
import MarcLogo from '../../components/marcLogo';
import ReportTitle from '../../components/ReportTitle';
import generatePdfFromUrl from '../../../util/generatePdf';
import '../../../_metronic/assets/css/animateProgressBar.css';
import BubbleChart from './components/BubbleChart';
import '../../../_metronic/assets/css/printPage.css';
import { useIntl } from 'react-intl';

type LocationState = {
  sme_model_no: string
}
type PageState = {
  loading: boolean
  alert: boolean
  alertTitle: string
  alertMessage: string
}

async function getReports(sme_model_no: string): Promise<IRequestComputeForm> {
  const url = '/api/model/sme/view'
  const response = await axios.post(url, { sme_model_no })
  return response.data.data
}

async function getMatrix(name: string = ''): Promise<IRequestMatrix> {
  const url = '/api/model/sme/matrix'
  const response = await axios.post(url, { name })
  return response.data.data
}

export default function SmeModelResult() {
  const location = useLocation()
  const [pageState, setPageState] = useState<PageState>({
    loading: false,
    alert: false,
    alertMessage: '',
    alertTitle: '',
  })
  const [computeForm, setComputeForm] = useState<IRequestComputeForm>(RequestComputeFormInit)
  const [matrix, setMatrix] = useState<IRequestMatrix>({
    Result: [
      {
        score_factor_min: 0,
        score_factor_max: 0,
        probability_of_default: 0,
        cm_band: '',
        risk_profile: '',
      },
    ],
  })
  const ref = useRef<HTMLDivElement>(null)
  const intl = useIntl()

  const handleGeneratePdf = async ({ reportName }: { reportName: string }) => {
    const urlCapture = window.location.href
    reportName = reportName.replace(/ /g, '_') + '_SME_Model_Result.pdf'

    setPageState({ ...pageState, loading: true, alert: false, alertTitle: '', alertMessage: '' })

    try {
      await generatePdfFromUrl(urlCapture, reportName)
    } catch (error) {
      console.error('Error:', error)
      setPageState({
        ...pageState,
        loading: false,
        alert: true,
        alertTitle: 'Error generating Pdf',
        alertMessage: 'Error generating pdf',
      })
    } finally {
      setPageState({ ...pageState, loading: false, alert: false, alertTitle: '', alertMessage: '' })
    }
  }

  useEffect(() => {
    ; (async () => {
      const sme_model_no = (location.state as LocationState)?.sme_model_no || ''
      const report = await getReports(sme_model_no)
      setComputeForm(report)
      const matrixData = await getMatrix('')
      setMatrix(matrixData)
    })()
  }, [location.state])

  const PrintButton = styled.button`
    @media print {
      display: none;
    }
  `

  const ResponsiveContainer = styled.div`
    @media (max-width: 768px) {
      position: relative;
      right: 75px;
      top: -125px;
    }
    @media (max-width: 1920px) {
      position: relative;
      right: 150px;
      top: -240px;
    }
    @media (max-width: 1366px) {
      position: relative;
      right: -85px;
      top: -320px;
    }
    @media (max-width: 480px) {
      position: relative;
      right: 25px;
      top: -60px;
    }
  `

  const addCommaToThousands = (value: string | number): string => {
    if (typeof value === 'number') {
      value = value.toString()
    }
    return value.replace(/\B(?=(\d{3})+(?!\d))/g, ',')
  }

  const smemodelResultBreadCrumbs: Array<PageLink> = [
    {
      title: intl.formatMessage({ id: 'SME_M.SME_MODEL' }),
      path: '/sme-model',
      isSeparator: false,
      isActive: false,
    },
    {
      title: '',
      path: '',
      isSeparator: true,
      isActive: false,
    },
    {
      title: intl.formatMessage({ id: 'SME_M.COMPUTE' }),
      path: '#',
      isSeparator: false,
      isActive: true,
    },
    {
      title: '',
      path: '',
      isSeparator: true,
      isActive: false,
    },
  ]

  return (
    <>
      <PageTitle breadcrumbs={smemodelResultBreadCrumbs}>{intl.formatMessage({id: 'GENERAL.RESULT'})}</PageTitle>
      <div ref={ref} className='card mb-5 mb-xl-10'>
        <div className='card-body pb-0'>
          <div className='d-flex flex-wrap flex-sm-nowrap'>
            <div className='flex-grow-1'>
              <div className='d-flex justify-content-between align-items-start flex-wrap'>
                <div className='d-flex flex-column'></div>
                <div className='d-flex flex-column'>
                  <ReactToPrint
                    documentTitle='SME Model Result'
                    bodyClass='text-gray-400 text-primary'
                    content={() => ref.current}
                    trigger={() => (
                      <PrintButton type='button' className='btn btn-light-primary btn-sm'>
                        <KTIcon
                          iconName='printer'
                          iconType='outline'
                          className='fs-3 text-default'
                        />
                      </PrintButton>
                    )}
                  />
                </div>
              </div>
            </div>
            <div className='card-body pt-9 pb-0' id='for-print'>
              <div>
                <div className='row'>
                  <div className='d-flex justify-content-between align-items-center'>
                    <ReportTitle />
                    <MarcLogo />
                  </div>
                </div>
                <div className='row'>
                  <div className='col-6'>
                    <div className='row row-cols-1'>
                      <div className='col'>
                        <div className='d-flex flex-column'>
                          <div>
                            <h2 className='h2ClassTop'>{intl.formatMessage({id: 'GENERAL.COMPANY_NAME'})}</h2>
                          </div>
                          <span>
                            <h1 style={{ fontStyle: 'italic' }}>
                              {computeForm.companyInformation.company_name}
                            </h1>
                          </span>
                          <div className='d-flex flex-column' style={{ marginTop: '20px' }}>
                            <div>
                              <h2 className='h2ClassTop'>{intl.formatMessage({id: 'AFS.BUSINESS_INFORMATION'})}</h2>
                            </div>
                            <table style={{ fontSize: '18px', margin: '0rem' }}>
                              <tr>
                                <td>
                                  {intl.formatMessage({id: 'COMPANY.REGISTER_NUMBER'})}</td>
                                <td>
                                  : <span className='fw-bold fs-4' style={{ fontSize: '18px' }}>
                                    {computeForm.companyInformation.register_number}
                                  </span>
                                </td>
                              </tr>
                              <tr>
                                <td>
                                  {intl.formatMessage({id: 'COMPANY.REFERENCE_NO'})}</td>
                                <td>
                                  : <span className='fw-bold fs-4' style={{ fontSize: '18px' }}>
                                    {computeForm.sme_model_no}
                                  </span>
                                </td>
                              </tr>
                              <tr>
                                <td>
                                  {intl.formatMessage({id: 'COMPANY.ISSUE_DATE_TIME'})}</td>
                                <td>
                                  : <span className='fw-bold fs-4' style={{ fontSize: '18px' }}>
                                    {new Date(computeForm.companyResult.updated_at).toLocaleString(
                                      'en-GB',
                                      {
                                        day: '2-digit',
                                        month: '2-digit',
                                        year: 'numeric',
                                        hour: '2-digit',
                                        minute: '2-digit',
                                        second: '2-digit',
                                      }
                                    )}
                                  </span>
                                </td>
                              </tr>
                            </table>
                          </div>
                        </div>
                      </div>
                      <div className='col'>
                        <div className='row'>
                          <div style={{ height: '30px' }}></div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className='col-6'>
                    <div className='flex flex

-column'>
                      <Speedometer
                        mainTitle={intl.formatMessage({id: 'RESULT.CREDIT_SCORE'})}
                        usage={Number(computeForm.companyResult.total_score_c?.toString())}
                        customSegmentStops={[
                          0,
                          Number(computeForm.companyResult.total_score_c?.toString()),
                          100,
                        ]}
                        segmentColors={['#36486b', '#9fa9a3']}
                        currentValueText={computeForm.companyResult.total_score_c?.toString()}
                        textColor={'#000000'}
                      />
                    </div>
                  </div>
                </div>
                <div className='row' style={{ transform: 'translateY(-180px)' }}>
                  <div className='col-12'>
                    <div className='d-flex flex-wrap'>
                      <div className='flex flex-column'>
                        <div>
                          <h2 className='h2ClassTop'>{intl.formatMessage({id: 'RESULT.BUSINESS_SUMMARY'})}</h2>
                        </div>
                        <table style={{ fontSize: '18px', margin: '0rem' }}>
                          <tr>
                            <td>
                              {intl.formatMessage({id: 'AFS.FINANCIAL_YEAR'})}</td>
                            <td>
                              : <span className='fw-bold fs-4' style={{ fontSize: '18px' }}>
                                {computeForm.companyInformation.financial_year}
                              </span>
                            </td>
                          </tr>
                          <tr>
                            <td>
                              {intl.formatMessage({id: 'RESULT.COMPANY_TYPE'})}</td>
                            <td>
                              : <span className='fw-bold fs-4' style={{ fontSize: '18px' }}>
                                {computeForm.companyInformation.company_classification_c}
                              </span>
                            </td>
                          </tr>
                          <tr>
                            <td>
                              {intl.formatMessage({id: 'COMPANY.BUMIPUTERA_STATUS'})}</td>
                            <td>
                              : <span className='fw-bold fs-4' style={{ fontSize: '18px' }}>
                                {computeForm.companyInformation.bumiputera_status}
                              </span>
                            </td>
                          </tr>
                          <tr>
                            <td>
                              {intl.formatMessage({id: 'COMPANY.BUSINESS_TYPE'})}</td>
                            <td>
                              : <span className='fw-bold fs-4' style={{ fontSize: '18px' }}>
                                {computeForm.companyInformation.business_type}
                              </span>
                            </td>
                          </tr>
                        </table>


                      </div>
                    </div>
                  </div>
                </div>
                <div className='row' style={{ transform: 'translateY(-110px)' }}>
                  <div className='col'>
                    <div className='p-3'>
                      <div className='card-title'>
                        <div
                          className='card card-flush bgi-no-repeat bgi-size-contain'
                          style={{
                            backgroundColor: '#36486b',
                            backgroundImage: "url('/media/patterns/patterns-3.png')",
                            backgroundPosition: 'right',
                          }}
                        >
                          <div className='card-header pt-5' style={{ height: '80px' }}>
                            <div className='card-title d-flex flex-column'>
                              <div className='white-text-on-blue-bg'>
                                <span className='fs-2hx fw-bold text-white me-2 lh-1 ls-n2'>
                                  <p style={{ fontSize: '24px' }}>
                                    {computeForm.companyResult.cm_band_c ?? ''}
                                  </p>
                                </span>
                              </div>
                              <div className='white-text-on-blue-bg' style={{ transform: 'translateY(-50px)' }}>
                                <span className='text-white opacity-75 pt-1 fw-semibold fs-5'>
                                  {intl.formatMessage({id: 'RESULT.SME_GRADE'})} <sup>1</sup>
                                </span>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className='col'>
                    <div className='p-3'>
                      <div className='card-title'>
                        <div
                          className='card card-flush bgi-no-repeat bgi-size-contain'
                          style={{
                            backgroundColor: '#36486b',
                            backgroundImage: "url('/media/patterns/vector-1.png')",
                            backgroundSize: '50%  100%',
                            backgroundPosition: 'right',
                          }}
                        >
                          <div className='card-header pt-5' style={{ height: '80px' }}>
                            <div className='card-title d-flex flex-column'>
                              <div className='white-text-on-blue-bg'>
                                <span className='fs-2hx fw-bold text-white me-2 lh-1 ls-n2'>
                                  <p style={{ fontSize: '24px' }}>
                                    {Number(
                                      computeForm.companyResult.probability_default_c?.toString()
                                    ).toFixed(2) + '%'}
                                  </p>
                                </span>
                              </div>
                              <div className='white-text-on-blue-bg' style={{ transform: 'translateY(-50px)' }}>
                                <span className='text-white opacity-75 pt-1 fw-semibold fs-5'>
                                  {intl.formatMessage({id: 'RESULT.PROBABILITY_OF_DEFAULT'})} <sup>2</sup>
                                </span>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className='col'>
                    <div className='p-3'>
                      <div className='card-title'>
                        <div
                          className='card card-flush bgi-no-repeat bgi-size-contain'
                          style={{
                            backgroundColor: '#36486b',
                            backgroundImage: "url('/media/patterns/vector-1.png')",
                            backgroundSize: '50%  100%',
                            backgroundPosition: 'right',
                          }}
                        >
                          <div className='card-header pt-5' style={{ height: '80px' }}>
                            <div className='card-title d-flex flex-column'>
                              <div className='white-text-on-blue-bg'>
                                <span className='fs-2hx fw-bold text-white me-2 lh-1 ls-n2'>
                                  <p style={{ fontSize: '24px' }}>
                                    {addCommaToThousands(
                                      computeForm.companyResult.proposed_credit_limit_c?.toString() ?? ''
                                    )}
                                  </p>
                                </span>
                              </div>
                              <div className='white-text-on-blue-bg' style={{ transform: 'translateY(-50px)' }}>
                                <span className='text-white opacity-75 pt-1 fw-semibold fs-5'>
                                  {intl.formatMessage({id: 'RESULT.INDICATIVE_CREDIT_LIMIT'})} <sup>3</sup>
                                </span>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className='row'>
                  <div className='col'>
                    <div className='card-body p-9'>
                      <BubbleChart cmBand={computeForm.companyResult.cm_band_c ?? ''} />
                    </div>
                  </div>
                </div>
                <div className='row'>
                  <div className='card-body p-9'>
                    <div style={{ pageBreakBefore: 'always' }}>
                      <div className='row'>
                        <div style={{ height: '10px' }}></div>
                      </div>
                      <div className='row mb-2'>
                        <span className='fw-bold fs-4'>
                          <sup>1</sup>&nbsp;{intl.formatMessage({id: 'RESULT.MARC_SCORE_DEFINITIONS'})}
                        </span>
                      </div>
                    </div>
                    <table className='table border table-row-bordered table-row-gray-300 gy-2'>
                      <thead className='fw-bolder fs-6 text-gray-800'>
                        <tr>
                          <th style={{ textAlign: 'justify', padding: '3px' }}>{intl.formatMessage({id: 'RESULT.SME_GRADE'})}</th>
                          <th style={{ textAlign: 'justify', padding: '3px' }}>{intl.formatMessage({id: 'RESULT.CREDIT_SCORE'})}</th>
                          <th style={{ textAlign: 'justify', padding: '3px' }}>{intl.formatMessage({id: 'RESULT.PD_PERCENT'})})</th>
                          <th style={{ textAlign: 'justify', padding: '3px' }}>{intl.formatMessage({id: 'RESULT.RISK_PROFILE'})}</th>
                        </tr>
                      </thead>
                      <tbody>
                        {matrix.Result?.map((creditGrade, index) => (
                          <tr
                            key={index}
                            className={clsx('', {
                              'table-active': computeForm.companyResult.cm_band_c === creditGrade.cm_band,
                            })}
                            style={{ textAlign: 'justify', padding: '3px' }}
                          >
                            <td style={{ textAlign: 'justify', padding: '3px' }}>{creditGrade.cm_band}</td>
                            <td style={{ textAlign: 'justify', padding: '3px' }}>
                              {creditGrade.score_factor_min}-{creditGrade.score_factor_max}
                            </td>
                            <td>
                              {numericFormatter(creditGrade.probability_of_default!.toString(), {
                                thousandSeparator: ',',
                                decimalScale: 2,
                                fixedDecimalScale: true,
                              })}
                            </td>
                            <td style={{ textAlign: 'justify', padding: '3px' }}>
                              {intl.formatMessage({id: 'RESULT.'+creditGrade.risk_profile})}
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>
                </div>
                <div className='row'>
                  <div className='card-body p-9'>
                    <span className='fw-bold fs-4'>
                      <sup>2</sup>&nbsp;{intl.formatMessage({id: 'RESULT.CREDIT_SCORING_METHODOLOGIES'})}
                    </span>
                    <div className='d-flex flex-wrap text-gray-600 fs-6 mb-2' style={{ textAlign: 'justify', padding: '3px' }}>
                      {intl.formatMessage({id: 'RESULT.METHODOLOGY_ONE'})}
                    </div>
                    <div className='d-flex flex-wrap text-gray-600 fs-6 mb-2' style={{ textAlign: 'justify', padding: '3px' }}>
                      {intl.formatMessage({id: 'RESULT.METHODOLOGY_TWO'})}
                    </div>
                    <div className='d-flex flex-wrap' style={{ pageBreakBefore: 'always' }}>
                      <table className='table table-striped border table-row-bordered table-row-gray-300 gy-2'>
                        <thead className='fw-bold fs-5'>
                          <tr>
                            <th style={{ textAlign: 'justify', padding: '3px' }}>
                              {intl.formatMessage({id: 'RESULT.FACTORS'})}
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            <td style={{ textAlign: 'justify', padding: '3px' }}>
                              {intl.formatMessage({id: 'RESULT.HISTORICAL_OWNERSHIP_TITLE'})} <br />
                              {intl.formatMessage({id: 'RESULT.HISTORICAL_OWNERSHIP_DESC'})}
                            </td>
                          </tr>
                          <tr>
                            <td style={{ textAlign: 'justify', padding: '3px' }}>
                              {intl.formatMessage({id: 'RESULT.LEVERAGE_ASSESSMENT_TITLE'})} <br />
                              {intl.formatMessage({id: 'RESULT.LEVERAGE_ASSESSMENT_DESC'})}
                            </td>
                          </tr>
                          <tr>
                            <td style={{ textAlign: 'justify', padding: '3px' }}>
                              {intl.formatMessage({id: 'RESULT.PROFITABILITY_ASSESSMENT_TITLE'})}<br />
                              {intl.formatMessage({id: 'RESULT.PROFITABILITY_ASSESSMENT_DESC'})}
                            </td>
                          </tr>
                          <tr>
                            <td style={{ textAlign: 'justify', padding: '3px' }}>
                              {intl.formatMessage({id: 'RESULT.LIQUIDITY_ASSESSMENT_TITLE'})} <br />
                              {intl.formatMessage({id: 'RESULT.LIQUIDITY_ASSESSMENT_DESC'})}
                            </td>
                          </tr>
                          <tr>
                            <td style={{ textAlign: 'justify', padding: '3px' }}>
                              {intl.formatMessage({id: 'RESULT.OPERATIONAL_EFFICIENCY_TITLE'})} <br />
                              {intl.formatMessage({id: 'RESULT.OPERATIONAL_EFFICIENCY_DESC'})}
                            </td>
                          </tr>
                          <tr>
                            <td style={{ textAlign: 'justify', padding: '3px' }}>
                              {intl.formatMessage({id: 'RESULT.EXTERNAL_AUDITOR_TITLE'})} <br />
                              {intl.formatMessage({id: 'RESULT.EXTERNAL_AUDITOR_DESC'})}
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                    <div className='d-flex flex-wrap text-gray-600 fs-6 mb-2' style={{ textAlign: 'justify', padding: '3px' }}>
                      {intl.formatMessage({id: 'RESULT.FACTOR_DESC'})}
                    </div>
                  </div>
                </div>
                <div className='row'>
                  <div style={{ height: '10px' }}></div>
                </div>
                <div className='row'>
                  <div className='card card-custom'>
                    <div className='card-body p-9'>
                      <div className='d-flex flex-column'>
                        <span className='fw-bold fs-4'>
                          <sup>3</sup>&nbsp;{intl.formatMessage({id: 'RESULT.INDICATIVE_CREDIT_LIMIT_TITLE'})}
                        </span>
                        <div className='d-flex flex-wrap text-gray-600 fs-6 mb-2' style={{ textAlign: 'justify', display: 'flex', flexDirection: 'column' }}>
                          {intl.formatMessage({id: 'RESULT.INDICATIVE_CREDIT_LIMIT_DESC_ONE'})}
                        </div>
                        <div className='d-flex flex-wrap text-gray-600 fs-6' style={{ textAlign: 'justify', display: 'flex', flexDirection: 'column' }}>
                          {intl.formatMessage({id: 'RESULT.INDICATIVE_CREDIT_LIMIT_DESC_TWO'})}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className='row'>
                  <div className='card card-custom'>
                    <div className='card-body p-9'>
                      <div className='d-flex flex-column'>
                        <span className='fw-bold fs-4'>
                          &nbsp;{intl.formatMessage({id: 'RESULT.SME_DISCLAIMER'})}
                        </span>
                        <div className='d-flex flex-wrap text-gray-600 fs-6 mb-2' style={{ textAlign: 'justify', display: 'flex', flexDirection: 'column' }}>
                          {intl.formatMessage({id: 'RESULT.SME_DISCLAIMER_DESC_ONE'})}
                          </div>
                        <div className='d-flex flex-wrap text-gray-600 fs-6 mb-2' style={{ textAlign: 'justify', display: 'flex', flexDirection: 'column' }}>
                          {intl.formatMessage({id: 'RESULT.SME_DISCLAIMER_DESC_TWO'})}
                          </div>
                        <div className='d-flex flex-wrap text-gray-600 fs-6 mb-2' style={{ textAlign: 'justify', display: 'flex', flexDirection: 'column' }}>
                          {intl.formatMessage({id: 'RESULT.SME_DISCLAIMER_DESC_THREE'})}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}