import React, { useState, useEffect } from 'react';
import { Form } from 'react-bootstrap';
import { Controller } from 'react-hook-form';
import axios from '../../../../lib/axios';

interface IOptions {
    key: string;
    value: string;
}

interface PeriodDropDownAutoPopulateProps {
    control: any; // This should now take 'control' prop instead of 'register'
    preselectedValue?: string;
}

const PeriodDropDownAutoPopulate = ({ control, preselectedValue }: PeriodDropDownAutoPopulateProps) => {
    const [options, setOptions] = useState<IOptions[]>([]);
    const URL_SETTINGS = '/api/site-settings/get-setting';

    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await axios.post(URL_SETTINGS, { key: "subscription_set" });
                const optionsData = JSON.parse(response.data.value);
                const optionsArray = Object.entries(optionsData).map(([key, value]) => ({
                    key,
                    value: value as string
                }));
                setOptions(optionsArray);
            } catch (error) {
                console.error('Failed to fetch options:', error);
            }
        };

        fetchData();
    }, []);

    //add key value "null" to the options array for case no selection
    //select if options array did not contain "Select Period" key
    if (!options.some(option => option.key === 'Select Period')){
        options.unshift({ key: 'Select Period', value: '' });

    }

    return (
        <Form.Group className="mb-3">
            <Form.Label>Period</Form.Label>
            <Controller
                name="subscription_period"
                control={control}
                rules={{ required: 'This field is required' }}
                defaultValue={preselectedValue || ''}
                render={({ field: { onChange, onBlur, value, ref } }) => (
                    <Form.Control
                        as="select"
                        onChange={onChange}
                        onBlur={onBlur}
                        value={value}
                        ref={ref}
                    >
                        {options.map(option => (
                            <option key={option.key} value={option.value}>
                                {option.key}
                            </option>
                        ))}
                    </Form.Control>
                )}
            />
        </Form.Group>
    );
};

export default PeriodDropDownAutoPopulate;
