import React from 'react';

interface Props {
    // Define your component props here
}

const MarcLogo: React.FC<Props> = () => {
    // Implement your component logic here

    return (
        // JS code for your component's UI
        <div>
            <img src="/media/logos/fintel-by-marc-logo.png" alt='MARC Berhad Logo 2020' className='marc-logo'
                style={{
                    maxWidth: '25%',
                    height: 'auto',
                    position: 'absolute',
                    left: '65%',
                    transform: 'translateY(-50%)'
                }} />
        </div>
    );
};

export default MarcLogo;