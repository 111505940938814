import React, { useState, useEffect } from 'react';
import axios from '../../../../lib/axios';
import { Button, Card } from 'react-bootstrap';
import LoadingSpinner from '../../../../_metronic/helpers/components/LoadingSpinner';
import { useNavigate } from 'react-router-dom';
import { ISubscription } from '../../../pages/subscriptions/Subscription';

// **TypeScript Interfaces**
interface TabData {
  [key: string]: any; // Assuming your tab data could have varying structures
}

interface TabComponentProps {
  subscriptionId: number;
  Subscription:ISubscription | null;
  showSuccess: (message: string) => void;
  showError: (message: string) => void;

}

// **Helper Function for Loading State**
const useTabData = (subscriptionId: number, tabId: string) => {
  const [isLoading, setIsLoading] = useState(true);
  const [data, setData] = useState<TabData | null>(null);
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    const fetchData = async () => {
      setIsLoading(true);
      try {
        const response = await axios.post(`/api/subscription/details`, {
          id: subscriptionId,
          tab_id: tabId
        });
        setData(response.data);
        setError(null);
      } catch (error) {
        console.error('Error fetching data for', tabId, error);
        setError('Failed to load data');
      } finally {
        setIsLoading(false);
      }
    };

    fetchData();
  }, [subscriptionId, tabId]);

  return { isLoading, data, error };
};

// **Individual Tab Content Components**
const Tab1Content = ({ data, subscription, showSuccess, showError }: { data: TabData | null, subscription: ISubscription | null, showSuccess: (message: string) => void, showError: (message: string) => void }) => {
  const URL_VIEW = '/subscriptions/admin-view-subscription';
  const navigate = useNavigate();
  const onClickView = () => {
    if (data) {
      navigate(
        URL_VIEW,
        { state: { subscriptionId: data.tab1?.id } }
      );
    } else {
      //do nothing
    }
  };

  const onClickRefresh = async (subscription) => {
    console.log('--- subscription data on clickrefresh ---');
    console.log(subscription);
    console.log('--- subscription data on clickrefresh ---');

    const URL_REFRESH = '/api/subscription/refresh-subscription';

    axios.post(URL_REFRESH,
      {
        user_id: subscription.userId,
        company_id: subscription.company_data.id,
        subscription_id: subscription.id

      }).then((response) => {
        if(response.status === 200){
          showSuccess(`Success refresh subscription usage`)
        } else {
          showError(`Failed refresh subscription usage`)
        }
      }).catch((error) => {
        console.error('Error refreshing subscription:', error);
        showError(`Failed refresh subscription usage`)

      });


  }
  if (!data) {
    return (
      <LoadingSpinner />
    ) // Or any other fallback UI
  }
  return (
    <>
      <Card>
        <Card.Body>
          <Card.Title>Subscription: {data.tab1?.name}</Card.Title>
          <Card.Text>
            <strong>Description:</strong> {data.tab1?.description} <br />
            <strong>Status:</strong> {data.tab1?.status} <br />
            <strong>Start Date:</strong> {data.tab1?.start_date} <br />
            <strong>End Date:</strong> {data.tab1?.end_date}
          </Card.Text>
        </Card.Body>
        <Card.Footer>
          <Button className='btn btn-sm btn-info' onClick={() => onClickView()}><i className='fa fa-eye' ></i> View </Button>
          <Button className='btn btn-sm btn-SYSTEM' onClick={() => onClickRefresh(subscription)}><i className='fa fa-o' ></i> Refresh Usage </Button>

        </Card.Footer>
      </Card>
    </>
  );
};


const Tab2Content = ({ data }: { data: TabData | null }) => {

  if (!data) {
    return (
      <LoadingSpinner />
    ) // Or any other fallback UI
  } else {


    return (
      <Card>
        <Card.Body>
          <Card.Title>Subscription Usage</Card.Title>
          <Card.Text>
            <strong>Description:</strong> {data.tab2?.description} <br />
            <strong>Usage:</strong> {data.tab2?.usage} <br />
            <strong>Date:</strong> {data.tab2?.date} <br />
            <strong>Limit:</strong> {data.tab2?.limit} <br />
            <strong>Limit Exceeded:</strong> {data.tab2?.is_limit_exceeded ? 'Yes' : 'No'}
          </Card.Text>
        </Card.Body>
      </Card>
    );
  }

};

const Tab3Content = ({ data }: { data: TabData | null }) => {
  if (!data || !data.tab3 || data.tab3.length === 0) {
    return <LoadingSpinner />; // Or any other fallback UI
  }

  const product = data.tab3[0]; // Assuming you want to display the first item, or you could map over them

  return (
    <>
      <Card>
        <Card.Body>
          <Card.Title>Linked Product</Card.Title>
          <Card.Text>
            <strong>Product ID:</strong> {product.id} <br />
            <strong>Product Name:</strong> {product.name} <br />
            <strong>Description:</strong> {product.description} <br />
            <strong>Type:</strong> {product.type} <br />
            <strong>Price:</strong> {product.price} <br />
            <strong>Quantity:</strong> {product.quantity} <br />
            <strong>Subscription Period:</strong> {product.subscription_period} <br />
            <strong>Created At:</strong> {product.created_at} <br />
            <strong>Updated At:</strong> {product.updated_at}
          </Card.Text>
        </Card.Body>
      </Card>
    </>
  );
};

const Tab4Content = ({ data }: { data: TabData | null }) => {
  if (!data || !data.tab4 || data.tab4.length === 0) {
    return (
      <LoadingSpinner />
    ) // Or any other fallback UI
  } else {
    const company = data.tab4[0]; // Assuming you want to display the first item, or you could map over them
    return (
      <>
        <Card>
          <Card.Body>
            <Card.Title>Linked Company</Card.Title>
            <Card.Text>
              <strong>Company Id ID:</strong> {company?.pivot?.company_id} <br />
              <strong> Name:</strong> {company?.name} <br />
              <strong>Address:</strong> {company?.address} <br />
              <strong>Website:</strong> {company?.website} <br />
              <strong>Phone:</strong> {company?.phone} <br />
              <strong>Created At:</strong> {company?.created_at} <br />
              <strong>Updated At:</strong> {company?.updated_at}<br />
              <strong>Company Active:</strong> {(company?.status === 'active') ? 'Yes' : 'No'}<br />

            </Card.Text>
          </Card.Body>
        </Card>
      </>
    );
  }
};

// **Main Tab Component**
const TabComponent: React.FC<TabComponentProps> = ({ subscriptionId, Subscription ,showSuccess,showError}) => {
  const [activeTab, setActiveTab] = useState('tab1'); // Default to tab1
  const tabs = ['tab1', 'tab2', 'tab3', 'tab4'];
  const tabsName = [
    'Subscription Details',
    'Subscription Usage',
    'Subscription Linked Product',
    'Company Details'
  ];

  // Use the helper function to manage state for each tab
  const { isLoading: isLoadingTab1, data: tab1Data, error: tab1Error } = useTabData(subscriptionId, 'tab1');
  const { isLoading: isLoadingTab2, data: tab2Data, error: tab2Error } = useTabData(subscriptionId, 'tab2');
  const { isLoading: isLoadingTab3, data: tab3Data, error: tab3Error } = useTabData(subscriptionId, 'tab3');
  const { isLoading: isLoadingTab4, data: tab4Data, error: tab4Error } = useTabData(subscriptionId, 'tab4');

  const handleTabChange = (tabId: string) => {
    setActiveTab(tabId);
  };

  return (
    <div>
      <ul className="nav nav-tabs nav-line-tabs mb-5 fs-6">
        {tabs.map((tab, index) => (
          <li key={tab} className="nav-item">
            <a className={`nav-link ${activeTab === tab ? 'active' : ''}`} href="#" onClick={(e) => {
              e.preventDefault();
              handleTabChange(tab);
            }}>
              {tabsName[index]}
            </a>
          </li>
        ))}
      </ul>

      <div className="tab-content" id="myTabContent">
        {activeTab === 'tab1' && (
          <div className="tab-pane fade show active">
            {isLoadingTab1 ? <LoadingSpinner /> : tab1Error ? <div>{tab1Error}</div> : <Tab1Content data={tab1Data} subscription={Subscription} showSuccess={showSuccess} showError={showError} />}
          </div>
        )}
        {activeTab === 'tab2' && (
          <div className="tab-pane fade show active">
            {isLoadingTab2 ? <LoadingSpinner /> : tab2Error ? <div>{tab2Error}</div> : <Tab2Content data={tab2Data} />}
          </div>
        )}
        {activeTab === 'tab3' && (
          <div className="tab-pane fade show active">
            {isLoadingTab3 ? <LoadingSpinner /> : tab3Error ? <div>{tab3Error}</div> : <Tab3Content data={tab3Data} />}
          </div>
        )}
        {activeTab === 'tab4' && (
          <div className="tab-pane fade show active">
            {isLoadingTab4 ? <LoadingSpinner /> : tab4Error ? <div>{tab4Error}</div> : <Tab4Content data={tab4Data} />}
          </div>
        )}
      </div>
    </div>
  );
};

export default TabComponent;
