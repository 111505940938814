import React, { useEffect } from 'react';
import { Button } from 'react-bootstrap';
import { AsideMenuItemWithSub } from '../../../../_metronic/layout/components/aside/AsideMenuItemWithSub';
import { AsideMenuItem } from '../../../../_metronic/layout/components/aside/AsideMenuItem';
import LoadingSpinner from '../../../helpers/components/LoadingSpinner';
import { useAuth } from '../../../../hooks/auth';
import { useIntl } from 'react-intl';

interface HeaderSwapMenuProps {
  UserId: string | null | undefined;
}

const HeaderSwapMenu: React.FC<HeaderSwapMenuProps> = ({ UserId }) => {
  const { loading, companies, currentCompany, toggleAccountState, user } = useAuth('auth');

  const handleSelect = (user_id: string, accountState: 'personal' | 'organization', companyId?: string) => {
    toggleAccountState(user_id, accountState, companyId || '');
  };

  const intl = useIntl()

  useEffect(() => {
    // Placeholder for potential setup or cleanup logic 
  }, [UserId, currentCompany, user]);

  if (loading) {
    return (
      <Button className="btn btn-primary btn-block" type="button" disabled>
        <LoadingSpinner />
      </Button>
    );
  }

  const title = (currentCompany && user?.mode === 'organization') ? `${intl.formatMessage({ id: 'MENU.SWITCH_COMPANY' })} ${currentCompany}` : intl.formatMessage({ id: 'MENU.SWITCH_PERSONAL' });

  return (
    <AsideMenuItemWithSub to='#' title={title} icon={undefined}>
      {user?.role !== 'Super Admin' && UserId && companies && companies.length > 0 ? (
        companies.map((company) => (
          <div
            key={company.id}
            onClick={() => handleSelect(UserId, 'organization', company.id?.toString())}
            style={company.name === currentCompany ? { color: 'green', fontWeight: 'bold' } : {}}
          >
            <AsideMenuItem
              to='#'
              title={company.name}
              hasBullet={false}
              icon={company.name === currentCompany ? 'check-square' : 'maximize'}
            />
          </div>
        ))
      ) : null}

      {user?.role !== 'User Regular' && (
        <div
          onClick={() => handleSelect(UserId || '', 'personal')}
          style={{ pointerEvents: user?.role === 'User Regular' ? 'none' : 'auto' }}
        >
          <AsideMenuItem
            to='#'
            title='Personal mode'
            hasBullet={true}
            icon={'ki-duotone ki-security-user'}
          />
        </div>
      )}
    </AsideMenuItemWithSub>
  );
};

export { HeaderSwapMenu };
