import React from 'react'
import {KTIcon} from '../../../../_metronic/helpers'
import clsx from 'clsx'

export default function ErrorAlert({title, message, enable, dismiss}) {
  return (
    <>
      <div className={clsx('alert-message-wrapper', {'visually-hidden': !enable})}>
        <div className='alert d-flex bg-light-danger rounded border-danger border border-dashed p-6'>
          <KTIcon iconName='shield-cross' className='fs-2tx text-warning me-4' />
          <div className='d-flex flex-column text-light pe-0 pe-sm-10'>
            <h5 className='mb-1'>{title}</h5>
            <span className='fs-6 text-danger pe-7'>{message}</span>
          </div>
          <button
            type='button'
            onClick={() => dismiss()}
            className='position-absolute position-sm-relative m-2 m-sm-0 top-0 end-0 btn btn-icon ms-sm-auto'
          >
            <KTIcon iconType='outline' iconName='cross' className='fs-2tx text-default me-4' />
          </button>
        </div>
      </div>
    </>
  )
}
