import axios from '../lib/axios';
import useSWR from 'swr';
import { useEffect, useCallback, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { ICompany } from '../app/pages/dashboard/DataDashboard';
import { IUserSubscription } from '../app/pages/subscriptions/Subscription';
import { useToast } from '../app/components/ToastProvider';
import { useIntl } from 'react-intl';

interface User {
  id: string;
  name: string;
  email: string;
  roles: [{ permissions: { name: string }[] }];
  company_id: string;
  subscription: IUserSubscription;
  role: string;
  is_organization?: boolean;
  is_personal?: boolean;
  created_at: string;
  updated_at: string;
  mode: 'personal' | 'organization';
  company?: string;
  limit: LimitData;
  usage: UsageData;
  usage_is_unlimited?: boolean;
  check_limit: boolean;
  subscription_active?: boolean;
  companies: ICompany[];
  default_subscription_id: string;
  is_company_admin?: boolean;
  is_internal_user?: boolean;
  analyst_subscription_active?: boolean;
  product_list: ProductData[];

}
interface ProductData {
  product_id: number;
  name: string;
  product_key: string;
  usage_count: number;
}

interface LimitData {
  total_limit: number;
  own_limit: number;
  is_split: boolean;
}

interface UsageData {
  is_split: boolean;
  usage_all: number;
  own_usage: number;
  usage_is_unlimited: boolean;
  is_consumed: boolean;
}

interface Usage {
  usage: number;
  is_limit_exceeded: boolean;
}

type UsageType = {
  usage: number;
  is_limit_exceeded: boolean;
}

interface ApiError {
  statusCode: number;
  message: string;
}

const fetchUser = async (setLoading: (loading: boolean) => void) => {
  setLoading(true);
  console.log('Loading started');
  //debugger
  try {
    const response = await axios.get<User>('/api/user');
    console.log('Response received:', response);

    return response.data;
  } catch (error: any) {
    console.error('Error occurred:', error);
    if (error.response?.status === 409) {
      console.log('Redirecting to verify email page due to 409 status');
      //return '/verify-email';
      window.location.pathname = 'verify-email';
    } else {
      throw error;

    }
  } finally {
    setLoading(false);
    console.log('Loading finished');
  }
};


export const useAuth = (middleware: string, redirectIfAuthenticated?: string) => {
  const intl = useIntl();
  const navigate = useNavigate();
  const params = useParams();
  const [loading, setLoading] = useState(false);
  const [accountState, setAccountState] = useState<'personal' | 'organization'>('personal');
  const [companies, setCompanies] = useState<ICompany[]>([]);
  const [currentCompany, setCurrentCompany] = useState<string>('');
  const [subscription, setSubscription] = useState<IUserSubscription | null>(null);
  const { showError, showInfo, showSuccess } = useToast();

  const { data: user, error, mutate } = useSWR<User>(
    '/api/user',
    () => fetchUser(setLoading) as Promise<User>,
    {
      refreshInterval: 30000,
      revalidateIfStale: false,
      revalidateOnFocus: false,
    }
  );

  const userCan = (name: string) => {
    const permissions = user?.roles[0]?.permissions || [];
    return permissions.some(permission => permission.name === name);
  };

  const csrf = async () => {
    await axios.get('/sanctum/csrf-cookie');
  };



  const toggleAccountState = async (userId: string, newAccountState: 'personal' | 'organization', companyId?: string | null) => {
    setLoading(true);

    const payload = { mode: newAccountState, company_id: companyId, user_id: userId };

    try {
      const response = await axios.post('/api/user/toggle-mode', payload);
      if (response.status === 200) {
        mutate();
        setAccountState(newAccountState);
        if (user?.company) {
          setCurrentCompany(user.company);
        }
        showInfo(`${response?.data.data.message}`);
      } else {
        showError('Unexpected response from the server');
      }
    } catch (error: any) {
      console.error('Error toggling account state:', error.response?.data || error.message);
      console.error('Error toggling account state:', JSON.stringify(error, null, 2));

      showError(error.response?.data.message || 'Error occurred while changing account state');
    } finally {
      setLoading(false);
    }
  };

  const setSubscriptionAsDefault = async (subscriptionId: string, mode: string) => {
    if (!user) {
      showError('User not available to manage subscriptions');
      return;
    }

    try {
      const payload = { subscription_id: subscriptionId, mode };
      const response = await axios.post('/api/subscription/set-default-subscription', payload);

      if (response.status === 200) {
        showInfo(`Subscription ${subscriptionId} set as default`);
      } else {
        showError('Subscription not set as default');
      }
    } catch (error) {
      console.error('Error setting subscription as default:', JSON.stringify(error, null, 2));

      console.error('Error setting subscription as default:', error);
      showError('Failed to set subscription as default');
    }
  };

  const setSubscriptionAsNotDefault = async (subscriptionId: string, mode: string) => {
    if (!user) {
      showError('User not available to manage subscriptions');
      return;
    }

    try {
      const payload = { subscription_id: subscriptionId, mode };
      const response = await axios.post('/api/subscription/unset-default-subscription', payload);

      if (response.status === 200) {
        showInfo(`Subscription ${subscriptionId} set as not default`);
      } else {
        showError('Subscription not set as not default');
      }
    } catch (error) {
      console.error('Error setting subscription  as not  default:', JSON.stringify(error, null, 2));

      console.error('Error setting subscription as not default:', error);
      showError('Failed to set subscription as not default');
    }
  };

  const register = async ({ setErrors, ...props }) => {
    await csrf();
    setErrors([]);
    axios.post('/register', props)
      .then(() => mutate())
      .catch((error) => {
        if (error.response.status !== 422) throw error;
        setErrors(Object.values(error.response.data.errors).flat());
      });
  };

  const login = async ({ setErrors, setStatus, ...props }) => {
    setLoading(true);
    await csrf();
    setErrors([]);
    setStatus(null);
    axios.post('/login', props)
      .then(() => {
        mutate();
        setLoading(false);
        showSuccess(intl.formatMessage({ id: 'TOAST.LOGIN_SUCCESS' }));
      })
      .catch((error) => {
        console.error('Error login:', JSON.stringify(error, null, 2));
        showError(error.response.data.message)

        if (error.response.status !== 422) throw error;
        setStatus(error.response.status);
        setErrors(Object.values(error.response.data.errors).flat());
        setLoading(false);
      });
  };

  const logout = useCallback(async () => {
    if (!error) {
      await axios.post('/logout');
      mutate();
    }
    window.location.pathname = '/auth/login';
  }, [error, mutate]);

  const forgotPassword = async ({ setErrors, setStatus, email }) => {
    await csrf();
    setErrors([]);
    setStatus(null);
    axios
      .post('/forgot-password', { email })
      .then((response) => setStatus(response.data.status))
      .catch((error) => {
        if (error.response.status !== 422) throw error;
        setErrors(Object.values(error.response.data.errors).flat());
      });
  };

  const resetPassword = async ({ setErrors, setStatus, ...props }) => {
    await csrf();
    setErrors([]);
    setStatus(null);
    axios
      .post('/reset-password', { token: params.token, ...props })
      .then((response) => navigate(`/auth/login/${btoa(response.data.status)}`))
      .catch((error) => {
        if (error.response.status !== 422) throw error;
        setErrors(Object.values(error.response.data.errors).flat());
      });
  };

  const resendEmailVerification = ({ setStatus }) => {
    axios
      .post('/email/verification-notification')
      .then((response) => setStatus(response.data.status));
  };

  const verifyEmail = async ({ setStatus }) => {
    await csrf();
    axios
      .get(`/email/verify/${params.id}/${params.hash}`)
      .then((response) => setStatus(response.data.status));
  };

  useEffect(() => {
    if (middleware === 'guest' && redirectIfAuthenticated && user) {
      navigate(redirectIfAuthenticated);
    }
    if (middleware === 'auth' && error) {
      logout();
    }

    if (user && user.role !== 'Super Admin') {
      setCompanies(user.companies);
      setCompanies(user.companies);
      if (user.mode === 'organization') {
        setAccountState('organization');
        setCurrentCompany(user.company || '');
      } else {
        setAccountState('personal');
        setCurrentCompany('');
      }
    }
  }, [middleware, redirectIfAuthenticated, logout, navigate, user, error]);

  return {
    user,
    loading,
    setLoading,
    accountState,
    companies,
    currentCompany,
    subscription,
    register,
    login,
    logout,
    userCan,
    toggleAccountState,
    setSubscriptionAsDefault,
    setSubscriptionAsNotDefault,
    forgotPassword,
    resetPassword,
    resendEmailVerification,
    verifyEmail,
  };
};
