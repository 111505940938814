import React, { useRef } from 'react'
import { PageLink, PageTitle } from '../../../_metronic/layout/core'
import axios from '../../../lib/axios'
import { useEffect, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import { RequestComputeFormInit, IRequestComputeForm } from './model'
import clsx from 'clsx'
import { LineInput } from './components/LineInput'
import ErrorAlert from './components/ErrorAlert'
import { KTIcon } from '../../../_metronic/helpers'
import { IRequestMatrix, IRequestResource } from './model/SmeModelModel'
import LineSelect from './components/LineSelect'
import SubmitButtonExternal from '../dashboard/component/SubmitButtonExternal'
import ResultButtonExternal from '../dashboard/component/ResultButtonExternal'
import './SmeModelCompute.css'
import { TransposeLineInput } from './components/TransposeLineInput'
import { TransposeLineDate } from './components/TransposeLineDate'
import { confirm } from '../../modules/confirmation/BasicConfirmation'
import { alert } from '../../modules/confirmation/BasicAlert'
import TransposeTable from './components/TransposeTable'
import { OverlayTrigger, Tooltip } from 'react-bootstrap'
import moment from 'moment'
import { LineViewer } from './components/LineViewer'
import TableEqualLine from './components/TableEqualLine'
import styled from 'styled-components'
import ReactToPrint from 'react-to-print'
import AFSPrintingHeader from './components/AFSPrintingHeader'

type LocationState = {
  sme_model_no: string
}

type PageState = {
  loading: boolean
  alert: boolean
  alertTitle: string
  alertMessage: string
}

const smemodelComputeBreadCrumbs: Array<PageLink> = [
  {
    title: 'SME Model',
    path: '/sme-model',
    isSeparator: false,
    isActive: false,
  },
  {
    title: '',
    path: '',
    isSeparator: true,
    isActive: false,
  },
]
async function getReports(sme_model_no: string): Promise<IRequestComputeForm> {
  // try {
  console.log('location.stateX :: ' + sme_model_no)
  const url = '/api/model/sme/view'
  const response = await axios.post(url, { sme_model_no })
  console.log(response.data.data)
  return response.data.data
  // } catch (err) {
  //   console.log(err);
  //   return [];
  // }
}

async function getResource(name: string | ''): Promise<IRequestResource> {
  // try {
  console.log('getResource :: ' + name)
  const url = '/api/model/sme/resource'
  const response = await axios.post(url, { name })
  console.log(response.data.data)
  return response.data.data
  // } catch (err) {
  //   console.log(err);
  //   return [];
  // }
}

async function getMatrix(name: string | ''): Promise<IRequestMatrix> {
  // try {
  console.log('getResource :: ' + name)
  const url = '/api/model/sme/matrix'
  const response = await axios.post(url, { name })
  console.log(response.data.data)
  return response.data.data
  // } catch (err) {
  //   console.log(err);
  //   return [];
  // }
}

async function getComputeReport(payload: IRequestComputeForm) {
  // try {
  const url = '/api/model/sme/compute'
  const response = await axios.post(url, payload)
  return response.data.data
  // } catch (err) {
  //   console.log(err);
  //   return [];
  // }
}

async function addFinancialAnalysis(payload) {
  // try {
  const url = '/api/model/sme/add-financial-analysis'
  const response = await axios.post(url, payload)
  return response.data.data
  // } catch (err) {
  //   console.log(err);
  //   return [];
  // }
}
const PrintButton = styled.button`
@media print {
  display: none;
},
@page {
  size: A4 landscape;
}
body {
  transform: scale(0.8);
}

  .print-black-font {
    color: #000 !important;
  }
}  `

const FSTableData = styled.td`
  font-weight: 400;
  text-align: right;
  padding: 3px;
  min-height: 25px;
`
const FSTableDataHead = styled.td`
  font-weight: 400;
  text-align: left;
  padding: 3px;
  min-height: 25px;
  border: 0px solid white;
`
export function SmeModelCompute() {



  console.log('SmeModelCompute')
  const location = useLocation()
  let navigate = useNavigate()
  const [ComputeForm, setComputeForm] = useState<IRequestComputeForm>(RequestComputeFormInit)
  const [PageState, setPageState] = useState<PageState>({
    loading: false,
    alert: false,
    alertMessage: '',
    alertTitle: '',
  })
  const [typeIsCorporate, setTypeIsCorporate] = useState(false)
  const [Resource, setResource] = useState<IRequestResource>({
    BusinessType: [{ name: '' }],
    BumiputeraStatus: [{ name: '' }],
  })
  const [Matrix, setMatrix] = useState<IRequestMatrix>({
    Litigation: [{ name: '' }],
    ManagementExperience: [{ name: '' }],
    YearsInCurrentBusiness: [{ name: '' }],
    AccountingFirm: [{ name: '' }],
  })
  const autoGenTooltip = 'Computed by the system upon saving.'
  const consolidated_income_statement_ref = useRef<HTMLDivElement>(null)
  const consolidated_balance_sheet_ref = useRef<HTMLDivElement>(null)
  const cash_flow_statement_ref = useRef<HTMLDivElement>(null)
  const financial_highlights_ref = useRef<HTMLDivElement>(null)

  //Activate On Load Page
  useEffect(() => {
    ; (async () => {
      const sme_model_no = (location.state as LocationState)?.sme_model_no
      console.log('location.state :: ' + sme_model_no)
      const report = await getReports(sme_model_no)
      setComputeForm(report)
      const resource = await getResource('')
      setResource(resource)
      const matrix = await getMatrix('')
      setMatrix(matrix)
    })()
  }, [location.state])

  useEffect(() => {
    //This to update the required states
  }, [])

  const buttonDisable = (
    status: string | undefined,
    company_classification: string | undefined,
    typeCorporate: boolean
  ): boolean => {
    //if must disable is true then return true
    if (!status || !company_classification) {
      return true
    }

    if (typeCorporate) {
      if (status === 'retrieved') {
        return false
      } else if (status === 'created') {
        return true
      } else {
        return true
      }
    }

    /**
     *  if user is corporate SME modal not applicable, hence button result must disable
     *
     */

    if (company_classification === 'SME' && status === 'retrieved') {
      return false
    } else if (company_classification === 'SME' && status === 'created') {
      return true
    } else if (company_classification === 'SME' && status === 'result') {
      return false
    } else if (company_classification === 'Corporate' && status === 'result') {
      return true
    } else if (company_classification === 'Corporate' && status === 'created') {
      return true
    } else if (company_classification === 'Corporate' && status === 'retrieved') {
      return false
    } else {
      return true
    }
  }

  const handleSubmit = () => {
    console.log('Request')
    console.log(ComputeForm)
    setPageState({ ...PageState, loading: true, alert: false, alertTitle: '', alertMessage: '' })
    getComputeReport(ComputeForm)
      .then((response) => {
        console.log('response')
        console.log(response)
        setComputeForm(response)
        setPageState({ ...PageState, loading: false, alert: false })
      })
      .catch(function (error) {
        console.log(error)
        setPageState({
          ...PageState,
          alert: true,
          alertTitle: 'Error',
          alertMessage: error.response.data.message,
        })
      })
  }

  const handleChange = (value: string | number, param1: string, param2: string) => {
    // console.log('=== handle   change ===')

    // console.log('=== handle   change  param1 ===')

    // console.log(param1)

    // console.log('=== handle   change  value ===')

    // console.log(value)

    if (value === 'Corporate') {
      setTypeIsCorporate(true)
    } else {
      setTypeIsCorporate(false)
    }
    setComputeForm((prevState) => {
      return {
        ...prevState,
        [param1]: {
          ...prevState[param1],
          [param2]: value,
        },
      }
    })
    console.log(value)
  }

  const handleYearlyDataChange = (
    value: string | number,
    key: number,
    param1: string = '',
    param2: string = '',
    param3: string = '',
    param4: string = ''
  ) => {
    console.log('handleYearlyDataChange')
    console.log(key)
    console.log(param1)
    console.log(param2)
    console.log(param3)
    console.log(value)
    setComputeForm((prevState) => {
      let arr = prevState.companyFinancialAnalysis?.map((data, index) => {
        if (index === key) {
          if (param1 && param2 && param3 && param4) {
            return {
              ...data,
              [param1]: {
                ...data[param1],
                [param2]: {
                  ...data[param1][param2],
                  [param3]: {
                    ...data[param1][param2][param3],
                    [param4]: value,
                  },
                },
              },
            }
          } else if (param1 && param2 && param3) {
            return {
              ...data,
              [param1]: {
                ...data[param1],
                [param2]: {
                  ...data[param1][param2],
                  [param3]: value,
                },
              },
            }
          } else if (param1 && param2) {
            return {
              ...data,
              [param1]: {
                ...data[param1],
                [param2]: value,
              },
            }
          } else if (param1) {
            return {
              ...data,
              [param1]: value,
            }
          }
        }
        return data
      })

      return {
        ...prevState,
        companyFinancialAnalysis: arr,
      }
    })
    console.log(value)
  }

  /**
   * trigger action when user click view result button
   */

  const ReportResultViewed = (smeModelNo: string): void => {
    console.log('ReportResultViewed')
    console.log(smeModelNo)
    console.log('ReportResultViewed')

    const apiUrl = '/api/model/sme/report-retrieved'

    const data = {
      sme_model_no: smeModelNo,
    }

    axios
      .post(apiUrl, data)
      .then((res) => {
        console.log(res.data)
      })
      .catch((err) => {
        console.log(err)
      })
  }

  const handleViewResult = async (sme_model_no: string) => {
    console.log('click:' + sme_model_no)
    navigate(`/sme-model/result`, { state: { sme_model_no } })
  }

  const handleConfirm = async () => {
    if (
      await confirm(
        'You are adding a new financial year. You will not be able to undo this action. Are you sure?',
        'Yes, Proceed',
        'Cancel'
      )
    ) {
      let i = ComputeForm.companyFinancialAnalysis?.length! - 1
      addFinancialAnalysis({
        sme_model_no: ComputeForm.sme_model_no,
        fy_start_date: ComputeForm.companyFinancialAnalysis![i].fy_start_date,
        fy_end_date: ComputeForm.companyFinancialAnalysis![i].fy_end_date,
      })
        .then((response) => {
          console.log('response')
          console.log(response)
            ; (async () => {
              const report = await getReports(ComputeForm.sme_model_no)
              setComputeForm(report)
            })()
        })
        .catch(function (error) {
          console.log(error)
          alert(error.response.data.message)
        })
    } else {
      console.log('Cancel Add FY')
    }
  }

  //get current time using moment.js
  const [CurrentTime, setCurrentTime] = useState(moment().format('DD/MM/YYYY, HH:mm:ss'))

  const handleBeforeGetContent = () => {
    setCurrentTime(moment().format('DD/MM/YYYY, HH:mm:ss'))
    return Promise.resolve()
  }
  //alert('A : ' + (ComputeForm.companyInformation.company_classification !== 'Corporate'))
  //alert('B : ' + (ComputeForm.companyInformation.company_classification_c !== 'Corporate'))
  return (
    <>
      <PageTitle breadcrumbs={smemodelComputeBreadCrumbs}>Compute</PageTitle>

      <div className='d-flex justify-content-between align-items-start flex-wrap mb-2'>
        <div className='d-flex flex-column my-4'>
          <div className='d-flex align-items-center mb-2'>
            <SubmitButtonExternal
              loading={PageState.loading}
              companyStatus={ComputeForm.companyInformation.status_c}
              onClick={handleSubmit}
            />
            <span
              className='text-gray-500 fw-bolder ms-2 fs-8 pb-4 px-3'
              style={{ fontStyle: 'italic' }}
            >
              {ComputeForm.companyInformation.updated_at &&
                'Updated at : ' +
                moment(ComputeForm.companyInformation.updated_at).format('Do MMMM YYYY hh:mm A')}
            </span>
          </div>
        </div>

        <div className='d-flex my-4'>
          {/* View Result Button */}
          <ResultButtonExternal
            buttonText={'View Result'}
            onClick={() => handleViewResult(ComputeForm.sme_model_no)}
            CompanyInformation={ComputeForm.companyInformation}
            pageLoading={PageState.loading}
            buttonDisabled={buttonDisable(
              ComputeForm.companyInformation.status_c,
              ComputeForm.companyInformation.company_classification_c,
              typeIsCorporate
            )}
          />
        </div>
      </div>

      <ErrorAlert
        title={PageState.alertTitle}
        message={PageState.alertMessage}
        enable={PageState.alert}
        dismiss={() => setPageState({ ...PageState, alert: false })}
      />

      <div
        className={clsx('alert-message-wrapper', {
          'visually-hidden':
            ComputeForm.companyInformation.company_classification !== 'Corporate' &&
            ComputeForm.companyInformation.company_classification_c !== 'Corporate',
        })}
      >
        <div className='alert d-flex bg-light-danger rounded border-danger border border-dashed p-6'>
          <KTIcon iconName='shield-cross' className='fs-2tx text-danger me-4' />
          <div className='d-flex flex-column text-light pe-0 pe-sm-10'>
            <h5 className='mb-1'>Attention</h5>
            <span className='fs-6 text-danger pe-7'>
              Based on the revenue figure you entered, this company does not meet the criteria to be
              classified as an SME for FINTEL SME. Therefore, you cannot proceed to use FINTEL SME
              in this case. If you have any questions or need further assistance, please feel free
              to reach out to us at &nbsp;
              <a href='mailto:data@marc.com.my?subject=Query%20on%20SME%20Classification'>
                data@marc.com.my
              </a>
              .
            </span>
          </div>
        </div>
      </div>

      <div
        className={clsx('alert-message-wrapper', {
          'visually-hidden': ComputeForm.companyInformation.status_c !== 'created',
        })}
      >
        <div className='alert d-flex bg-light-danger rounded border-danger border border-dashed p-6'>
          <KTIcon iconName='shield-cross' className='fs-2tx text-danger me-4' />
          <div className='d-flex flex-column text-light pe-0 pe-sm-10'>
            <h5 className='mb-1 text-danger'>Attention</h5>
            <span className='fs-6 text-danger pe-7'>
              This system does not generate results for Corporate companies. Please provide details
              for SME to ensure accurate processing.
            </span>
            <div className='separator border-danger my-5'></div>
            <span className='fs-6 text-gray-700 pe-7'>Classification for SME Companies</span>
            <div className='border border-gray-600 text-gray-700 my-3 p-3'>
              Manufacturing sector: Total revenue not exceeding RM50 million <br />
              Other sectors: Total revenue not exceeding RM20 million
            </div>
            <span className='fs-6 text-gray-700 pe-7 mb-2'>
              If a company exceeds the total revenue threshold above for 2 consecutive years (based
              on its financial year) then it can no longer be deemed as SME. Similarly, a company
              that is initially Corporate can become SME if it fulfills the above criteria for 2
              consecutive years.
            </span>
            <span className='fs-6 text-gray-700 pe-7'>
              FINTEL SME's classification criteria are solely based on total revenue and do not
              consider the number of employees within the company.
            </span>
          </div>
        </div>
      </div>

      {/*COMPANY INFORMATION TAB*/}
      <div className='card mb-5 mb-xl-10'>
        <div
          className='card-header border-0 cursor-pointer'
          role='button'
          data-bs-toggle='collapse'
          data-bs-target='#company_information_tab'
        >
          <div className='card-title m-0'>
            <h3 className='fw-bolder m-0'>Company Information</h3>
          </div>
        </div>

        <div id='company_information_tab' className='collapse show'>
          <div className='card-body border-top p-9'>
            {/* <LineInput
              label='Financial Year'
              type='number'
              param1='companyInformation'
              param2='financial_year'
              value={ComputeForm}
              onchange={(e, p1, p2) => handleChange(e, p1, p2)}
            /> */}
            <LineInput
              label='Company Name'
              type='text'
              param1='companyInformation'
              param2='company_name'
              value={ComputeForm}
              required={true}
              onchange={(e, p1, p2) => handleChange(e, p1, p2)}
            />
            <LineInput
              label='Registration Number'
              type='text'
              param1='companyInformation'
              param2='register_number'
              value={ComputeForm}
              required={true}
              onchange={(e, p1, p2) => handleChange(e, p1, p2)}
            />
            <LineInput
              label='Incorporate Year'
              type='text'
              param1='companyInformation'
              param2='incorporate_year'
              value={ComputeForm}
              onchange={(e, p1, p2) => handleChange(e, p1, p2)}
            />
            <LineSelect
              label='Bumiputera Status'
              param1='companyInformation'
              param2='bumiputera_status'
              value={ComputeForm}
              required={true}
              onchange={(e, p1, p2) => handleChange(e, p1, p2)}
              options={Resource?.BumiputeraStatus!}
            />
            <LineSelect
              label='Business Type'
              param1='companyInformation'
              param2='business_type'
              value={ComputeForm}
              required={true}
              onchange={(e, p1, p2) => handleChange(e, p1, p2)}
              options={Resource?.BusinessType!}
            />
            <LineSelect
              label='Company Classification'
              param1='companyInformation'
              param2='company_classification'
              value={ComputeForm}
              required={true}
              onchange={(e, p1, p2) => handleChange(e, p1, p2)}
              options={[{ name: 'SME' }, { name: 'Corporate' }]}
            />
          </div>
        </div>
      </div>

      {/*COMPANY NON FINANCIAL ANALYSIS TAB*/}
      <div className='card mb-5 mb-xl-10'>
        <div
          className='card-header border-0 cursor-pointer'
          role='button'
          data-bs-toggle='collapse'
          data-bs-target='#non_financial_analysis_tab'
        >
          <div className='card-title m-0'>
            <h3 className='fw-bolder m-0'>Non-Financial Analysis</h3>
          </div>
        </div>

        <div id='non_financial_analysis_tab' className='collapse show'>
          <div className='card-body border-top p-9'>
            {/*   companyNonFinancialAnalysis: {
                      litigation_legal: '',
                      management_experience: '',
                      years_in_current_business: '',
                      accounting_firm: '',
            }, */}
            <LineSelect
              label='Litigation/Legal Status'
              param1='companyNonFinancialAnalysis'
              param2='litigation_legal'
              value={ComputeForm}
              required
              onchange={(e, p1, p2) => handleChange(e, p1, p2)}
              options={Matrix?.Litigation!}
              tooltip='Does the company currently have any outstanding lawsuits or legal disputes in which it is named as a defendant?'
            />
            <LineSelect
              label='Management Experience'
              param1='companyNonFinancialAnalysis'
              param2='management_experience'
              value={ComputeForm}
              required
              onchange={(e, p1, p2) => handleChange(e, p1, p2)}
              options={Matrix?.ManagementExperience!}
              tooltip='How many years of experience does the key individual in the company possess? This individual, often holding the highest seniority and authority such as the CEO or Director, is pivotal in decision-making and strategic direction. Please note that this total reflects the experience of a single person, not a collective sum.'
            />
            <LineSelect
              label='Years in Current Business'
              param1='companyNonFinancialAnalysis'
              param2='years_in_current_business'
              value={ComputeForm}
              required
              onchange={(e, p1, p2) => handleChange(e, p1, p2)}
              options={Matrix?.YearsInCurrentBusiness!}
              tooltip='How many years has the company been in operation?'
            />
            <LineSelect
              label='Accounting Firm'
              param1='companyNonFinancialAnalysis'
              param2='accounting_firm'
              value={ComputeForm}
              required
              onchange={(e, p1, p2) => handleChange(e, p1, p2)}
              options={Matrix?.AccountingFirm!}
              tooltip='Which auditing firm did the company appoint during the financial year?'
            />
          </div>
        </div>
      </div>

      {/*COMPANY FINANCIAL ANALYSIS TAB*/}
      <div className='card mb-5 mb-xl-10'>
        <div
          className='card-header border-0 cursor-pointer'
          role='button'
          data-bs-toggle='collapse'
          data-bs-target='#company_financial_analysis_tab'
        >
          <div className='card-title m-0'>
            <h3 className='fw-bolder m-0'>Financial Analysis</h3>
          </div>
        </div>

        <div id='company_financial_analysis_tab' className='collapse show'>
          <div className='card-body border-top pt-0'>
            <div className='card card-custom shadow'>
              <div className='card-body pt-0'>
                <div className='row' id='myTabContent'>
                  <div className='d-flex justify-content-between align-items-start flex-wrap mb-2'>
                    <div className='d-flex my-4'></div>

                    <div className='d-flex my-4'>
                      <OverlayTrigger
                        placement='left'
                        overlay={
                          <Tooltip id='tooltip-left' className='fs-8'>
                            Click to add more financial years
                          </Tooltip>
                        }
                      >
                        <span
                          className={clsx('mt-3 mx-3', {
                            'visually-hidden': ComputeForm.companyFinancialAnalysis!.length > 2,
                          })}
                        >
                          <KTIcon iconType='solid' iconName='question-2' className='fs-3' />
                        </span>
                      </OverlayTrigger>
                      {/* Add Year Button */}

                      <button
                        className={clsx('btn btn-success btn-sm', {
                          'visually-hidden': ComputeForm.companyFinancialAnalysis!.length > 2,
                        })}
                        onClick={() => { handleConfirm(); handleSubmit(); }}

                      >
                        <i className='ki-duotone ki-plus fs-4'></i>
                      </button>
                    </div>
                  </div>
                  <div className='row' style={{ overflow: 'auto' }}>
                    <TransposeTable>
                      {ComputeForm.companyFinancialAnalysis?.map((companyFA, i) => (
                        <tr
                          className={clsx('cell-offset transpose-table', { 'w-500px': i === 0 })}
                          key={i}
                        >
                          <td>
                            <div className='row h-50px'>
                              <div className='col-lg-6'></div>
                              <div className='col-lg-6'>
                                <div
                                  className={clsx('alert-message-wrapper', {
                                    'visually-hidden': i !== 0,
                                  })}
                                >
                                  <div className='alert d-flex bg-light-success rounded border-success border border-solid p-2'>
                                    <div className='d-flex flex-column text-light'>
                                      <span className='fs-8 text-success'>
                                        <KTIcon
                                          iconName='information-5'
                                          className='text-success me-2'
                                        />
                                        Insert latest financial year here
                                      </span>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </td>
                          <TransposeLineInput
                            label='Financial Year'
                            type='text'
                            param1='financial_year_c'
                            value={companyFA}
                            list={i}
                            disabled
                            onchange={(d, p1) => handleYearlyDataChange(d, i, p1)}
                            tooltip={autoGenTooltip}
                          />
                          <TransposeLineDate
                            label='Start Date'
                            param1='fy_start_date'
                            value={companyFA}
                            list={i}
                            required
                            onchange={(d, p1) => handleYearlyDataChange(d, i, p1)}
                          />
                          <TransposeLineDate
                            label='End Date'
                            param1='fy_end_date'
                            value={companyFA}
                            list={i}
                            required
                            onchange={(d, p1) => handleYearlyDataChange(d, i, p1)}
                          />
                          <TransposeLineInput
                            label='Number of Months'
                            type='number'
                            param1='number_of_months_c'
                            value={companyFA}
                            list={i}
                            disabled
                            onchange={(e, p1) => handleYearlyDataChange(e, i, p1)}
                            tooltip={autoGenTooltip}
                          />
                        </tr>
                      ))}
                    </TransposeTable>
                  </div>

                  {/*INCOME STATEMENT*/}
                  <div className='income-statement-wrapper border-default border p-6 mb-6'>
                    <div className='d-flex justify-content-between align-items-start flex-wrap mb-2'>
                      <div className='d-flex my-4'>
                        <KTIcon
                          iconName='questionnaire-tablet'
                          className='fs-2tx text-primary me-4'
                        />
                        <div className='d-flex flex-column'>
                          <h3 className='mb-1'>Consolidated Income Statement</h3>
                          <div className='fs-6 fw-bold text-gray-400'>
                            Data required for accuracy of results.
                          </div>
                        </div>
                      </div>
                      <div className='d-flex my-4'>
                        <ReactToPrint
                          documentTitle='SME Model Result'
                          bodyClass='print-black-font'
                          content={() => consolidated_income_statement_ref.current}
                          trigger={() => (
                            <PrintButton type='button' className='btn btn-primary btn-sm'>
                              <KTIcon
                                iconName='printer'
                                iconType='outline'
                                className='fs-3 text-default'
                              />
                            </PrintButton>
                          )}
                          onBeforeGetContent={() => handleBeforeGetContent()}
                        />
                      </div>
                    </div>

                    <div className='row' style={{ overflow: 'auto' }}>
                      <TransposeTable>
                        {ComputeForm.companyFinancialAnalysis?.map((companyFA, i) => (
                          <tr
                            className={clsx('cell-offset transpose-table', { 'w-500px': i === 0 })}
                            key={i}
                          >
                            <TransposeLineInput
                              label='Revenue'
                              type='number'
                              param1='incomeStatement'
                              param2='revenue'
                              value={companyFA}
                              onchange={(e, p1, p2, p3) => handleYearlyDataChange(e, i, p1, p2, p3)}
                              list={i}
                            />
                            <TransposeLineInput
                              label='Cost of Sales'
                              type='number'
                              param1='incomeStatement'
                              param2='cost_of_sales'
                              value={companyFA}
                              onchange={(e, p1, p2) => handleYearlyDataChange(e, i, p1, p2)}
                              list={i}
                            />
                            <TransposeLineInput
                              label='Gross Profit'
                              type='number'
                              param1='incomeStatement'
                              param2='is_gross_profit_c'
                              value={companyFA}
                              disabled
                              onchange={(e, p1, p2) => handleYearlyDataChange(e, i, p1, p2)}
                              list={i}
                              tooltip={autoGenTooltip}
                            />
                            <TransposeLineInput
                              label='SGA Expenses'
                              type='number'
                              param1='incomeStatement'
                              param2='sga_expenses'
                              value={companyFA}
                              onchange={(e, p1, p2) => handleYearlyDataChange(e, i, p1, p2)}
                              list={i}
                            />
                            <TransposeLineInput
                              label='Depreciation & Amortization'
                              type='number'
                              param1='incomeStatement'
                              param2='is_depreciation_amortization'
                              value={companyFA}
                              onchange={(e, p1, p2) => handleYearlyDataChange(e, i, p1, p2)}
                              list={i}
                            />
                            <TransposeLineInput
                              label='Other Income'
                              type='number'
                              param1='incomeStatement'
                              param2='other_income'
                              value={companyFA}
                              onchange={(e, p1, p2) => handleYearlyDataChange(e, i, p1, p2)}
                              list={i}
                            />
                            <TransposeLineInput
                              label='Other Expenses'
                              type='number'
                              param1='incomeStatement'
                              param2='other_expenses'
                              value={companyFA}
                              onchange={(e, p1, p2) => handleYearlyDataChange(e, i, p1, p2)}
                              list={i}
                            />
                            <TransposeLineInput
                              label='EBIT'
                              type='number'
                              param1='incomeStatement'
                              param2='ebit_c'
                              value={companyFA}
                              disabled
                              onchange={(e, p1, p2) => handleYearlyDataChange(e, i, p1, p2)}
                              list={i}
                              tooltip={autoGenTooltip}
                            />
                            <TransposeLineInput
                              label='Interest Income/(Expense), net'
                              type='number'
                              param1='incomeStatement'
                              param2='interest_expense'
                              value={companyFA}
                              onchange={(e, p1, p2) => handleYearlyDataChange(e, i, p1, p2)}
                              list={i}
                              tooltip='Enter negative sign if it is net interest expense'
                            />
                            <TransposeLineInput
                              label='EBT'
                              type='number'
                              param1='incomeStatement'
                              param2='ebt_c'
                              value={companyFA}
                              disabled
                              onchange={(e, p1, p2) => handleYearlyDataChange(e, i, p1, p2)}
                              list={i}
                              tooltip={autoGenTooltip}
                            />
                            <TransposeLineInput
                              label='Tax Income/(Expense), net'
                              type='number'
                              param1='incomeStatement'
                              param2='tax_expense'
                              value={companyFA}
                              onchange={(e, p1, p2) => handleYearlyDataChange(e, i, p1, p2)}
                              list={i}
                              tooltip='Enter negative sign for net tax expense'
                            />
                            <TransposeLineInput
                              label='Net Profit/(Loss)'
                              type='number'
                              param1='incomeStatement'
                              param2='net_profit_c'
                              value={companyFA}
                              disabled
                              onchange={(e, p1, p2) => handleYearlyDataChange(e, i, p1, p2)}
                              list={i}
                              tooltip={autoGenTooltip}
                            />
                            <TransposeLineInput
                              label='Other Comprehensive Income'
                              type='number'
                              param1='incomeStatement'
                              param2='other_comprehensive_income'
                              value={companyFA}
                              onchange={(e, p1, p2) => handleYearlyDataChange(e, i, p1, p2)}
                              list={i}
                              tooltip='Enter negative sign for net OCL'
                            />
                            <TransposeLineInput
                              label='Total Comprehensive Income/(Expense)'
                              type='number'
                              param1='incomeStatement'
                              param2='total_comprehensive_income_c'
                              value={companyFA}
                              disabled
                              onchange={(e, p1, p2) => handleYearlyDataChange(e, i, p1, p2)}
                              list={i}
                              long
                              tooltip={autoGenTooltip}
                            />
                          </tr>
                        ))}
                      </TransposeTable>
                    </div>
                    {/* Printable Consolidated Income Statement */}
                    <div className='row'>
                      <div
                        ref={consolidated_income_statement_ref}
                        className='print-only card-body p-9'
                      >
                        <AFSPrintingHeader
                          Title='Consolidated Income Statement'
                          ComputeForm={ComputeForm}
                          CurrentTime={CurrentTime}
                        />
                        <div className='row mb-2'>
                          <table className='transpose-table'>
                            <tbody>
                              <tr className='min-w-50'>
                                <FSTableDataHead className='white-text-on-blue-bg fw-bold text-white'>
                                  &nbsp;
                                </FSTableDataHead>
                                <FSTableDataHead className='fw-bold cell-highlight'>
                                  Revenue
                                </FSTableDataHead>
                                <FSTableDataHead>Cost of sales</FSTableDataHead>
                                <TableEqualLine lineType='single' offset />
                                <FSTableDataHead className='fw-bold cell-highlight'>
                                  Gross profit
                                </FSTableDataHead>
                                <FSTableDataHead>
                                  Selling, general and administrative expense
                                </FSTableDataHead>
                                <FSTableDataHead>Depreciation and amortization</FSTableDataHead>
                                <FSTableDataHead>Other income</FSTableDataHead>
                                <FSTableDataHead>Other expenses</FSTableDataHead>
                                <TableEqualLine lineType='single' offset />
                                <FSTableDataHead className='fw-bold cell-highlight'>
                                  EBIT
                                </FSTableDataHead>
                                <FSTableDataHead>Interest income/(expense), net</FSTableDataHead>
                                <TableEqualLine lineType='single' offset />
                                <FSTableDataHead className='fw-bold cell-highlight'>
                                  EBT
                                </FSTableDataHead>
                                <FSTableDataHead>Tax income/(expense), net</FSTableDataHead>
                                <TableEqualLine lineType='single' offset />
                                <FSTableDataHead className='fw-bold cell-highlight'>
                                  Net Profit/(Loss)
                                </FSTableDataHead>
                                <FSTableDataHead>Other comprehensive</FSTableDataHead>
                                <TableEqualLine lineType='single' offset />
                                <FSTableDataHead className='fw-bold cell-highlight'>
                                  Total Comprehensive Income/(Expense)
                                </FSTableDataHead>
                              </tr>
                              {ComputeForm.companyFinancialAnalysis?.map((companyFA, i) => (
                                <tr style={{ minWidth: '15%' }} key={i}>
                                  <FSTableData
                                    className='white-text-on-blue-bg fw-bold text-white'
                                    style={{
                                      backgroundColor: '#36486b',
                                    }}
                                  >
                                    {companyFA.financial_year_c}
                                  </FSTableData>
                                  <FSTableData className='fw-bold cell-highlight'>
                                    <LineViewer
                                      param1='incomeStatement'
                                      param2='revenue'
                                      value={companyFA}
                                    />
                                  </FSTableData>

                                  <FSTableData>
                                    <LineViewer
                                      param1='incomeStatement'
                                      param2='cost_of_sales'
                                      value={companyFA}
                                    />
                                  </FSTableData>
                                  <TableEqualLine lineType='single' />
                                  <FSTableData className='fw-bold cell-highlight'>
                                    <LineViewer
                                      param1='incomeStatement'
                                      param2='is_gross_profit_c'
                                      value={companyFA}
                                    />
                                  </FSTableData>

                                  <FSTableData>
                                    <LineViewer
                                      param1='incomeStatement'
                                      param2='sga_expenses'
                                      value={companyFA}
                                    />
                                  </FSTableData>

                                  <FSTableData>
                                    <LineViewer
                                      param1='incomeStatement'
                                      param2='is_depreciation_amortization'
                                      value={companyFA}
                                    />
                                  </FSTableData>

                                  <FSTableData>
                                    <LineViewer
                                      param1='incomeStatement'
                                      param2='other_income'
                                      value={companyFA}
                                    />
                                  </FSTableData>

                                  <FSTableData>
                                    <LineViewer
                                      param1='incomeStatement'
                                      param2='other_expenses'
                                      value={companyFA}
                                    />
                                  </FSTableData>
                                  <TableEqualLine lineType='single' />
                                  <FSTableData className='fw-bold cell-highlight'>
                                    <LineViewer
                                      param1='incomeStatement'
                                      param2='ebit_c'
                                      value={companyFA}
                                    />
                                  </FSTableData>

                                  <FSTableData>
                                    <LineViewer
                                      param1='incomeStatement'
                                      param2='interest_expense'
                                      value={companyFA}
                                    />
                                  </FSTableData>
                                  <TableEqualLine lineType='single' />
                                  <FSTableData className='fw-bold cell-highlight'>
                                    <LineViewer
                                      param1='incomeStatement'
                                      param2='ebt_c'
                                      value={companyFA}
                                    />
                                  </FSTableData>

                                  <FSTableData>
                                    <LineViewer
                                      param1='incomeStatement'
                                      param2='tax_expense'
                                      value={companyFA}
                                    />
                                  </FSTableData>
                                  <TableEqualLine lineType='single' />
                                  <FSTableData className='fw-bold cell-highlight'>
                                    <LineViewer
                                      param1='incomeStatement'
                                      param2='net_profit_c'
                                      value={companyFA}
                                    />
                                  </FSTableData>

                                  <FSTableData>
                                    <LineViewer
                                      param1='incomeStatement'
                                      param2='other_comprehensive_income'
                                      value={companyFA}
                                    />
                                  </FSTableData>
                                  <TableEqualLine lineType='single' />
                                  <FSTableData className='fw-bold cell-highlight'>
                                    <LineViewer
                                      param1='incomeStatement'
                                      param2='total_comprehensive_income_c'
                                      value={companyFA}
                                    />
                                  </FSTableData>
                                </tr>
                              ))}
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>
                  </div>

                  {/*CASH FLOW ANALYSIS*/}
                  <div className='cash-flow-analysis-wrapper border-default border p-6 mb-6'>
                    <div className='d-flex justify-content-between align-items-start flex-wrap mb-2'>
                      <div className='d-flex'>
                        <KTIcon
                          iconName='questionnaire-tablet'
                          className='fs-2tx text-primary me-4'
                        />
                        <div className='d-flex flex-column'>
                          <h3 className='mb-1'>Cash Flow Statement</h3>
                          <div className='fs-6 fw-bold text-gray-400'>
                            Data required for accuracy of results.
                          </div>
                        </div>
                      </div>
                      <div className='d-flex my-4'>
                        <ReactToPrint
                          documentTitle='SME Model Result'
                          bodyClass='print-black-font'
                          content={() => cash_flow_statement_ref.current}
                          trigger={() => (
                            <PrintButton type='button' className='btn btn-primary btn-sm'>
                              <KTIcon
                                iconName='printer'
                                iconType='outline'
                                className='fs-3 text-default'
                              />
                            </PrintButton>
                          )}
                          onBeforeGetContent={() => handleBeforeGetContent()}
                        />
                      </div>
                    </div>
                    <div className='row' style={{ overflow: 'auto' }}>
                      <TransposeTable>
                        {ComputeForm.companyFinancialAnalysis?.map((companyFA, i) => (
                          <tr
                            className={clsx('cell-offset transpose-table', { 'w-500px': i === 0 })}
                            key={i}
                          >
                            <TransposeLineInput
                              label='Profit/(Loss) Before Taxation'
                              type='number'
                              param1='cashFlowAnalysis'
                              param2='profit_before_taxation_c'
                              value={companyFA}
                              disabled
                              onchange={(e, p1, p2) => handleYearlyDataChange(e, i, p1, p2)}
                              list={i}
                              tooltip={autoGenTooltip}
                            />
                          </tr>
                        ))}
                      </TransposeTable>
                    </div>
                    {/*ADJUSTMENT*/}
                    <div className='adjustments-wrapper border-default border p-6'>
                      <div className='d-flex'>
                        <KTIcon
                          iconName='questionnaire-tablet'
                          className='fs-2tx text-primary me-4'
                        />
                        <div className='d-flex flex-column'>
                          <h3 className='mb-1'>Adjustments</h3>
                          <div className='fs-6 fw-bold text-gray-400'>
                            Data required for accuracy of results.
                          </div>
                        </div>
                      </div>
                      <div className='row' style={{ overflow: 'auto' }}>
                        <TransposeTable>
                          {ComputeForm.companyFinancialAnalysis?.map((companyFA, i) => (
                            <tr
                              className={clsx('cell-offset transpose-table', { 'w-500px': i === 0 })}
                              key={i}
                            >
                              <TransposeLineInput
                                label='Depreciation & Amortization'
                                type='number'
                                param1='cashFlowAnalysis'
                                param2='adjustments'
                                param3='cf_depreciation_amortization'
                                value={companyFA}
                                list={i}
                                onchange={(e, p1, p2, p3) =>
                                  handleYearlyDataChange(e, i, p1, p2, p3)
                                }
                              />
                              <TransposeLineInput
                                label='Interest Expense/(Income)'
                                type='number'
                                param1='cashFlowAnalysis'
                                param2='adjustments'
                                param3='interest_expense_income'
                                value={companyFA}
                                list={i}
                                onchange={(e, p1, p2, p3) =>
                                  handleYearlyDataChange(e, i, p1, p2, p3)
                                }
                              />
                              <TransposeLineInput
                                label='Dividend Income'
                                type='number'
                                param1='cashFlowAnalysis'
                                param2='adjustments'
                                param3='dividend_income'
                                value={companyFA}
                                list={i}
                                onchange={(e, p1, p2, p3) =>
                                  handleYearlyDataChange(e, i, p1, p2, p3)
                                }
                              />
                              <TransposeLineInput
                                label='Other Adjustments'
                                type='number'
                                param1='cashFlowAnalysis'
                                param2='adjustments'
                                param3='other_adjustments'
                                value={companyFA}
                                list={i}
                                onchange={(e, p1, p2, p3) =>
                                  handleYearlyDataChange(e, i, p1, p2, p3)
                                }
                              />
                            </tr>
                          ))}
                        </TransposeTable>
                      </div>
                    </div>
                    <div className='row' style={{ overflow: 'auto' }}>
                      <TransposeTable>
                        {ComputeForm.companyFinancialAnalysis?.map((companyFA, i) => (
                          <tr
                            className={clsx('cell-offset transpose-table', { 'w-500px': i === 0 })}
                            key={i}
                          >
                            <TransposeLineInput
                              label='Operating Profit/(Loss) Before Working Capital Changes'
                              type='number'
                              param1='cashFlowAnalysis'
                              param2='operating_profit_before_wc_changes_c'
                              value={companyFA}
                              list={i}
                              long
                              disabled
                              onchange={(e, p1, p2) => handleYearlyDataChange(e, i, p1, p2)}
                              tooltip={autoGenTooltip}
                            />
                          </tr>
                        ))}
                      </TransposeTable>
                    </div>
                    {/*WORKING CAPITAL CHANGES*/}
                    <div className='working-capital-changes-wrapper border-default border p-6'>
                      <div className='d-flex'>
                        <KTIcon
                          iconName='questionnaire-tablet'
                          className='fs-2tx text-primary me-4'
                        />
                        <div className='d-flex flex-column'>
                          <h3 className='mb-1'>Working Capital Changes</h3>
                          <div className='fs-6 fw-bold text-gray-400'>
                            Data required for accuracy of results.
                          </div>
                        </div>
                      </div>
                      <div className='row' style={{ overflow: 'auto' }}>
                        <TransposeTable>
                          {ComputeForm.companyFinancialAnalysis?.map((companyFA, i) => (
                            <tr
                              className={clsx('cell-offset transpose-table', { 'w-500px': i === 0 })}
                              key={i}
                            >
                              <TransposeLineInput
                                label='Inventories'
                                type='number'
                                param1='cashFlowAnalysis'
                                param2='working_capital_changes'
                                param3='cf_inventories'
                                value={companyFA}
                                list={i}
                                onchange={(e, p1, p2, p3) =>
                                  handleYearlyDataChange(e, i, p1, p2, p3)
                                }
                              />
                              <TransposeLineInput
                                label='Trade Receivables'
                                type='number'
                                param1='cashFlowAnalysis'
                                param2='working_capital_changes'
                                param3='cf_trade_receivables'
                                value={companyFA}
                                list={i}
                                onchange={(e, p1, p2, p3) =>
                                  handleYearlyDataChange(e, i, p1, p2, p3)
                                }
                              />
                              <TransposeLineInput
                                label='Other Receivables & Prepayments'
                                type='number'
                                param1='cashFlowAnalysis'
                                param2='working_capital_changes'
                                param3='cf_other_receivables_prepayment'
                                value={companyFA}
                                list={i}
                                onchange={(e, p1, p2, p3) =>
                                  handleYearlyDataChange(e, i, p1, p2, p3)
                                }
                              />
                              <TransposeLineInput
                                label='Trade Payables'
                                type='number'
                                param1='cashFlowAnalysis'
                                param2='working_capital_changes'
                                param3='cf_trade_payables'
                                value={companyFA}
                                list={i}
                                onchange={(e, p1, p2, p3) =>
                                  handleYearlyDataChange(e, i, p1, p2, p3)
                                }
                              />
                              <TransposeLineInput
                                label='Other Payables & Accruals'
                                type='number'
                                param1='cashFlowAnalysis'
                                param2='working_capital_changes'
                                param3='cf_trade_payables_accruals'
                                value={companyFA}
                                list={i}
                                onchange={(e, p1, p2, p3) =>
                                  handleYearlyDataChange(e, i, p1, p2, p3)
                                }
                              />
                              <TransposeLineInput
                                label='Amount Due to Directors/Related Parties'
                                type='number'
                                param1='cashFlowAnalysis'
                                param2='working_capital_changes'
                                param3='cf_amount_due_to_directors_related_parties'
                                value={companyFA}
                                list={i}
                                long
                                onchange={(e, p1, p2, p3) =>
                                  handleYearlyDataChange(e, i, p1, p2, p3)
                                }
                              />
                              <TransposeLineInput
                                label='Other Changes in Working Capital'
                                type='number'
                                param1='cashFlowAnalysis'
                                param2='working_capital_changes'
                                param3='other_changes_working_capital'
                                value={companyFA}
                                list={i}
                                onchange={(e, p1, p2, p3) =>
                                  handleYearlyDataChange(e, i, p1, p2, p3)
                                }
                              />
                            </tr>
                          ))}
                        </TransposeTable>
                      </div>
                    </div>
                    <div className='row' style={{ overflow: 'auto' }}>
                      <TransposeTable>
                        {ComputeForm.companyFinancialAnalysis?.map((companyFA, i) => (
                          <tr
                            className={clsx('cell-offset transpose-table', { 'w-500px': i === 0 })}
                            key={i}
                          >
                            <TransposeLineInput
                              label='Cash Generated From/(Used in) Operating Activities'
                              type='number'
                              param1='cashFlowAnalysis'
                              param2='cash_generated_used_operating_activities_c'
                              value={companyFA}
                              list={i}
                              long
                              disabled
                              onchange={(e, p1, p2) => handleYearlyDataChange(e, i, p1, p2)}
                              tooltip={autoGenTooltip}
                            />
                            <TransposeLineInput
                              label='Tax (Paid)/Refund'
                              type='number'
                              param1='cashFlowAnalysis'
                              param2='tax_paid_refund'
                              value={companyFA}
                              list={i}
                              onchange={(e, p1, p2) => handleYearlyDataChange(e, i, p1, p2)}
                            />
                            <TransposeLineInput
                              label='Other Operating Activities'
                              type='number'
                              param1='cashFlowAnalysis'
                              param2='other_operating_activities'
                              value={companyFA}
                              list={i}
                              onchange={(e, p1, p2) => handleYearlyDataChange(e, i, p1, p2)}
                            />
                            <TransposeLineInput
                              label='Net Cash Generated From/(Used in) Operating Activities'
                              type='number'
                              param1='cashFlowAnalysis'
                              param2='net_cash_generated_used_operating_activities_c'
                              value={companyFA}
                              list={i}
                              long
                              disabled
                              onchange={(e, p1, p2) => handleYearlyDataChange(e, i, p1, p2)}
                              tooltip={autoGenTooltip}
                            />
                          </tr>
                        ))}
                      </TransposeTable>
                    </div>
                    {/*INVESTING ACTIVITIES*/}
                    <div className='investing-activities-wrapper border-default border p-6'>
                      <div className='d-flex'>
                        <KTIcon
                          iconName='questionnaire-tablet'
                          className='fs-2tx text-primary me-4'
                        />
                        <div className='d-flex flex-column'>
                          <h3 className='mb-1'>Cash Flow from Investing Activities</h3>
                          <div className='fs-6 fw-bold text-gray-400'>
                            Data required for accuracy of results.
                          </div>
                        </div>
                      </div>
                      <div className='row' style={{ overflow: 'auto' }}>
                        <TransposeTable>
                          {ComputeForm.companyFinancialAnalysis?.map((companyFA, i) => (
                            <tr
                              className={clsx('cell-offset transpose-table', { 'w-500px': i === 0 })}
                              key={i}
                            >
                              <TransposeLineInput
                                label='Disposal/(Acquisition) of PPE'
                                type='number'
                                param1='cashFlowAnalysis'
                                param2='investingActivities'
                                param3='disposal_acquisition_ppe'
                                value={companyFA}
                                list={i}
                                onchange={(e, p1, p2, p3) =>
                                  handleYearlyDataChange(e, i, p1, p2, p3)
                                }
                              />
                              <TransposeLineInput
                                label='Disposal/(Acquisition) of Equity and Debt'
                                type='number'
                                param1='cashFlowAnalysis'
                                param2='investingActivities'
                                param3='disposal_acquisition_equity_debt'
                                value={companyFA}
                                list={i}
                                long
                                onchange={(e, p1, p2, p3) =>
                                  handleYearlyDataChange(e, i, p1, p2, p3)
                                }
                              />
                              <TransposeLineInput
                                label='Dividends Received'
                                type='number'
                                param1='cashFlowAnalysis'
                                param2='investingActivities'
                                param3='dividends_received'
                                value={companyFA}
                                list={i}
                                onchange={(e, p1, p2, p3) =>
                                  handleYearlyDataChange(e, i, p1, p2, p3)
                                }
                              />
                              <TransposeLineInput
                                label='Interest Received'
                                type='number'
                                param1='cashFlowAnalysis'
                                param2='investingActivities'
                                param3='interest_received'
                                value={companyFA}
                                list={i}
                                onchange={(e, p1, p2, p3) =>
                                  handleYearlyDataChange(e, i, p1, p2, p3)
                                }
                              />
                              <TransposeLineInput
                                label='Other Investing Activities'
                                type='number'
                                param1='cashFlowAnalysis'
                                param2='investingActivities'
                                param3='other_investing_activities'
                                value={companyFA}
                                list={i}
                                onchange={(e, p1, p2, p3) =>
                                  handleYearlyDataChange(e, i, p1, p2, p3)
                                }
                              />
                              <TransposeLineInput
                                label='Net Cash Generated From/(Used in) Investing Activities'
                                type='number'
                                param1='cashFlowAnalysis'
                                param2='investingActivities'
                                param3='net_cash_generated_used_investing_activities_c'
                                value={companyFA}
                                list={i}
                                long
                                disabled
                                onchange={(e, p1, p2, p3) =>
                                  handleYearlyDataChange(e, i, p1, p2, p3)
                                }
                                tooltip={autoGenTooltip}
                              />
                            </tr>
                          ))}
                        </TransposeTable>
                      </div>
                    </div>

                    {/*FINANCING ACTIVITIES*/}
                    <div className='financing-activities-wrapper border-default border p-6'>
                      <div className='d-flex'>
                        <KTIcon
                          iconName='questionnaire-tablet'
                          className='fs-2tx text-primary me-4'
                        />
                        <div className='d-flex flex-column'>
                          <h3 className='mb-1'>Cash Flow from Financing Activities</h3>
                          <div className='fs-6 fw-bold text-gray-400'>
                            Data required for accuracy of results.
                          </div>
                        </div>
                      </div>
                      <div className='row' style={{ overflow: 'auto' }}>
                        <TransposeTable>
                          {ComputeForm.companyFinancialAnalysis?.map((companyFA, i) => (
                            <tr
                              className={clsx('cell-offset transpose-table', { 'w-500px': i === 0 })}
                              key={i}
                            >
                              <TransposeLineInput
                                label='Issuance/(Repurchase) of Shares'
                                type='number'
                                param1='cashFlowAnalysis'
                                param2='financingActivities'
                                param3='issuance_repurchase_shares'
                                value={companyFA}
                                list={i}
                                onchange={(e, p1, p2, p3) =>
                                  handleYearlyDataChange(e, i, p1, p2, p3)
                                }
                              />
                              <TransposeLineInput
                                label='Issuance/(Repayment) of Debt'
                                type='number'
                                param1='cashFlowAnalysis'
                                param2='financingActivities'
                                param3='issuance_repayment_debt'
                                value={companyFA}
                                list={i}
                                onchange={(e, p1, p2, p3) =>
                                  handleYearlyDataChange(e, i, p1, p2, p3)
                                }
                              />
                              {/* interest_paid
                              dividends_paid */}
                              <TransposeLineInput
                                label='Dividends Paid'
                                type='number'
                                param1='cashFlowAnalysis'
                                param2='financingActivities'
                                param3='dividends_paid'
                                value={companyFA}
                                list={i}
                                onchange={(e, p1, p2, p3) =>
                                  handleYearlyDataChange(e, i, p1, p2, p3)
                                }
                              />
                              <TransposeLineInput
                                label='Interest Paid'
                                type='number'
                                param1='cashFlowAnalysis'
                                param2='financingActivities'
                                param3='interest_paid'
                                value={companyFA}
                                list={i}
                                onchange={(e, p1, p2, p3) =>
                                  handleYearlyDataChange(e, i, p1, p2, p3)
                                }
                              />
                              <TransposeLineInput
                                label='Other Financing Activities'
                                type='number'
                                param1='cashFlowAnalysis'
                                param2='financingActivities'
                                param3='other_financing_activities'
                                value={companyFA}
                                list={i}
                                onchange={(e, p1, p2, p3) =>
                                  handleYearlyDataChange(e, i, p1, p2, p3)
                                }
                              />
                              <TransposeLineInput
                                label='Net Cash Generated From/(Used in) Financing Activities'
                                type='number'
                                param1='cashFlowAnalysis'
                                param2='financingActivities'
                                param3='net_cash_generated_used_financing_activities_c'
                                value={companyFA}
                                list={i}
                                long
                                disabled
                                onchange={(e, p1, p2, p3) =>
                                  handleYearlyDataChange(e, i, p1, p2, p3)
                                }
                                tooltip={autoGenTooltip}
                              />
                            </tr>
                          ))}
                        </TransposeTable>
                      </div>
                    </div>
                    <div className='row' style={{ overflow: 'auto' }}>
                      <TransposeTable>
                        {ComputeForm.companyFinancialAnalysis?.map((companyFA, i) => (
                          <tr
                            className={clsx('cell-offset transpose-table', { 'w-500px': i === 0 })}
                            key={i}
                          >
                            <TransposeLineInput
                              label='Net Increase/(Decrease) in Cash and Cash Equivalents'
                              type='number'
                              param1='cashFlowAnalysis'
                              param2='net_cce_change_c'
                              value={companyFA}
                              list={i}
                              long
                              disabled
                              onchange={(e, p1, p2) => handleYearlyDataChange(e, i, p1, p2)}
                              tooltip={autoGenTooltip}
                            />
                            <TransposeLineInput
                              label='Other Adjustments to Ending Cash'
                              type='number'
                              param1='cashFlowAnalysis'
                              param2='other_adjustments_to_ending_cash'
                              value={companyFA}
                              list={i}
                              onchange={(e, p1, p2) => handleYearlyDataChange(e, i, p1, p2)}
                            />
                            <TransposeLineInput
                              label='Cash and Cash Equivalents at the Beginning of Financial Year'
                              type='number'
                              param1='cashFlowAnalysis'
                              param2='cce_start_fin_year'
                              value={companyFA}
                              list={i}
                              long
                              onchange={(e, p1, p2) => handleYearlyDataChange(e, i, p1, p2)}
                            />
                            <TransposeLineInput
                              label='Cash and Cash Equivalents at the End of Financial Year'
                              type='number'
                              param1='cashFlowAnalysis'
                              param2='cce_end_fin_year_c'
                              value={companyFA}
                              list={i}
                              long
                              disabled
                              onchange={(e, p1, p2) => handleYearlyDataChange(e, i, p1, p2)}
                              tooltip={autoGenTooltip}
                            />
                          </tr>
                        ))}
                      </TransposeTable>
                    </div>
                    <div className='row'>
                      {/* Printable Cash Flow Statement */}
                      <div ref={cash_flow_statement_ref} className='print-only card-body p-9'>
                        <AFSPrintingHeader
                          Title='Cash Flow Statement'
                          ComputeForm={ComputeForm}
                          CurrentTime={CurrentTime}
                        />

                        <div className='row mb-2'>
                          <table className='transpose-table'>
                            <tbody>
                              <tr className='min-w-50'>
                                <FSTableDataHead className='white-text-on-blue-bg fw-bold text-white'>
                                  &nbsp;
                                </FSTableDataHead>
                                <FSTableDataHead className='fw-bold cell-highlight'>
                                  Profit/(loss) before taxation
                                </FSTableDataHead>
                                {/* Adjustment */}
                                <FSTableDataHead>Adjustment for:</FSTableDataHead>
                                <FSTableDataHead>Depreciation and amortization</FSTableDataHead>
                                <FSTableDataHead>Interest expense/(income)</FSTableDataHead>
                                <FSTableDataHead>Dividend income</FSTableDataHead>
                                <FSTableDataHead>Other Adjustments</FSTableDataHead>
                                <TableEqualLine lineType='single' offset />
                                <FSTableDataHead className='fw-bold cell-highlight'>
                                  Operating Profit/(Loss) Before Working Capital Changes
                                </FSTableDataHead>
                                <TableEqualLine lineType='double' offset />
                                {/* Change in working capital */}
                                <FSTableDataHead className='fw-bold'>
                                  Change in working capital
                                </FSTableDataHead>
                                <FSTableDataHead>Inventories</FSTableDataHead>
                                <FSTableDataHead>Trade receivables</FSTableDataHead>
                                <FSTableDataHead>Other receivables and prepayments</FSTableDataHead>
                                <FSTableDataHead>Trade payables</FSTableDataHead>
                                <FSTableDataHead>Other payables and accruals</FSTableDataHead>
                                <FSTableDataHead>
                                  Amount due to director/related parties
                                </FSTableDataHead>
                                <FSTableDataHead>Other changes in working capital</FSTableDataHead>
                                <TableEqualLine lineType='single' offset />
                                <FSTableDataHead className='fw-bold cell-highlight'>
                                  Cash Generated From/(Used in) Operating Activities
                                </FSTableDataHead>
                                <FSTableDataHead>Tax (paid/refund)</FSTableDataHead>
                                <FSTableDataHead>Other operating activities</FSTableDataHead>
                                <TableEqualLine lineType='single' offset />
                                <FSTableDataHead className='fw-bold cell-highlight'>
                                  Net cash generated from/(used in) operating Activities
                                </FSTableDataHead>
                                <TableEqualLine lineType='double' offset />
                                <FSTableDataHead className='fw-bold'>
                                  Cash Flow Investing Activities
                                </FSTableDataHead>
                                <FSTableDataHead>Disposal/(acquisition) of PPE</FSTableDataHead>
                                <FSTableDataHead>
                                  Disposal/(acquisition) of equity and debt
                                </FSTableDataHead>
                                <FSTableDataHead>Dividends received</FSTableDataHead>
                                <FSTableDataHead>Interest received</FSTableDataHead>
                                <FSTableDataHead>Other investing activities</FSTableDataHead>
                                <TableEqualLine lineType='single' offset />
                                <FSTableDataHead className='fw-bold'>
                                  Net Cash Generated From/(Used in) Investing Activities
                                </FSTableDataHead>
                                <TableEqualLine lineType='double' offset />
                                <FSTableDataHead className='fw-bold cell-highlight'>
                                  Cash Flow Financing Activities
                                </FSTableDataHead>
                                <FSTableDataHead>Issuance/(repurchase) of shares</FSTableDataHead>
                                <FSTableDataHead>Issuance/(repayment) of debt</FSTableDataHead>
                                <FSTableDataHead>Dividends paid</FSTableDataHead>
                                <FSTableDataHead>Interest paid</FSTableDataHead>
                                <FSTableDataHead>Other financing activities</FSTableDataHead>
                                <TableEqualLine lineType='single' offset />
                                <FSTableDataHead className='fw-bold cell-highlight'>
                                  Net Cash Generated From/(Used in) Financing Activities
                                </FSTableDataHead>
                                <TableEqualLine lineType='double' offset />
                                <FSTableDataHead className='fw-bold'>
                                  Net increase/(decrease) in cash and cash equivalent
                                </FSTableDataHead>
                                <FSTableDataHead>Other adjustments to ending cash</FSTableDataHead>
                                <FSTableDataHead>
                                  Cash and cash equivalents at the beginning
                                </FSTableDataHead>
                                <TableEqualLine lineType='single' offset />
                                <FSTableDataHead className='fw-bold'>
                                  Cash and cash equivalents at the end
                                </FSTableDataHead>
                                <TableEqualLine lineType='double' offset />
                              </tr>
                              {ComputeForm.companyFinancialAnalysis?.map((companyFA, i) => (
                                <tr style={{ minWidth: '15%' }} key={i}>
                                  <FSTableData
                                    className='white-text-on-blue-bg fw-bold text-white'
                                    style={{
                                      backgroundColor: '#36486b',
                                    }}
                                  >
                                    {companyFA.financial_year_c}
                                  </FSTableData>
                                  <FSTableData className='fw-bold cell-highlight'>
                                    <LineViewer
                                      param1='cashFlowAnalysis'
                                      param2='profit_before_taxation_c'
                                      value={companyFA}
                                    />
                                  </FSTableData>
                                  <FSTableData>&nbsp;</FSTableData>
                                  {/* Adjustment */}
                                  <FSTableData>
                                    <LineViewer
                                      param1='cashFlowAnalysis'
                                      param2='adjustments'
                                      param3='cf_depreciation_amortization'
                                      value={companyFA}
                                    />
                                  </FSTableData>

                                  <FSTableData>
                                    <LineViewer
                                      param1='cashFlowAnalysis'
                                      param2='adjustments'
                                      param3='interest_expense_income'
                                      value={companyFA}
                                    />
                                  </FSTableData>

                                  <FSTableData>
                                    <LineViewer
                                      param1='cashFlowAnalysis'
                                      param2='adjustments'
                                      param3='dividend_income'
                                      value={companyFA}
                                    />
                                  </FSTableData>

                                  <FSTableData>
                                    <LineViewer
                                      param1='cashFlowAnalysis'
                                      param2='adjustments'
                                      param3='other_adjustments'
                                      value={companyFA}
                                    />
                                  </FSTableData>
                                  <TableEqualLine lineType='single' />
                                  <FSTableData className='fw-bold cell-highlight'>
                                    <LineViewer
                                      param1='cashFlowAnalysis'
                                      param2='operating_profit_before_wc_changes_c'
                                      value={companyFA}
                                    />
                                  </FSTableData>
                                  <TableEqualLine lineType='double' />
                                  <FSTableData>&nbsp;</FSTableData>
                                  {/* Change in working capital */}
                                  <FSTableData>
                                    <LineViewer
                                      param1='cashFlowAnalysis'
                                      param2='working_capital_changes'
                                      param3='cf_inventories'
                                      value={companyFA}
                                    />
                                  </FSTableData>

                                  <FSTableData>
                                    <LineViewer
                                      param1='cashFlowAnalysis'
                                      param2='working_capital_changes'
                                      param3='cf_trade_receivables'
                                      value={companyFA}
                                    />
                                  </FSTableData>

                                  <FSTableData>
                                    <LineViewer
                                      param1='cashFlowAnalysis'
                                      param2='working_capital_changes'
                                      param3='cf_other_receivables_prepayment'
                                      value={companyFA}
                                    />
                                  </FSTableData>

                                  <FSTableData>
                                    <LineViewer
                                      param1='cashFlowAnalysis'
                                      param2='working_capital_changes'
                                      param3='cf_trade_payables'
                                      value={companyFA}
                                    />
                                  </FSTableData>

                                  <FSTableData>
                                    <LineViewer
                                      param1='cashFlowAnalysis'
                                      param2='working_capital_changes'
                                      param3='cf_trade_payables_accruals'
                                      value={companyFA}
                                    />
                                  </FSTableData>

                                  <FSTableData>
                                    <LineViewer
                                      param1='cashFlowAnalysis'
                                      param2='working_capital_changes'
                                      param3='cf_amount_due_to_directors_related_parties'
                                      value={companyFA}
                                    />
                                  </FSTableData>

                                  <FSTableData>
                                    <LineViewer
                                      param1='cashFlowAnalysis'
                                      param2='working_capital_changes'
                                      param3='other_changes_working_capital'
                                      value={companyFA}
                                    />
                                  </FSTableData>
                                  <TableEqualLine lineType='single' />
                                  <FSTableData className='fw-bold cell-highlight'>
                                    <LineViewer
                                      param1='cashFlowAnalysis'
                                      param2='cash_generated_used_operating_activities_c'
                                      value={companyFA}
                                    />
                                  </FSTableData>

                                  <FSTableData>
                                    <LineViewer
                                      param1='cashFlowAnalysis'
                                      param2='tax_paid_refund'
                                      value={companyFA}
                                    />
                                  </FSTableData>

                                  <FSTableData>
                                    <LineViewer
                                      param1='cashFlowAnalysis'
                                      param2='other_operating_activities'
                                      value={companyFA}
                                    />
                                  </FSTableData>

                                  <TableEqualLine lineType='single' />
                                  <FSTableData className='fw-bold cell-highlight'>
                                    <LineViewer
                                      param1='cashFlowAnalysis'
                                      param2='net_cash_generated_used_operating_activities_c'
                                      value={companyFA}
                                    />
                                  </FSTableData>
                                  <TableEqualLine lineType='double' />

                                  <FSTableData>&nbsp;</FSTableData>

                                  <FSTableData>
                                    <LineViewer
                                      param1='cashFlowAnalysis'
                                      param2='investingActivities'
                                      param3='disposal_acquisition_ppe'
                                      value={companyFA}
                                    />
                                  </FSTableData>

                                  <FSTableData>
                                    <LineViewer
                                      param1='cashFlowAnalysis'
                                      param2='investingActivities'
                                      param3='disposal_acquisition_equity_debt'
                                      value={companyFA}
                                    />
                                  </FSTableData>

                                  <FSTableData>
                                    <LineViewer
                                      param1='cashFlowAnalysis'
                                      param2='investingActivities'
                                      param3='dividends_received'
                                      value={companyFA}
                                    />
                                  </FSTableData>

                                  <FSTableData>
                                    <LineViewer
                                      param1='cashFlowAnalysis'
                                      param2='investingActivities'
                                      param3='interest_received'
                                      value={companyFA}
                                    />
                                  </FSTableData>

                                  <FSTableData>
                                    <LineViewer
                                      param1='cashFlowAnalysis'
                                      param2='investingActivities'
                                      param3='other_investing_activities'
                                      value={companyFA}
                                    />
                                  </FSTableData>
                                  <TableEqualLine lineType='single' />
                                  <FSTableData className='fw-bold'>
                                    <LineViewer
                                      param1='cashFlowAnalysis'
                                      param2='investingActivities'
                                      param3='net_cash_generated_used_investing_activities_c'
                                      value={companyFA}
                                    />
                                  </FSTableData>
                                  <TableEqualLine lineType='double' />

                                  <FSTableData className='fw-bold cell-highlight'>
                                    &nbsp;
                                  </FSTableData>
                                  <FSTableData>
                                    <LineViewer
                                      param1='cashFlowAnalysis'
                                      param2='financingActivities'
                                      param3='issuance_repurchase_shares'
                                      value={companyFA}
                                    />
                                  </FSTableData>

                                  <FSTableData>
                                    <LineViewer
                                      param1='cashFlowAnalysis'
                                      param2='financingActivities'
                                      param3='issuance_repayment_debt'
                                      value={companyFA}
                                    />
                                  </FSTableData>

                                  <FSTableData>
                                    <LineViewer
                                      param1='cashFlowAnalysis'
                                      param2='financingActivities'
                                      param3='dividends_paid'
                                      value={companyFA}
                                    />
                                  </FSTableData>

                                  <FSTableData>
                                    <LineViewer
                                      param1='cashFlowAnalysis'
                                      param2='financingActivities'
                                      param3='interest_paid'
                                      value={companyFA}
                                    />
                                  </FSTableData>

                                  <FSTableData>
                                    <LineViewer
                                      param1='cashFlowAnalysis'
                                      param2='financingActivities'
                                      param3='other_financing_activities'
                                      value={companyFA}
                                    />
                                  </FSTableData>
                                  <TableEqualLine lineType='single' />
                                  <FSTableData className='fw-bold cell-highlight'>
                                    <LineViewer
                                      param1='cashFlowAnalysis'
                                      param2='financingActivities'
                                      param3='net_cash_generated_used_financing_activities_c'
                                      value={companyFA}
                                    />
                                  </FSTableData>
                                  <TableEqualLine lineType='double' />

                                  <FSTableData className='fw-bold'>
                                    <LineViewer
                                      param1='cashFlowAnalysis'
                                      param2='net_cce_change_c'
                                      value={companyFA}
                                    />
                                  </FSTableData>

                                  <FSTableData>
                                    <LineViewer
                                      param1='cashFlowAnalysis'
                                      param2='other_adjustments_to_ending_cash'
                                      value={companyFA}
                                    />
                                  </FSTableData>
                                  <FSTableData>
                                    <LineViewer
                                      param1='cashFlowAnalysis'
                                      param2='cce_start_fin_year'
                                      value={companyFA}
                                    />
                                  </FSTableData>
                                  <TableEqualLine lineType='single' />
                                  <FSTableData className='fw-bold'>
                                    <LineViewer
                                      param1='cashFlowAnalysis'
                                      param2='cce_end_fin_year_c'
                                      value={companyFA}
                                    />
                                  </FSTableData>
                                  <TableEqualLine lineType='double' />
                                </tr>
                              ))}
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>
                  </div>

                  {/*CONSOLIDATED BALANCE SHEET*/}
                  <div className='consolidated-balance-sheet-wrapper border-default border p-6 mb-6'>
                    <div className='d-flex justify-content-between align-items-start flex-wrap mb-2'>
                      <div className='d-flex my-4'>
                        <KTIcon
                          iconName='questionnaire-tablet'
                          className='fs-2tx text-primary me-4'
                        />
                        <div className='d-flex flex-column'>
                          <h3 className='mb-1'>Consolidated Balance Sheet</h3>
                          <div className='fs-6 fw-bold text-gray-400'>
                            Data required for accuracy of results.
                          </div>
                        </div>
                      </div>
                      <div className='d-flex my-4'>
                        <ReactToPrint
                          documentTitle='SME Model Result'
                          bodyClass='print-black-font'
                          content={() => consolidated_balance_sheet_ref.current}
                          trigger={() => (
                            <PrintButton type='button' className='btn btn-primary btn-sm'>
                              <KTIcon
                                iconName='printer'
                                iconType='outline'
                                className='fs-3 text-default'
                              />
                            </PrintButton>
                          )}
                          onBeforeGetContent={() => handleBeforeGetContent()}
                        />
                      </div>
                    </div>

                    {/*ASSETS*/}
                    <div className='assets-wrapper border-default border p-6'>
                      <div className='d-flex'>
                        <KTIcon
                          iconName='questionnaire-tablet'
                          className='fs-2tx text-primary me-4'
                        />
                        <div className='d-flex flex-column'>
                          <h3 className='mb-1'>Assets</h3>
                          <div className='fs-6 fw-bold text-gray-400'>
                            Data required for accuracy of results.
                          </div>
                        </div>
                      </div>
                      <div className='row' style={{ overflow: 'auto' }}>
                        <TransposeTable>
                          {ComputeForm.companyFinancialAnalysis?.map((companyFA, i) => (
                            <tr
                              className={clsx('cell-offset transpose-table', { 'w-500px': i === 0 })}
                              key={i}
                            >
                              <TransposeLineInput
                                label='Inventories'
                                type='number'
                                param1='consolidatedBalanceSheet'
                                param2='assets'
                                param3='ast_inventories'
                                value={companyFA}
                                list={i}
                                onchange={(e, p1, p2, p3) =>
                                  handleYearlyDataChange(e, i, p1, p2, p3)
                                }
                              />
                              <TransposeLineInput
                                label='Trade Receivables'
                                type='number'
                                param1='consolidatedBalanceSheet'
                                param2='assets'
                                param3='ast_trade_receivables'
                                value={companyFA}
                                list={i}
                                onchange={(e, p1, p2, p3) =>
                                  handleYearlyDataChange(e, i, p1, p2, p3)
                                }
                              />
                              <TransposeLineInput
                                label='Other Receivables & Prepayments'
                                type='number'
                                param1='consolidatedBalanceSheet'
                                param2='assets'
                                param3='ast_other_receivables_prepayments'
                                value={companyFA}
                                list={i}
                                long
                                onchange={(e, p1, p2, p3) =>
                                  handleYearlyDataChange(e, i, p1, p2, p3)
                                }
                                tooltip='Includes advanced payments'
                              />
                              <TransposeLineInput
                                label='Cash and Cash Equivalents'
                                type='number'
                                param1='consolidatedBalanceSheet'
                                param2='assets'
                                param3='ast_cash_and_cash_equivalents'
                                value={companyFA}
                                list={i}
                                onchange={(e, p1, p2, p3) =>
                                  handleYearlyDataChange(e, i, p1, p2, p3)
                                }
                              />
                              <TransposeLineInput
                                label='Other Current Assets'
                                type='number'
                                param1='consolidatedBalanceSheet'
                                param2='assets'
                                param3='other_current_assets'
                                value={companyFA}
                                list={i}
                                onchange={(e, p1, p2, p3) =>
                                  handleYearlyDataChange(e, i, p1, p2, p3)
                                }
                              />
                              <TransposeLineInput
                                label='Total Current Assets'
                                type='number'
                                param1='consolidatedBalanceSheet'
                                param2='assets'
                                param3='total_current_assets_c'
                                value={companyFA}
                                list={i}
                                disabled
                                onchange={(e, p1, p2, p3) =>
                                  handleYearlyDataChange(e, i, p1, p2, p3)
                                }
                                tooltip={autoGenTooltip}
                              />
                              <TransposeLineInput
                                label='Property Plant & Equipment, net'
                                type='number'
                                param1='consolidatedBalanceSheet'
                                param2='assets'
                                param3='property_plant_equipment_net'
                                value={companyFA}
                                list={i}
                                onchange={(e, p1, p2, p3) =>
                                  handleYearlyDataChange(e, i, p1, p2, p3)
                                }
                                tooltip='Net depreciation'
                              />
                              <TransposeLineInput
                                label='Intangible Assets, net'
                                type='number'
                                param1='consolidatedBalanceSheet'
                                param2='assets'
                                param3='intangible_assets_net'
                                value={companyFA}
                                list={i}
                                onchange={(e, p1, p2, p3) =>
                                  handleYearlyDataChange(e, i, p1, p2, p3)
                                }
                                tooltip='Net amortization'
                              />
                              <TransposeLineInput
                                label='Deferred Tax Assets'
                                type='number'
                                param1='consolidatedBalanceSheet'
                                param2='assets'
                                param3='deferred_tax_assets'
                                value={companyFA}
                                list={i}
                                onchange={(e, p1, p2, p3) =>
                                  handleYearlyDataChange(e, i, p1, p2, p3)
                                }
                              />
                              <TransposeLineInput
                                label='Other Non Current Assets'
                                type='number'
                                param1='consolidatedBalanceSheet'
                                param2='assets'
                                param3='other_non_current_assets'
                                value={companyFA}
                                list={i}
                                onchange={(e, p1, p2, p3) =>
                                  handleYearlyDataChange(e, i, p1, p2, p3)
                                }
                              />
                              <TransposeLineInput
                                label='Total Asset'
                                type='number'
                                param1='consolidatedBalanceSheet'
                                param2='assets'
                                param3='ast_total_asset_c'
                                value={companyFA}
                                list={i}
                                disabled
                                onchange={(e, p1, p2, p3) =>
                                  handleYearlyDataChange(e, i, p1, p2, p3)
                                }
                                tooltip={autoGenTooltip}
                              />
                            </tr>
                          ))}
                        </TransposeTable>
                      </div>
                    </div>

                    {/*LIABILITY AND EQUITY*/}
                    <div className='liabilities-and-equity-wrapper border-default border p-6'>
                      <div className='d-flex'>
                        <KTIcon
                          iconName='questionnaire-tablet'
                          className='fs-2tx text-primary me-4'
                        />
                        <div className='d-flex flex-column'>
                          <h3 className='mb-1'>Liabilities And Equity</h3>
                          <div className='fs-6 fw-bold text-gray-400'>
                            Data required for accuracy of results.
                          </div>
                        </div>
                      </div>
                      <div className='row' style={{ overflow: 'auto' }}>
                        <TransposeTable>
                          {ComputeForm.companyFinancialAnalysis?.map((companyFA, i) => (
                            <tr
                              className={clsx('cell-offset transpose-table', { 'w-500px': i === 0 })}
                              key={i}
                            >
                              <TransposeLineInput
                                label='Trade Payables'
                                type='number'
                                param1='consolidatedBalanceSheet'
                                param2='liabilitiesAndEquity'
                                param3='lieq_trade_payables'
                                value={companyFA}
                                list={i}
                                onchange={(e, p1, p2, p3) =>
                                  handleYearlyDataChange(e, i, p1, p2, p3)
                                }
                              />
                              <TransposeLineInput
                                label='Short Term Borrowings'
                                type='number'
                                param1='consolidatedBalanceSheet'
                                param2='liabilitiesAndEquity'
                                param3='short_term_borrowings'
                                value={companyFA}
                                list={i}
                                onchange={(e, p1, p2, p3) =>
                                  handleYearlyDataChange(e, i, p1, p2, p3)
                                }
                              />
                              <TransposeLineInput
                                label='Amount Due to Directors Related Parties'
                                type='number'
                                param1='consolidatedBalanceSheet'
                                param2='liabilitiesAndEquity'
                                param3='lieq_amount_due_to_directors_related_parties'
                                value={companyFA}
                                list={i}
                                long
                                onchange={(e, p1, p2, p3) =>
                                  handleYearlyDataChange(e, i, p1, p2, p3)
                                }
                              />
                              <TransposeLineInput
                                label='Other Payables & Accruals'
                                type='number'
                                param1='consolidatedBalanceSheet'
                                param2='liabilitiesAndEquity'
                                param3='lieq_other_payables_accruals'
                                value={companyFA}
                                list={i}
                                onchange={(e, p1, p2, p3) =>
                                  handleYearlyDataChange(e, i, p1, p2, p3)
                                }
                                tooltip='Includes unearned revenue'
                              />
                              <TransposeLineInput
                                label='Other Current Liabilities'
                                type='number'
                                param1='consolidatedBalanceSheet'
                                param2='liabilitiesAndEquity'
                                param3='lieq_other_current_liabilities'
                                value={companyFA}
                                list={i}
                                onchange={(e, p1, p2, p3) =>
                                  handleYearlyDataChange(e, i, p1, p2, p3)
                                }
                              />
                              <TransposeLineInput
                                label='Total Current Liabilities'
                                type='number'
                                param1='consolidatedBalanceSheet'
                                param2='liabilitiesAndEquity'
                                param3='total_current_liabilities_c'
                                value={companyFA}
                                list={i}
                                disabled
                                onchange={(e, p1, p2, p3) =>
                                  handleYearlyDataChange(e, i, p1, p2, p3)
                                }
                                tooltip={autoGenTooltip}
                              />
                              <TransposeLineInput
                                label='Long Term Borrowings'
                                type='number'
                                param1='consolidatedBalanceSheet'
                                param2='liabilitiesAndEquity'
                                param3='long_term_borrowings'
                                value={companyFA}
                                list={i}
                                onchange={(e, p1, p2, p3) =>
                                  handleYearlyDataChange(e, i, p1, p2, p3)
                                }
                              />
                              <TransposeLineInput
                                label='Deferred Tax Liabilities'
                                type='number'
                                param1='consolidatedBalanceSheet'
                                param2='liabilitiesAndEquity'
                                param3='deferred_tax_liabilities'
                                value={companyFA}
                                list={i}
                                onchange={(e, p1, p2, p3) =>
                                  handleYearlyDataChange(e, i, p1, p2, p3)
                                }
                              />
                              <TransposeLineInput
                                label='Other Non Current Liabilities'
                                type='number'
                                param1='consolidatedBalanceSheet'
                                param2='liabilitiesAndEquity'
                                param3='other_non_current_liabilities'
                                value={companyFA}
                                list={i}
                                onchange={(e, p1, p2, p3) =>
                                  handleYearlyDataChange(e, i, p1, p2, p3)
                                }
                              />
                              <TransposeLineInput
                                label='Total Liabilities'
                                type='number'
                                param1='consolidatedBalanceSheet'
                                param2='liabilitiesAndEquity'
                                param3='lieq_total_liabilities_c'
                                value={companyFA}
                                list={i}
                                disabled
                                onchange={(e, p1, p2, p3) =>
                                  handleYearlyDataChange(e, i, p1, p2, p3)
                                }
                                tooltip={autoGenTooltip}
                              />
                              <TransposeLineInput
                                label='Share Capital'
                                type='number'
                                param1='consolidatedBalanceSheet'
                                param2='liabilitiesAndEquity'
                                param3='share_capital'
                                value={companyFA}
                                list={i}
                                onchange={(e, p1, p2, p3) =>
                                  handleYearlyDataChange(e, i, p1, p2, p3)
                                }
                              />
                              <TransposeLineInput
                                label='Retained Earnings/(Losses)'
                                type='number'
                                param1='consolidatedBalanceSheet'
                                param2='liabilitiesAndEquity'
                                param3='retained_earnings_losses'
                                value={companyFA}
                                list={i}
                                onchange={(e, p1, p2, p3) =>
                                  handleYearlyDataChange(e, i, p1, p2, p3)
                                }
                                tooltip='Enter negative sign for retained losses'
                              />
                              <TransposeLineInput
                                label='Other Equity'
                                type='number'
                                param1='consolidatedBalanceSheet'
                                param2='liabilitiesAndEquity'
                                param3='other_equity'
                                value={companyFA}
                                list={i}
                                onchange={(e, p1, p2, p3) =>
                                  handleYearlyDataChange(e, i, p1, p2, p3)
                                }
                              />
                              <TransposeLineInput
                                label='Accumulated Other Comprehensive Income/Losses'
                                type='number'
                                param1='consolidatedBalanceSheet'
                                param2='liabilitiesAndEquity'
                                param3='accumulated_oci'
                                value={companyFA}
                                list={i}
                                long
                                onchange={(e, p1, p2, p3) =>
                                  handleYearlyDataChange(e, i, p1, p2, p3)
                                }
                                tooltip='Enter negative sign for accumulated OCL'
                              />

                              <TransposeLineInput
                                label='Total Equity'
                                type='number'
                                param1='consolidatedBalanceSheet'
                                param2='liabilitiesAndEquity'
                                param3='lieq_total_equity_c'
                                value={companyFA}
                                list={i}
                                disabled
                                onchange={(e, p1, p2, p3) =>
                                  handleYearlyDataChange(e, i, p1, p2, p3)
                                }
                                tooltip={autoGenTooltip}
                              />
                              <TransposeLineInput
                                label='Total Liabilities & Equity'
                                type='number'
                                param1='consolidatedBalanceSheet'
                                param2='liabilitiesAndEquity'
                                param3='total_liabilities_equity_c'
                                value={companyFA}
                                list={i}
                                disabled
                                onchange={(e, p1, p2, p3) =>
                                  handleYearlyDataChange(e, i, p1, p2, p3)
                                }
                                tooltip={autoGenTooltip}
                              />
                            </tr>
                          ))}
                        </TransposeTable>
                      </div>
                    </div>
                    <div className='row'>
                      {/* Printable Consolidated Balance Sheet */}
                      <div
                        ref={consolidated_balance_sheet_ref}
                        className='print-only card-body p-9'
                      >
                        <AFSPrintingHeader
                          Title='Consolidated Balance Sheet'
                          ComputeForm={ComputeForm}
                          CurrentTime={CurrentTime}
                        />
                        <div className='row mb-2'>
                          <table className='transpose-table'>
                            <tbody>
                              <tr className='min-w-50'>
                                <FSTableDataHead className='white-text-on-blue-bg fw-bold text-white'>
                                  &nbsp;
                                </FSTableDataHead>
                                <FSTableDataHead className='fw-bold cell-highlight'>
                                  Assets
                                </FSTableDataHead>
                                <FSTableDataHead>Inventories</FSTableDataHead>
                                <FSTableDataHead>Trade receivables</FSTableDataHead>
                                <FSTableDataHead>Other receivables and prepayments</FSTableDataHead>
                                <FSTableDataHead>Cash and cash equivalent</FSTableDataHead>
                                <FSTableDataHead>Other current assets</FSTableDataHead>
                                {/* Single Equal to line offset*/}
                                <TableEqualLine lineType='single' offset />
                                <FSTableDataHead className='fw-bold cell-highlight'>
                                  Total Current Assets
                                </FSTableDataHead>
                                <FSTableDataHead>
                                  Property. plant and equipment, net
                                </FSTableDataHead>
                                <FSTableDataHead>Intangible assets, net</FSTableDataHead>
                                <FSTableDataHead>Deferred tax assets</FSTableDataHead>
                                <FSTableDataHead>Other non-current assets</FSTableDataHead>
                                <TableEqualLine lineType='single' offset />
                                <FSTableDataHead className='fw-bold cell-highlight'>
                                  Total Assets
                                </FSTableDataHead>
                                <TableEqualLine lineType='double' offset />
                                <FSTableDataHead className='fw-bold'>
                                  Liabilities and Equities
                                </FSTableDataHead>
                                <FSTableDataHead>Trade payables</FSTableDataHead>
                                <FSTableDataHead>Short-term borrowings</FSTableDataHead>
                                <FSTableDataHead>
                                  Amount due to directors/related parties
                                </FSTableDataHead>
                                <FSTableDataHead>Other payables and accruals</FSTableDataHead>
                                <FSTableDataHead>Other current liabilities</FSTableDataHead>
                                <TableEqualLine lineType='single' offset />
                                <FSTableDataHead className='fw-bold'>
                                  Total Current Liabilities
                                </FSTableDataHead>
                                <FSTableDataHead>Long-term borrowings</FSTableDataHead>
                                <FSTableDataHead>Deferred tax liabilities</FSTableDataHead>
                                <FSTableDataHead>Other nan-current liabilities</FSTableDataHead>
                                <TableEqualLine lineType='single' offset />
                                <FSTableDataHead className='fw-bold'>
                                  Total Liabilities
                                </FSTableDataHead>
                                <FSTableDataHead>Share capital</FSTableDataHead>
                                <FSTableDataHead>Retained earnings/(losses)</FSTableDataHead>
                                <FSTableDataHead>Other equity</FSTableDataHead>
                                <FSTableDataHead>Accumulated OCI</FSTableDataHead>
                                <TableEqualLine lineType='single' offset />
                                <FSTableDataHead className='fw-bold'>Total Equity</FSTableDataHead>
                                <TableEqualLine lineType='single' offset />
                                <FSTableDataHead className='fw-bold'>
                                  Total Liabilities and Equity
                                </FSTableDataHead>
                              </tr>
                              {ComputeForm.companyFinancialAnalysis?.map((companyFA, i) => (
                                <tr style={{ minWidth: '15%' }} key={i}>
                                  <FSTableData
                                    className='white-text-on-blue-bg fw-bold text-white'
                                    style={{
                                      backgroundColor: '#36486b',
                                    }}
                                  >
                                    {companyFA.financial_year_c}
                                  </FSTableData>
                                  <FSTableData className='fw-bold cell-highlight'>
                                    &nbsp;
                                  </FSTableData>
                                  <FSTableData>
                                    <LineViewer
                                      param1='consolidatedBalanceSheet'
                                      param2='assets'
                                      param3='ast_inventories'
                                      value={companyFA}
                                    />
                                  </FSTableData>
                                  <FSTableData>
                                    <LineViewer
                                      param1='consolidatedBalanceSheet'
                                      param2='assets'
                                      param3='ast_trade_receivables'
                                      value={companyFA}
                                    />
                                  </FSTableData>
                                  <FSTableData>
                                    <LineViewer
                                      param1='consolidatedBalanceSheet'
                                      param2='assets'
                                      param3='ast_other_receivables_prepayments'
                                      value={companyFA}
                                    />
                                  </FSTableData>
                                  <FSTableData>
                                    <LineViewer
                                      param1='consolidatedBalanceSheet'
                                      param2='assets'
                                      param3='ast_cash_and_cash_equivalents'
                                      value={companyFA}
                                    />
                                  </FSTableData>
                                  <FSTableData>
                                    <LineViewer
                                      param1='consolidatedBalanceSheet'
                                      param2='assets'
                                      param3='other_current_assets'
                                      value={companyFA}
                                    />
                                  </FSTableData>
                                  {/* Single Equal to line */}
                                  <TableEqualLine lineType='single' />
                                  <FSTableData className='fw-bold cell-highlight'>
                                    <LineViewer
                                      param1='consolidatedBalanceSheet'
                                      param2='assets'
                                      param3='total_current_assets_c'
                                      value={companyFA}
                                    />
                                  </FSTableData>
                                  <FSTableData>
                                    <LineViewer
                                      param1='consolidatedBalanceSheet'
                                      param2='assets'
                                      param3='property_plant_equipment_net'
                                      value={companyFA}
                                    />
                                  </FSTableData>
                                  <FSTableData>
                                    <LineViewer
                                      param1='consolidatedBalanceSheet'
                                      param2='assets'
                                      param3='intangible_assets_net'
                                      value={companyFA}
                                    />
                                  </FSTableData>
                                  <FSTableData>
                                    <LineViewer
                                      param1='consolidatedBalanceSheet'
                                      param2='assets'
                                      param3='deferred_tax_assets'
                                      value={companyFA}
                                    />
                                  </FSTableData>
                                  <FSTableData>
                                    <LineViewer
                                      param1='consolidatedBalanceSheet'
                                      param2='assets'
                                      param3='other_non_current_assets'
                                      value={companyFA}
                                    />
                                  </FSTableData>
                                  <TableEqualLine lineType='single' />
                                  <FSTableData className='fw-bold cell-highlight'>
                                    <LineViewer
                                      param1='consolidatedBalanceSheet'
                                      param2='assets'
                                      param3='ast_total_asset_c'
                                      value={companyFA}
                                    />
                                  </FSTableData>
                                  <TableEqualLine lineType='double' />
                                  <FSTableData>&nbsp;</FSTableData>
                                  <FSTableData>
                                    <LineViewer
                                      param1='consolidatedBalanceSheet'
                                      param2='liabilitiesAndEquity'
                                      param3='lieq_trade_payables'
                                      value={companyFA}
                                    />
                                  </FSTableData>
                                  <FSTableData>
                                    <LineViewer
                                      param1='consolidatedBalanceSheet'
                                      param2='liabilitiesAndEquity'
                                      param3='short_term_borrowings'
                                      value={companyFA}
                                    />
                                  </FSTableData>
                                  <FSTableData>
                                    <LineViewer
                                      param1='consolidatedBalanceSheet'
                                      param2='liabilitiesAndEquity'
                                      param3='lieq_amount_due_to_directors_related_parties'
                                      value={companyFA}
                                    />
                                  </FSTableData>
                                  <FSTableData>
                                    <LineViewer
                                      param1='consolidatedBalanceSheet'
                                      param2='liabilitiesAndEquity'
                                      param3='lieq_other_payables_accruals'
                                      value={companyFA}
                                    />
                                  </FSTableData>

                                  <FSTableData>
                                    <LineViewer
                                      param1='consolidatedBalanceSheet'
                                      param2='liabilitiesAndEquity'
                                      param3='lieq_other_current_liabilities'
                                      value={companyFA}
                                    />
                                  </FSTableData>
                                  <TableEqualLine lineType='single' />
                                  <FSTableData className='fw-bold'>
                                    <LineViewer
                                      param1='consolidatedBalanceSheet'
                                      param2='liabilitiesAndEquity'
                                      param3='total_current_liabilities_c'
                                      value={companyFA}
                                    />
                                  </FSTableData>
                                  <FSTableData>
                                    <LineViewer
                                      param1='consolidatedBalanceSheet'
                                      param2='liabilitiesAndEquity'
                                      param3='long_term_borrowings'
                                      value={companyFA}
                                    />
                                  </FSTableData>
                                  <FSTableData>
                                    <LineViewer
                                      param1='consolidatedBalanceSheet'
                                      param2='liabilitiesAndEquity'
                                      param3='deferred_tax_liabilities'
                                      value={companyFA}
                                    />
                                  </FSTableData>
                                  <FSTableData>
                                    <LineViewer
                                      param1='consolidatedBalanceSheet'
                                      param2='liabilitiesAndEquity'
                                      param3='other_non_current_liabilities'
                                      value={companyFA}
                                    />
                                  </FSTableData>
                                  <TableEqualLine lineType='single' />
                                  <FSTableData className='fw-bold'>
                                    <LineViewer
                                      param1='consolidatedBalanceSheet'
                                      param2='liabilitiesAndEquity'
                                      param3='lieq_total_liabilities_c'
                                      value={companyFA}
                                    />
                                  </FSTableData>
                                  <FSTableData>
                                    <LineViewer
                                      param1='consolidatedBalanceSheet'
                                      param2='liabilitiesAndEquity'
                                      param3='share_capital'
                                      value={companyFA}
                                    />
                                  </FSTableData>
                                  <FSTableData>
                                    <LineViewer
                                      param1='consolidatedBalanceSheet'
                                      param2='liabilitiesAndEquity'
                                      param3='retained_earnings_losses'
                                      value={companyFA}
                                    />
                                  </FSTableData>
                                  <FSTableData>
                                    <LineViewer
                                      param1='consolidatedBalanceSheet'
                                      param2='liabilitiesAndEquity'
                                      param3='other_equity'
                                      value={companyFA}
                                    />
                                  </FSTableData>
                                  <FSTableData>
                                    <LineViewer
                                      param1='consolidatedBalanceSheet'
                                      param2='liabilitiesAndEquity'
                                      param3='accumulated_oci'
                                      value={companyFA}
                                    />
                                  </FSTableData>
                                  <TableEqualLine lineType='single' />
                                  <FSTableData className='fw-bold'>
                                    <LineViewer
                                      param1='consolidatedBalanceSheet'
                                      param2='liabilitiesAndEquity'
                                      param3='lieq_total_equity_c'
                                      value={companyFA}
                                    />
                                  </FSTableData>
                                  <TableEqualLine lineType='single' />
                                  <FSTableData className='fw-bold'>
                                    <LineViewer
                                      param1='consolidatedBalanceSheet'
                                      param2='liabilitiesAndEquity'
                                      param3='total_liabilities_equity_c'
                                      value={companyFA}
                                    />
                                  </FSTableData>
                                </tr>
                              ))}
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>
                  </div>
                  {/* KEY RATIOS */}
                  <div className='consolidated-balance-sheet-wrapper border-default border p-6 mb-6'>
                    <div className='d-flex justify-content-between align-items-start flex-wrap mb-2'>
                      <div className='d-flex'>
                        <h3 className='mt-6'>Key Ratios</h3>
                      </div>
                      <div className='d-flex my-4'>
                        <ReactToPrint
                          documentTitle='SME Model Result'
                          bodyClass='print-black-font'
                          content={() => financial_highlights_ref.current}
                          trigger={() => (
                            <PrintButton type='button' className='btn btn-primary btn-sm'>
                              <KTIcon
                                iconName='printer'
                                iconType='outline'
                                className='fs-3 text-default'
                              />
                            </PrintButton>
                          )}
                          onBeforeGetContent={() => handleBeforeGetContent()}
                        />
                      </div>
                    </div>
                    {/*PROFITABILITY*/}
                    <div className='profitability-efficiency-ratio-wrapper border-default border p-6 mb-6'>
                      <div className='d-flex'>
                        <KTIcon
                          iconName='questionnaire-tablet'
                          className='fs-2tx text-primary me-4'
                        />
                        <div className='d-flex flex-column'>
                          <h3 className='mb-1'>Profitability</h3>
                          <div className='fs-6 fw-bold text-gray-400'>
                            Data required for accuracy of results.
                          </div>
                        </div>
                      </div>
                      <div className='row' style={{ overflow: 'auto' }}>
                        <TransposeTable>
                          {ComputeForm.companyFinancialAnalysis?.map((companyFA, i) => (
                            <tr
                              className={clsx('cell-offset transpose-table', { 'w-500px': i === 0 })}
                              key={i}
                            >
                              <TransposeLineInput
                                label='Gross Profit Margin (%)'
                                type='number'
                                param1='profitabilityEfficiencyRatio'
                                param2='gross_profit_margin_c'
                                value={companyFA}
                                list={i}
                                disabled
                                onchange={(e, p1, p2) => handleYearlyDataChange(e, i, p1, p2)}
                                tooltip={autoGenTooltip}
                              />

                              <TransposeLineInput
                                label='EBITDA Margin (%)'
                                type='number'
                                param1='profitabilityEfficiencyRatio'
                                param2='ebitda_margin_c'
                                value={companyFA}
                                list={i}
                                disabled
                                onchange={(e, p1, p2) => handleYearlyDataChange(e, i, p1, p2)}
                                tooltip={autoGenTooltip}
                              />
                              <TransposeLineInput
                                label='Net Profit Margin (%)'
                                type='number'
                                param1='profitabilityEfficiencyRatio'
                                param2='net_profit_margin_c'
                                value={companyFA}
                                list={i}
                                disabled
                                onchange={(e, p1, p2) => handleYearlyDataChange(e, i, p1, p2)}
                                tooltip={autoGenTooltip}
                              />
                              <TransposeLineInput
                                label='Return on Asset (%)'
                                type='number'
                                param1='profitabilityEfficiencyRatio'
                                param2='return_on_asset_c'
                                value={companyFA}
                                list={i}
                                disabled
                                onchange={(e, p1, p2) => handleYearlyDataChange(e, i, p1, p2)}
                                tooltip={autoGenTooltip}
                              />
                              <TransposeLineInput
                                label='Return on Equity (%)'
                                type='number'
                                param1='profitabilityEfficiencyRatio'
                                param2='return_on_equity_c'
                                value={companyFA}
                                list={i}
                                disabled
                                onchange={(e, p1, p2) => handleYearlyDataChange(e, i, p1, p2)}
                                tooltip={autoGenTooltip}
                              />
                            </tr>
                          ))}
                        </TransposeTable>
                      </div>
                    </div>
                    {/*LIQUIDITY*/}
                    <div className='cashflow-management-wrapper border-default border p-6 mb-6'>
                      <div className='d-flex'>
                        <KTIcon
                          iconName='questionnaire-tablet'
                          className='fs-2tx text-primary me-4'
                        />
                        <div className='d-flex flex-column'>
                          <h3 className='mb-1'>Liquidity</h3>
                          <div className='fs-6 fw-bold text-gray-400'>
                            Data required for accuracy of results.
                          </div>
                        </div>
                      </div>
                      <div className='row' style={{ overflow: 'auto' }}>
                        <TransposeTable>
                          {ComputeForm.companyFinancialAnalysis?.map((companyFA, i) => (
                            <tr
                              className={clsx('cell-offset transpose-table', { 'w-500px': i === 0 })}
                              key={i}
                            >
                              <TransposeLineInput
                                label='Cash Ratio (x)'
                                type='number'
                                param1='cashFlowManagement'
                                param2='cash_ratio_c'
                                value={companyFA}
                                list={i}
                                disabled
                                onchange={(e, p1, p2) => handleYearlyDataChange(e, i, p1, p2)}
                                tooltip={autoGenTooltip}
                              />
                              <TransposeLineInput
                                label='Quick Ratio (x)'
                                type='number'
                                param1='cashFlowManagement'
                                param2='quick_ratio_c'
                                value={companyFA}
                                list={i}
                                disabled
                                onchange={(e, p1, p2) => handleYearlyDataChange(e, i, p1, p2)}
                                tooltip={autoGenTooltip}
                              />
                              <TransposeLineInput
                                label='Current Ratio (x)'
                                type='number'
                                param1='cashFlowManagement'
                                param2='current_ratio_c'
                                value={companyFA}
                                list={i}
                                disabled
                                onchange={(e, p1, p2) => handleYearlyDataChange(e, i, p1, p2)}
                                tooltip={autoGenTooltip}
                              />
                            </tr>
                          ))}
                        </TransposeTable>
                      </div>
                    </div>
                    {/*LEVERAGE*/}
                    <div className='capital-structure-wrapper border-default border p-6 mb-6'>
                      <div className='d-flex'>
                        <KTIcon
                          iconName='questionnaire-tablet'
                          className='fs-2tx text-primary me-4'
                        />
                        <div className='d-flex flex-column'>
                          <h3 className='mb-1'>Leverage</h3>
                          <div className='fs-6 fw-bold text-gray-400'>
                            Data required for accuracy of results.
                          </div>
                        </div>
                      </div>
                      <div className='row' style={{ overflow: 'auto' }}>
                        <TransposeTable>
                          {ComputeForm.companyFinancialAnalysis?.map((companyFA, i) => (
                            <tr
                              className={clsx('cell-offset transpose-table', { 'w-500px': i === 0 })}
                              key={i}
                            >
                              <TransposeLineInput
                                label='Debt to Equity Ratio (x)'
                                type='number'
                                param1='capitalStructure'
                                param2='debt_to_equity_ratio_c'
                                value={companyFA}
                                list={i}
                                disabled
                                onchange={(e, p1, p2) => handleYearlyDataChange(e, i, p1, p2)}
                                tooltip={autoGenTooltip}
                              />
                              <TransposeLineInput
                                label='Debt Service Ratio (x)'
                                type='number'
                                param1='capitalStructure'
                                param2='debt_service_ratio_c'
                                value={companyFA}
                                list={i}
                                disabled
                                onchange={(e, p1, p2) => handleYearlyDataChange(e, i, p1, p2)}
                                tooltip={autoGenTooltip}
                              />
                              <TransposeLineInput
                                label='Gearing Ratio (x)'
                                type='number'
                                param1='capitalStructure'
                                param2='gearing_ratio_c'
                                value={companyFA}
                                list={i}
                                disabled
                                onchange={(e, p1, p2) => handleYearlyDataChange(e, i, p1, p2)}
                                tooltip={autoGenTooltip}
                              />
                            </tr>
                          ))}
                        </TransposeTable>
                      </div>
                    </div>
                    {/* EFFICIENCY */}
                    <div className='cashflow-management-wrapper border-default border p-6 mb-6'>
                      <div className='d-flex'>
                        <KTIcon
                          iconName='questionnaire-tablet'
                          className='fs-2tx text-primary me-4'
                        />
                        <div className='d-flex flex-column'>
                          <h3 className='mb-1'>Efficiency</h3>
                          <div className='fs-6 fw-bold text-gray-400'>
                            Data required for accuracy of results.
                          </div>
                        </div>
                      </div>
                      <div className='row' style={{ overflow: 'auto' }}>
                        <TransposeTable>
                          {ComputeForm.companyFinancialAnalysis?.map((companyFA, i) => (
                            <tr
                              className={clsx('cell-offset transpose-table', { 'w-500px': i === 0 })}
                              key={i}
                            >
                              <TransposeLineInput
                                label='Day Receivables (Days)'
                                type='number'
                                param1='cashFlowManagement'
                                param2='day_receivables_c'
                                value={companyFA}
                                list={i}
                                disabled
                                onchange={(e, p1, p2) => handleYearlyDataChange(e, i, p1, p2)}
                                tooltip={autoGenTooltip}
                              />
                              <TransposeLineInput
                                label='Day Payables (Days)'
                                type='number'
                                param1='cashFlowManagement'
                                param2='day_payables_c'
                                value={companyFA}
                                list={i}
                                disabled
                                onchange={(e, p1, p2) => handleYearlyDataChange(e, i, p1, p2)}
                                tooltip={autoGenTooltip}
                              />
                              <TransposeLineInput
                                label='Inventory Turnover (Days)'
                                type='number'
                                param1='cashFlowManagement'
                                param2='inventory_turnover_c'
                                value={companyFA}
                                list={i}
                                disabled
                                onchange={(e, p1, p2) => handleYearlyDataChange(e, i, p1, p2)}
                                tooltip={autoGenTooltip}
                              />
                              <TransposeLineInput
                                label='Cash Conversion Cycle (Days)'
                                type='number'
                                param1='cashFlowManagement'
                                param2='cash_conversion_cycle_c'
                                value={companyFA}
                                list={i}
                                disabled
                                onchange={(e, p1, p2) => handleYearlyDataChange(e, i, p1, p2)}
                                tooltip={autoGenTooltip}
                              />
                              <TransposeLineInput
                                label='Sales Over Total Assets (x)'
                                type='number'
                                param1='profitabilityEfficiencyRatio'
                                param2='sales_over_total_assets_c'
                                value={companyFA}
                                list={i}
                                disabled
                                onchange={(e, p1, p2) => handleYearlyDataChange(e, i, p1, p2)}
                                tooltip={autoGenTooltip}
                              />
                            </tr>
                          ))}
                        </TransposeTable>
                      </div>
                    </div>
                    <div className='row'>
                      <div ref={financial_highlights_ref} className='print-only card-body p-9'>
                        <AFSPrintingHeader
                          Title='Key Ratios'
                          ComputeForm={ComputeForm}
                          CurrentTime={CurrentTime}
                        />
                        <div className='row mb-2'>
                          <table className='transpose-table'>
                            <tbody>
                              <tr className='min-w-50'>
                                <FSTableDataHead className='white-text-on-blue-bg fw-bold text-white'>
                                  &nbsp;
                                </FSTableDataHead>
                                <FSTableDataHead className='fw-bold'>Profitability</FSTableDataHead>

                                <FSTableDataHead>Gross Profit Margin (%)</FSTableDataHead>
                                <FSTableDataHead>EBITDA Margin (%)</FSTableDataHead>
                                <FSTableDataHead>Net Profit Margin (%)</FSTableDataHead>
                                <FSTableDataHead>Return on Equity (%)</FSTableDataHead>
                                <FSTableDataHead>Return on Assets (%)</FSTableDataHead>
                                <FSTableDataHead>&nbsp;</FSTableDataHead>
                                <FSTableDataHead className='fw-bold'>Liquidity</FSTableDataHead>
                                <FSTableDataHead>Cash Ratio (x)</FSTableDataHead>
                                <FSTableDataHead>Quick Ratio (x)</FSTableDataHead>
                                <FSTableDataHead>Current Ratio (x)</FSTableDataHead>
                                <FSTableDataHead>&nbsp;</FSTableDataHead>
                                <FSTableDataHead className='fw-bold'>Leverage</FSTableDataHead>
                                <FSTableDataHead>Debt to Equity Ratio (x)</FSTableDataHead>
                                <FSTableDataHead>Debt Service Ratio (x)</FSTableDataHead>
                                <FSTableDataHead>Gearing Ratio (x)</FSTableDataHead>
                                <FSTableDataHead>&nbsp;</FSTableDataHead>
                                <FSTableDataHead className='fw-bold'>Efficiency</FSTableDataHead>
                                <FSTableDataHead>Day Receivables (Days)</FSTableDataHead>
                                <FSTableDataHead>Day Payables (Days)</FSTableDataHead>
                                <FSTableDataHead>Inventory Turnover (Days)</FSTableDataHead>
                                <FSTableDataHead>Cash Conversion Cycle (Days)</FSTableDataHead>
                                <FSTableDataHead>Sales Over Total Assets (x)</FSTableDataHead>
                              </tr>
                              {ComputeForm.companyFinancialAnalysis?.map((companyFA, i) => (
                                <tr style={{ minWidth: '15%' }} key={i}>
                                  <FSTableData
                                    className='white-text-on-blue-bg fw-bold text-white'
                                    style={{
                                      backgroundColor: '#36486b',
                                    }}
                                  >
                                    {companyFA.financial_year_c}
                                  </FSTableData>
                                  {/* PROFITABILITY */}
                                  <FSTableData>&nbsp;</FSTableData>

                                  <FSTableData>
                                    <LineViewer
                                      param1='profitabilityEfficiencyRatio'
                                      param2='gross_profit_margin_c'
                                      value={companyFA}
                                    />
                                  </FSTableData>

                                  <FSTableData>
                                    <LineViewer
                                      param1='profitabilityEfficiencyRatio'
                                      param2='ebitda_margin_c'
                                      value={companyFA}
                                    />
                                  </FSTableData>
                                  <FSTableData>
                                    <LineViewer
                                      param1='profitabilityEfficiencyRatio'
                                      param2='net_profit_margin_c'
                                      value={companyFA}
                                    />
                                  </FSTableData>
                                  <FSTableData>
                                    <LineViewer
                                      param1='profitabilityEfficiencyRatio'
                                      param2='return_on_asset_c'
                                      value={companyFA}
                                    />
                                  </FSTableData>
                                  <FSTableData>
                                    <LineViewer
                                      param1='profitabilityEfficiencyRatio'
                                      param2='return_on_equity_c'
                                      value={companyFA}
                                    />
                                  </FSTableData>
                                  <FSTableData>&nbsp;</FSTableData>
                                  <FSTableData>&nbsp;</FSTableData>
                                  {/* LIQUIDITY */}
                                  <FSTableData>
                                    <LineViewer
                                      param1='cashFlowManagement'
                                      param2='cash_ratio_c'
                                      value={companyFA}
                                    />
                                  </FSTableData>
                                  <FSTableData>
                                    <LineViewer
                                      param1='cashFlowManagement'
                                      param2='quick_ratio_c'
                                      value={companyFA}
                                    />
                                  </FSTableData>
                                  <FSTableData>
                                    <LineViewer
                                      param1='cashFlowManagement'
                                      param2='current_ratio_c'
                                      value={companyFA}
                                    />
                                  </FSTableData>
                                  <FSTableData>&nbsp;</FSTableData>
                                  <FSTableData>&nbsp;</FSTableData>

                                  {/* LEVERAGE */}

                                  <FSTableData>
                                    <LineViewer
                                      param1='capitalStructure'
                                      param2='debt_to_equity_ratio_c'
                                      value={companyFA}
                                    />
                                  </FSTableData>
                                  <FSTableData>
                                    <LineViewer
                                      param1='capitalStructure'
                                      param2='debt_service_ratio_c'
                                      value={companyFA}
                                    />
                                  </FSTableData>
                                  <FSTableData>
                                    <LineViewer
                                      param1='capitalStructure'
                                      param2='gearing_ratio_c'
                                      value={companyFA}
                                    />
                                  </FSTableData>

                                  <FSTableData>&nbsp;</FSTableData>
                                  <FSTableData>&nbsp;</FSTableData>

                                  {/* EFFICIENCY */}
                                  <FSTableData>
                                    <LineViewer
                                      param1='cashFlowManagement'
                                      param2='day_receivables_c'
                                      value={companyFA}
                                    />
                                  </FSTableData>

                                  <FSTableData>
                                    <LineViewer
                                      param1='cashFlowManagement'
                                      param2='day_payables_c'
                                      value={companyFA}
                                    />
                                  </FSTableData>

                                  <FSTableData>
                                    <LineViewer
                                      param1='cashFlowManagement'
                                      param2='inventory_turnover_c'
                                      value={companyFA}
                                    />
                                  </FSTableData>

                                  <FSTableData>
                                    <LineViewer
                                      param1='cashFlowManagement'
                                      param2='cash_conversion_cycle_c'
                                      value={companyFA}
                                    />
                                  </FSTableData>

                                  <FSTableData>
                                    <LineViewer
                                      param1='profitabilityEfficiencyRatio'
                                      param2='sales_over_total_assets_c'
                                      value={companyFA}
                                    />
                                  </FSTableData>
                                </tr>
                              ))}
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}