import React, { useState, useEffect } from 'react';
import { Button, Table } from 'react-bootstrap';
import { useAuth } from '../../../hooks/auth';
import { useToast } from '../../components/ToastProvider';
import axios from '../../../lib/axios';
import { useNavigate } from 'react-router-dom';
import { KTCard } from '../../../_metronic/helpers';
import { PageLink, PageTitle } from '../../../_metronic/layout/core';

interface User {
  id: string;
  user_id: string;
  role: string;
  user: {
    id: number;
    name: string;
    email: string;

  };
}

const PageBreadCrumbs: Array<PageLink> = [
  {
    title: 'Internal User List',
    path: '/user/admin-all-internal-user',
    isSeparator: false,
    isActive: false,
  },
  {
    title: '',
    path: '',
    isSeparator: true,
    isActive: false,
  },

];

const AdminAllInternalUser: React.FC = () => {
  const navigate = useNavigate();
  const { loading, user } = useAuth('auth');
  const { showError, showSuccess } = useToast();
  const [users, setUsers] = useState<User[]>([]);
  const [selectedId, setSelectedId] = useState<string | null>(null);
  const [initialLoad, setInitialLoad] = useState(true);


  useEffect(() => {
    const fetchDataUser = async () => {
      try {
        const response = await axios.get('/api/internal-users');

        setUsers(response.data);
        if (initialLoad) {
          showSuccess('User data loaded successfully');
          setInitialLoad(false);
        }
      } catch (error) {
        showError('Error loading user data');
      }
    };

    fetchDataUser();
  }, [showSuccess, showError, initialLoad]);

  useEffect(() => {
    if (!loading && !['Super Admin', 'Data Analyst'].includes(user?.role ?? '')) {
      showError('You are not authorized to view this page');
      navigate('/unauthorized'); // Redirect to an unauthorized page
    }
  }, [loading, user, showError, navigate]);

  const navigateAdminAddUser = () => {
    navigate('/user/admin-add-internal-user', {
      state: { userId: selectedId }
    });
  };

  const navigateAdminViewUser = (userId) => {
    navigate('/user/admin-view-internal-user', {
      state: { userId: userId }
    });
  };
  const refreshData = async () => {
    try {
      const response = await axios.get('/api/internal-users');
      setUsers(response.data.data);
    } catch (error) {
      showError('Error loading user data');
    }
  };
  const TableHeader = () => (
    <thead>
      <tr>
        <th>ID</th>
        <th>Name</th>
        <th>Email</th>
        <th>Role</th>
        <th>Actions</th>
      </tr>
    </thead>
  );

  const TableBody = ({ users }: { users: User[] }) => {
    if (users && users.length > 0) {
      return (
        <tbody>
          {users.map((user) => (
            <tr key={user.id}>
              <td>{user.id}</td>
              <td>{user.user.name}</td>
              <td>{user.user.email}</td>
              <td>{user.role}</td>
              <td>
                <Button className="btn btn-success" onClick={() => navigateAdminViewUser(user.id)}>
                  <i className="fa-regular fa-hand"></i> Select
                </Button>
              </td>
            </tr>
          ))}
        </tbody>
      );
    } else {
      return (
        <tbody>
          <tr>
            <td colSpan={6}>No Users found</td>
          </tr>
        </tbody>
      );
    }
  };
  if (loading) {
    return <div>Loading...</div>;
  }

  return (
    <>

      <PageTitle breadcrumbs={PageBreadCrumbs}>{'Internal User List'}</PageTitle>

      <KTCard className="card-header">
        <div className="card">
          <div className='card-header'>
            <h3 className="card-title">
              List of internal users
            </h3>
            <div className="card-toolbar">
              <button className="btn btn-sm btn-primary" onClick={navigateAdminAddUser}>
                <i className="bi bi-plus"></i>
                <span className="ms-1">Add User</span>
              </button>
              <button className="btn btn-sm btn-warning" onClick={refreshData}>
                <i className="fa fa-refresh"></i>
                <span className="ms-1">Refresh</span>
              </button>
            </div>
          </div>
          <div className='card-body'>
            <Table className='table table-bordered'>
              <TableHeader />
              <TableBody users={users} />
            </Table>
          </div>
        </div>
      </KTCard>
    </>
  );
};

export default AdminAllInternalUser;